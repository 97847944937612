// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fonts{
    font-family: "Inter", sans-serif;
}

.heading-row{
    padding:10px
}

.heading{
    font-weight: 600;
    font-size: 22px;
}

.sub-heading{
    font-size: 15px;
    font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/styles/commonModal.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;AACpC;;AAEA;IACI;AACJ;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".fonts{\n    font-family: \"Inter\", sans-serif;\n}\n\n.heading-row{\n    padding:10px\n}\n\n.heading{\n    font-weight: 600;\n    font-size: 22px;\n}\n\n.sub-heading{\n    font-size: 15px;\n    font-weight: 500;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
