// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-1pvvkxv-MuiButtonBase-root-MuiAccordionSummary-root , .css-15v22id-MuiAccordionDetails-root{
    padding:0px !important
}

hr{
    margin:auto;
}

.make-grey{
    background: #F8F8F8;
}`, "",{"version":3,"sources":["webpack://./src/styles/accordian.css"],"names":[],"mappings":"AAAA;IACI;AACJ;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".css-1pvvkxv-MuiButtonBase-root-MuiAccordionSummary-root , .css-15v22id-MuiAccordionDetails-root{\n    padding:0px !important\n}\n\nhr{\n    margin:auto;\n}\n\n.make-grey{\n    background: #F8F8F8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
