// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.text-font{
    font-family: "Inter", sans-serif;
}


.main-div{
    height: 90%;
}

.button-container-com{
    margin-bottom: 10px;
}


.header{
    font-size: 24px;
    font-weight: 600;
}

.sub-heading{
    font-size: 14px;
    font-weight: 400;
}



.field-heading , .css-ahj2mt-MuiTypography-root{
    font-size: 14px !important;
    font-weight: 500 !important;
}

.back{
    box-shadow: none;
    border: 1px solid #E5E7EB !important;
    color: #374151 !important;
    text-decoration: none  !important;
    font-size: 16px;
    font-weight: 500;
    font-family: "Inter";
    text-transform: none !important;
    width: 49%;
}

.proceed{
    box-shadow: none;
   
    font-size: 16px;
    font-weight: 600;
    font-family: "Inter";
    text-transform: none !important;
    width: 49%;
}





`, "",{"version":3,"sources":["webpack://./src/styles/bankDetails.css"],"names":[],"mappings":";;AAEA;IACI,gCAAgC;AACpC;;;AAGA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;;AAGA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;;;AAIA;IACI,0BAA0B;IAC1B,2BAA2B;AAC/B;;AAEA;IACI,gBAAgB;IAChB,oCAAoC;IACpC,yBAAyB;IACzB,iCAAiC;IACjC,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,+BAA+B;IAC/B,UAAU;AACd;;AAEA;IACI,gBAAgB;;IAEhB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,+BAA+B;IAC/B,UAAU;AACd","sourcesContent":["\n\n.text-font{\n    font-family: \"Inter\", sans-serif;\n}\n\n\n.main-div{\n    height: 90%;\n}\n\n.button-container-com{\n    margin-bottom: 10px;\n}\n\n\n.header{\n    font-size: 24px;\n    font-weight: 600;\n}\n\n.sub-heading{\n    font-size: 14px;\n    font-weight: 400;\n}\n\n\n\n.field-heading , .css-ahj2mt-MuiTypography-root{\n    font-size: 14px !important;\n    font-weight: 500 !important;\n}\n\n.back{\n    box-shadow: none;\n    border: 1px solid #E5E7EB !important;\n    color: #374151 !important;\n    text-decoration: none  !important;\n    font-size: 16px;\n    font-weight: 500;\n    font-family: \"Inter\";\n    text-transform: none !important;\n    width: 49%;\n}\n\n.proceed{\n    box-shadow: none;\n   \n    font-size: 16px;\n    font-weight: 600;\n    font-family: \"Inter\";\n    text-transform: none !important;\n    width: 49%;\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
