import React, { useState, useEffect, useRef } from 'react';
import { Context } from './context.js';
import { TextField, InputAdornment, Checkbox, Slide, Button, Dialog, FormControlLabel, Radio, Alert, Select, MenuItem } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OtpVerificationModal from '../commonComponents/OtpVerificationModal.js';
import BottomStickModal from '../commonComponents/BottomStickModal.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../images/photoLogo/1592220663157 1.png'
import paymentLogo from '../images/downpaymentLogo/wallet-check.png'
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/button.css';
import person from '../images/person/Vector.png';
import bulb from '../images/bulb/Group.png';
import glass from '../images/glasses/glass.png';
import mute from '../images/muteLogo/volume-slash.png';
import { getStatus, verifyOtp, getAScoreData, verifyLiveliness, verifySelfie, getNachStatusUrl } from '../apis';
import Recorder from "../commonComponents/useRecorder";
import axios from 'axios';
import { validateToken , getPaymentStatus,getPageStatusUrl, updatePageStatusUrl } from '../apis';
import { convertDob, InputFieldsJson, stateConvertionMapping } from '../helpers/index.js';
import { useLocation } from 'react-router-dom';
import { States, Cities } from '../helpers/data.js';
import { pincodeData } from '../helpers/negativeAreaData.js';
import moment from "moment";
import BottomStickModal2 from '../commonComponents/BottomStickModal2.js';
import CameraCounter from '../commonComponents/cameraCounter.js';
import { offerDet, colenderSelectorV3, nachUrl, getXmlUi, getOfferData } from "../apis";
import nachLogo from '../images/nachLogo/nach_logo.svg';
import queryString from "query-string";



const validateData = (type, value) => {
    switch (type) {
        case "area":
        case "streetName":
        case "flatNo": {
            const string = /^.{1,40}$/;
            return string.test(value) && value?.length < 40;
        }
        //   case "streetNo": {
        //     const number = /^[0-9.]/;
        //     return number.test(value);
        //   }
        //   case "area": {
        //     const number = /^[0-9.]/;
        //     return number.test(value);
        //   }
        case "pincode": {
            const number = /^[0-9]{6}$/;
            return number.test(value);
        }
        case 'email': {
            const email = /^([a-z0-9]+(?:[._-][a-z0-9]+)*)@([a-z0-9]+(?:[.-][a-z0-9]+)*\.[a-z]{2,})$/i;
            return email.test(value) && value?.length <= 50;
        }
        case 'fatherName': {
            const name = /^[a-zA-Z ]{3,50}$/;
            return name.test(value);
        }
        case 'monthlyIncome': {
            const number = /^([0-9]*[.])?[0-9]{5,8}$/;
            return number.test(value) && value > 25000;
        }
        case 'ifsc': {
            const number = /^[A-Z]{4}[A-Z0-9]{7}$/;
            return number.test(value);
        }
        case 'account_number': {
            const number = /^\d{5,}$/;
            return number.test(value);
        }

        //#endregion
        default: {
            return true;
        }
    }
};


const ContextProvider = ({ children }) => {
    const location = useLocation();
    const mobileValidation = /^[1-9][0-9]*$/;
    const panValidation = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    const errorAdhaar = "Invalid Aadhaar Number";

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [request_id, setRequestId] = useState(searchParams.get('request_id') ? searchParams.get('request_id') :  null);
    const [encryptText, setEncryptText] = useState(searchParams.get('data') ? searchParams.get('data') : null);
    const [jsonPages, setJsonPages] = useState(null);
    const [mobileSuccessText, setMobileSuccessText] = useState(null);
    const [mobileErrorMessage, setMobileErrorMessag] = useState(null);
    const [mobile, setMobile] = useState({ error: "", value: "", text: mobileSuccessText });
    const [panErrorMessage, setPanErrorMessag] = useState(null);
    const [pan, setPan] = useState({ error: "", value: "", text: "", verified: false });
    const [dob, setDob] = useState({ error: "", value: "", text: "" });
    const [name, setName] = useState({ error: "", value: "", text: "" });
    const [adhaar, setAdhaar] = useState({ error: "", value: "", text: "", verified: false, request_id: "" });
    const [isChecked, setIsChecked] = useState([]);
    const [radios, setRadios] = useState(null)
    const [ifsc, setIfsc] = useState({ error: "", text: "", value: "" });
    const [bankName, setBankName] = useState({ error: "", text: "", value: "" });
    const [accountNum, setAccountNum] = useState({ error: "", text: "", value: "" });
    const [confirmAccountNum, setConfirmAccountNum] = useState({ error: "", text: "", value: "" });
    const [requiredFields, setRequiredFields] = useState([]);
    const [showHeader, setShowHeader] = useState(true);
    const [showFooter, setShowFooter] = useState(true);
    const [showOtp, setShowOtp] = useState(false);
    const [permissionProvided, setPermissionProvided] = useState(false);
    const [stage, setStage] = useState(0);
    const [openPermBox, setOpenPermBox] = useState(false);
    const [showSubmit, setShowSubmit] = useState(true);
    const [nextRoute, setNextRoute] = useState(null);
    const [prevRoute, setPrevRoute] = useState(null);
    const [currentRoute, setCurrentRoute] = useState("/");
    const [consent, setConsent] = useState(false);
    const [loading, setLoading] = useState(false)
    const [pageNum, setPageNum] = useState(-1);
    const [pollAttemps, setPollAttempts] = useState(0);
    const [errorAlert, setErrorAlert] = useState({ show: false, message: "" });
    const [showModal, setShowModal] = useState({ show: false, message: "" });
    const [retry, setRetry] = useState(false);
    const [aadharAddress, setAadharAddress] = useState("");
    const [img, setImg] = useState(null);
    const [currentAddress, setCurrentAddress] = useState({
        flatNo: '',
        streetName: '',
        area: '',
        pincode: '',
        city: '',
        state: '',
    });
    const [currentAddressError, setCurrentAddressError] = useState({
        flatNo: '',
        streetName: '',
        area: '',
        pincode: '',
        city: '',
        state: '',
    });
    const [cityOpt, setCityOpt] = useState([]);
    const [stateOpt, setStateOpt] = useState(States ?? []);
    const [leadData, setLeadData] = useState([]);
    const [stateData, setStateData] = useState({});
    const [stateDataValidation, setStateDataValidation] = useState({});
    const [showPincodeModal, setShowPincodeModal] = useState({ show: false, message: "", showRetryBtn: false });
    const [retryPincode, setRetryPincode] = useState(3);
    const [code, setCode] = useState(null);
    const [wrongOtp, setWrongOtp] = useState(false);
    const [rpsData, setRpsData] = useState(null);
    const imageVal = useRef(null);
    const bankResp = useRef(null);
    const queries = queryString.parse(window.location.search);
    const nachData = useRef(queries?.order_id || queries?.payment_id || queries?.subReference_id ? {
        subscriptionId : queries?.order_id?.toString(),
        paymentId : queries?.payment_id?.toString(),
        subReferenceId : queries?.subReference_id?.toString(),
    } : null)
    const [loadMsg, setLoadMsg] = useState(null);

    // useEffect(() => {
    //     if (location?.pathname === '/communication-details' && leadData?.pincode) {
    //         if (pincodeData.hasOwnProperty(`${leadData?.pincode}`)) {
    //             setShowPincodeModal({ ...{ show: true, message: `Our services are currently not available in your area (Pincode: ${leadData?.pincode})`, showRetryBtn: false } })
    //         }
    //     }
    // }, [leadData])

    const callRpsApi = async (offer_tenure) => {

        const rpsUrl = process.env.REACT_APP_SERVICE_URL + offerDet + `?request_id=${request_id}`;
        const user_payload = JSON.parse(localStorage.getItem("user_payload"));
        const payload = {
            tenure: parseFloat(offer_tenure),
            policy_premium: user_payload?.policy_premium_amount && parseFloat(user_payload.policy_premium_amount),
        }

        await axios.post(rpsUrl, payload, {
            headers: {
                "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
            }
        })
            .then((resp) => {
                if (resp?.status === 200 && resp?.data?.success) {
                    setRpsData(resp?.data);
                }
                else {
                    navigate('/error');

                }
            })
            .catch((e) => {
                console.log('Error : ', e?.response?.data);
                navigate('/error');

            })

    }

    const redirectToPayments = (paymentId) => {
        const cashfree = window.Cashfree({
            mode: process.env.NODE_ENV === 'production' ? 'production' : "sandbox", //"sandbox" //or production
        });

        let checkoutOptions = {
            paymentSessionId: `${paymentId}`,
            redirectTarget: "_self",
        }

        cashfree.checkout(checkoutOptions);
    }



    const invokePolling = async (pageType, pageName = "") => {
        return new Promise(async (resolve) => {
            try {
                let page = jsonPages?.find((page) => {
                    return page.currentRoute === location.pathname
                })

                if (!page) page = { id: pageName }

                let interval = "";
                if (pageType === 'kyc') {
                    const resp = await kycPollingAsync(page);
                    if (resp && resp.status == 2) interval = setInterval(() => kycPolling(page, interval, resolve), 5000);
                    else if (resp && resp.status == 1) return resolve({ status: true, data: resp.data });
                } else if (pageType == 'communication' || pageType == 'personalDetails') {
                    const resp = await commnunicationPollingAsync(page);
                    if (resp && resp.status == 2) interval = setInterval(() => communicationPolling(page, interval, resolve), 3000);
                    else if (resp && resp.status == 1) return resolve({ status: true, data: resp.data });
                } else if (pageType == 'landing') {
                    const resp = await leadPollingAsync(page);
                    if (resp && resp.status == 2) interval = setInterval(() => leadPolling("", interval, resolve), 5000);
                    else if (resp && resp.status == 1) return resolve({ status: true, data: resp.data });
                } else if (pageType == 'selfie_req') {
                    const resp = await selfiePollingAsync(page);
                    if (resp && resp.status == 2) interval = setInterval(() => selfiePolling("", interval, resolve), 5000);
                } else if (pageType == 'selfie_check') {
                    const resp = await selfieCheckPollingAsync(page);
                    if (resp && resp.status == 2) interval = setInterval(() => selfieCheckPolling("", interval, resolve), 5000);
                } else if (pageType == 'verifyotp') {
                    const resp = await otpPollingAsync(page);
                    if (resp && resp.status == 2) interval = setInterval(() => otpPolling(page, interval, resolve), 3000);
                    else return resolve({ status: false, data: resp })
                } else if (pageType == 'ascore_request') {
                    const resp = await ascorePollingAsync(page);
                    if (resp && resp.status == 2) interval = setInterval(() => ascorePolling(page, interval, resolve), 3000);
                    else if (resp && resp.status == 1) return resolve({ status: true, data: resp.data });
                    else return resolve({ status: false, data: resp.data })
                } else if (pageType == 'ascore_check') {
                    const resp = await ascoreCheckPollingAsync(page);
                    if (resp && resp.status == 2) interval = setInterval(() => ascoreCheckPolling(page, interval, resolve), 3000);
                    else if (resp && resp.status == 1) return resolve({ status: true, data: resp.data });
                    else return resolve({ status: false, data: resp.data })
                } else if (pageType == 'co_lender_selector_v3') {
                    const resp = await coLenderPollingAsync(page);
                    if (resp && resp.status == 2) interval = setInterval(() => coLenderPolling(page, interval, resolve), 3000);
                    else if (resp && resp.status == 1) return resolve({ status: true, data: resp.data });
                    else return resolve({ status: false, data: resp.data })
                }
                else if (pageType == 'bank_verify_penny_drop') {
                    const resp = await pennyDropPollingAsync(page);
                    if (resp && resp.status == 2) interval = setInterval(() => pennyDropPolling(page, interval, resolve), 3000);
                    else if (resp && resp.status == 1) return resolve({ status: true, data: resp.data });
                    else return resolve({ status: false, data: resp.data })
                }
                else if (pageType == 'bank_verify_name_match') {
                    const resp = await bankVerifyNameMatchPollingAsync(page);
                    if (resp && resp.status == 2) interval = setInterval(() => bankVerifyNameMatchPolling(page, interval, resolve), 2000);
                    else if (resp && resp.status == 1) return resolve({ status: true, data: resp.data });
                    else return resolve({ status: false, data: resp.data })
                }
                else if (pageType == 'bank_verify_loan_creation') {
                    const resp = await loanCreationPollingAsync(page);
                    if (resp && resp?.status == 2) interval = setInterval(() => loanCreationPolling(page, interval, resolve), 3000);
                    else if (resp && resp.status == 1) return resolve({ status: true, data: resp?.data });
                    else return resolve({ status: false, data: resp?.data })
                } else if (pageType === 'selfie_upload') {
                    const resp = await selfieUploadPollingAsync();
                    if (resp && resp.status == 2) interval = setInterval(() => selfieUploadPolling(interval, resolve), 3000);
                    else if (resp && resp.status == 1) return resolve({ status: true, data: resp.data });
                    else return resolve({ status: false, data: resp.data })

                }
                else if (pageType == 'bank_verify_rps_save') {
                    const resp = await rpsCreationPollingAsync(page);
                    if (resp && resp?.status == 2) interval = setInterval(() => rpsCreationPolling(page, interval, resolve), 3000);
                    else if (resp && resp.status == 1) return resolve({ status: true, data: resp?.data });
                    else return resolve({ status: false, data: resp?.data })
                }
                else if (pageType == 'create_nach_subscription') {
                    const resp = await createSubscriptionPollingAsync(page, pageName);
                    if (resp && resp?.status == 2) interval = setInterval(() => createSubscriptionPolling(page, interval, resolve, pageName), 3000);
                    else if (resp && resp.status == 1) return resolve({ status: true, data: resp?.data });
                    else return resolve({ status: false, data: resp?.data })
                }
                else if (pageType == 'get_nach_status') {
                    const resp = await nachStatusPollingAsync(page, pageName);
                    if (resp && resp?.status == 2) interval = setInterval(() => nachStatusPolling(page, interval, resolve, pageName), 3000);
                    else if (resp && resp.status == 1) return resolve({ status: true, data: resp?.data });
                    else return resolve({ status: false, data: resp?.data })
                }
                else if (pageType == 'payments_create_order') {
                    const resp = await createOrderPollingAsync(page,pageName);
                    if (resp && resp?.status == 2) interval = setInterval(() => createOrderPolling(page, interval, resolve, pageName), 3000);
                    else if (resp && resp.status == 1) return resolve({ status: true, data: resp?.data });
                    else return resolve({ status: false, data: resp?.data })
                }
                else if (pageType == 'get_down_payment_status') {
                    const resp = await downPayStatusPollingAsync(page, pageName);
                    if (resp && resp?.status == 2) interval = setInterval(() => downPayStatusPolling(page, interval, resolve, pageName), 2000);
                    else if (resp && resp.status == 1) return resolve({ status: true, data: resp?.data });
                    else return resolve({ status: false, data: resp?.data })
                } else if (pageType == 'check_subscription_created') {
                    const resp = await checkSubscriptionCreatedPollingAsync(page, pageName);
                    if (resp && resp?.status == 2) interval = setInterval(() => checkSubscriptionCreatedPolling(page, interval, resolve, pageName), 2000);
                    else if (resp && resp.status == 1) return resolve({ status: 1, data: resp?.data });
                    else return resolve({ status: resp.status, data: resp?.data })
                } else if (pageType == 'check_order_created') {
                    const resp = await checkOrderCreatedPollingAsync(page, pageName);
                    if (resp && resp?.status == 2) interval = setInterval(() => checkOrderCreatedPolling(page, interval, resolve, pageName), 2000);
                    else if (resp && resp.status == 1) return resolve({ status: 1, data: resp?.data });
                    else return resolve({ status: resp.status, data: resp?.data })
                }


            } catch (e) {
                console.log(e)
                resolve({ status: false, data: [] });
                // navigate('/error');
                setLoading(false);
            }
        })


    }

    const selfieCheckPollingAsync = async () => {
        try {
            if (!loading) setLoading(true);
            let panNum = pan && pan.value ? pan.value : JSON.parse(localStorage.getItem("user_payload")).pan;
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL + getStatus + `?pageName=livenessCheck&pan=${panNum}&nodeName=LIVENESS_CHECK`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });
            if (resp && resp.status === 200) {
                if (resp.data.status.toLowerCase() == 'success') {
                    setPollAttempts(0);
                    selfieUpload();
                } else if (resp.data.status.toLowerCase() === 'initiated') {
                    return { status: 2 };
                } else if (resp.data.status.toLowerCase() === 'failure') {
                    setRetry(true);
                    setLoading(false);
                    setPollAttempts(0);
                    navigate('/error');
                    return { status: 3 };
                }
            } else {
                setPollAttempts(0);
                throw new Error("Technical issue");
            }
        } catch (e) {
            console.log(e)
            navigate('/error');
            setLoading(false);
            setPollAttempts(0);
        }
    }

    const selfieUpload = async () => {
        try {
            if (!loading) setLoading(true);

            const payload = JSON.parse(localStorage.getItem("user_payload"));
            const body = {
                "context": {
                    "partner_loan_app_id": payload.partner_loan_app_id,
                    "partner_borrower_id": payload.partner_borrower_id,
                    "loan_app_id": payload.loan_app_id,
                    "borrower_id": payload.borrower_id,
                    "code": "003",
                    "pan": payload && payload.pan,
                    "base64pdfencodedfile": img ?? imageVal?.current,
                }
            }

            imageVal.current = null;

            const urlImg = process.env.REACT_APP_SERVICE_URL + verifySelfie + `?encryptText=${encryptText}`;
            debugger;
            const resp = await axios.post(urlImg, body, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });
            if (resp && resp.status === 200) {
                setLoading(false);
                setPollAttempts(0);
                await updatePageStatus();
                let pageDetails = jsonPages?.find((page) => {
                    return page?.currentRoute === location?.pathname
                }); 
                navigate(pageDetails?.nextRoute?.trim() ?? '/bank-details')
            }
        } catch (e) {
            console.log(e);
            navigate('/error');
            setLoading(false);
            setPollAttempts(0);
        }
    }

    const selfieUploadPollingAsync = async () => {

    }

    const selfieUploadPolling = async (interval, resolve) => {

    }

    const selfieCheckPolling = (page, interval, resolve) => {
        if (!loading) setLoading(true);
        let panNum = pan && pan.value ? pan.value : JSON.parse(localStorage.getItem("user_payload")).pan;
        axios.get(process.env.REACT_APP_SERVICE_URL + getStatus + `?pageName=livenessCheck&pan=${panNum}&nodeName=LIVENESS_CHECK`, {
            headers: {
                "api-key": `Bearer ${request_id}`
            }
        })
            .then((resp) => {
                if (resp && resp.status === 200) {
                    if (resp.data.status.toLowerCase() == 'success') {
                        clearInterval(interval);
                        selfieUpload();
                    } else if (resp.data.status.toLowerCase() === 'initiated') {
                        setPollAttempts((prevState) => {
                            if (prevState === 2) {
                                setPollAttempts(0);
                                clearInterval(interval)
                                setLoading(false);
                                navigate('/error');
                                resolve({ status: false, data: resp.data.queueDetails })
                            }
                            else return prevState + 1;
                        });
                    } else if (resp.data.status.toLowerCase() === 'failure') {
                        setRetry(true);
                        setPollAttempts(0);
                        clearInterval(interval)
                        setLoading(false);
                        navigate('/error');
                        resolve({ status: false, data: resp.data.queueDetails });
                    }
                } else {
                    setPollAttempts(0);
                    throw new Error("Technical issue");
                }
            })
            .catch((e) => {
                console.log(e)
                navigate('/error');
                setLoading(false);
                setPollAttempts(0);
                clearInterval(interval);
            })
    }

    const leadPolling = (page, interval, resolve) => {
        if (!loading) setLoading(true);
        const user_payload = JSON.parse(localStorage.getItem("user_payload"));
        let panNum = pan && pan.value ? pan.value : JSON.parse(localStorage.getItem("user_payload")).pan;
        axios.get(process.env.REACT_APP_SERVICE_URL + getStatus +
            `?pageName=startPageDetails&pan=${panNum}`, {
            headers: {
                "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
            }
        })
            .then((resp) => {
                if (resp && resp.status === 200) {
                    if (resp.data.status.toLowerCase() == 'success') {
                        clearInterval(interval);
                        setLoading(false);
                        resolve({ status: true, data: "" })
                    } else if (resp.data.status.toLowerCase() === 'initiated') {
                        setPollAttempts((prevState) => {
                            if (prevState === 2) {
                                setPollAttempts(0);
                                clearInterval(interval)
                                setLoading(false);
                                navigate('/error');
                                resolve({ status: false, data: resp.data.queueDetails })
                            }
                            else return prevState + 1;
                        });
                    } else if (resp.data.status.toLowerCase() === 'failure') {
                        setPollAttempts(0);
                        clearInterval(interval)
                        setLoading(false);
                        resolve({ status: false, data: resp.data.queueDetails });
                    }

                } else {
                    setPollAttempts(0);
                    throw new Error("Technical issue");
                }

            })
            .catch((e) => {
                console.log('Error : ', e?.response?.data)
                navigate('/error');
                clearInterval(interval);
                setLoading(false);
                setPollAttempts(0);
            })
    }


    const kycPolling = (page, interval, resolve) => {
        axios.get(process.env.REACT_APP_SERVICE_URL + getStatus +
            `?pageName=${page?.id?.trim()}&pan=${pan.value}&nodeName=${pan.verified ? 'aadhaarvalidation' : "panvalidation"}`, {
            headers: {
                "api-key": `Bearer ${request_id}`
            }
        })
            .then((resp) => {
                if (resp && resp.status === 200) {
                    if (resp?.data?.queueDetails?.at(0)?.status.toLowerCase() == 'success') {
                        if (!pan.verified) {
                            let panObj = pan;
                            panObj.verified = true
                            setPan({ ...panObj });
                        } else if (!adhaar.verified) {

                            let request_id = resp.data.queueDetails[0].resBody.data.requestId;
                            let adhaarObj = adhaar;
                            adhaarObj.verified = true;
                            adhaarObj.request_id = request_id;
                            setLoading(false);
                            setAdhaar({ ...adhaarObj });
                            setShowOtp(true)
                        }
                        clearInterval(interval);
                        resolve({ status: true, data: resp.data.queueDetails });
                    } else if (resp?.data?.queueDetails?.at(0)?.status.toLowerCase() === 'initiated') {
                        setPollAttempts((prevState) => {
                            if (prevState === 15) {
                                setPollAttempts(0);
                                clearInterval(interval)
                                setLoading(false);
                                navigate('/error');
                                resolve({ status: false, data: resp.data.queueDetails });
                                return 0;
                            }
                            else return prevState + 1;
                        });
                    } else if (resp?.data?.queueDetails?.at(0)?.status.toLowerCase() === 'failure') {
                        clearInterval(interval);
                        setLoading(false);
                        setPollAttempts(0);
                        navigate('/error');
                        resolve({ status: false, data: resp.data.queueDetails });
                    }

                } else {
                    setPollAttempts(0);
                    throw new Error("Technical issue");
                }

            })
            .catch((e) => {
                console.log("Error : ", e?.response?.data)
                resolve({ status: false, data: [] });
                navigate('/error');
                clearInterval(interval);
                setLoading(false);
                setPollAttempts(0);
                resolve({ status: false, data: [] });
            })
    }

    const selfiePolling = (page, interval, resolve) => {
        if (!loading) setLoading(true);
        let panNum = pan && pan.value ? pan.value : JSON.parse(localStorage.getItem("user_payload")).pan;
        axios.get(process.env.REACT_APP_SERVICE_URL + getStatus + `?pageName=livenessCheck&pan=${panNum}&nodeName=LIVENESS_REQUEST`, {
            headers: {
                "api-key": `Bearer ${request_id}`
            }
        })
            .then((resp) => {
                if (resp && resp.status === 200) {
                    if (resp.data.status.toLowerCase() == 'success') {
                        clearInterval(interval);
                        invokePolling("selfie_check")
                        resolve({ status: true, data: "" })
                    } else if (resp.data.status.toLowerCase() === 'initiated') {
                        setPollAttempts((prevState) => {
                            if (prevState === 2) {
                                clearInterval(interval)
                                setLoading(false);
                                navigate('/error');
                                resolve({ status: false, data: resp.data.queueDetails })
                                return 0;
                            }
                            else return prevState + 1;
                        });
                    } else if (resp.data.status.toLowerCase() === 'failure') {
                        setRetry(true);
                        setPollAttempts(0);
                        clearInterval(interval)
                        setLoading(false);
                        resolve({ status: false, data: resp.data.queueDetails });
                    }
                } else {
                    setPollAttempts(0);
                    throw new Error("Technical issue");
                }
            })
            .catch((e) => {
                console.log(e)
                navigate('/error');
                setLoading(false);
                setPollAttempts(0);
                clearInterval(interval);
            })
    }


    const communicationPolling = async (page, interval, resolve) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${page?.id?.trim()}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'update_lead'
                )
                : null;

            if (resp?.data?.status?.toLowerCase() === 'success') {
                clearInterval(interval);
                // setLoading(false);
                // navigate(page?.nextRoute);
                resolve({ status: true, data: resp?.data });
            } else if (resp?.data?.status?.toLowerCase() === 'initiated') {
                setPollAttempts((prevState) => {
                    if (prevState === 10) {
                        // setPollAttempts(0);
                        clearInterval(interval)
                        setLoading(false);
                        navigate('/error');
                        resolve({ status: false, data: resp?.data });
                        return 0;
                    }
                    else return prevState + 1;
                });
            } else if (resp?.data?.status?.toLowerCase() === 'failure') {
                clearInterval(interval);
                setLoading(false);
                setPollAttempts(0);
                navigate('/error');

                resolve({ status: false, data: resp?.data });
            }
        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate('/error');

            clearInterval(interval);
            setLoading(false);
            setPollAttempts(0);
            resolve({ status: false, data: [] });

        }
    }

    const leadPollingAsync = async (page) => {
        try {
            if (!loading) setLoading(true);
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            let panNum = pan && pan.value ? pan.value : JSON.parse(localStorage.getItem("user_payload")).pan;
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL + getStatus + `?pageName=startPageDetails&pan=${panNum}`, {
                headers: {
                    "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
                }
            });
            if (resp && resp.status === 200) {
                if (resp.data.status.toLowerCase() == 'success') {
                    setLoading(false);
                    return { status: 1, data: resp.data.queueDetails };
                } else if (resp.data.status.toLowerCase() === 'initiated') {
                    return { status: 2 };
                } else if (resp.data.status.toLowerCase() === 'failure') {
                    setLoading(false);
                    setPollAttempts(0);
                    navigate('/error');
                    return { status: 3 };
                }
            } else {
                setPollAttempts(0);
                throw new Error("Technical issue");
            }
        } catch (e) {
            console.log(e)
            navigate('/error');
            setLoading(false);
            setPollAttempts(0);
        }
    }

    const kycPollingAsync = async (page) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL + getStatus + `?pageName=${page?.id?.trim()}&pan=${user_payload?.pan ?? pan.value}&nodeName=${pan.verified ? 'aadhaarvalidation' : "panvalidation"}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });
            if (resp && resp.status === 200) {
                if (resp?.data?.queueDetails?.at(0)?.status.toLowerCase() == 'success') {
                    if (!pan.verified) {
                        let panObj = pan;
                        panObj.verified = true
                        setPan({ ...panObj });
                    } else if (!adhaar.verified) {

                        let request_id = resp.data.queueDetails[0].resBody.data.requestId;
                        let adhaarObj = adhaar;
                        adhaarObj.verified = true;
                        adhaarObj.request_id = request_id;
                        setLoading(false);
                        setAdhaar({ ...adhaarObj });
                        setShowOtp(true)
                    }
                    return { status: 1, data: resp.data.queueDetails };
                } else if (resp?.data?.queueDetails?.at(0)?.status.toLowerCase() === 'initiated') {
                    return { status: 2 }
                } else if (resp?.data?.queueDetails?.at(0)?.status.toLowerCase() === 'failure') {
                    setLoading(false);
                    setPollAttempts(0);
                    navigate('/error');
                    return { status: 3 };
                }
            } else {
                setPollAttempts(0);
                throw new Error("Technical issue");
            }
        } catch (e) {
            console.log(e)
            navigate('/error');
            setLoading(false);
            setPollAttempts(0);
            return { status: 4 };
        }
    }

    const commnunicationPollingAsync = async (page) => {
        try {
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL + getStatus + `?pan=${pan?.value}&pageName=${page?.id?.trim()}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'update_lead'
                )
                : null;

            if (resp?.data?.status?.toLowerCase() === 'success') {
                return { status: 1, data: resp?.data };
            } else if (resp?.data?.status?.toLowerCase() === 'initiated') {
                return { status: 2 };
            } else if (resp?.data?.status?.toLowerCase() === 'failure') {
                setLoading(false);
                navigate('/error');

                return { status: 3, data: resp?.data };
            } else {
                return { status: 2 };
            }
        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate('/error');

            setLoading(false);
            return { status: 4, data: [] };
        }
    }

    const coLenderPolling = async (page, interval, resolve) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${page?.id}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'co_lender_selector_v3'
                )
                : null;

            if (queueData?.status?.toLowerCase() === 'success') {
                clearInterval(interval);
                resolve({ status: true, data: resp?.data });
            } else if (queueData?.status?.toLowerCase() === 'initiated') {
                setPollAttempts((prevState) => {
                    if (prevState === 10) {
                        clearInterval(interval)
                        setLoading(false);
                        navigate('/error');;
                        resolve({ status: false, data: resp?.data });
                        setPollAttempts(0)
                    } else return prevState + 1;
                });
            } else if (queueData?.status?.toLowerCase() === 'failure') {
                clearInterval(interval);
                setLoading(false);
                setPollAttempts(0);
                navigate('/error');
                resolve({ status: false, data: resp?.data });
            }


        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate('/error');
            clearInterval(interval);
            setLoading(false);
            setPollAttempts(0);
            resolve({ status: false, data: [] });
        }
    }

    const ascoreCheckPollingAsync = async (page) => {
        //debugger
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${page?.id}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });

            const queueDataAscoreStatusCheck = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'ascore_status_check'
                )
                : null;


            if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'success') {
                return { status: 1, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'initiated') {
                return { status: 2, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'failure') {
                setLoading(false);
                navigate('/error');
                return { status: 3, data: resp?.data };
            }

        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate('/error');
            setLoading(false);
            return { status: 4, data: [] };

        }
    }

    const ascoreCheckPolling = async (page, interval, resolve) => {

        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${page?.id}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });

            const queueDataAscoreStatusCheck = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'ascore_status_check'
                )
                : null;


            if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'success') {
                clearInterval(interval);
                resolve({ status: true, data: resp?.data });
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'initiated') {
                setPollAttempts((prevState) => {
                    if (prevState === 10) {
                        clearInterval(interval)
                        setLoading(false);
                        navigate('/error');;
                        resolve({ status: false, data: resp?.data });
                        return 0;
                    }
                    else return prevState + 1;
                });
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'failure') {
                clearInterval(interval);
                setLoading(false);
                setPollAttempts(0);
                navigate('/error');
                resolve({ status: false, data: resp?.data });
            }

        } catch (e) {

            console.log('Error : ', e?.response?.data)
            navigate('/error');
            clearInterval(interval);
            setLoading(false);
            setPollAttempts(0);
            resolve({ status: false, data: [] });

        }
    }

    const ascorePollingAsync = async (page) => {
        //debugger
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));

            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${page?.id}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'ascore_request'
                )
                : null;


            if (queueData?.status?.toLowerCase() === 'success') {
                const respfromStatusCheck = await invokePolling("ascore_check", page.id);
                return { status: respfromStatusCheck?.status ? 1 : 3, data: respfromStatusCheck?.data };
            } else if (queueData?.status?.toLowerCase() === 'initiated') {
                return { status: 2, data: resp?.data };
            } else if (queueData?.status?.toLowerCase() === 'failure') {
                setLoading(false);
                navigate('/error');
                return { status: 3, data: resp?.data };
            }

        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate('/error');
            setLoading(false);
            return { status: 4, data: [] };
        }
    }

    const ascorePolling = (page, interval, resolve) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${page?.id}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'ascore_request'
                )
                : null;


            if (queueData?.status?.toLowerCase() === 'success') {
                setPollAttempts(0);
                clearInterval(interval);
                const respfromStatusCheck = invokePolling("ascore_check");
                resolve({ status: respfromStatusCheck?.status ? 1 : 3, data: respfromStatusCheck?.data });
            } else if (queueData?.status?.toLowerCase() === 'initiated') {
                setPollAttempts((prevState) => {
                    if (prevState === 10) {
                        clearInterval(interval)
                        setLoading(false);
                        navigate('/error');;
                        resolve({ status: false, data: resp?.data });
                        return 0;
                    }
                    else return prevState + 1;
                });
            } else if (queueData?.status?.toLowerCase() === 'failure') {
                clearInterval(interval);
                setLoading(false);
                setPollAttempts(0);
                navigate('/error');
                resolve({ status: false, data: resp?.data });
            }

        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate('/error');
            clearInterval(interval);
            setLoading(false);
            setPollAttempts(0);
            resolve({ status: false, data: [] });
        }
    }

    const coLenderPollingAsync = async (page) => {
        //debugger
        try {
            //debugger
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${page?.id}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });

            const queueDataAscoreStatusCheck = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'co_lender_selector_v3'
                )
                : null;


            if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'success') {
                return { status: 1, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'initiated') {
                return { status: 2, data: resp?.data, page: page.id };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'failure') {
                setLoading(false);
                navigate('/error');
                return { status: 3, data: resp?.data };
            }

        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate('/error');
            setLoading(false);
            return { status: 4, data: [] };

        }
    }

    const pennyDropPolling = async (page, interval, resolve) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${page?.id}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            })

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'bankvalidation'
                )
                : null;

            if (queueData?.status?.toLowerCase() === 'success') {
                clearInterval(interval);
                // setLoading(false);
                // navigate(page?.nextRoute);
                const finalResp = await invokePolling('bank_verify_name_match')
                resolve({ status: finalResp?.status, data: finalResp?.data });
            } else if (queueData?.status?.toLowerCase() === 'initiated') {
                setPollAttempts((prevState) => {
                    if (prevState === 10) {
                        // setPollAttempts(0);
                        clearInterval(interval)
                        setLoading(false);
                        navigate('/error');
                        resolve({ status: false, data: resp?.data });
                        return 0;
                    }
                    else return prevState + 1;
                });
            } else if (queueData?.status?.toLowerCase() === 'failure') {
                clearInterval(interval);
                setLoading(false);
                setPollAttempts(0);
                if (!queueData?.resBody?.data?.result?.bankTxnStatus || !queueData?.resBody?.success) {
                    setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account number you shared is inactive. Please enter details of an active bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                }
                else {
                    navigate('/error');
                }
                resolve({ status: false, data: resp?.data });
            }


        } catch (e) {
            console.log('Error : ', e?.response?.data)

            navigate('/error')
            clearInterval(interval);
            setLoading(false);
            setPollAttempts(0);
            resolve({ status: false, data: [] });
        }
    }

    const pennyDropPollingAsync = async (page) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${page?.id}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'bankvalidation'
                )
                : null;


            if (queueData?.status?.toLowerCase() === 'success') {
                const resp2 = await invokePolling("bank_verify_name_match");
                return { status: resp2?.status, data: resp2?.data };
            } else if (queueData?.status?.toLowerCase() === 'initiated') {
                return { status: 2, data: resp?.data };
            } else if (queueData?.status?.toLowerCase() === 'failure') {
                setLoading(false);
                if (!queueData?.resBody?.data?.result?.bankTxnStatus || !queueData?.resBody?.success) {
                    setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account number you shared is inactive. Please enter details of an active bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                }
                else {

                    navigate('/error')
                }
                return { status: 3, data: resp?.data };
            }

        } catch (e) {
            console.log('Error : ', e?.response?.data)

            navigate('/error');
            setLoading(false);
            return { status: 4, data: [] };

        }
    }

    const bankVerifyNameMatchPolling = async (page, interval, resolve) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${page?.id}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'namematching'
                )
                : null;

            if (queueData?.status?.toLowerCase() === 'success') {
                clearInterval(interval);
                // setLoading(false);
                // navigate(page?.nextRoute);
                setStateData(prev => ({
                    ...prev,
                    "bankHolderName": queueData?.reqBody?.kyc_name,
                }));
                resolve({ status: true, data: resp?.data });
            } else if (queueData?.status?.toLowerCase() === 'initiated') {
                setPollAttempts((prevState) => {
                    if (prevState === 15) {
                        // setPollAttempts(0);
                        clearInterval(interval)
                        setLoading(false);
                        ;
                        navigate('/error');
                        resolve({ status: false, data: resp?.data });
                        return 0;
                    }
                    else return prevState + 1;
                });
            } else if (queueData?.status?.toLowerCase() === 'failure') {

                clearInterval(interval);
                setLoading(false);
                setPollAttempts(0);
                if (!queueData?.resBody?.data?.result?.result || queueData?.resBody?.data?.result?.result === 'false') {
                    setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account holder's name does not match the name as per PAN. Please try again with another bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                }
                else {
                    navigate('/error')
                }
                resolve({ status: false, data: resp?.data });
            }


        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate('/error')
            clearInterval(interval);
            setLoading(false);
            setPollAttempts(0);
            resolve({ status: false, data: [] });
        }
    }

    const bankVerifyNameMatchPollingAsync = async (page) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${page?.id}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'namematching'
                )
                : null;


            if (queueData?.status?.toLowerCase() === 'success') {
                setStateData(prev => ({
                    ...prev,
                    "bankHolderName": queueData?.reqBody?.kyc_name,
                }));
                return { status: 1, data: resp?.data };
            } else if (queueData?.status?.toLowerCase() === 'initiated') {
                return { status: 2, data: resp?.data };
            } else if (queueData?.status?.toLowerCase() === 'failure') {
                setLoading(false);
                if (!queueData?.resBody?.data?.result?.result) {
                    setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account holder's name does not match the name as per PAN. Please try again with another bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                }
                else {
                    navigate('/error')
                }
                return { status: 3, data: resp?.data };
            }

        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate('/error')
            setLoading(false);
            return { status: 4, data: [] };

        }
    }

    const loanCreationPolling = async (page, interval, resolve) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${page?.id}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'loancreation'
                )
                : null;

            if (queueData?.status?.toLowerCase() === 'success') {
                clearInterval(interval);
                // setLoading(false);
                // navigate(page?.nextRoute);
                resolve({ status: true, data: resp?.data });
            } else if (queueData?.status?.toLowerCase() === 'initiated') {
                setPollAttempts((prevState) => {
                    if (prevState === 15) {
                        // setPollAttempts(0);
                        clearInterval(interval)
                        setLoading(false);
                        navigate('/error')
                        resolve({ status: false, data: resp?.data });
                        return 0;
                    }
                    else return prevState + 1;
                });
            } else if (queueData?.status?.toLowerCase() === 'failure') {
                if (queueData?.resBody?.message !== "EX_LOAN_017 Loan Application ID already exists. Kindly verify if correct loan application ID has been entered.") {
                    clearInterval(interval);
                    setLoading(false);
                    setPollAttempts(0);
                    navigate('/error')
                    resolve({ status: false, data: resp?.data });
                } else {
                    clearInterval(interval);
                    // setLoading(false);
                    // navigate(page?.nextRoute);
                    resolve({ status: true, data: resp?.data });
                }
            }


        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate('/error')
            clearInterval(interval);
            setLoading(false);
            setPollAttempts(0);
            resolve({ status: false, data: [] });
        }
    }

    const loanCreationPollingAsync = async (page) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${page?.id}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });

            const queueDataAscoreStatusCheck = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'loancreation'
                )
                : null;


            if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'success') {
                return { status: 1, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'initiated') {
                return { status: 2, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'failure') {

                if (queueDataAscoreStatusCheck?.resBody?.message !== "EX_LOAN_017 Loan Application ID already exists. Kindly verify if correct loan application ID has been entered.") {
                    setLoading(false);
                    navigate('/error')
                    return { status: 3, data: resp?.data };
                } else {
                    return { status: 1, data: resp?.data };
                }
            }

        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate('/error')
            setLoading(false);
            return { status: 4, data: [] };

        }
    }

    const rpsCreationPolling = async (page, interval, resolve) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${page?.id}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'rpscreation'
                )
                : null;

            if (queueData?.status?.toLowerCase() === 'success') {
                clearInterval(interval);
                // setLoading(false);
                // navigate(page?.nextRoute);
                resolve({ status: true, data: resp?.data });
            } else if (queueData?.status?.toLowerCase() === 'initiated') {
                setPollAttempts((prevState) => {
                    if (prevState === 10) {
                        // setPollAttempts(0);
                        clearInterval(interval)
                        setLoading(false);
                        navigate('/error')
                        resolve({ status: false, data: resp?.data });
                        return 0;
                    }
                    else return prevState + 1;
                });
            } else if (queueData?.status?.toLowerCase() === 'failure') {
                clearInterval(interval);
                setLoading(false);
                setPollAttempts(0);
                navigate('/error')
                resolve({ status: false, data: resp?.data });
            }


        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate('/error')
            clearInterval(interval);
            setLoading(false);
            setPollAttempts(0);
            resolve({ status: false, data: [] });
        }
    }

    const rpsCreationPollingAsync = async (page) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${page?.id}`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });

            const queueDataAscoreStatusCheck = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'rpscreation'
                )
                : null;


            if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'success') {
                return { status: 1, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'initiated') {
                return { status: 2, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'failure') {
                setLoading(false);
                navigate('/error')
                return { status: 3, data: resp?.data };
            }

        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate('/error')
            setLoading(false);
            return { status: 4, data: [] };

        }
    }

    const createSubscriptionPolling = async (page, interval, resolve, pageName) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${pageName}`, {
                headers: {
                    "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'createsubscription'
                )
                : null;

            if (queueData?.status?.toLowerCase() === 'success') {
                clearInterval(interval);
                // setLoading(false);
                // navigate(page?.nextRoute);
                resolve({ status: true, data: resp?.data });
            } else if (queueData?.status?.toLowerCase() === 'initiated') {
                setPollAttempts((prevState) => {
                    if (prevState === 10) {
                        // setPollAttempts(0);
                        clearInterval(interval)
                        setLoading(false);
                        navigate('/error')
                        resolve({ status: false, data: resp?.data });
                        return 0;
                    }
                    else return prevState + 1;
                });
            } else if (queueData?.status?.toLowerCase() === 'failure') {
                clearInterval(interval);
                setLoading(false);
                setPollAttempts(0);
                navigate("/error");
                resolve({ status: false, data: resp?.data });
            }


        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate("/error");
            clearInterval(interval);
            setLoading(false);
            setPollAttempts(0);
            resolve({ status: false, data: [] });
        }
    }

    const createSubscriptionPollingAsync = async (page, pageName) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${pageName}`, {
                headers: {
                    "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
                }
            });

            const queueDataAscoreStatusCheck = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'createsubscription'
                )
                : null;


            if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'success') {
                return { status: 1, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'initiated') {
                return { status: 2, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'failure') {
                setLoading(false);
                navigate("/error");
                return { status: 3, data: resp?.data };

            }

        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate("/error");
            setLoading(false);
            return { status: 4, data: [] };

        }
    }

    const nachStatusPolling = async (page, interval, resolve, pageName) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${ pageName ?? 'PAYMENTS'}`, {
                headers: {
                    "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'getsubscriptionstatus'
                )
                : null;

            if (queueData?.status?.toLowerCase() === 'success') {
                clearInterval(interval);
                // setLoading(false);
                // navigate(page?.nextRoute);
                // await checkNachTransactionStatus(queueData?.resBody?.payment_status?.toLowerCase(), queueData);
                // if (queueData?.resBody?.payment_status?.toLowerCase() === "success") {
                //     resolve({ status: true, data: resp?.data });
                // } else {
                //     navigate('/error');
                // }
                resolve({ status: true, data: resp?.data });
            } else if (queueData?.status?.toLowerCase() === 'initiated') {
                setPollAttempts((prevState) => {
                    if (prevState === 10) {
                        clearInterval(interval)
                        setLoading(false);
                        navigate("/error");;
                        resolve({ status: false, data: resp?.data });
                        return 0;
                    }
                    else return prevState + 1;
                });
            } else if (queueData?.status?.toLowerCase() === 'failure') {
                clearInterval(interval);
                setLoading(false);
                setPollAttempts(0);
                navigate("/error");
                resolve({ status: false, data: resp?.data });
            }


        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate("/error");
            clearInterval(interval);
            setLoading(false);
            setPollAttempts(0);
            resolve({ status: false, data: [] });
        }
    }

    const nachStatusPollingAsync = async (page, pageName) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${pageName ?? "PAYMENTS"}`, {
                headers: {
                    "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
                }
            });

            const queueDataAscoreStatusCheck = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'getsubscriptionstatus'
                )
                : null;


            if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'success') {
                return { status: 1, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'initiated') {
                return { status: 2, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'failure') {
                setLoading(false);
                navigate("/error");
                return { status: 3, data: resp?.data };

            }

        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate("/error");
            setLoading(false);
            return { status: 4, data: [] };

        }
    }

    const createOrderPolling = async (page, interval, resolve, pageName) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${pageName !== "" ? pageName : page?.id?.trim()}`, {
                headers: {
                    "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'createorder'
                )
                : null;

            if (queueData?.status?.toLowerCase() === 'success') {
                clearInterval(interval);
                // setLoading(false);
                // navigate(page?.nextRoute);
                resolve({ status: true, data: resp?.data });
            } else if (queueData?.status?.toLowerCase() === 'initiated') {
                setPollAttempts((prevState) => {
                    if (prevState === 10) {
                        // setPollAttempts(0);
                        clearInterval(interval)
                        setLoading(false);
                        navigate("/error");;
                        resolve({ status: false, data: resp?.data });
                        return 0;
                    }
                    else return prevState + 1;
                });
            } else if (queueData?.status?.toLowerCase() === 'failure') {
                clearInterval(interval);
                setLoading(false);
                setPollAttempts(0);
                navigate("/error");
                resolve({ status: false, data: resp?.data });
            }


        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate("/error");
            clearInterval(interval);
            setLoading(false);
            setPollAttempts(0);
            resolve({ status: false, data: [] });
        }
    }

    const createOrderPollingAsync = async (page, pageName) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${pageName !== "" ? pageName : page?.id?.trim()}`, {
                headers: {
                    "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
                }
            });

            const queueDataAscoreStatusCheck = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'createorder'
                )
                : null;


            if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'success') {
                return { status: 1, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'initiated') {
                return { status: 2, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'failure') {
                setLoading(false);
                navigate("/error");
                return { status: 3, data: resp?.data };

            }

        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate("/error");
            setLoading(false);
            return { status: 4, data: [] };

        }
    }

    const downPayStatusPolling = async (page, interval, resolve, pageName) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${pageName}`, {
                headers: {
                    "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'getorderstatus'
                )
                : null;

            if (queueData?.status?.toLowerCase() === 'success') {
                clearInterval(interval);
                // setLoading(false);
                // navigate(page?.nextRoute);
                // checkPaymentTransactionStatus(queueData?.resBody?.order_status?.toLowerCase(),resp,resolve)
                resolve({ status: true, data: resp?.data });
            } else if (queueData?.status?.toLowerCase() === 'initiated') {
                setPollAttempts((prevState) => {
                    if (prevState === 10) {
                        clearInterval(interval)
                        setLoading(false);
                        navigate("/error");;
                        resolve({ status: false, data: resp?.data });
                        return 0;
                    }
                    else return prevState + 1;
                });
            } else if (queueData?.status?.toLowerCase() === 'failure') {
                clearInterval(interval);
                setLoading(false);
                setPollAttempts(0);
                navigate("/error");
                resolve({ status: false, data: resp?.data });
            }


        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate("/error");
            clearInterval(interval);
            setLoading(false);
            setPollAttempts(0);
            resolve({ status: false, data: [] });
        }
    }

    const downPayStatusPollingAsync = async (page, pageName) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${pageName}`, {
                headers: {
                    "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
                }
            });

            const queueDataAscoreStatusCheck = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'getorderstatus'
                )
                : null;


            if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'success') {
                return { status: 1, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'initiated') {
                return { status: 2, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'failure') {
                setLoading(false);
                navigate("/error");
                return { status: 3, data: resp?.data };

            }

        } catch (e) {
            console.log('Error : ', e?.response?.data)
            navigate("/error");
            setLoading(false);
            return { status: 4, data: [] };

        }
    }

    const checkSubscriptionCreatedPollingAsync = async (page, pageName) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${pageName}`, {
                headers: {
                    "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
                }
            });

            const queueDataAscoreStatusCheck = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'createsubscription'
                )
                : null;


            if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'success') {
                return { status: 1, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'initiated') {
                return { status: 2, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'failure') {
                setLoading(false);
                return { status: 3, data: resp?.data };

            }

        } catch (e) {
            console.log('Error : ', e?.response?.data)
            setLoading(false);
            if(e?.response?.data?.message !== 'No matching status found') {
                navigate("/error");
                return { status: 4, data: e?.response?.data };
            }
            return { status: 3, data: e?.response?.data };
        }
    }

    const checkSubscriptionCreatedPolling = async (page, interval, resolve, pageName) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${pageName}`, {
                headers: {
                    "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'createsubscription'
                )
                : null;

            if (queueData?.status?.toLowerCase() === 'success') {
                clearInterval(interval);
                // setLoading(false);
                // navigate(page?.nextRoute);
                resolve({ status: 1, data: resp?.data });
            } else if (queueData?.status?.toLowerCase() === 'initiated') {
                setPollAttempts((prevState) => {
                    if (prevState === 10) {
                        // setPollAttempts(0);
                        clearInterval(interval)
                        setLoading(false);
                        navigate('/error')
                        resolve({ status: 2, data: resp?.data });
                        return 0;
                    }
                    else return prevState + 1;
                });
            } else if (queueData?.status?.toLowerCase() === 'failure') {
                clearInterval(interval);
                setLoading(false);
                setPollAttempts(0);
                resolve({ status: 3, data: resp?.data });
            }


        } catch (e) {
            console.log('Error : ', e?.response?.data)
            clearInterval(interval);
            setLoading(false);
            setPollAttempts(0);
            if(e?.response?.data?.message !== 'No matching status found') {
                navigate("/error");
                resolve({ status: 4, data: [] });
            }
            resolve({ status: 3, data: [] });
        }
    }

    const checkOrderCreatedPollingAsync = async (page, pageName) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${pageName}`, {
                headers: {
                    "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
                }
            });

            const queueDataAscoreStatusCheck = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'createorder'
                )
                : null;


            if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'success') {
                return { status: 1, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'initiated') {
                return { status: 2, data: resp?.data };
            } else if (queueDataAscoreStatusCheck?.status?.toLowerCase() === 'failure') {
                setLoading(false);
                return { status: 3, data: resp?.data };

            }

        } catch (e) {
            console.log('Error : ', e?.response?.data)
            setLoading(false);
            if(e?.response?.data?.message !== 'No matching status found') {
                navigate("/error");
                return { status: 4, data: e?.response?.data };
            }
            return { status: 3, data: e?.response?.data };
        }
    }

    const checkOrderCreatedPolling = async (page, interval, resolve, pageName) => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL +
                getStatus +
                `?pan=${user_payload?.pan ?? pan?.value}&pageName=${pageName}`, {
                headers: {
                    "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
                }
            });

            const queueData = resp?.data?.queueDetails?.length > 0
                ? resp.data.queueDetails.find(item =>
                    item?.nodeName?.toLowerCase() === 'createorder'
                )
                : null;

            if (queueData?.status?.toLowerCase() === 'success') {
                clearInterval(interval);
                // setLoading(false);
                // navigate(page?.nextRoute);
                resolve({ status: 1, data: resp?.data });
            } else if (queueData?.status?.toLowerCase() === 'initiated') {
                setPollAttempts((prevState) => {
                    if (prevState === 10) {
                        // setPollAttempts(0);
                        clearInterval(interval)
                        setLoading(false);
                        navigate('/error')
                        resolve({ status: 2, data: resp?.data });
                        return 0;
                    }
                    else return prevState + 1;
                });
            } else if (queueData?.status?.toLowerCase() === 'failure') {
                clearInterval(interval);
                setLoading(false);
                setPollAttempts(0);
                resolve({ status: 3, data: resp?.data });
            }


        } catch (e) {
            console.log('Error : ', e?.response?.data)
            clearInterval(interval);
            setLoading(false);
            setPollAttempts(0);
            if(e?.response?.data?.message !== 'No matching status found') {
                navigate("/error");
                resolve({ status: 4, data: [] });
            }
            resolve({ status: 3, data: [] });
        }
    }


    const checkPaymentTransactionStatus = (status, data, resolve) => {

        //#region  response ffrom cashfree on different cases
        // sucess - Paid
        // pending/ user dropped - active
        // failed - active

        // return 1 -- exit the loop and go to next page
        // return 2 -- continue the loop
        // return 3 -- exit the loop and retry the payment
        switch (status) {
            case 'success':
            case 'paid':
                return "1";
            // case 'initialized' || "'pending" || "active":
            //     return "2";
            case 'failed':
            case 'cancelled':
            case "active":
                return "3";
        }
        return false;
    }

    const checkNachTransactionStatus = (subscriptionStatus, authStatus, data, resolve) => {

        //#region  response ffrom cashfree on different cases
        // sucess - Paid
        // pending/ user dropped - active
        // failed - active

        // return 1 -- exit the loop and go to next page
        // return 2 -- continue the loop
        // return 3 -- exit the loop and retry by creating new nach
        // return 4 -- authroize the old nach

        switch (subscriptionStatus) {
            case 'success':
            case 'paid':
            case 'active':
                return "1";
            case "bank approval pending" :
            case "pending" :
                return "2";
            case 'failed':
            case 'cancelled':
            case "link expired":
            case "completed":            
                return "3";
            case 'initialized' :
                if(authStatus === 'failed') return "3";
                return "4";

        }
        return false;
    }


    // setting up the json of pages received from the api and also the routes that exist to those pages.
    const setPages = (pages) => {
        setJsonPages([...pages]);
    }


    const generateRandomNum = () => {
        const code = Math.floor(1000 + Math.random() * 9000);
        setCode(code);
        return code;
    }


    const setRoute = (flag, next) => {
        if (flag) setCurrentRoute(location.pathname);
        if (next) setNextRoute(next);
    }

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });


    const openCamera = async (fromDialog = false) => {
        //
        if (stage == 0) {
            if (!permissionProvided) setOpenPermBox(true);
            if (fromDialog) setPermissionProvided(true)
            setStage(1);
        } else if (stage == 1) {

            setStage(2);
            generateRandomNum();
        } else if (stage == 2) {
            setStage(3);
            setShowSubmit(true)
        } else if (stage == 3) {
            setStage(4);
        }


    };

    const getHeader = (label) => {
        return (
            <div className="row">
                <div className="col-12 header">
                    {label}
                </div>
            </div>
        )
    }

    const getSubHeader = (label) => {
        return (
            <div className="row">
                <div className="col-12">
                    {label}
                </div>
            </div>
        )
    }


    const getCameraField = () => {
        return (
            <>
                {openPermBox && !permissionProvided ?
                    <Dialog
                        open={openPermBox}
                        className="text-font"
                        TransitionComponent={Transition}
                        aria-describedby="alert-dialog-slide-description"
                    >
                        <div className='container' style={{ padding: "20px" }}>
                            <div className="row d-flex justify-content-center">
                                <div className="col-8">
                                    <center>“Arthmate” would like to access the camera</center>
                                </div>

                            </div>
                            <div className="row d-flex justify-content-center mt-3">
                                <div className="col-8">
                                    <center style={{ fontWeight: "400", fontSize: "14px", color: "#9CA3AF" }}>This permission is needed to take a selfie</center>
                                </div>

                            </div>

                            <div className="row d-flex justify-content-center mt-3">
                                <div className="col-6 d-flex justify-content-center">
                                    <Button onClick={() => setOpenPermBox(false)} style={{ fontWeight: "400", fontSize: "16px", color: '#161719' }}>Don't Allow</Button>
                                </div>
                                <div className="col-6 d-flex justify-content-center">
                                    <Button onClick={() => openCamera(true)} style={{ fontWeight: "600", fontSize: "16px", color: '#0076B2' }}>Allow</Button>
                                </div>


                            </div>
                        </div>
                    </Dialog>
                    : ""
                }
                <div className="d-flex main-div flex-column text-font">
                    <div className="mt-4">
                        <div className="">
                            <div style={permissionProvided ? {} : { padding: "40px" }} className={`row default-photo-div`}>
                                <div style={permissionProvided ? { padding: "0" } : {}} className="col-12  d-flex justify-content-center">
                                    {
                                        permissionProvided ?
                                            <Recorder stage={stage} /> :
                                            <img src={logo} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {stage == 2 ? <div className="container mt-4">
                        <div className="container">
                            <div className={`row otp-div`}>
                                <div style={{ backgroundColor: '#D4E3FC', borderRadius: "10px" }} className="col-12  d-flex justify-content-center">
                                    otp - {code}
                                </div>
                            </div>
                        </div>
                    </div> : ""}

                    <div className="mt-4">
                        <div className="pointers">
                            {
                                permissionProvided ?
                                    retry ?
                                        errorAlert && <center><div className='d-flex justify-content-center text-danger'> {errorAlert.message}  </div></center>
                                        :
                                        stage === 3 ?
                                            <div>
                                                <div style={{ fontSize: "12px", fontWeight: "400" }} className='d-flex justify-content-center'>If everything looks good, press  <span style={{ fontWeight: '700' }}>  'Submit'</span>   </div>
                                                <div style={{ fontSize: "12px", fontWeight: "400" }} className='d-flex justify-content-center'>If you want to record again, press <span style={{ fontWeight: '700' }}>'Retry'</span> </div>
                                            </div>
                                            :
                                            stage === 2 ?
                                                <div>
                                                    <div style={{ fontSize: "12px", fontWeight: "400" }} className='d-flex justify-content-center'>Ensure your face is clearly visible </div>
                                                    <div style={{ fontSize: "12px", fontWeight: "400" }} className='d-flex justify-content-center'>and say the <span style={{ fontWeight: '700' }}> OTP </span> clearly.</div>
                                                </div>
                                                :
                                                <>
                                                    <div className="row photo-pointers">
                                                        <div className="col-12">
                                                            Follow the steps:
                                                        </div>
                                                    </div>

                                                    <div className="container">
                                                        <div className="row mt-2">
                                                            <div className="col-12">
                                                                1. Click <span className="photo-pointers">'Start'</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-12">
                                                                2. Say the <span className="photo-pointers"> OTP</span> displayed on the screen
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-12">
                                                                3. Click <span className="photo-pointers">'Submit'</span> after saying the <span className="photo-pointers"> OTP</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                    :
                                    <>
                                        <div className="row">
                                            <div className="col-1">
                                                <img src={person} />

                                            </div>
                                            <div className="col-11">
                                                <span>Ensure your face is visible within the frame</span>
                                            </div>

                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-1">
                                                <img src={bulb} />

                                            </div>
                                            <div className="col-11">
                                                <span>Ensure that you are in a place with good lighting</span>
                                            </div>

                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-1">
                                                <img src={glass} />

                                            </div>
                                            <div className="col-11">
                                                <span>Please take a selfie without wearing a hat, glasses or any other accessories which may block your face</span>
                                            </div>

                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-1">
                                                <img src={mute} />

                                            </div>
                                            <div className="col-11">
                                                <span>Please ensure you are in a quiet environment. You will be required to speak an OTP which will be captured during the process</span>
                                            </div>

                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                    {!showSubmit ?

                        <div className="btn-submit-selfie row">
                            {stage == 2 &&
                                <div className='mb-3'>
                                    <CameraCounter />
                                </div>}
                            <div className="col-12">
                                <Button onClick={openCamera} className='submit-selfie' variant='contained' style={{ width: "100%" }}>{stage == 0 ? "Proceed" : stage == 1 ? "Start" : stage == 2 ? "Stop" : ""}</Button>
                            </div>
                        </div>
                        :
                        retry ?
                            <div className="btn-submit-selfie row">
                                <div className="col-12">
                                    <Button onClick={onRetry} className='submit-selfie' variant='contained' style={{ width: "100%" }}>Retry</Button>
                                </div>
                            </div>
                            :
                            <div className="btn-submit-selfie row">
                                <div className="col-12">
                                    <Button onClick={onRetry} className='submit-selfie mb-2' variant='contained' style={{ width: "100%", backgroundColor: '#D4E3FC', color: "black", boxShadow: "none" }}>Retry</Button>
                                </div>
                                <div className="col-12">
                                    <Button onClick={openCamera} className='submit-selfie' variant='contained' style={{ width: "100%" }}>Submit</Button>
                                </div>
                            </div>
                    }
                </div>
            </>
        )
    }

    const sendSelfieDetailsData = async (blobs, img) => {
        try {
            setLoading(true);


            const form = new FormData();
            form.append('video', blobs);
            form.append('otp', code);
            form.append('pan', pan.value);
            const urlVideo = process.env.REACT_APP_SERVICE_URL + verifyLiveliness + `?encryptText=${encryptText}`;
            const headers = {
                'Content-Type': `multipart/form-data`,
                "api-key": `Bearer ${request_id}`

            }
            setImg(img);
            imageVal.current = img;

            const respArr = await axios.post(urlVideo, form, {headers});
            if (respArr && respArr.status === 200) {
                await invokePolling('selfie_req');
            }

        } catch (e) {
            console.log(e);
            setLoading(false);
            navigate('/error');
        }
    }

    const selfiePollingAsync = async (page) => {
        try {
            if (!loading) setLoading(true);
            let panNum = pan && pan.value ? pan.value : JSON.parse(localStorage.getItem("user_payload")).pan;
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL + getStatus + `?pageName=livenessCheck&pan=${panNum}&nodeName=LIVENESS_REQUEST`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });
            if (resp && resp.status === 200) {
                if (resp.data.status.toLowerCase() == 'success') {
                    invokePolling('selfie_check')
                } else if (resp.data.status.toLowerCase() === 'initiated') {
                    return { status: 2 };
                } else if (resp.data.status.toLowerCase() === 'failure') {
                    setRetry(true);
                    setLoading(false);
                    setPollAttempts(0);
                    navigate('/error');
                    return { status: 3 };
                }
            } else {
                setPollAttempts(0);
                throw new Error("Technical issue");
            }
        } catch (e) {
            console.error(e)
            navigate('/error');
            setLoading(false);
            setPollAttempts(0);
        }
    }



    const onRetry = () => {
        setRetry(false);
        setShowSubmit(false);
        setStage(1);
    }


    const getmobileField = (label, placeHolder) => {
        return (
            <>
                <div className="row mt-4">
                    <div className="col-12 field-headers">
                        {label}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextField key={"mobileField"} onChange={(e) => onMobileChange(e.target.value)} onBlurCapture={() => checkIfMobileValid()} value={mobile.value} error={mobile.error} className="input" id="outlined-basic" label="" placeholder={placeHolder} variant="outlined" />
                        {mobile && mobile.text && <p className={`error-desc ${mobile && mobile.error ? 'text-danger' : ""}`}>{mobile && mobile.text ? mobile.text : ""}</p>}
                    </div>
                </div>
            </>
        )
    }


    const onMobileChange = (value) => {
        if ((mobileValidation.test(value) || value == "" || !value) && value.length <= 10) {
            let mob = mobile;
            mob.value = value;
            setMobile({ ...mob });
            checkIfMobileValid();
        }
    }

    const checkIfMobileValid = () => {
        if (!mobile.value) return;

        let mobileObj = mobile;
        if (mobileValidation.test(mobile.value) && mobile.value.length == 10) {
            mobileObj.error = false;
            mobileObj.text = mobileSuccessText;
        } else {
            mobileObj.error = true;
            mobileObj.text = mobileErrorMessage;
        }

        setMobile({ ...mobileObj });
    }


    const getPayments = (obj, index) => {
        return (
            <>
                <div key={index} className='row mt-3'>
                    <div style={{ fontSize: "14px", fontWeight: "500" }} className='col-12'>
                        Payable Amount
                    </div>
                </div>
                <div key={index} style={{ alignItems: "center", height: "100%" }} className='row mt-2'>
                    <div className='col-2'>
                        <div className='d-flex justify-content-center' style={{ background: "#D4E3FC", borderRadius: "20px", height: "100%" }}>
                            <img style={{ padding: "10px" }} src={paymentLogo} />
                        </div>
                    </div>
                    <div className='col-9'>

                        <div className='row'>
                            <div style={{ fontWeight: "600", fontSize: "16px", color: "#181818" }} className='col-12 d-flex justify-content-between'>
                                <div>
                                    Down Payment
                                </div>
                                <div>
                                    ₹{rpsData?.data?.installments?.at(0)?.emi_amount ?? obj.amt}
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div style={{ fontWeight: "400", fontSize: "12px" }} className='col-12'>
                                Due on {rpsData?.data?.installments?.at(0)?.due_date ?? obj.date}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div style={{ fontSize: "14px", fontWeight: "500" }} className='col-12'>
                        <Alert style={{ fontWeight: "400", fontSize: "12px", color: "black", backgroundColor: "#FFFBEB", border: "1px solid #00000014" }} variant="filled" severity="info">
                            By making a down payment, you can start enjoying the benefits of your policy right away, while we handle the rest. It's like paying a small part upfront to get full protection.

                            <div className='mt-2'>Please note, you will not be able to proceed without completing the down payment.</div>
                        </Alert>
                    </div>
                </div>
            </>
        )
    }

    const getNameField = (label, placeHolder) => {
        return (
            <>
                <div className="row mt-4">
                    <div className="col-12 field-headers">
                        {label}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextField key={'nameField'} value={name.value} disabled={true} className="input disabled" id="outlined-basic" label="" placeholder={placeHolder} variant="outlined" />
                    </div>
                </div>
            </>)
    }

    const getDobField = (label, placeHolder) => {
        return (
            <>
                <div className="row mt-4">
                    <div className="col-12 field-headers">
                        {label}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextField key={'dobField'} value={dob.value} disabled={true} className="input disabled" id="outlined-basic" label="" placeholder={placeHolder} variant="outlined" />
                    </div>
                </div>
            </>)
    }

    const getpanField = (label, placeHolder) => {
        return (
            <>
                <div className="row mt-4">
                    <div className="col-12 field-headers">
                        {label}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextField key={'panField'} onBlurCapture={checkIfPanValid} value={pan.value} error={pan.error} disabled={true} className="input disabled" id="outlined-basic" label="" placeholder={placeHolder}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {pan.verified && <CheckCircleIcon color="success" />}
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined" />
                        {pan && pan.error && <p className={`error-desc text-danger`}>{pan && pan.text}</p>}

                    </div>
                    {pan.verified && <div>
                        <Alert
                            className="pan-alert"
                            severity="info"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            Your PAN details are verified.
                        </Alert>
                    </div>}
                </div>
            </>
        )
    }


    const checkIfPanValid = () => {
        if (!pan.value) return;

        let panObj = pan;
        if (panValidation.test(panObj.value)) {
            panObj.error = false;
            panObj.text = "";
        } else {
            panObj.error = true;
            panObj.text = panErrorMessage
        }
        setPan({ ...panObj });
    }


    const getadhaarField = (label, placeHolder, helperText, error, required) => {
        return (
            <>
                <div className="row mt-4">
                    <div className="col-12 field-headers">
                        {label}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextField key={'aadhaarField'} error={adhaar.error} onBlurCapture={checkifAdhaarNumberIsValid} onChange={setANumber} value={adhaar.value} className="input" id="outlined-basic" label="" placeholder={placeHolder} variant="outlined" />
                        {adhaar && adhaar.error && <p className={`error-desc text-danger`}>{adhaar && adhaar.text}</p>}
                    </div>
                </div>
            </>
        )
    }


    const checkifAdhaarNumberIsValid = (ad) => {
        if (!adhaar.value && !ad.value) return;
        let adhaarObj = adhaar && adhaar.value ? adhaar : ad;
        if (adhaarObj.value.split(" ").join("").length == 12) {
            adhaarObj.error = false;
            adhaarObj.text = "";
        } else {
            adhaarObj.error = true;
            adhaarObj.text = errorAdhaar;
        }
        setAdhaar({ ...adhaarObj });
    }

    const setANumber = (val) => {
        let value = val.target.value;
        if ((/^[1-9][0-9]*$/.test(value) || value == "" || !value) && value.length <= 12) {
            setAdhaarNumber(value);
        }
    }

    const setAdhaarNumber = (val) => {
        let adhaarObj = adhaar;
        adhaar.value = val;

        setAdhaar({ ...adhaarObj });
    }


    const getIfscField = (label, placeHolder) => {
        return (
            <>
                <div className="row mt-3">
                    <div className="col-12 field-heading">
                        {label}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextField onBlurCapture={validateIfsc} value={ifsc.value} onChange={(e) => onIfscChange(e)} error={ifsc.error} className="input" id="outlined-basic" label="" placeholder={placeHolder} variant="outlined" />
                        <p className={`error-desc ${ifsc && ifsc.error ? 'text-danger' : ""}`}>{ifsc && ifsc.text ? ifsc.text : ""}</p>
                    </div>
                </div>
            </>
        )
    }

    const onIfscChange = (e) => {
        const value = e.target.value;
        let ifscObj = ifsc;
        ifscObj.value = value;

        setIfsc({ ...ifscObj });
        validateIfsc();
    }

    const validateIfsc = () => {
        let ifscCode = ifsc.value;
        let ifscObj = ifsc;
        if (!ifscCode) {
            ifscObj.text = "Ifsc code is requried";
            ifscObj.error = true;
        } else {
            const isValid = validateData("ifsc", ifscCode?.toString());
            ifscObj.text = !isValid ? "Invalid IFSC. Please try again." : "";
            ifscObj.error = !isValid;
        }

        setIfsc({ ...ifscObj });
    }

    const getBankNameField = (label, placeHolder) => {
        return (
            <>
                <div className="row mt-3">
                    <div className="col-12 field-heading">
                        {label}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextField onChange={onBankNameChange} error={bankName.error} value={bankName.value} onBlurCapture={validateBankName} className="input" id="outlined-basic" label="" placeholder={placeHolder} variant="outlined" />
                        <p className={`error-desc ${bankName && bankName.error ? 'text-danger' : ""}`}>{bankName && bankName.text ? bankName.text : ""}</p>
                    </div>
                </div>
            </>
        )
    }



    const validateBankName = () => {
        let name = bankName.value;
        let bankObj = bankName;
        if (!name) {
            bankObj.text = "Bank name is requried";
            bankObj.error = true;
        } else {
            bankObj.text = "";
            bankObj.error = false;
        }

        setBankName({ ...bankObj });
    }

    const onBankNameChange = (e) => {
        const value = e.target.value;
        let bankObj = bankName;
        bankObj.value = value;

        setBankName({ ...bankObj });
        validateBankName();
    }

    const getAccNumField = (label, placeHolder) => {
        return (
            <>
                <div className="row mt-3">
                    <div className="col-12 field-heading">
                        {label}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextField onChange={onAccNumChange} onBlurCapture={validateAccNum} error={accountNum.error} value={accountNum.value} className="input" id="outlined-basic" label="" placeholder={placeHolder} variant="outlined" />
                        <p className={`error-desc ${accountNum && accountNum.error ? 'text-danger' : ""}`}>{accountNum && accountNum.text ? accountNum.text : ""}</p>
                    </div>
                </div>
            </>
        )
    }

    const onAccNumChange = (e) => {
        const value = e.target.value;
        let accObj = accountNum;
        accObj.value = value;

        setAccountNum({ ...accObj });
        validateAccNum();
    }

    const validateAccNum = () => {
        let accObj = accountNum;
        if (!accObj.value) {
            accObj.text = "Account number is required";
            accObj.error = true;
        } else {
            const isValid = validateData("account_number", accObj?.value);
            accObj.text = !isValid ? "Account number should be more than 5 digits" : "";
            accObj.error = !isValid;
        }

        setAccountNum({ ...accObj });
    }

    const getConfirmAccNumField = (label, placeHolder) => {
        return (
            <>
                <div className="row mt-3">
                    <div className="col-12 field-heading">
                        {label}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextField onChange={onConfirmAccNumChange} onBlurCapture={validateConfirmAccNum} error={confirmAccountNum.error} value={confirmAccountNum.value} className="input" id="outlined-basic" label="" placeholder={placeHolder} variant="outlined" />
                        <p className={`error-desc ${confirmAccountNum && confirmAccountNum.error ? 'text-danger' : ""}`}>{confirmAccountNum && confirmAccountNum.text ? confirmAccountNum.text : ""}</p>
                    </div>
                </div>
            </>
        )
    }

    const onConfirmAccNumChange = (e) => {
        const value = e.target.value;
        let accObj = confirmAccountNum;
        accObj.value = value;

        setConfirmAccountNum({ ...accObj });
        validateConfirmAccNum();
    }

    const validateConfirmAccNum = () => {
        let accObj = confirmAccountNum;
        if (!accObj.value) {
            accObj.text = "Account number is required";
            accObj.error = true;
        } else if (accObj.value != accountNum.value) {
            accObj.text = "Account number does not match";
            accObj.error = true;
        } else {
            const isValid = validateData("account_number", accObj?.value);
            accObj.text = !isValid ? "Account number should be more than 5 digits" : "";
            accObj.error = !isValid;
        }

        setConfirmAccountNum({ ...accObj });
    }



    const getCheckBoxField = (label, id, padding = false) => {
        return (
            <div className={`row pt-2 ${padding ? "pb-5" : "pb-2"}`}>
                <div className="col-1">
                    <Checkbox onClick={() => setCheck(id)} checked={isChecked[id]} className="checkbox" />
                </div>
                <div className="col-10"><span> {label} </span></div>
            </div>
        )
    }

    const getEmailField = (label, placeHolder) => {
        return (
            <div className="row pt-3">
                <div className="col-12 input-headers">
                    {label}
                </div>
                <div className="col-12">
                    <TextField name="email" error={stateDataValidation?.email ?? ""} value={stateData?.email ?? ""} onChange={(e) => handleStateDataChange(e, e?.target?.name)} className="input" id="outlined-basic" label="" placeholder={placeHolder} variant="outlined" />
                    <p className={`error-desc ${stateDataValidation?.email ? 'text-danger' : ""}`}>{stateDataValidation?.email ?? ""}</p>
                </div>
            </div>
        )
    }

    const getFatherName = (label, placeHolder) => {
        return (
            <div className="row mt-4">
                <div className="col-12 input-headers">
                    {label}
                </div>
                <div className="col-12">
                    <TextField name="fatherName" error={stateDataValidation?.fatherName ?? ""} value={stateData?.fatherName ?? ""} onChange={(e) => handleStateDataChange(e, e?.target?.name)} className="input" id="outlined-basic" label="" placeholder={placeHolder} variant="outlined" />
                    <p className={`error-desc ${stateDataValidation?.fatherName ? 'text-danger' : ""}`}>{stateDataValidation?.fatherName ?? ""}</p>
                </div>
            </div>
        )
    }

    const getMonthlyIncome = (label, placeHolder) => {
        return (
            <div className="row mt-4">
                <div className="col-12 input-headers">
                    {label}
                </div>
                <div className="col-12">
                    <TextField name="monthlyIncome" error={stateDataValidation?.monthlyIncome ?? ""} value={stateData?.monthlyIncome ?? ""} onChange={(e) => handleStateDataChange(e, e?.target?.name)} className="input" id="outlined-basic" label="" placeholder={placeHolder} variant="outlined" />
                    <p className={`error-desc ${stateDataValidation?.monthlyIncome ? 'text-danger' : ""}`}>{stateDataValidation?.monthlyIncome ?? ""}</p>
                </div>
            </div>
        )
    }

    // handle all state changes
    const handleStateDataChange = (e, name) => {
        const value = e?.target?.value;
        const isValid = validateData(name, value);

        if (name === 'empStatus' && value !== undefined && value !== null) {
            setStateData(prev => ({
                ...prev,
                [name]: value,
            }));
            return;
        }


        setStateData(prev => ({
            ...prev,
            [name]: value,
        }));

        setStateDataValidation(prev => ({
            ...prev,
            [name]: !isValid ? InputFieldsJson[name]?.validationMsg ?? 'Invalid data' : null,
        }));

        if (name === 'monthlyIncome' && value !== undefined && value !== null) {
            setStateDataValidation(prev => ({
                ...prev,
                [name]: !isValid ? value.toString().length > 8 ? InputFieldsJson[name]?.errorHelperTextRequired : InputFieldsJson[name]?.validationMsg : null,
            }));
        }
    }

    const getEmpStatus = (label, placeHolder, items) => {
        return (
            <div className="row mt-4 mb-4">
                <div className="col-12 input-headers">
                    {label}
                </div>
                <div className="col-12">
                    <Select
                        labelId="demo-simple-select-label"
                        id={label}
                        placeholder={placeHolder}
                        label=""
                        style={{ width: "100%" }}
                        value={stateData?.empStatus ?? "0"}
                        name="empStatus"
                        onChange={(e) => handleStateDataChange(e, e.target.name)}
                    >
                        {/* <MenuItem value={'Salaried'}>Salaried</MenuItem>
                        <MenuItem value={'Self-Employed'}>Self-Employed</MenuItem> */}
                        <MenuItem disabled={true} value={"0"}>Select</MenuItem>
                        {
                            items.length && items.map((obj) => {
                                return (<MenuItem value={obj.value}>{obj.label}</MenuItem>)
                            })
                        }
                    </Select>
                </div>
            </div>
        )
    }

    const setCheck = (id) => {
        let checks = isChecked;
        checks[id] = !checks[id];
        setIsChecked([...checks]);
    }


    const getAddressField = (label, placeHolder) => {
        return (
            <>
                <div className="row mt-4">
                    <div className="col-12 field-headers">
                        {label}
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextField className="input"
                            id="outlined-multiline-static"
                            label=""
                            placeholder={placeHolder}
                            multiline
                            style={{ width: "100%", height: "auto" }}
                            name='aadharAddress'
                            value={aadharAddress}
                            onChange={(e) => setAadharAddress(e?.target?.value)}
                        //onBlurCapture={() => checkIfMobileValid()}
                        // error={currentAddressError?.flatNo}
                        />
                        {/* <p className={`error-desc ${mobile && mobile.error ? 'text-danger' : ""}`}>{mobile && mobile.text ? mobile.text : ""}</p> */}
                    </div>
                </div>
            </>
        )
    }


    const handleGetCities = async (value) => {
        setCityOpt(Cities(value));
    };

    const change = (e, name) => {
        const value = e?.target?.value;
        const isValid = validateData(name, value);

        if (name === 'state' && value !== undefined && value !== null) {
            handleGetCities(value);

            setCurrentAddress(prev => ({
                ...prev,
                [name]: value,
                "city": "",
            }));
            return;
        }

        if (name === 'city' && value !== undefined && value !== null) {
            setCurrentAddress(prev => ({
                ...prev,
                [name]: value,
            }));
            return;
        }

        setCurrentAddressError(prev => ({
            ...prev,
            [name]: !isValid ? InputFieldsJson[name]?.validationMsg ?? 'Invalid data' : null,
        }));

        setCurrentAddress(prev => ({
            ...prev,
            [name]: value,
        }));

    }

    const getNewAddress = () => {
        return (
            <>
                <div className="row">
                    <div className="col-12 address-details">
                        Present Address Details
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-12">
                        <Alert
                            className="info-box"
                            severity="info"
                            variant="filled"
                            sx={{ width: '100%' }}
                        >
                            Details filled based on your current location
                        </Alert>
                    </div>
                </div>
                <div className="row mb-3 location-details">
                    <div className="col-12">
                        Flat No./ House No./Building
                    </div>
                    <div className="col-12">
                        <TextField className="input"
                            id="outlined-multiline-static"
                            label=""
                            placeholder="Address Line 1"
                            style={{ width: "100%" }}
                            name='flatNo'
                            value={currentAddress?.flatNo}
                            onChange={(e) => change(e, e.target.name)}
                            //onBlurCapture={() => checkIfMobileValid()}
                            error={currentAddressError?.flatNo}
                        />
                        <p className={`error-desc ${currentAddressError?.flatNo ? 'text-danger' : ""}`}>{currentAddressError?.flatNo ? currentAddressError.flatNo : ""}</p>
                    </div>
                </div>
                <div className="row location-details mb-3">
                    <div className="col-12">
                        Street Name
                    </div>
                    <div className="col-12">
                        <TextField className="input"
                            id="outlined-multiline-static"
                            label=""
                            placeholder="Address Line 2"
                            style={{ width: "100%" }}
                            name='streetName'
                            value={currentAddress?.streetName}
                            onChange={(e) => change(e, e.target.name)}
                            error={currentAddressError?.streetName}
                        />
                        <p className={`error-desc ${currentAddressError?.streetName ? 'text-danger' : ""}`}>{currentAddressError?.streetName ? currentAddressError.streetName : ""}</p>
                    </div>
                </div>
                <div className="row location-details mb-3">
                    <div className="col-12">
                        Area
                    </div>
                    <div className="col-12">
                        <TextField className="input"
                            id="outlined-multiline-static"
                            label=""
                            placeholder="Enter Here"

                            style={{ width: "100%" }}
                            name='area'
                            value={currentAddress?.area}
                            onChange={(e) => change(e, e.target.name)}
                            error={currentAddressError?.area}
                        />
                        <p className={`error-desc ${currentAddressError?.area ? 'text-danger' : ""}`}>{currentAddressError?.area ? currentAddressError.area : ""}</p>
                    </div>
                </div>
                <div className="row location-details mb-3">
                    <div className="col-12">
                        Pincode
                    </div>
                    <div className="col-12">
                        <TextField className="input"
                            id="outlined-multiline-static"
                            label=""
                            placeholder="Enter 6-digit Pincode"

                            style={{ width: "100%" }}
                            name='pincode'
                            value={currentAddress?.pincode}
                            onChange={(e) => change(e, e.target.name)}
                            error={currentAddressError?.pincode}
                        />
                        <p className={`error-desc ${currentAddressError?.pincode ? 'text-danger' : ""}`}>{currentAddressError?.pincode ? currentAddressError.pincode : ""}</p>
                    </div>
                </div>
                <div className="row location-details mb-1">
                    <div className="col-6">
                        State
                    </div>
                    <div className="col-6">
                        City
                    </div>
                </div>
                <div style={{ paddingBottom: "40px" }} className="row location-details">
                    <div className="col-6">
                        <Select
                            labelId="demo-simple-select-label"
                            // value="select"
                            label="select"
                            style={{ width: "100%" }}
                            placeholder="Select"
                            id="state"
                            // defaultValue={"Select"}

                            name='state'
                            value={currentAddress?.state ? currentAddress?.state : 'select'}
                            onChange={(e) => change(e, e?.target?.name ?? 'state')}
                        // error={currentAddressError?.pincode}
                        >
                            <MenuItem disabled={true} value={'select'}>Select</MenuItem>
                            {stateOpt?.map((item, index) => {
                                return (
                                    <MenuItem value={item} key={index}>{item.label}</MenuItem>
                                )
                            })}

                            {/* <MenuItem value={"MAHARASHTRA"}>Maharashtra</MenuItem>
                                <MenuItem value={"DELHI"}>Delhi</MenuItem>
                                <MenuItem value={"RAJASTHAN"}>Rajasthan</MenuItem> */}
                        </Select>
                    </div>
                    <div className="col-6">
                        <Select
                            labelId="demo-simple-select-label"
                            id="city"
                            placeholder="Select"
                            label="Select"
                            // value={0}
                            style={{ width: "100%" }}
                            defaultValue={0}

                            name='city'
                            value={currentAddress?.city ? currentAddress?.city : 'select'}
                            onChange={(e) => change(e, e?.target?.name ?? 'city')}
                            disabled={!currentAddress?.state}
                        // error={currentAddressError?.pincode}
                        >
                            <MenuItem disabled={true} value={'select'}>Select</MenuItem>
                            {cityOpt?.map((item, index) => {
                                return (
                                    <MenuItem value={item} key={index}>{item.label}</MenuItem>
                                )
                            })}
                            {/* <MenuItem value={"MUMBAI"}>Mumbai</MenuItem>
                            <MenuItem value={"GURUGRAM"}>Gurugram</MenuItem>
                            <MenuItem value={"JAIPUR"}>Jaipur</MenuItem> */}
                        </Select>
                    </div>
                </div>
            </>
        )
    }


    const getRadioButtonFields = (heading = '', comp) => {
        return (
            <>
                {heading && <div className="row mt-4">
                    <div className="col-12 field-headers">
                        Is your current address the same as your
                        Aadhaar-registered address?
                    </div>
                </div>}
                <div className="row mt-3">
                    <div className="col-12 d-flex field-heading">
                        {
                            comp.length && comp.map(({ label, value }) => {
                                return (<FormControlLabel onClick={onRadioChange} value={value} checked={getValue(value)} control={<Radio />} label={label} />)
                            })
                        }
                    </div>
                </div>
                {
                    radios.length && radios.map((obj) => {
                        if (obj.checked && obj.value === 'no')
                            return getNewAddress();

                    })
                }
            </>
        )
    }


    const getValue = (value) => {
        const val = radios.find((obj) => {
            return obj.value == value
        })

        return val.checked;
    }


    const onRadioChange = (e) => {
        let radioOptions = radios;
        const selectedVal = e.target.value;
        radioOptions.map((obj) => {
            if (obj.value === selectedVal) obj.checked = true;
            else obj.checked = false
        })

        setRadios([...radioOptions]);
    }

    const getButtonField = (label) => {
        return (
            <div className="p-3 width-screen">
                {errorAlert.show && <div className='row'>
                    <div style={{ position: "relative" }} className='col-12 mb-4 error-alert'>
                        {errorAlert.message} <span onClick={() => setErrorAlert({ ...{ error: false, message: "" } })} style={{ position: "absolute", top: "1px", right: "8px", cursor: "pointer" }}>x</span>
                    </div>
                </div>}
                <div className="btn-submit row">
                    <div className="col-12">
                        <Button onClick={(e) => submit(location.pathname)} disabled={buttonDisabled()} className='submit-button' variant='contained' style={{ width: "100%" }}>{label}</Button>
                    </div>
                    {showOtp && <OtpVerificationModal setWrongOtp={() => setWrongOtp(false)} wrongOtp={wrongOtp} />}
                    {showModal.show &&
                        <BottomStickModal
                            buttons={
                                <div className="col-6">
                                    <Button onClick={onBack} style={{ width: "100%" }} variant="contained"> Back</Button>
                                </div>}
                            subHeading={showModal.message} />}

                    {/* showing service not avaialble at pincode*/}
                    {showPincodeModal?.show &&
                        <BottomStickModal2
                            buttons={
                                <div className={`btn-submit ${showPincodeModal?.showRetryBtn ? "row" : ""} mt-5 mb-2`}>
                                    {showPincodeModal?.showRetryBtn && <div className="col-6">
                                        <Button onClick={onRetryClick} style={{ width: "100%", border: "1px solid black" }} variant='string'> {`Retry (${(retryPincode)})`} </Button>
                                    </div>}
                                    <div className={`${showPincodeModal?.showRetryBtn ? "col-6" : ""}`}>
                                        <Button onClick={onBackToHome} style={{ width: "100%" }} variant="contained"> Back to Home </Button>
                                    </div>
                                </div>
                            }
                            header={showPincodeModal?.message} customStyle1="container" customStyle2="my-5" customStyle3="col-12 d-flex justify-content-center align-items-center" styling3={{ color: "#767888", fontSize: "16px" }} />
                    }


                    {/* Bank details confirmation modal */}
                    {showPincodeModal?.showBankConfirmModal &&
                        <BottomStickModal2
                            buttons={
                                <div className={`btn-submit ${showPincodeModal?.showBackBtn ? "row" : ""} mt-5 mb-2`}>
                                    {showPincodeModal?.showBackBtn && <div className="col-6">
                                        <Button onClick={() => setShowPincodeModal({})} style={{ width: "100%", border: "1px solid black" }} variant='string'> Back </Button>
                                    </div>}
                                    <div className={`${showPincodeModal?.showBackBtn ? "col-6" : ""}`}>
                                        <Button onClick={(e) => bankSubmit("/bank-details-pennydrop")} style={{ width: "100%" }} variant="contained"> Proceed </Button>
                                    </div>
                                </div>
                            }
                            header={showPincodeModal?.header} subHeading={showPincodeModal?.subHeading} />}
                </div>
            </div>

        )
    }

    const onBack = () => {
        setShowModal({ ...{ show: false, message: "" } })
    }

    const onRetryClick = () => {
        setShowPincodeModal({ ...{ show: false, message: "", showRetryBtn: retryPincode > 0 ? true : false } });
        setRetryPincode(prev => prev - 1);
    }

    const onBackToHome = () => {
        setShowPincodeModal({ ...{ show: false, message: "", showRetryBtn: retryPincode > 0 ? true : false } })
        navigate('/')
    }

    const bankSubmit = (e) => {
        setShowPincodeModal({});
        submit(e);
    }

    const submit = async (e) => {
        setLoading(true);
        const page = jsonPages?.find((page) => {
            return page.currentRoute === location.pathname
        })

        const user_payload = JSON.parse(localStorage.getItem("user_payload"));

        const headers = {
            "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
        }

        const config = page?.button?.apiConfig;
        const url = process.env.REACT_APP_SERVICE_URL +
            config?.url +
            `?encryptText=${encryptText}`;
        const method = config?.type;
        const pageName = page?.id?.trim();
        switch (e) {
            case "/kyc-verification":
                const payload = JSON.parse(localStorage.getItem("user_payload"));
                if (pan.verified && adhaar.verified) {
                    setLoading(false);
                    setShowOtp(true);
                } else await kycVerify(url, method, pageName, payload, headers);

                break;
            case "/communication-details":
                await communicationDetailsUpdate(url, method, user_payload, pageName, page,headers);
                break;
            case "/personal-details":
                await personalDetailsUpdate(url, method, user_payload, pageName, page, headers);
                break;
            case "/bank-details":
                setShowPincodeModal({ showBankConfirmModal: true, header: "Confirm Submission", showBackBtn: true, subHeading: "Please ensure you have shared the correct bank account details. Your EMI will be deducted from the account shared here." });
                setLoading(false);
                // await pennyDropApiCall(url, method, user_payload, pageName, page);
                break;
            case "/bank-details-pennydrop":
                await pennyDropApiCall(url, method, user_payload, pageName, page, headers);
                break;
            case "/bank-details-loan-creation":
                await loanCreationApiCall(url, method, user_payload, pageName, page,headers);
                break;
            case "/bank-details-rps-save":
                await rpsSaveApiCall(url, method, user_payload, pageName, page,headers);
                break;
            case "/create-nach-subscription":
                const nachModifiedUrl = process.env.REACT_APP_SERVICE_URL + nachUrl + `?encryptText=${encryptText}`;
                const nachMethod = 'post';

                await createNachSubscription(nachModifiedUrl, nachMethod, user_payload, pageName ?? 'PAYMENTS', page, headers);

                break;
            case "/payments":
                setLoadMsg(`You are now being redirected 
                to complete your down payment through 
                a payment gateway. Please do not refresh the page 
                or click the “Back” 
                or “Close” button of your browser`);
                await createOrderApiCall(url, method, user_payload, pageName, page, headers);
                break;

            default:
                setLoading(false);
                navigate(nextRoute);
        }

        setLoading(false);


    }


    const kycVerify = async (url, method, pageName, payload, headers) => {
        try {

            let payload = JSON.parse(localStorage.getItem("user_payload"));
            if (!payload.aadhaar_number || payload.aadhaar_number != adhaar.value) {
                payload['aadhaar_number'] = adhaar.value;
                localStorage.setItem('user_payload', JSON.stringify(payload));
            }
            let modifiedUrl = url;

            if (!pan.verified) modifiedUrl = modifiedUrl + `&validationName=panvalidation`;
            else if (!adhaar.verified) modifiedUrl = modifiedUrl + `&validationName=aadhaarvalidation`;

            const resp = await axios({
                method,
                url: modifiedUrl,
                data: {
                    "submitData": payload,
                    "pageName": pageName
                },
                headers
            })

            if (resp.status === 200) {
                const response = resp.data;
                if (response.success) {
                    if (response.statusCode === 101) {
                        const pollResp = await invokePolling('kyc');
                        if (pollResp && pollResp.status && !adhaar.verified) await kycVerify(url, method, pageName, payload, headers);
                    } else if (response.statusCode === 102) {
                        setShowModal({ ...{ show: true, message: "An application with this PAN already exists. Please try using a different PAN." } })
                        setLoading(false);
                    }
                }
            }
        } catch (e) {
            console.log('Error : ', e?.response?.data);
            setLoading(false);
            navigate('/error');
        }
    }


    const buttonDisabled = () => {
        /*
            checkboxes if present then always
            radio button is present then always


            pan
            adhaar
            mobile
        */

        if (requiredFields.includes('mobile')) {
            if (!mobile.value || !mobileValidation.test(mobile.value) || mobile.value.length !== 10) return true;
        }

        if (requiredFields.includes('pan')) {
            if (!pan.value || !panValidation.test(pan.value)) return true;
        }

        if (requiredFields.includes('adhaar')) {
            if (!adhaar.value || adhaar.value.length != 12) return true;
        }

        if (isChecked && isChecked.length) {
            if (isChecked.includes(false)) return true;
        }


        if (requiredFields.includes('ifsc')) {
            if (!ifsc.value) return true;
        }

        if (requiredFields.includes('bankName')) {
            if (!bankName.value) return true
        }

        if (requiredFields.includes('accNum')) {
            if (!accountNum.value) return true
        }

        if (requiredFields.includes('confirmAccNum')) {
            if (accountNum.value != confirmAccountNum.value || !confirmAccountNum.value) return true;
        }



        if (location?.pathname === '/personal-details') {
            if (requiredFields.includes('email')) {
                if (!stateData?.email || !validateData('email', stateData?.email)) return true;
            }
            if (requiredFields.includes('fatherName')) {
                if (!stateData?.fatherName || !validateData('fatherName', stateData?.fatherName)) return true;
            }
            if (requiredFields.includes('income')) {
                if (!stateData?.monthlyIncome || !validateData('monthlyIncome', stateData?.monthlyIncome)) return true;
            }
            if (requiredFields.includes('empStatus')) {
                if (!stateData?.empStatus) return true;
            }
            return false;
        }

        if (location?.pathname === '/communication-details') {
            let addressSameAsAadhar = false;
            for (let i = 0; i < radios?.length; i++) {
                const item = radios[i];
                if (item?.checked && item?.value === 'yes') {
                    addressSameAsAadhar = true;
                    break;
                }
            };
            if (addressSameAsAadhar) return false;

            if (!currentAddress?.flatNo || !validateData('flatNo', currentAddress?.flatNo) || !currentAddress?.area || !validateData('area', currentAddress?.area) || !currentAddress?.streetName || !validateData('streetName', currentAddress?.streetName) || !currentAddress?.pincode || !validateData('pincode', currentAddress?.pincode) || !currentAddress?.city || !currentAddress?.state) {
                return true;
            }
        }

        if (location?.pathname !== '/communication-details') {
            if (requiredFields.includes('radio')) {
                for (let i = 0; i < radios.length; i++) {
                    if (radios[i].checked) return false;
                }

                return true;
            }
        }




        return false;
    }

    const tokenValidation = async () => {
        const tk = localStorage.getItem("user_payload");
        let token = "";

        if (tk) token = JSON.parse(localStorage.getItem("user_payload"));
        const requestId = searchParams.get('request_id') || token?.request_id;
        const data = searchParams.get('data') || token?.user_details;
        if (!request_id) setRequestId(requestId);
        if (!encryptText) setEncryptText(data);
        if (!requestId || !data) {
            navigate('/error');
            return;
        }

        setLoading(true);
        try {
            const url = process.env.REACT_APP_SERVICE_URL;
            const headers = {
                "api-key": `Bearer ${requestId}`
            }
            let resp = await axios.post(url + validateToken + `?encryptText=${data}`, {}, { headers })
            let response = null;

            if (resp && resp.status && resp.data.decryptedPayload) {
                if (!tk) {
                    resp.data.decryptedPayload['loan_app_id'] = resp?.data?.payload?.data?.loan_app_id;
                    resp.data.decryptedPayload['state'] = resp?.data?.payload?.data?.state;
                    resp.data.decryptedPayload['request_id'] = requestId;
                    resp.data.decryptedPayload['user_details'] = data;
                    localStorage.setItem("user_payload", JSON.stringify(resp.data.decryptedPayload));
                    response = resp.data.decryptedPayload;
                } else {
                    if (!token.loan_app_id) token['loan_app_id'] = resp?.data?.payload?.data?.loan_app_id;
                    if (!token.state) token['state'] = resp?.data?.payload?.data?.state;
                    if (!token.city) token['city'] = resp?.data?.payload?.data?.city;
                    if (!token.pincode) token['pincode'] = resp?.data?.payload?.data?.pincode;
                    if (!token.borrower_id) token['borrower_id'] = resp?.data?.payload?.data?.borrower_id;
                    if (!token.request_id) token['request_id'] = requestId;
                    if (!token.user_details) token['user_details'] = data;
                    localStorage.setItem("user_payload", JSON.stringify(token));

                    response = token
                }
            } else {
                navigate('/error');
                return;
            }



            if (resp && resp?.status === 200 && resp?.data?.message?.toLowerCase() == 'token is valid') {

                const responseLeadData = resp.data?.payload?.data;

                setPan({ ...{ error: "", value: response.pan, text: "", verified: false } });
                setName({ ...{ error: "", value: (response?.first_name + " " + response?.last_name), text: "" } })
                setAdhaar({ ...{ error: "", value: response?.aadhaar_number, text: "", verified: false, request_id: "" } })
                checkifAdhaarNumberIsValid({ error: "", value: response?.aadhaar_number, text: "" })
                setDob({ ...{ error: "", value: convertDob(response?.dob), text: "" } })
                setMobile({ ...{ error: "", value: response?.appl_phone, text: "" } });
                setLeadData({ ...responseLeadData });
                let addrStr = responseLeadData?.resi_addr_ln1 ?? "";
                addrStr += " " + (responseLeadData?.resi_addr_ln2 ?? "");
                addrStr += ", " + (responseLeadData?.city ?? "");
                addrStr += ", " + (responseLeadData?.state ?? "");
                addrStr += ", " + (responseLeadData?.pincode?.toString() ?? "");
                setAadharAddress(addrStr);
            }

            return { status: resp?.data?.payload.success, data: resp.data?.payload?.data, payload: resp?.data?.decryptedPayload };
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.code && e.response.data.code.toLowerCase() == "ageerror") {
                return { status: 2 };
            } else navigate('/error');

        } finally {
            setLoading(false)
        }
    }

    const communicationDetailsUpdate = async (url, method, user_payload, pageName, pageDetails, headers) => {
        try {

            let addressSameAsAadhar = false;
            for (let i = 0; i < radios?.length; i++) {
                const item = radios[i];
                if (item?.checked && item?.value === 'yes') {
                    addressSameAsAadhar = true;
                    break;
                }
            };

            const pincodeVal = addressSameAsAadhar ? leadData?.pincode ?? "" : currentAddress?.pincode ? parseFloat(currentAddress.pincode) : "";

            // checking service available at pincode
            if (pincodeVal && pincodeData.hasOwnProperty(`${pincodeVal}`)) {
                setShowPincodeModal({ ...{ show: true, message: `Our services are currently not available in your area (Pincode: ${pincodeVal})`, showRetryBtn: retryPincode > 0 ? true : false } })
                setLoading(false);
                return;
            }

            let addrStr = currentAddress.flatNo ? currentAddress.flatNo : "";
            addrStr += currentAddress.streetName ? currentAddress.streetName : "";
            addrStr += currentAddress.area ? currentAddress.area : "";
            const addressLine1 = addressSameAsAadhar ? leadData?.resi_addr_ln1 ?? "" : addrStr?.substring(0, 40)?.trim() ?? "";
            const addressLine2 = addressSameAsAadhar ? leadData?.resi_addr_ln2 ?? "" : addrStr?.substring(40)?.trim() ?? "";

            const payload = {
                "submitData": { //context

                    // checks data
                    "partner_loan_app_id": user_payload?.partner_loan_app_id ?? leadData?.partner_loan_app_id,
                    "partner_borrower_id": user_payload?.partner_borrower_id ?? leadData?.partner_borrower_id,
                    "pan": user_payload?.pan ?? pan?.value,
                    "first_name": user_payload?.first_name ?? leadData?.first_name,
                    "last_name": user_payload?.last_name ?? leadData?.last_name,
                    "dob": user_payload?.dob ?? leadData?.dob,

                    // additional data
                    "resi_addr_ln1": addressLine1,
                    "resi_addr_ln2": addressLine2,
                    "pincode": pincodeVal,
                    "city": addressSameAsAadhar ? leadData?.city ?? "" : currentAddress?.city?.value ? currentAddress.city.value?.toUpperCase() : '',
                    "state": addressSameAsAadhar ? leadData?.state ?? "" : currentAddress?.state?.value ? currentAddress.state.value?.toUpperCase() : '',
                },
                pageName,
                queueData : [{...pageDetails?.nodes}],
            };

            const resp = await axios({
                method,
                url,
                data: payload,
                headers
            })
            //debugger;

            if (resp?.data?.success) {
                const pollResponse = await invokePolling('communication');
                const queueData = pollResponse?.data?.queueDetails?.length > 0
                    ? pollResponse.data.queueDetails.find(item =>
                        item?.nodeName?.toLowerCase() === 'update_lead'
                    )
                    : null;
                if (pollResponse?.status && pollResponse?.data?.status?.toLowerCase() === "success") {
                    if(pageDetails?.nextRoute) {
                        await updatePageStatus();
                        navigate(pageDetails?.nextRoute?.trim())
                    }
                    return true;
                }

            } else {
                setLoading(false);
                navigate('/error');
            }

        } catch (e) {
            console.log('Error : ', e?.response?.data);
            setLoading(false);
            navigate('/error');
        }
    }

    const personalDetailsUpdate = async (url, method, user_payload, pageName, pageDetails, headers) => {

        try {

            const payload = {
                "submitData": { //context

                    "partner_loan_app_id": user_payload?.partner_loan_app_id ?? leadData?.partner_loan_app_id,
                    "partner_borrower_id": user_payload?.partner_borrower_id ?? leadData?.partner_borrower_id,
                    "pan": user_payload?.pan ?? pan?.value,
                    "first_name": user_payload?.first_name ?? leadData?.first_name,
                    "last_name": user_payload?.last_name ?? leadData?.last_name,
                    "dob": user_payload?.dob ?? leadData?.dob,

                    // additional data
                    "email_id": stateData?.email,
                    "father_fname": stateData?.fatherName?.split(" ")[0] ?? stateData?.fatherName,
                    "father_lname": stateData?.fatherName?.split(" ")[1] ?? "",
                    "borrower_monthly_income": stateData?.monthlyIncome,
                    "borrower_profile": stateData?.empStatus

                },
                pageName,
                queueData : [{...pageDetails?.nodes}],
            };

            const resp = await axios({
                method,
                url,
                data: payload,
                headers
            });


            if (resp?.data?.success) {
                const pollResponse = await invokePolling('personalDetails');
                const queueData = pollResponse?.data?.queueDetails?.length > 0
                    ? pollResponse.data.queueDetails.find(item =>
                        item?.nodeName?.toLowerCase() === 'update_lead'
                    )
                    : null;
                if (pollResponse?.status && pollResponse?.data?.status?.toLowerCase() === "success") {
                    let userPayload = JSON.parse(localStorage.getItem('user_payload'));
                    userPayload['emp_status'] = stateData.empStatus;
                    localStorage.setItem('user_payload' , JSON.stringify(userPayload));
                    setLoading(false);
                    await updatePageStatus();
                    navigate(pageDetails?.nextRoute);
                }
            } else {
                setLoading(false);
                navigate('/error');
            }
        } catch (e) {
            console.log('Error : ', e?.response?.data);
            setLoading(false);
            navigate('/error');
        };

    }

    const callAscoreRequest = async (pageName, pageDetails) => {

        try {
            let user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const payload = {
                "submitData": {
                    "pan": user_payload?.pan ?? pan?.value,
                    "first_name": user_payload?.first_name,
                    "last_name": user_payload?.last_name,
                    "dob": user_payload?.dob,
                    "loan_app_id": user_payload?.loan_app_id,
                    "gender": user_payload?.gender?.trim()?.toUpperCase(),
                    "mobile_number": user_payload?.appl_phone,
                    "address": (user_payload?.resi_addr_ln1 ? user_payload.resi_addr_ln1 : (leadData?.resi_addr_ln1 ?? "")) + " " + (user_payload?.resi_addr_ln2 ? user_payload.resi_addr_ln2 : (leadData?.resi_addr_ln2 ?? "")),
                    "city": user_payload?.city,
                    "pin_code": user_payload?.pincode?.toString(),
                    "enquiry_amount": user_payload?.loan_amount ? user_payload.loan_amount : leadData?.loan_amount ? leadData.loan_amount : "120000",
                    "bureau_type": "cibil",
                    "tenure": user_payload?.loan_tenure ? parseFloat(user_payload?.loan_tenure) : leadData?.loan_tenure ? parseFloat(leadData?.loan_tenure) : leadData?.tenure ? parseFloat(leadData?.tenure) : 13,
                    "consent": "Y",
                    "consent_timestamp": moment().format("YYYY-MM-DD HH:mm:ss"),
                    "state_code": stateConvertionMapping[user_payload?.state?.toUpperCase()],
                    "enquiry_purpose": "01",
                    "product_type": "STPL",
                    "enquiry_stage": "PRE-SCREEN",
                    "en_acc_account_number_1": user_payload?.loan_app_id,
                },
                pageName,
            };

            const url = process.env.REACT_APP_SERVICE_URL + getAScoreData +
                `?encryptText=${encryptText}`;


            const resp = await axios({
                method: "post",
                url,
                data: payload,
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });
            // if (resp?.data?.success) {
            if (resp?.status === 200 && resp.data.success) {

                const pollResponse = await invokePolling('ascore_request', pageName);

                const queueData = pollResponse?.data?.queueDetails?.length > 0
                    ? pollResponse.data.queueDetails.find(item =>
                        item?.nodeName?.toLowerCase() === 'ascore_request'
                    )
                    : null;
                const queueDataAscoreStatusCheck = pollResponse?.data?.queueDetails?.length > 0
                    ? pollResponse.data.queueDetails.find(item =>
                        item?.nodeName?.toLowerCase() === 'ascore_status_check'
                    )
                    : null;

                if (pollResponse?.status && queueData?.status?.toLowerCase() === "success" && queueDataAscoreStatusCheck?.status?.toLowerCase() === "success") //  && queueData?.resBody?.data?.score?.NCVL_AScore >= 600 && queueData?.resBody?.data?.score?.bureau_score >= 600 ) 
                {
                    const tenure = leadData?.tenure ? parseFloat(leadData.tenure) : leadData?.loan_tenure ? parseFloat(leadData.loan_tenure) : 10;

                    const interest_rate = user_payload?.interest_rate ? parseFloat(user_payload.interest_rate) : leadData?.interest_rate ? parseFloat(leadData.interest_rate) : 10;

                    const sanction_amount = user_payload?.loan_amount ? parseFloat(user_payload.loan_amount) : leadData?.loan_amount ? parseFloat(leadData.loan_amount) : 100000;

                    const respV3 = await coLenderSelectorV3Api(pageName, user_payload, pageDetails, tenure, sanction_amount, interest_rate);

                    if (respV3?.status) navigate(pageDetails?.nextRoute);
                    else navigate('/error');

                } else {
                    setLoading(false);
                    navigate('/error');
                    return false;
                }
            } else {
                setLoading(false);
                navigate('/error');
                return false;
            }
        } catch (e) {
            console.log('Error : ', e?.response?.data);
            setLoading(false);
            navigate('/error');
            return false;
        };



    }


    const coLenderSelectorV3Api = async (pageName, user_payload, pageDetails, tenureVal, sanctionAmountVal, interestRateVal) => {
        try {

            const payload = {
                "submitData": { //context

                    "partner_loan_app_id": user_payload?.partner_loan_app_id ?? leadData?.partner_loan_app_id,
                    "partner_borrower_id": user_payload?.partner_borrower_id ?? leadData?.partner_borrower_id,
                    "pan": user_payload?.pan ?? pan?.value,
                    "first_name": user_payload?.first_name ?? leadData?.first_name,
                    "last_name": user_payload?.last_name ?? leadData?.last_name,
                    "dob": user_payload?.dob ?? leadData?.dob,




                    // additional data
                    "loan_app_id": user_payload?.loan_app_id ?? leadData?.loan_app_id,
                    "appl_pan": user_payload?.pan ?? pan?.value,
                    // "first_name": "Sen",
                    // "last_name": "B",
                    // "dob": "1990-05-15",
                    "gender": user_payload?.gender ?? leadData?.gender,
                    "appl_phone": user_payload?.appl_phone ?? leadData?.appl_phone,
                    "address": (user_payload?.resi_addr_ln1 ? user_payload.resi_addr_ln1 : (leadData?.resi_addr_ln1 ?? "")) + " " + (user_payload?.resi_addr_ln2 ? user_payload.resi_addr_ln2 : (leadData?.resi_addr_ln2 ?? "")),
                    "city": user_payload?.city ?? leadData?.city,
                    "pincode": user_payload?.pincode?.toString() ?? leadData?.pincode?.toString(),
                    "state": stateConvertionMapping[user_payload?.state?.toUpperCase() ?? leadData?.state?.toUpperCase()] ?? '',
                    "borrower_profile": "Others",
                    "tenure": tenureVal,
                    "interest_rate": interestRateVal,
                    "sanction_amount": sanctionAmountVal,
                    "consent": "Y",
                    "consent_timestamp": moment().format("YYYY-MM-DD HH:mm:ss"),
                    "enquiry_purpose": "05",
                    "product_type_code": "STPL",

                },
                pageName,
            };

            const url = process.env.REACT_APP_SERVICE_URL + colenderSelectorV3 +
                `?encryptText=${encryptText}`;

            const resp = await axios({
                method: "post",
                url,
                data: payload,
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });


            if (resp?.data?.success) {
                ;
                const pollResponse = await invokePolling('co_lender_selector_v3', pageName);

                const queueData = pollResponse?.data?.queueDetails?.length > 0
                    ? pollResponse.data.queueDetails.find(item =>
                        item?.nodeName?.toLowerCase() === 'co_lender_selector_v3'
                    )
                    : null;

                if (pollResponse?.status && queueData?.status?.toLowerCase() === "success") {
                    setLoading(false);
                    return pollResponse;
                } else {
                    setLoading(false);
                    navigate('/error');;
                    return pollResponse;
                }
            } else {
                setLoading(false);
                navigate('/error');
                return { status: false, data: [] };
            }
        } catch (e) {
            console.log('Error : ', e?.response?.data);
            setLoading(false);
            navigate('/error');
            return { status: false, data: [] };
        };



    }



    const sendOtpToUser = async (otp) => {

        // 1 success
        // 2 failure because otp is wrong or any other error but api working
        // 3 other errors
        try {
            const url = process.env.REACT_APP_SERVICE_URL + verifyOtp + `?encryptText=${encryptText}`;
            const user_payload = JSON.parse(localStorage.getItem('user_payload'));
            const payload = {
                "request_id": adhaar.request_id,
                "loan_app_id": user_payload.loan_app_id,
                "aadhaar_number": adhaar.value,
                "pan": pan.value,
                otp: otp.join("")
            }
            const resp = await axios.post(url, payload, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });
            const response = resp.data;

            if (response.statusCode === 102) {
                setShowOtp(false);
                let modalPayload = showModal;
                modalPayload.show = true;
                modalPayload.message = "No Loan application found";
                setShowModal({ ...modalPayload });
                return { status: 2 };

            } else if (response.statusCode === 101) {
                let resp = await invokePolling('verifyotp');
                return resp;
            }

        } catch (e) {
            console.log(e)
        }


    }

    const otpPolling = (page, interval, resolve) => {
        // if (!loading) setLoading(true);
        let panNum = pan && pan.value ? pan.value : JSON.parse(localStorage.getItem("user_payload")).pan;
        axios.get(process.env.REACT_APP_SERVICE_URL + getStatus + `?pageName=kycVerification&pan=${panNum}&nodeName=AadhaarOtpVerify`, {
            headers: {
                "api-key": `Bearer ${request_id}`
            }
        })
            .then((resp) => {
                if (resp && resp.status === 200) {
                    if (resp.data.status.toLowerCase() == 'success') {
                        // setLoading(false);
                        setShowOtp(false);
                        clearInterval(interval);
                        updatePageStatus();
                        navigate(page?.nextRoute?.trim());
                        resolve({ status: true, data: resp?.data?.queueDetails });
                    } else if (resp.data.status.toLowerCase() === 'initiated') {
                        setPollAttempts((prevState) => {
                            if (prevState === 10) {
                                setPollAttempts(0);
                                clearInterval(interval)
                                setShowOtp(false);
                                // setLoading(false);
                                navigate('/error');
                                resolve({ status: false, data: resp.data.queueDetails })
                                return 0;
                            }
                            else return prevState + 1;
                        });
                    } else if (resp.data.status.toLowerCase() === 'failure') {
                        let details = resp?.data?.queueDetails;
                        console.log(details)
                        details = details[details.length - 1];
                        if (details?.resBody?.data?.statusCode === 102) {
                            setWrongOtp(true);
                        }
                        clearInterval(interval);
                        setPollAttempts(0);
                        resolve({ status: false, data: resp?.data?.queueDetails });
                    }

                }
            })
            .catch((e) => {
                console.log(e);
                setShowOtp(false);
                navigate('/error');
                clearInterval(interval);
                setPollAttempts(0);
                resolve({ status: false, data: "" })
            })
    }

    const otpPollingAsync = async (page) => {
        try {
            //debugger;
            // if (!loading) setLoading(true);
            let panNum = pan && pan.value ? pan.value : JSON.parse(localStorage.getItem("user_payload")).pan;
            const resp = await axios.get(process.env.REACT_APP_SERVICE_URL + getStatus + `?pageName=kycVerification&pan=${panNum}&nodeName=AadhaarOtpVerify`, {
                headers: {
                    "api-key": `Bearer ${request_id}`
                }
            });
            if (resp && resp.status === 200) {
                if (resp.data.status.toLowerCase() == 'success') {
                    // setLoading(false);
                    setShowOtp(false)
                    await updatePageStatus();
                    navigate(page?.nextRoute)
                    return { status: 1, data: resp.data.queueDetails };
                } else if (resp.data.status.toLowerCase() === 'initiated') {
                    return { status: 2 }
                } else if (resp.data.status.toLowerCase() === 'failure') {

                    let details = resp?.data?.queueDetails;
                    details = details[details.length - 1];
                    if (details?.resBody?.data?.statusCode === 102) {
                        setWrongOtp(true)
                    }
                    setPollAttempts(0);
                    return { status: 3 };
                }

            } else {
                setPollAttempts(0);
                throw new Error("Technical issue");
            }
        } catch (e) {
            console.log(e)
            navigate('/error');
            setPollAttempts(0);
            return { status: 4 };
        }
    }

    const pennyDropApiCall = async (url, method, user_payload, pageName, pageDetails, headers, nodeName = 'bankvalidation', pollingName = 'bank_verify_penny_drop') => {

        try {

            let account_type = undefined;
            for (let i = 0; i < radios?.length; i++) {
                const item = radios[i];
                if (item?.checked) {
                    account_type = item?.value;
                    break;
                }
            };

            const queueDetails = pageDetails?.nodes?.filter(item => item?.nodeName?.toLowerCase() === nodeName);

            const payload = {
                "submitData": { //context
                    // "partner_loan_app_id": user_payload?.partner_loan_app_id ?? leadData?.partner_loan_app_id,
                    // "partner_borrower_id": user_payload?.partner_borrower_id ?? leadData?.partner_borrower_id,
                    "pan": user_payload?.pan ?? pan?.value,
                    // "first_name": user_payload?.first_name ?? leadData?.first_name,
                    // "last_name": user_payload?.last_name ?? leadData?.last_name,
                    // "dob": user_payload?.dob ?? leadData?.dob,

                    // additional data
                    "loan_app_id": user_payload?.loan_app_id ?? leadData?.loan_app_id,
                    "ifsc": ifsc?.value,
                    "account_number": accountNum?.value,
                    "account_type": account_type,
                    "bank_name": bankName?.value,
                },
                pageName,
                nodeNameData: nodeName,
                queueData : [{
                    action : queueDetails[0]?.nodeName,
                    queue_name : queueDetails[0]?.queueName,
                }]
            };

            const resp = await axios({
                method,
                url,
                data: payload,
                headers
            });

            if (resp?.data?.success) {
                const pollResponse = await invokePolling(pollingName);
                const queueData = pollResponse?.data?.queueDetails?.length > 0
                    ? pollResponse.data.queueDetails.find(item =>
                        item?.nodeName?.toLowerCase() === 'namematching'
                    )
                    : null;
                const bankData = pollResponse?.data?.queueDetails?.length > 0
                    ? pollResponse.data.queueDetails.find(item =>
                        item?.nodeName?.toLowerCase() === nodeName
                    )
                    : null;

                if (pollResponse?.status && queueData?.status?.toLowerCase() === "success") {
                    // setLoading(false);
                    bankResp.current = bankData?.resBody?.data?.result;
                    await submit("/bank-details-loan-creation");
                }
                //  else {
                //     setLoading(false);
                //     setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account holder's name does not match the name as per PAN. Please try again with another bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                // }
            } else {
                // setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account number you shared is inactive. Please enter details of an active bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                // setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account holder's name does not match the name as per PAN. Please try again with another bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                setLoading(false);
                navigate('/error')
            }
        } catch (e) {
            console.log('Error : ', e?.response?.data);
            setLoading(false);
            navigate('/error')
        };

    }

    const loanCreationApiCall = async (url, method, user_payload, pageName, pageDetails, headers, nodeName = 'loancreation', pollingName = 'bank_verify_loan_creation') => {

        try {

            const data = await tokenValidation();
            const updatedLeadData = data?.data ?? leadData;
            //console.log('leaddata ', leadData)
            setLoading(true);
            const installments = rpsData?.data?.installments?.length > 0 ? rpsData.data.installments : [];
            const first_inst_date = installments?.at(1)?.due_date;
            const first_emi_amount = installments?.at(1)?.emi_amount;
            const emi_count = installments?.length - 1;
            const loan_int_amt = installments?.reduce((sum, curr_emi) => sum + curr_emi?.int_amount, installments?.at(0)?.int_amount) - installments?.at(0)?.int_amount;

            let account_type = undefined;
            for (let i = 0; i < radios?.length; i++) {
                const item = radios[i];
                if (item?.checked) {
                    account_type = item?.value;
                    break;
                }
            };

            const offer_tenure = await getOfferDataApi(); //JSON.parse(localStorage.getItem("offer_tenure"));
            let full_name = updatedLeadData?.first_name?.trim()
            full_name += (updatedLeadData?.middle_name !== "" ? " " + updatedLeadData?.middle_name?.trim() : "");
            full_name += (updatedLeadData?.last_name !== "" ? " " + updatedLeadData?.last_name?.trim() : "");


            const beneficiaryBankBetails =
                // user_payload?.beneficiary_details?.length > 0 ? user_payload.beneficiary_details[0] : updatedLeadData?.beneficiary_details?.length > 0 ? updatedLeadData.beneficiary_details[0] :
                    {
                        bank_name: `${updatedLeadData?.beneficiary_details?.at(0)?.bank_name ?? bankName?.value}`,
                        account_number: `${bankResp?.current?.accountNumber ?? accountNum?.value}`,
                        ifsc: `${bankResp?.current?.ifsc ?? ifsc?.value}`,
                        name: `${bankResp?.current?.accountName ? bankResp.current.accountName : (stateData?.bankHolderName ?? full_name)}`,
                        account_type: `${updatedLeadData?.beneficiary_details?.at(0)?.account_type ?? account_type}`,
                    };

            const queueDetails = pageDetails?.nodes?.filter(item => item?.nodeName?.toLowerCase() === nodeName);


            const payload = {
                "submitData": { //context

                    "partner_loan_app_id": user_payload?.partner_loan_app_id ?? updatedLeadData?.partner_loan_app_id,
                    "partner_borrower_id": user_payload?.partner_borrower_id ?? updatedLeadData?.partner_borrower_id,
                    "loan_app_id": user_payload?.loan_app_id ?? updatedLeadData?.loan_app_id,
                    "borrower_id": user_payload?.borrower_id ?? updatedLeadData?.borrower_id,
                    // "partner_loan_id": "MNTYU138100", create on bff using date and loan app id now
                    partner_loan_id: (user_payload?.loan_app_id ?? leadData?.loan_app_id) + user_payload?.pan,
                    "a_score_request_id": updatedLeadData?.a_score_request_id ?? "HAP0039-BUREAU-SCORECARD-V2-1721647798318", // from lead data
                    "co_lender_assignment_id": updatedLeadData?.co_lender_assignment_id ?? 10474, // from lead data
                    "loan_app_date": moment().format('YYYY-MM-DD'), //create on bff current date
                    "loan_amount_requested": user_payload?.requested_loan_amount?.toString() ?? updatedLeadData?.requested_loan_amount?.toString(), // from lead data
                    "sanction_amount": user_payload?.requested_loan_amount?.toString() ?? updatedLeadData?.requested_loan_amount?.toString(), // from lead data
                    "processing_fees_perc": "0", // default 0
                    "processing_fees_amt": '0', // // from lead data // tech_fees
                    "gst_on_pf_perc": '0', // default 0 ?
                    "gst_on_pf_amt": '0', // // from lead data // tech_fees_gst
                    "conv_fees": `${Number(user_payload?.tech_fees) + Number(user_payload?.gst_on_tech_fees)}`, // default 100
                    "insurance_amount": "0", // default 0
                    "net_disbur_amt": `${parseFloat(user_payload?.policy_premium_amount)}`, //// from lead data // policy_premium_amount
                    "int_type": "Reducing", // default Reducing
                    "loan_int_rate": user_payload?.loan_interest_rate?.toString() ?? updatedLeadData?.loan_interest_rate?.toString(), //"NOT AVAILABLE", //loan_int_rate
                    "loan_int_amt": `${loan_int_amt?.toFixed(2)}`, // for now defaul =1223 // later aakash will decide
                    broken_period_int_amt: "0", // defualt 0
                    "repayment_type": "Monthly", // default Monthly
                    "tenure_type": "Month", // default Month
                    "tenure": offer_tenure?.offer_tenure?.toString() ?? '12', // from lead data // loan_tenure or tenure


                    "first_inst_date": `${first_inst_date}`, // copy a function fron nodeapi //call calculateInstalmentDate func OR // call rps api and get this value
                    "emi_amount": `${first_emi_amount}`, // call rps api and get this value
                    "emi_count": `${emi_count}`, // call rps api and get this value


                    "final_approve_date": moment().format('YYYY-MM-DD'), // current date
                    "final_remarks": "NIL", // default NIL
                    "monthly_income": user_payload?.borrower_monthly_income?.toString() ?? updatedLeadData?.borrower_monthly_income?.toString(), // from lead data 


                    "borro_bank_name": beneficiaryBankBetails?.bank_name, // same as beneficiary data
                    "borro_bank_acc_num": beneficiaryBankBetails?.account_number,
                    "borro_bank_ifsc": beneficiaryBankBetails?.ifsc,
                    "borro_bank_account_holder_name": beneficiaryBankBetails?.name ?? user_payload?.customer_name,
                    "borro_bank_account_type": beneficiaryBankBetails?.account_type,
                    "bene_bank_name": beneficiaryBankBetails?.bank_name,
                    "bene_bank_acc_num": beneficiaryBankBetails?.account_number,
                    "bene_bank_ifsc": beneficiaryBankBetails?.ifsc,
                    "bene_bank_account_holder_name": beneficiaryBankBetails?.name ?? user_payload?.customer_name,
                    "bene_bank_account_type": beneficiaryBankBetails?.account_type,

                    "program_type": "Bureau", // default Bureau
                    "purpose_of_loan": "Insurance",  //default Insurance

                    //additional data
                    "pan": user_payload?.pan ?? pan?.value,
                    "first_name": user_payload?.first_name ?? updatedLeadData?.first_name,
                    "last_name": user_payload?.last_name ?? updatedLeadData?.last_name,
                    "dob": user_payload?.dob ?? updatedLeadData?.dob,
                    "employment_status":JSON.parse(localStorage.getItem("user_payload"))?.emp_status,
                    'src':"pwa"

                },
                pageName,
                nodeNameData: nodeName,
                queueData : [{
                    action : queueDetails[0]?.nodeName,
                    queue_name : queueDetails[0]?.queueName,
                }]
            };

            if(!payload?.submitData?.employment_status) {
                payload.submitData = {
                    ...payload?.submitData,
                    "employment_status" : leadData?.borrower_profile,
                }
            }


            const resp = await axios({
                method,
                url,
                data: payload,
                headers
            });

            if (resp?.data?.success) {
                const pollResponse = await invokePolling(pollingName);
                const queueData = pollResponse?.data?.queueDetails?.length > 0
                    ? pollResponse.data.queueDetails.find(item =>
                        item?.nodeName?.toLowerCase() === nodeName
                    )
                    : null;
                if (pollResponse?.status && nodeName === 'loancreation' && (queueData?.resBody?.message === "EX_LOAN_017 Loan Application ID already exists. Kindly verify if correct loan application ID has been entered." || queueData?.status?.toLowerCase() === "success")) {
                    // setLoading(false);
                    // navigate(pageDetails?.nextRoute);

                    // await submit('/create-nach-subscription');
                    await submit('/bank-details-rps-save');

                } else {
                    setLoading(false);
                    navigate("/error");
                }
            } else {
                setLoading(false);
                navigate("/error");
            }
        } catch (e) {
            console.log('Error : ', e?.response?.data);
            setLoading(false);
            navigate("/error");
        };

    }

    const rpsSaveApiCall = async (url, method, user_payload, pageName, pageDetails,headers, nodeName = 'rpscreation', pollingName = 'bank_verify_rps_save') => {

        try {

            const data = await tokenValidation();
            const updatedLeadData = data?.data ?? leadData;
            setLoading(true);
            const installments = rpsData?.data?.installments?.length > 0 ? rpsData.data.installments : [];
            // const downPay_due_date = installments?.at(0)?.due_date;
            // const downPay_amount = installments?.at(0)?.emi_amount;

            // const offer_tenure = JSON.parse(localStorage.getItem("offer_tenure"));

            const queueDetails = pageDetails?.nodes?.filter(item => item?.nodeName?.toLowerCase() === nodeName);


            const payload = {
                "submitData": { //context

                    "loan_app_id": user_payload?.loan_app_id ?? updatedLeadData?.loan_app_id,
                    "appl_pan": user_payload?.pan ?? pan?.value,
                    "loan_id": updatedLeadData?.loan_id,
                    "installments": installments,
                    // "company_id": updatedLeadData?.company_id,
                    // "product_id": updatedLeadData?.product_id,
                    // "tenure": parseFloat(offer_tenure?.offer_tenure),
                    // "sanction_amount": parseFloat(user_payload?.requested_loan_amount ?? updatedLeadData?.requested_loan_amount),
                    // "downpayment_pre_disbursement": parseFloat(downPay_amount),
                    // "downpayment_pre_disb_date": downPay_due_date,
                    // "loan_app_date": moment().format('YYYY-MM-DD'),
                    // "roi": parseFloat(user_payload?.loan_interest_rate ?? updatedLeadData?.loan_interest_rate),
                    // "repayment_type": "Monthly",

                },
                pageName,
                nodeNameData: nodeName,
                queueData : [{
                    action : queueDetails[0]?.nodeName,
                    queue_name : queueDetails[0]?.queueName,
                }]
            };


            const resp = await axios({
                method,
                url,
                data: payload,
                headers
            });

            if (resp?.data?.success) {
                const pollResponse = await invokePolling(pollingName);
                const queueData = pollResponse?.data?.queueDetails?.length > 0
                    ? pollResponse.data.queueDetails.find(item =>
                        item?.nodeName?.toLowerCase() === nodeName
                    )
                    : null;
                if (pollResponse?.status && queueData?.status?.toLowerCase() === "success") {
                    await updatePageStatus();
                    setLoading(false);
                    navigate(pageDetails?.nextRoute?.trim());


                    // await submit('/create-nach-subscription');
                    // window.location.href = `https://cfre.in/mzmufed`; 

                } else {
                    setLoading(false);
                    navigate("/error");
                }
            } else {
                setLoading(false);
                navigate("/error");
            }
        } catch (e) {
            console.log('Error : ', e?.response?.data);
            setLoading(false);
            navigate("/error");
        };

    }

    const createNachSubscription = async (url, method, user_payload, pageName = 'PAYMENTS', pageDetails, headers, nodeName = 'createsubscription', pollingName = 'create_nach_subscription') => {

        try {

            const installments = rpsData?.data?.installments?.length > 0 ? rpsData.data.installments : [];
            const last_inst_date = installments && installments[installments?.length - 1]?.due_date;
            const first_emi_amount = installments?.at(1)?.emi_amount;
            const emi_count = installments?.length - 1;

            let full_name = leadData?.first_name?.trim()
            full_name += (leadData?.middle_name !== "" ? " " + leadData?.middle_name?.trim() : "");
            full_name += (leadData?.last_name !== "" ? " " + leadData?.last_name?.trim() : "");

            const subscription_id = (user_payload?.loan_app_id ?? leadData?.loan_app_id) + user_payload?.pan + moment().format('YYYYMMDDHHmmss');

            const queueDetails = pageDetails?.nodes?.filter(item => item?.nodeName?.toLowerCase() === nodeName);

            const payload = {
                "submitData": {  // context
                    "loan_app_id": user_payload?.loan_app_id ?? leadData?.loan_app_id,
                    "appl_pan": user_payload?.pan ?? leadData?.appl_pan,
                    "subscription_id": subscription_id, //250 characters
                    "customer_name": full_name,
                    "customer_phone": leadData?.appl_phone ?? user_payload?.appl_phone,
                    "customer_email": leadData?.email_id,
                    "return_url": `${process.env.REACT_APP_SERVICE_URL}${process.env.REACT_APP_NACH_RETURN_URL?.split('/')?.at(1)}`,
                    "auth_amount": 1, //1
                    "expires_on": `${moment(last_inst_date).add(2, 'months').format('YYYY-MM-DD')} ${moment().format('HH:mm:ss')}`, //"2024-12-02 09:20:12"
                    "notes": {
                        "key1": "value1",
                        "key2": "value2",
                        "key3": "value3",
                        "key4": "value4"
                    },
                    "plan_info": {
                        "plan_id": leadData?.borrower_id + moment().format('YYYYMMDDHHMMSS'), // borrower_id+timestamp
                        "type": "ON_DEMAND", //"PERIODIC" OR "ON_DEMAND",
                        "plan_name": user_payload?.policy_type, // to be decided //policy_premium
                        "max_amount": parseFloat(first_emi_amount), // first emi
                        "max_cycles": parseFloat(emi_count), // emi count
                        "link_expiry": 5, // authUrl in response
                        "amount": 1, //
                        "interval": 1,
                        "interval_type": "month",
                        "description": "This is a sample periodic plan"
                    },
                    "notification_channels": [
                        "EMAIL",
                        "SMS"
                    ]
                },
                pageName,
                nodeNameData: nodeName,
                queueData : [{
                    action : queueDetails[0]?.nodeName,
                    queue_name : queueDetails[0]?.queueName,
                }]
            };


            const resp = await axios({
                method,
                url,
                data: payload,
                headers
            });

            if (resp?.data?.success) {
                const pollResponse = await invokePolling(pollingName, pageName);
                const queueData = pollResponse?.data?.queueDetails?.length > 0
                    ? pollResponse.data.queueDetails.find(item =>
                        item?.nodeName?.toLowerCase() === nodeName
                    )
                    : null;
                if (pollResponse?.status && queueData?.status?.toLowerCase() === "success" && queueData?.resBody?.data?.authLink) {
                    // setLoading(false);
                    // setTimeout(() => {
                    //     window.location.href = `${queueData?.resBody?.data?.authLink}`; //https://cfre.in/mzmufed
                    //   }, 2000);
                    window.location.href = `${queueData?.resBody?.data?.authLink}`;

                    // await submit('/create-nach-subscription');
                    // navigate(pageDetails?.nextRoute); 
                } else {
                    setLoading(false);
                    navigate('/error')
                }
            } else {
                setLoading(false);
                navigate('/error')
            }
        } catch (e) {
            console.log('Error : ', e?.response?.data);
            setLoading(false);
            navigate('/error')
        };

    }

    const createOrderApiCall = async (url, method, user_payload, pageName, pageDetails, headers, nodeName = 'createorder', pollingName = 'payments_create_order') => {

        try {


            const order_id = (user_payload?.loan_app_id ?? leadData?.loan_app_id) + user_payload?.pan + moment().format('YYYYMMDDHHmmss');
            const customer_id = (user_payload?.loan_app_id ?? leadData?.loan_app_id) + user_payload?.pan;

            const queueDetails = pageDetails?.nodes?.filter(item => item?.nodeName?.toLowerCase() === nodeName);


            const payload = {
                "submitData": { //context
                    appl_pan: user_payload?.pan ?? leadData?.appl_pan,
                    "loan_app_id": user_payload?.loan_app_id ?? leadData?.loan_app_id,
                    "customer_phone": leadData?.appl_phone ?? user_payload?.appl_phone,
                    "customer_email": leadData?.email_id,
                    "customer_id": customer_id,
                    "return_url": `${process.env.REACT_APP_RETURN_BASE_URL}?order_id={order_id}&redirectToPayments=true`,//`${process.env.REACT_APP_RETURN_BASE_URL}${process.env.REACT_APP_PAYMENT_RETURN_URL?.split('/')?.at(1)}?order_id={order_id}`,
                    "notify_url": "",
                    "order_id": order_id,
                    "order_amount": parseFloat(rpsData?.data?.installments?.at(0)?.emi_amount),
                    "order_currency": "INR"
                },
                pageName,
                queueData : [{
                    action : queueDetails[0]?.nodeName,
                    queue_name : queueDetails[0]?.queueName,
                }]
            };

            const resp = await axios({
                method,
                url,
                data: payload,
                headers
            });

            if (resp?.data?.success) {
                const pollResponse = await invokePolling(pollingName);
                const queueData = pollResponse?.data?.queueDetails?.length > 0
                    ? pollResponse.data.queueDetails.find(item =>
                        item?.nodeName?.toLowerCase() === 'createorder'
                    )
                    : null;

                if (pollResponse?.status && queueData?.status?.toLowerCase() === "success") {
                    setLoading(false);
                    setLoadMsg(null);
                    // await submit("/bank-details-loan-creation");
                    if (queueData?.resBody?.payment_session_id) {
                        redirectToPayments(queueData?.resBody?.payment_session_id);
                    }
                }
                //  else {
                //     setLoading(false);
                //     setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account holder's name does not match the name as per PAN. Please try again with another bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                // }
            } else {
                // setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account number you shared is inactive. Please enter details of an active bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                // setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account holder's name does not match the name as per PAN. Please try again with another bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                setLoading(false);
                navigate("/error");
            }
        } catch (e) {
            console.log('Error : ', e?.response?.data);
            setLoading(false);
            navigate("/error");
        };

    }

    const getPaymentStatus123 = async (order_id, pageName, pageDetails, pollingName = 'get_down_payment_status', nodeName = 'getorderstatus') => {

        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));

            const url = process.env.REACT_APP_SERVICE_URL +
            getPaymentStatus +
                `?encryptText=${encryptText ?? user_payload?.user_details}`;

            const payload = {
                "submitData": { //context
                    appl_pan: user_payload?.pan ?? leadData?.appl_pan,
                    "loan_app_id": user_payload?.loan_app_id ?? leadData?.loan_app_id,
                    "order_id": order_id,
                },
                pageName: pageName?.trim(),
            };

            const resp = await axios({
                method: "post",
                url,
                data: payload,
                headers: {
                    "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
                }
            });

            if (resp?.data?.success) {
                const pollResponse = await invokePolling(pollingName, pageName?.trim());
                const queueData = pollResponse?.data?.queueDetails?.length > 0
                    ? pollResponse.data.queueDetails.find(item =>
                        item?.nodeName?.toLowerCase() === nodeName
                    )
                    : null;

                if (pollResponse?.status && queueData?.status?.toLowerCase() === "success") {
                    const result = checkPaymentTransactionStatus(queueData?.resBody?.order_status?.toLowerCase())
                    // setLoading(false);
                    return result;
                    // await submit("/bank-details-loan-creation");
                    // if(queueData?.resBody?.payment_session_id) {
                    //     redirectToPayments(queueData?.resBody?.payment_session_id);
                    // }
                }
                //  else {
                //     setLoading(false);
                //     setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account holder's name does not match the name as per PAN. Please try again with another bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                // }
            } else {
                // setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account number you shared is inactive. Please enter details of an active bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                // setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account holder's name does not match the name as per PAN. Please try again with another bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                setLoading(false);
                navigate("/error");
            }
        } catch (e) {
            console.log('Error : ', e?.response?.data);
            setLoading(false);
            navigate("/error");
        };

    }

    const getPageStatus = async (pathName=null, pages=null) => {
        // if(location?.pathname !== '/') {
        //     try {

        //         const user_payload = JSON.parse(localStorage.getItem("user_payload"));
        //         let user_id = user_payload?.user_id;
        //         if(typeof(user_id) === 'object') user_id = user_id?.user_id;
        //         const token = user_payload?.request_id;
        //         const finalPages = pages ? pages : jsonPages; 
        //         const finalPathname = pathName ? pathName : location?.pathname;
        //         let pageDetails = finalPages?.find((page) => {
        //             return page?.currentRoute === finalPathname
        //         })

        //         const url = process.env.REACT_APP_SERVICE_URL + getPageStatusUrl + `/${user_id}/${pageDetails?.id?.trim()}`;
        //         const headers = {
        //             'api-key' : `Bearer ${token}`
        //         }


        //         const resp = await axios({
        //             method: 'get',
        //             url,
        //             headers
        //         });

        //         if (resp?.data?.success) {
        //             if(resp?.data?.code === 102)
        //             {
        //                 if(resp?.data?.data?.page_id) 
        //                 {
        //                     let pageToRoute = finalPages?.find((page) => {
        //                         return page?.id === resp.data.data.page_id
        //                     })
        //                     navigate(`${pageToRoute?.currentRoute}`);
        //                     return true;
        //                 }
        //                 else navigate('/error');
        //             }
        //         } else {
        //             navigate("/error");
        //         }
        //         setLoading(false);    
        //     } catch (e) {
        //         console.log('Error : ', e?.response?.data);
        //         setLoading(false);
        //         navigate("/error");
        //     };
        // }
return;
    }

    const updatePageStatus = async (pathName=null, pages=null) => {
return;
        try {

            const user_payload = JSON.parse(localStorage.getItem("user_payload"));
            const user_id = user_payload?.user_id;
            const token = user_payload?.request_id;
            const finalPages = pages ? pages : jsonPages; 
            const finalPathname = pathName ? pathName : location?.pathname;
            let pageDetails = finalPages?.find((page) => {
                return page?.currentRoute === finalPathname
            })

            const url = process.env.REACT_APP_SERVICE_URL + updatePageStatusUrl + `/${user_id}/${pageDetails?.id?.trim()}`;
            const headers = {
                'api-key' : `Bearer ${token}`
            }


            const resp = await axios({
                method: 'post',
                url,
                headers
            });

            if (resp?.data?.success) {
                navigate(pageDetails?.nextRoute?.trim());
            } else {
                navigate("/error");
            }
            setLoading(false);    
        } catch (e) {
            console.log('Error : ', e?.response?.data);
            setLoading(false);
            navigate("/error");
        };

    }

    const fetchXml = async () => {
        setLoading(true);

        
        const user_payload = localStorage.getItem("user_payload") ? JSON.parse(localStorage.getItem("user_payload")) : null;

        const body = {
            user_id : user_payload?.user_id,
        }

        const token = user_payload?.request_id;

        const headers = {
            "api-key":`Bearer ${token}`
        }

        if(!user_payload?.user_id || !token) {
            navigate('/error');
            return;
        }

        const resp = await axios.post(process.env.REACT_APP_SERVICE_URL + getXmlUi , body , {headers});
        if(resp && resp.status === 200 && resp.data && resp.data.success){
            const pages = resp.data.data;
            setPages(pages);
            setRoute(false,pages[0]?.currentRoute);
            return [...pages];
        }
        setLoading(false);
    }

    const getNachStatus = async (order_id,payment_id, pageName, pageDetails, pollingName = 'get_nach_status', nodeName = 'getsubscriptionstatus') => {

        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));

            const url = process.env.REACT_APP_SERVICE_URL +
                getNachStatusUrl +
                `?encryptText=${encryptText ?? user_payload?.user_details}`;

            const queueDetails = pageDetails?.nodes?.filter(item => item?.nodeName?.toLowerCase() === nodeName);

            const payload = {
                "submitData": { //context
                    "appl_pan": user_payload?.pan ?? leadData?.appl_pan,
                    "loan_app_id": user_payload?.loan_app_id ?? leadData?.loan_app_id,
                    "subscription_id": order_id,
                    "payment_id": payment_id,
                },
                pageName: pageName?.trim(),
                queueData : [{
                    action : queueDetails[0]?.nodeName,
                    queue_name : queueDetails[0]?.queueName,
                }]
            };

            

            const resp = await axios({
                method: "post",
                url,
                data: payload,
                headers: {
                    "api-key": `Bearer ${request_id ?? user_payload?.request_id}`
                }
            });

            if (resp?.data?.success) {
                const pollResponse = await invokePolling(pollingName, pageName?.trim());
                const queueData = pollResponse?.data?.queueDetails?.length > 0
                    ? pollResponse.data.queueDetails.find(item =>
                        item?.nodeName?.toLowerCase() === nodeName
                    )
                    : null;

                if (pollResponse?.status && queueData?.status?.toLowerCase() === "success") {
                    const result = checkNachTransactionStatus(queueData?.resBody?.subscription_status?.toLowerCase(), queueData?.resBody?.authorization_details?.authorization_status?.toLowerCase() )
                    // setLoading(false);
                    return result;
                    // await submit("/bank-details-loan-creation");
                    // if(queueData?.resBody?.payment_session_id) {
                    //     redirectToPayments(queueData?.resBody?.payment_session_id);
                    // }
                }
                //  else {
                //     setLoading(false);
                //     setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account holder's name does not match the name as per PAN. Please try again with another bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                // }
            } else {
                // setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account number you shared is inactive. Please enter details of an active bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                // setShowPincodeModal({ ...{ show: true, message: `We could not verify your bank details as the account holder's name does not match the name as per PAN. Please try again with another bank account.`, showRetryBtn: retryPincode > 0 ? true : false } })
                setLoading(false);
                navigate("/error");
            }
        } catch (e) {
            console.log('Error : ', e?.response?.data);
            setLoading(false);
            navigate("/error");
        };

    }

    const getOfferDataApi = async () => {
        try {
            const user_payload = JSON.parse(localStorage.getItem("user_payload"));

            const headers = {
                'api-key': `Bearer ${request_id ?? user_payload?.request_id}`
            }
            
            const url = process.env.REACT_APP_SERVICE_URL + getOfferData + `?loan_app_id=${user_payload?.loan_app_id}`;
            if (!user_payload?.loan_app_id) throw new Error("Loan App Id not found");

            const resp = await axios.get(url, { headers });
            if (resp && resp.data && resp.data.statusCode === 200 && resp?.data?.body?.data?.tenure?.toString()) {
               return {offer_tenure : resp?.data?.body?.data?.tenure?.toString()};
            } else {
                throw new Error("Offer tenure details not found");
            }
        } catch (e) {
            console.log('Error : ',e?.response?.data);
            navigate('/error');
        }
    }

    const getNachUi = () => {
        return (
            <div className="main-div fonts">
                <div className="container-div">
                    <div className="image-div d-flex justify-content-center">
                        <img src={nachLogo} />
                    </div>
                    <center className="mt-2">
                        <div style={{ fontWeight: "700", fontSize:"23px", fontFamily:"Inter", color:"#161719" }} className="content d-flex justify-content-center">
                            Your NACH is being processed                    
                        </div>
                        <div style={{ color:"#6B7280", fontSize:"18px" }} className="content d-flex justify-content-center mt-3">
                            {`Your NACH (National Automated Clearing House) request is currently being processed. This can take between 6 to 24 hours for confirmation from your bank.`}                    
                        </div>
                    </center>
                    {/* <div className="d-flex justify-content-center mt-5">
                        <a href="/" className="footer"> Home </a>
                    </div> */}
                    <div className="content d-flex justify-content-center">
                    <div style={{ position: 'fixed', bottom: '0'}} className=" mb-2 width-screen ">
                        <div className="col-12">
                            <Button onClick={() => navigate('/') } className='submit-button' variant='contained' style={{ width: "100%" }}>Home</Button>
                        </div>
                    </div>
                    </div>
                    
                </div>
            </div>
        )

    }

    const createNach = async () => {
        setLoading(true);
        setLoadMsg(`You are now being redirected 
        to set up your NACH authorization 
        for auto-debit. Please do not refresh the page 
        or click the “Back” or “Close” button of your browser`);
        await submit('/create-nach-subscription');   
    }

    const executeOnNachPage = async () => {
        setLoading(true);

        let page = jsonPages?.find((page) => {
            return page.currentRoute === location?.pathname
        })

        const result = await invokePolling('check_subscription_created',page?.id?.trim() ?? 'PAYMENTS');

        switch(result?.status) {
            case 1: // enach subscription already created, now check for nach status
                
                const queueData = result?.data?.queueDetails?.length > 0 
                ? result.data.queueDetails.find(item => 
                    item?.nodeName?.toLowerCase() === 'createsubscription'
                ) 
                : null;     
                
                setLoading(true);

                const subscriptionId = queueData?.reqBody?.subscription_id?.toString();
                let paymentId = localStorage?.getItem("payment_id") && JSON.parse(localStorage?.getItem("payment_id"))?.toString();
                const subReferenceId = queueData?.resBody?.data?.subReferenceId?.toString();

                let callFromCashfree = false;
                let callFromPageOrder = false;

                if(nachData?.current?.subscriptionId && nachData?.current?.paymentId && nachData?.current?.subReferenceId) {
                    callFromCashfree = true;
                    if(localStorage?.getItem("payment_id")) {
                        localStorage?.removeItem("payment_id");
                    }
                    localStorage?.setItem("payment_id",nachData?.current?.paymentId);
                    paymentId = nachData?.current?.paymentId

                } else {
                    callFromPageOrder = true;
                }

                if(callFromCashfree && !callFromPageOrder) {
                    if(!(subscriptionId === nachData?.current?.subscriptionId && subReferenceId === nachData?.current?.subReferenceId)) {
                        navigate('/error');
                        return;
                    }
                }

                let poll = "2";
                while(poll === "2") {
                    poll = await getNachStatus(subscriptionId,paymentId,page?.id?.trim() ?? 'PAYMENTS',page);
                    if(poll === '1') { // nach success
                        await updatePageStatus();
                        setLoading(false);
                        navigate(page?.nextRoute?.trim() ?? '/payments');
                        break;
                    } else if(poll === '3') {  // nach failed, create new nach one more time
                        await createNach();
                        break;                         
                    } else if(poll === '4') {  // nach initialized, user has not authorized it yet
                        window.location.href = `${queueData?.resBody?.data?.authLink}`;
                        break;                         
                    } else if (poll === false) { // unexcepted response or some api failure
                        setLoading(false);
                        navigate('/error');
                        break;
                    }
                }
    
                setLoading(false);

                break;
            case 2: // enach subscription created but in initiated state
            case 4:  // enach subscription creation failed
                break;
            case 3: // create enach subscription
                createNach();
                break;
            
        }


    }

    const executeOnDownPaymentsPage = async () => {
        setLoading(true);

        let page = jsonPages?.find((page) => {
            return page.currentRoute === location?.pathname
        })

        const result = await invokePolling('check_order_created',page?.id?.trim() ?? 'cashfreePayments');

        switch(result?.status) {
            case 1: // down payment order already created, now check for down payment status
                
                const queueData = result?.data?.queueDetails?.length > 0 
                ? result.data.queueDetails.find(item => 
                    item?.nodeName?.toLowerCase() === 'createorder'
                ) 
                : null;     

                const orderId = queueData?.reqBody?.order_id?.toString();

                let callFromCashfree = false;
                let callFromPageOrder = false;

                if(searchParams.get('order_id')) {
                    callFromCashfree = true;
                } else {
                    callFromPageOrder = true;
                }

                if(callFromCashfree && !callFromPageOrder) {
                    if(!(orderId === searchParams.get('order_id'))) {
                        navigate('/error');
                        return;
                    }
                }

                let poll = "2";
                while(poll === "2") {
                    poll = await getPaymentStatus(orderId,page?.id ?? 'cashfreePayments',page);
                    if(poll === '1') { // down payment success
                        await updatePageStatus();
                        setLoading(false);
                        navigate(`${process.env.REACT_APP_PAYMENT_RETURN_URL}`);
                        break;
                    } else if(poll === '3') {  // down payment failed, create new down payment order
                        break;                         
                    } else if (poll === false) { // unexcepted response or some api failure
                        setLoading(false);
                        navigate('/error');
                        break;
                    }
                }
    
                setLoading(false);

                break;
            case 2: // down payment order created but in initiated state
            case 4:  // down payment order creation failed
                break;
            case 3: // create down payment order
                break;
            
        }


    }
    



    const contexts = {
        setPages,
        showHeader,
        showFooter,
        setShowHeader,
        setShowFooter,
        jsonPages,
        getHeader,
        getSubHeader,
        getmobileField,
        getpanField,
        getadhaarField,
        setRequiredFields,
        setMobile,
        setMobileSuccessText,
        setMobileErrorMessag,
        setPanErrorMessag,
        setPan,
        setAdhaar,
        getCheckBoxField,
        setIsChecked,
        getButtonField,
        showOtp,
        setShowOtp,
        nextRoute,
        getIfscField,
        getBankNameField,
        getAccNumField,
        getConfirmAccNumField,
        getRadioButtonFields,
        setIfsc,
        setBankName,
        setAccountNum,
        setConfirmAccountNum,
        setRadios,
        getEmailField,
        getMonthlyIncome,
        getFatherName,
        getEmpStatus,
        getAddressField,
        getCameraField,
        showSubmit,
        consent,
        setConsent,
        getNameField,
        getDobField,
        getPayments,
        tokenValidation,
        loading,
        setRoute,
        currentRoute,
        pageNum,
        setPageNum,
        currentAddress,
        setCurrentAddress,
        currentAddressError,
        setCurrentAddressError,
        cityOpt,
        setCityOpt,
        stateOpt,
        setStateOpt,
        aadharAddress,
        setAadharAddress,
        leadData,
        setLeadData,
        invokePolling,
        setLoading,
        sendOtpToUser,
        setShowSubmit,
        showSubmit,
        openCamera,
        stage,
        sendSelfieDetailsData,
        wrongOtp,
        setWrongOtp,
        coLenderSelectorV3Api,
        errorAlert,
        setErrorAlert,
        callAscoreRequest,
        rpsData,
        callRpsApi,
        getPaymentStatus,
        request_id,        
        setShowPincodeModal,
        getPageStatus,
        updatePageStatus,
        fetchXml,
        getNachStatus,
        getOfferDataApi,
        createNachSubscription,
        getNachUi,
        loadMsg,
        setLoadMsg,
        executeOnNachPage,
        executeOnDownPaymentsPage,
        

    };
    return (
        <Context.Provider value={contexts}>
            {children}
        </Context.Provider>
    )


}

export default ContextProvider;