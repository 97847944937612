import React from 'react';
import { useState, useEffect } from 'react';
import { Button, Alert } from '@mui/material';
import Footer from "../commonComponents/Footer";
import logo from '../images/downpaymentLogo/wallet-check.png'
import '../styles/payments.css'
import 'bootstrap/dist/css/bootstrap.css';
import FlowHeader from '../commonComponents/FlowHeader';
import RouteProtector from '../commonComponents/routeProctector'
import { load } from '@cashfreepayments/cashfree-js';
import { getUserToken } from '../helpers/data';
import { getOfferData, offerDet, createOrder, getStatus } from '../apis';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from '../commonComponents/loader';
import moment from 'moment';

const Payments = (props) => {


    const [loading, setLoading] = useState(false);
    const [amount, setAmount] = useState("");

    const [createOrderPollAttempts, setCreateOrderPollAttempts] = useState(10);

    const navigate = useNavigate();

    useEffect(() => {
        getSelectedTenure()
    }, []);


    const redirectToPayments = async () => {
        setLoading(true);
        try {
            const id = await getOrderId();
            if (!id.status) throw new Error();

            const cashfree = await load({
                mode: process.env.NODE_ENV === 'production' ? 'production' : 'sandbox'
            });

            let checkoutOptions = {
                paymentSessionId: `session_-_9QZ-XBL07QUGHmSTiRJTAyWU2haV6Z6rXh3ukl7rsKWDJLmM_QMfNzJ5wyrqp_ulo9DFrUC0kT0f33MnMUThYUqHz_u_18JIpUBsWyuuhW`,
                redirectTarget: "_self",
            }

            cashfree.checkout(checkoutOptions);
        } catch (e) {
            navigate('/error');
        }
        setLoading(false);
    }

    const getOrderId = async () => {
        try {
            const token = getUserToken();
            const request_id = localStorage.getItem("request_id");

            const headers = {
                'api-key': `Bearer ${request_id}`
            }
            const order_id = token.loan_app_id + token.pan + moment().format('YYYYMMDDHHmmss');
            const customer_id = token.loan_app_id + token.pan



            const payload = {
                "submitData": {
                    "appl_pan": token.pan,
                    "loan_app_id": token.loan_app_id,
                    "customer_phone": token.appl_phone,
                    "customer_email": token.email_id,
                    "customer_id": customer_id,
                    "return_url": `${process.env.REACT_APP_RETURN_BASE_URL}?order_id={order_id}`,
                    "notify_url": "",
                    "order_id": order_id,
                    "order_amount": parseFloat(amount),
                    "order_currency": "INR"
                },
                pageName: "cashfreePayments",
                queueData: [{
                    action: 'CreateOrder',
                    queue_name: "process_queue",
                }]
            };


            const url = process.env.REACT_APP_SERVICE_URL + createOrder;

            const response = await axios.post(url, payload, { headers });

            if (response && response.data && response.data.success) {
                const resp = await pollOrderIdStatus();
                if (!resp.status) throw new Error();
                return { status: true, id: resp.data };
            } else throw new Error();
        } catch (e) {
            return { status: false }
        };
    }

    const pollOrderIdStatus = async () => {
        return new Promise(async (resolve) => {
            try {
                let interval = "";
                const response = await getOrderIdStatus(interval, resolve);
                debugger;
                if (response.status === 2) {
                    interval = setInterval(async () => {
                        const resp = await getOrderIdStatus(interval, resolve);
                        if (resp && resp.status) {
                            if (resp.status == 1) {
                                clearInterval(interval);
                                return resolve({ status: true, data: resp.id });
                            } else if (resp.status == 3) {
                                clearInterval(interval);
                                return resolve({ status: false });
                            }
                        }
                    }, 3000)
                } else if (response.status === 1) return resolve({ status: true, data: response.id });
                else if (response.status === 3) return resolve({ status: false });
            } catch (e) {
                return resolve({ status: false });
            }
        })
    }

    const getOrderIdStatus = async (interval, resolve) => {
        try {
            const token = getUserToken();
            const request_id = localStorage.getItem("request_id");

            const headers = {
                "api-key": `Bearer ${request_id}`
            }

            const url = process.env.REACT_APP_SERVICE_URL + getStatus + `?pan=${token.pan}&pageName=cashfreePayments`

            const response = await axios.get(url, { headers });
            if (response && response.data && response.data.queueDetails) {
                const queueDetails = response.data.queueDetails.find((obj) => obj.apiName === 'CREATE_ORDER');
                if (queueDetails.apiStatus.toLowerCase() === 'success') {
                    return {status:1 , id:queueDetails.resBody.payment_session_id}
                } else if (queueDetails.apiStatus.toLowerCase() === 'initiated') {
                    if (interval) {
                        setCreateOrderPollAttempts((prevState) => {
                            if (prevState === 1) {
                                clearInterval(interval);
                                return resolve({ status: false })
                            } else return prevState = prevState - 1;
                        })
                    } else return { status: 2 }
                } else if (queueDetails.apiStatus.toLowerCase() === 'failure') {
                    return { status: 3 }
                } else throw new Error();
            } else throw new Error();
        } catch (e) {
            console.log(e);
            return { status: 3 }
        }
    }

    const getSelectedTenure = async () => {
        setLoading(true);
        try {
            const request_id = localStorage.getItem("request_id");
            const token = getUserToken();
            const headers = {
                'api-key': `Bearer ${request_id}`
            }


            const id = process.env.NODE_ENV === 'production' ? token.loan_app_id : 'ANANT-5101116443755'

            const url = process.env.REACT_APP_SERVICE_URL + getOfferData + `?loan_app_id=${id}`;
            const resp = await axios.get(url, { headers });
            if (resp && resp.data && resp.data.statusCode === 200) {
                const tenure = resp.data.body.data.tenure;
                const response = await getDownPaymentAmount(tenure);
                if (!response) throw new Error();
            }
        } catch (e) {
            navigate('/error');
        }

        setLoading(false);
    }

    const getDownPaymentAmount = async (tenure) => {
        try {
            const request_id = localStorage.getItem("request_id");
            const token = getUserToken();
            const headers = {
                'api-key': `Bearer ${request_id}`
            }
            const policy_premium = token.policy_premium_amount;

            const url = process.env.REACT_APP_SERVICE_URL + offerDet;

            const reqBody = {
                tenure: tenure,
                policy_premium
            }
            const offerDetailsResp = await axios.post(url, reqBody, { headers });

            if (offerDetailsResp && offerDetailsResp.data && offerDetailsResp.data.data && offerDetailsResp.data.data.installments) {
                const downPayment = offerDetailsResp.data.data.installments[0].emi_amount;
                setAmount(downPayment);
                return true;
            }
            return false;
        } catch (e) {
            return false;
        }
    }

    return (
        <>
            {loading && <Loader />}
            <FlowHeader page = {props.page} />
            <div style={{ height: "90%", fontFamily: '"Inter", sans-serif' }} className="d-flex flex-column justify-content-between">
                <div className="container mt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 header">
                                Make Payment
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                You're almost there! Complete the down payment to finalize your loan approval
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div style={{ fontSize: "14px", fontWeight: "500" }} className='col-12'>
                                Payable Amount
                            </div>
                        </div>
                        <div style={{ alignItems: "center", height: "100%" }} className='row mt-2'>
                            <div className='col-2'>
                                <div className='d-flex justify-content-center' style={{ background: "#D4E3FC", borderRadius: "20px", height: "100%" }}>
                                    <img style={{ padding: "10px" }} src={logo} />
                                </div>
                            </div>
                            <div className='col-9'>

                                <div className='row'>
                                    <div style={{ fontWeight: "600", fontSize: "16px", color: "#181818" }} className='col-12 d-flex justify-content-between'>
                                        <div>
                                            Down Payment
                                        </div>
                                        <div>
                                            {amount}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div style={{ fontWeight: "400", fontSize: "12px" }} className='col-12'>
                                        Due on 01/04/24
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>
                            <div style={{ fontSize: "14px", fontWeight: "500" }} className='col-12'>
                                <Alert style={{ fontWeight: "400", fontSize: "12px", color: "black", backgroundColor: "#FFFBEB", border: "1px solid #00000014" }} variant="filled" severity="info">
                                    By making a down payment, you can start enjoying the benefits of your policy right away, while we handle the rest. It's like paying a small part upfront to get full protection.
                                    <br />
                                    <br />
                                    Please note, you will not be able to proceed without completing the down payment.
                                </Alert>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container button-container mt-4">
                    <div className="container">
                        <div className="row button-container-com">
                            <div className="col-12">
                                <Button onClick={redirectToPayments} className='submit-button' variant='contained' style={{ width: "100%" }}>Pay Now</Button>
                            </div>

                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default RouteProtector(Payments);