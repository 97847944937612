// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-div{
    height: 100%;
}


.fonts{
    font-family: "Inter", sans-serif;
}

.container-div{
    height: 100%;
    margin:auto;
    padding: 10px;
    padding: 20px;
}

.image-div-fallback{
    height: auto;
    padding: 10px;
}

.heading{
    font-weight: 600;
    font-size: 26px;
    color: #424345;
}

.content{
    font-weight: 500;
    font-size: 16px;
    color: #4F4F4F;
}

.footer{
    font-size: 16px;
    font-weight: 500;
    color:#0076B2;
    
}`, "",{"version":3,"sources":["webpack://./src/styles/fallback.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;;AAGA;IACI,gCAAgC;AACpC;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,aAAa;;AAEjB","sourcesContent":[".main-div{\n    height: 100%;\n}\n\n\n.fonts{\n    font-family: \"Inter\", sans-serif;\n}\n\n.container-div{\n    height: 100%;\n    margin:auto;\n    padding: 10px;\n    padding: 20px;\n}\n\n.image-div-fallback{\n    height: auto;\n    padding: 10px;\n}\n\n.heading{\n    font-weight: 600;\n    font-size: 26px;\n    color: #424345;\n}\n\n.content{\n    font-weight: 500;\n    font-size: 16px;\n    color: #4F4F4F;\n}\n\n.footer{\n    font-size: 16px;\n    font-weight: 500;\n    color:#0076B2;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
