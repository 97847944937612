// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.support-cards{
    padding:20px;
    background: #F8F8F8;
    border-radius: 20px;
}

.img-support{
    padding: 10px;
    background: white;
    border-radius: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/customerSupport.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".support-cards{\n    padding:20px;\n    background: #F8F8F8;\n    border-radius: 20px;\n}\n\n.img-support{\n    padding: 10px;\n    background: white;\n    border-radius: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
