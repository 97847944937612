import React, { useContext, useEffect, useState } from "react";
import logo from '../images/statusLogo/4457 1 (1).png';
import 'bootstrap/dist/css/bootstrap.css';
import { Button } from "@mui/material";
import Footer from "../commonComponents/Footer";
import queryString from "query-string";
import { useNavigate } from 'react-router-dom';
import { Context } from "../context/context";
import { useLocation, Link } from 'react-router-dom';
import Loader from "../commonComponents/loader";
import { getPaymentStatus, getStatus } from "../apis";
import { getUserToken, updateAndNext } from "../helpers/data";
import axios from 'axios';

const PaymentStatus = () => {


    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [orderStatusPollAttempts , setOrderStatusPollAttempts] = useState(10);



    useEffect(() => {
        getOrderStatus();
    }, [])


    const getOrderStatus = async () => {
        debugger;
        setLoading(true);
        try {
            const searchParams = new URLSearchParams(location.search);
            const order_id = searchParams.get('order_id');

            if (!order_id) {
                navigate('/');
                return;
            }

            const request_id = localStorage.getItem("request_id");
            const headers = {
                "api-key": `Bearer ${request_id}`
            }
            const user_payload = getUserToken();

            const url = process.env.REACT_APP_SERVICE_URL + getPaymentStatus;

            const payload = {
                "submitData": {
                    "appl_pan": user_payload.pan,
                    "loan_app_id": user_payload.loan_app_id,
                    "order_id": order_id,
                },
                pageName: "payments"
            };

            const resp = await axios.post(url, payload, { headers });

            if (resp && resp.data && resp.data.success) {
                const orderStatusResp = await pollOrderStatus();
                if(!orderStatusResp) {
                    navigate('/error');
                    return;
                }

                await updateAndNext('/payments');
            } else throw new Error();

        } catch (e) {
            navigate('/error')
        };
        setLoading(false);
    }

    const pollOrderStatus = async () => {
        return new Promise(async (resolve) => {
            try {
                let interval = "";
                const response = await checkOrderStatus(interval , resolve);
                if(response == 2){
                    interval = setInterval(async () => {
                        const resp = await checkOrderStatus(interval , resolve);
                        if(resp == 1){
                            clearInterval(interval);
                            return resolve(true);
                        }else if(resp === 3){
                            clearInterval(interval);
                            return resolve(false);
                        }
                    },3000)
                }else if(response == 1) return resolve(true);
                else if(response === 3) return resolve(false);
            } catch (e) {
                resolve(false);
            }
        })
    }

    const checkOrderStatus = async (interval , resolve) => {
        try {
            const user_payload = getUserToken();
            const request_id = localStorage.getItem("request_id");
            const headers = {
                'api-key': `Bearer ${request_id}`
            }
            const url = process.env.REACT_APP_SERVICE_URL + getStatus + `?pan=${user_payload.pan}&pageName=payments`
            const resp = await axios.get(url, { headers });
            if(resp && resp.data && resp.data.queueDetails){
                const details = resp.data.queueDetails.find((obj) => obj.nodeName.toLowerCase() === 'getorderstatus');
                if(details.apiStatus.toLowerCase() === 'success'){
                    return 1;
                }else if(details.apiStatus.toLowerCase() === 'initiated'){
                    if(interval){
                        setOrderStatusPollAttempts((prevState) => {
                            if(prevState === 1){
                                clearInterval(interval);
                                return resolve(false);
                            }else return prevState = prevState - 1;
                        })
                    }else return 2;
                }else if(details.apiStatus.toLowerCase() === 'failure'){
                    return 3;
                }
            }else throw new Error();
        } catch (e) {
            return 3;
        }
    }


    return (
        <>
            {loading && <Loader />}
            <div style={{ height: "-webkit-fill-available" }} className="d-flex flex-column justify-content-between">
                <div className="container pt-4">
                    <div className="container d-flex justify-content-center">
                        <img src={logo} />
                    </div>
                    <div style={{ fontWeight: "600", fontSize: "28px", fontFamily: '"Inter", sans-serif' }} className="container d-flex justify-content-center mt-5">
                        Congratulations
                    </div>
                    <div style={{ fontWeight: "400", fontSize: "14px", fontFamily: '"Inter", sans-serif', color: "#9CA3AF" }} className="container d-flex justify-content-center mt-3">
                        <center>Your down payment is successfully paid and loan application has been successfully submitted.</center>
                    </div>
                    <div style={{ fontWeight: "500", fontSize: "14px", fontFamily: '"Inter", sans-serif', color: "#2087BC" }} className="container d-flex justify-content-center mt-4">
                        <Link to='/payment-schedule' className="d-flex justify-content-center" style={{ color: "#2087BC" }}>Click here to check your repayment schedule.</Link>
                    </div>
                    <div style={{ fontWeight: "400", fontSize: "14px", fontFamily: '"Inter", sans-serif', color: "#9CA3AF" }} className="container d-flex justify-content-center mt-3">
                        <center>In order to have a smooth repayment experience, please ensure that you have enough balance in your account every month, to pay your EMI on time.</center>
                    </div>
                </div>
                <div className="container mb-2">
                    <div className="container">
                        <Button variant="contained" style={{ width: "100%" }} onClick={() => navigate('/')} >Back to Home</Button>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentStatus;