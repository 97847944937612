import React from "react";
import FlowHeader from "../commonComponents/FlowHeader";
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/customerSupport.css';
import phone from '../images/phone/call.png';
import email from '../images/email/sms.png';
const CustomerSupport = (props) => {
    return (
        <>
        <FlowHeader showProgress = {false}/>
            <div style={{ fontFamily: '"Inter", sans-serif' }} className="container">
                <div className="container">
                    <div style={{ fontWeight: "600", fontSize: "24px", paddingTop: "10px" }}>
                        Customer Support
                    </div>
                    <div className="support-cards mt-4">
                        <div className="row">
                            <div className="col-3">
                                <div>
                                    <img className="img-support"  src={phone} />
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="row">
                                    <div style={{ fontWeight: "400", fontSize: "18px", color: '#161719' }} className="col-12">
                                        Call Us
                                    </div>
                                </div>
                                <div className="row">
                                    <div style={{ fontWeight: "600", fontSize: "16px"}} className="col-12">
                                    <a style = {{color: '#0076B2' ,textDecoration:'none'}} href="tel:+919617892765">+91 9617892765</a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="support-cards mt-4">
                        <div className="row">
                            <div className="col-3">
                                <div>
                                    <img className="img-support"  src={email} />
                                </div>
                            </div>
                            <div className="col-9">
                                <div className="row">
                                    <div style={{ fontWeight: "400", fontSize: "18px", color: '#161719' }} className="col-12">
                                        Email Us
                                    </div>
                                </div>
                                <div className="row">
                                    <div style={{ fontWeight: "600", fontSize: "16px" }} className="col-12">
                                        <a style={{ color: '#0076B2' , textDecoration:'none'}} href={`mailto:${'support@arthmate.com'}`}>support@arthmate.com</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerSupport;