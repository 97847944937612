import React from "react";
import '../styles/fallback.css';
import logo from '../images/landingPageLogo/4457 1.png';

// use Link instead of <a> below once wrapped in browser router
const SuccessPage = () => {
    return (
        <div className="main-div fonts">
            <div className="container-div">
                <div className="image-div d-flex justify-content-center">
                    <img src={logo} />
                </div>
                <center className="mt-2">
                    <div style={{ fontWeight: "700", fontSize:"28px" }} className="content d-flex justify-content-center">
                        Congratulations!
                    </div>
                    <div className="content d-flex justify-content-center">
                    Your loan applications has been successfully submitted.                    </div>
                </center>
                <div className="d-flex justify-content-center mt-5">
                    <a href="/" className="footer"> Back to Home </a>
                </div>
            </div>
        </div>
    )
}

export default SuccessPage;