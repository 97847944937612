import { useState, useRef, useEffect, useContext, useReducer } from "react";
import { Context } from "../context/context";
// 0 - off , 1 - on , 2 - recording ,
const Recorder = (props) => {
    const [recorder, setRecorder] = useState(null);
    const [stream, setStream] = useState(null);
    const [blobs, setBlobs] = useState(null);
    const [blobUrl, setBlobUrl] = useState(null);
    const [img , setImg] = useState('');
    let videoRef = useRef(null);
    let canvasRef = useRef(null);


    const global = useContext(Context);

    useEffect(() => {
        if (videoRef.current && stream && props.stage == 1) videoRef.current.srcObject = stream;
    }, [videoRef.current, stream])

    useEffect(() => {
        if (props.stage == 1) start();
        else if (props.stage == 2) record();
        else if (props.stage == 3) stop();
        else if (props.stage == 4) props.callApi(blobs , img);
    }, [props.stage])


    const start = async () => {
        try {
            //debugger
            const streamObj = await navigator.mediaDevices.getUserMedia({
                video: true,
                audio: true
            });
            const mimeType = navigator && navigator.userAgentData && navigator.userAgentData.platform === "Linux" ? "video/webm" : "video/mp4";
            const mediaRecorder = new MediaRecorder(streamObj, { mimeType });
            let blobs = []
            setStream(streamObj);
            setRecorder(mediaRecorder);


            mediaRecorder.addEventListener('dataavailable', e => {
                blobs.push(e.data)
            });

            mediaRecorder.addEventListener('stop', () => {
                let video_local = URL.createObjectURL(new Blob(blobs, { type: mimeType }));
                setBlobUrl(video_local);
                const finalBlob = new Blob(blobs, { type: mimeType });

                setBlobs(finalBlob);
                mediaRecorder.removeEventListener('stop', () => { });
                mediaRecorder.removeEventListener('dataavailable', () => { });
            });


        } catch (e) {
            alert(e.message);
            console.log("Camera could not be started..");
        }
    }

    const record = () => {
        recorder.start(1000);
        setTimeout(takePhoto(), 1000);
    }

    const stop = () => {
        recorder.stop();
    }

    const takePhoto = () => {
        if (videoRef.current && stream) {
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');
            context.drawImage(videoRef.current, 0, 0, canvas.height, canvas.width);
            const image = canvas.toDataURL('application/pdf');
            const base64 = image.split(',')[1];
            setImg(base64);
        }
    }

    return (
        <>
            {
                props.stage >= 3 ?
                    <> <video playsInline style={{ width: "-webkit-fill-available", borderRadius: "10px" }} id="video" controls src={blobUrl} /></>
                    :
                    <>
                        <video playsInline style={{ width: "-webkit-fill-available", borderRadius: "10px" }} autoPlay id="video" ref={videoRef} />
                        <canvas ref={canvasRef} style={{ display: 'none' }} width="200" height="200"></canvas>
                    </>
            }
        </>
    )
}

export default Recorder;
