export const landingPagePoints = {
    0:"Complete KYC Verification",
    1:"Share Profile Details",
    2:'Select EMI Plan',
    3:'Complete Selfie ID Verification',
    4:'Add Bank Details',
    5:'Complete Down Payment'
}

export const termsAndConditions = 
`I hereby declare that I am an Indian National. 
I agree to Arthmate Financing India Private Limited 
(AFIPL) privacy policy, grievance redressal policy and 
authorize AFIPL to carry out credit enquiries on the Credit Bureaus. 
I give my consent to one or more lending partners of AFIPL to make one or 
multiple credit enquiries with the credit bureaus or any other agencies authorized by RBI. 
I also authorize AFIPL and one or more lending partners of AFIPL to retrieve credit reports on 
my behalf from CRIF Highmark Private Limited or Experian India Private Limited or Transunion CIBIL Limited
.By clicking on verify, you consent to receiving communications including but not 
limited to SMS, e-mails, phone calls, whatsapp from us and/or our partners, 
including Banks and NBFCs, with respect to your transaction on the website or for any other purpose.`
