
import React from 'react';
import { useState, useEffect, useContext } from 'react';
import logo from '../images/landingPageLogo/4457 1.png';
import '../styles/landingPage.css';
import 'bootstrap/dist/css/bootstrap.css';
import { landingPagePoints } from '../constants';
import Button from '@mui/material/Button';
import lineLogo from '../images/dottedLine/Line 10.png';
import Footer from '../commonComponents/Footer';
import { Link, useNavigate } from 'react-router-dom';
import { Context } from '../context/context';
import Permissions from '../commonComponents/permissions';
import { createLead, getStatus } from '../apis';
import Loader from '../commonComponents/loader';
import axios from 'axios';
import BottomStickModal from '../commonComponents/BottomStickModal';
import RouteProtector from '../commonComponents/routeProctector';
import { getUserToken , getRoute } from '../helpers/data';

const button = <div className="col-6">
    <a href=""><Button style={{ width: "100%" }} variant="contained"> Back To Home</Button></a>
</div>
const LandingPage = (props) => {
    // const globalStates = useContext(Context);
    const navigate = useNavigate();
    const [error, setError] = useState({ status: false, message: "" });
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pollAttempts, setPollAttempts] = useState(5);
    const [nextRoute , setNextRoute] = useState("");
    const [consent , setConsent] = useState(false);
    useEffect(() => {
        getNextRoute();
    }, [])


    const getNextRoute = async () => {
        debugger
        const request_id = localStorage.getItem('request_id');
        const resp = await getRoute(request_id);

        if(resp && resp.status) setNextRoute(resp.url)
        else navigate('/error');
    }

    const createLeadOnLanding = async () => {
        try {
            setLoading(true)
            const payload = getUserToken();
            const request_id = localStorage.getItem('request_id');
            const data = localStorage.getItem('data');
            const submitData = {
                "pan": payload.pan,
                "dob": payload.dob,
                "policy_number": payload.policy_number,
                "policy_premium_amount": payload.policy_premium_amount,
                "policy_tenure": payload.policy_tenure,
                "gender": payload.gender,
                "email_id": payload.email_id,
                "appl_phone": payload.appl_phone,
                "policy_type": payload.policy_type,
                "nominee_name": payload.nominee_name,
                "user_id": payload.user_id,
                "first_name": payload.first_name,
                "middle_name": payload.middle_name,
                "last_name": payload.last_name,
                "partner_loan_app_id": payload.partner_loan_app_id,
                "partner_borrower_id": payload.partner_borrower_id,
                "requested_loan_amount": payload.requested_loan_amount,
                "loan_amount": payload.loan_amount,
                "tenure": payload.tenure,
                "loan_interest_rate": payload.loan_interest_rate,
                "interest_rate": payload.interest_rate,
                "loan_app_id": payload.loan_app_id,
                "borrower_id": payload.borrower_id
            }


            const body = {
                pageName: "startPageDetails",
                submitData
            }

            const headers = { 'api-key': `Bearer ${request_id}` }

            const url = process.env.REACT_APP_SERVICE_URL + createLead + `?encryptText=${data}`;
            const response = await axios.post(url, body, { headers });
            if (response && response.data && response.data.success) {
                await pollStatus();
            }

            setLoading(false);
        } catch (e) {
            setLoading(false);
            navigate('/error');
        }


    }


    const pollStatus = async () => {
        return new Promise(async (resolve) => {
            try {
                let interval = "";
                const response = await checkLeadStatus(interval);
                if (response && response.status == 2) {
                    interval = setInterval(() => checkLeadStatus(interval), 3000);
                }
                resolve();
            } catch (e) {
                navigate('/error');
            }
        })
    }


    const checkLeadStatus = async (interval) => {
        try {
            const token = getUserToken();
            const pan = token.pan;
            const request_id = localStorage.getItem("request_id");
            const headers = {
                'api-key': `Bearer ${request_id}`
            }
            const url = process.env.REACT_APP_SERVICE_URL + getStatus + `?pageName=startPageDetails&pan=${pan}`;
            const response = await axios.get(url, { headers });
            if (response && response.data && response.data.queueDetails && response.data.queueDetails.length) {
                const queueDetails = response.data.queueDetails[0];
                if (queueDetails.status.toLowerCase() === 'success') navigate(nextRoute)
                else if (queueDetails.status.toLowerCase() === 'initiated') {
                    setPollAttempts((prevState) => {
                        if (prevState === 1) {
                            clearInterval(interval);
                        } else return prevState = prevState - 1;
                    })
                    return {status:2};
                } else if (queueDetails.status.toLowerCase() === 'failure') navigate('/error');
            } else throw new Error();
        } catch (e) {
            clearInterval(interval);
            navigate('/error');
        }
    }


    const closeModal = () => {
        setOpen(false);
    }

    const openModal = () => {
        setConsent(true);
        setOpen(true);
    }

    return (
        <div className='text-font' style={{ height: "100%", width: '100%' }}>
            {consent && !loading && <Permissions callApi = {createLeadOnLanding} createLead = {props.new} route = {nextRoute} close={closeModal} open={open} />}
            {loading && <Loader />}
            {error.status && <BottomStickModal buttons={button} subHeading={error.message} />}
            <div className='d-flex justify-content-center image-div div-width'>
                <img src={logo} />
            </div>
            <div key='loan-ins-header' className='mt-2 div-width'>
                <span key={'span-ins-header-123'} className='text'>Get a loan for your insurance in
                    <b className='focus-text'> 6 easy steps!</b>
                </span>
            </div>
            <div className='d-flex flex-column image-div div-width'>
                {
                    Object.keys(landingPagePoints).map((ind, index) => {
                        return (
                            <>
                                <div key={`${index}-ind1`} className='d-flex'>
                                    <div className='bullets align-middle' />
                                    <span className='align-middle'>{landingPagePoints[ind]}</span>
                                </div>
                                {index != 5 && <div key={`${index}-ind`} className='d-flex'>
                                    <img style={{ marginLeft: "9px" }} src={lineLogo} />
                                </div>}
                            </>
                        )
                    })
                }
            </div>
            <div className='d-flex justify-content-center mt-5 div-width'>
                {!consent ? <Button disabled={loading} onClick={openModal} className='start-button' variant='contained' style={{ width: "100%" }}>START</Button> :
                    <Link to={nextRoute} style={{ width: "100%" }} >
                        <Button disabled={loading} className='start-button' variant='contained' style={{ width: "100%" }}>START</Button>
                    </Link>}
            </div>

            <Footer />

        </div>
    );
}

export default RouteProtector(LandingPage);
