import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { styled } from '@mui/material/styles';
import plus from '../images/plus/plus.png';
import cross from '../images/cross/cross.png';
import FlowHeader from "../commonComponents/FlowHeader";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
const FAQs = (props) => {


    const [accordians, setAccordians] = useState([false])
    // remove all the applied css by mui
    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
    }));


    const handleAccordianChange = (index) => {
        let accordianState = accordians;
        accordianState[index] = !accordianState[index];
        setAccordians([...accordianState]);
    }

    return (
        <>
            <FlowHeader showProgress={false} />
            <div style={{ height: "80%", fontFamily: '"Inter", sans-serif' }}>
                <div className="container mt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 header">
                                Frequently Asked Questions
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                If you encounter any challenges not addressed by the FAQs, please feel free to reach out to the Point of Contact (PoCs) for assistance. We are here to help you
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`mt-3 pt-3 pb-4 ${accordians[0] ? 'make-grey' : ""}`}>
                    <div className="container">
                        <div className="container">
                            <Accordion className={accordians[0] ? `make-grey` : ""} onChange={() => handleAccordianChange(0)} expanded={accordians[0]}>
                                <MuiAccordionSummary sx={accordians[0] ? { background: "#F8F8F8", padding: '0px' } : {}} expandIcon={<img src={accordians[0] ? cross : plus} />}>
                                    Header
                                </MuiAccordionSummary>
                                <MuiAccordionDetails className={accordians[0] ? `make-grey` : ""}>
                                    Details
                                </MuiAccordionDetails>
                            </Accordion>
                            {accordians[0] ? "" : <hr />}
                        </div>
                    </div>
                </div>
                <div className={`pt-3 pb-4 ${accordians[0] ? 'make-grey' : ""}`}>
                    <div className="container">
                        <div className="container">
                            <Accordion className={accordians[0] ? `make-grey` : ""} onChange={() => handleAccordianChange(0)} expanded={accordians[0]}>
                                <MuiAccordionSummary sx={accordians[0] ? { background: "#F8F8F8", padding: '0px' } : {}} expandIcon={<img src={accordians[0] ? cross : plus} />}>
                                    Header
                                </MuiAccordionSummary>
                                <MuiAccordionDetails className={accordians[0] ? `make-grey` : ""}>
                                    Details
                                </MuiAccordionDetails>
                            </Accordion>
                            {accordians[0] ? "" : <hr />}
                        </div>
                    </div>
                </div>
                <div className={`pt-3 pb-4 ${accordians[0] ? 'make-grey' : ""}`}>
                    <div className="container">
                        <div className="container">
                            <Accordion className={accordians[0] ? `make-grey` : ""} onChange={() => handleAccordianChange(0)} expanded={accordians[0]}>
                                <MuiAccordionSummary sx={accordians[0] ? { background: "#F8F8F8", padding: '0px' } : {}} expandIcon={<img src={accordians[0] ? cross : plus} />}>
                                    Header
                                </MuiAccordionSummary>
                                <MuiAccordionDetails className={accordians[0] ? `make-grey` : ""}>
                                    Details
                                </MuiAccordionDetails>
                            </Accordion>
                            {accordians[0] ? "" : <hr />}
                        </div>
                    </div>
                </div>
            </div>
            <div className="make-grey">
                <div className="container pt-3 pb-3">
                    <div className="container">
                        <div className="row">
                            <div style={{ fontWeight: "500", fontSize: "16px" }} className="col-12">
                                Have any other questions?
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12" style={{ fontWeight: "400", fontSize: "14px" }}>
                                Please click here to reach out to our <Link to={'/customer-support'} style={{ color: '#0076B2' }}>customer support</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FAQs;