import React from "react";
import Counter from "./counter";
import { verifyOtp, getStatus } from "../apis";
import 'bootstrap/dist/css/bootstrap.css';
import CircularProgress from '@mui/material/CircularProgress';
import '../styles/otpValidation.css';
import { useRef, useEffect, useState } from "react";
import { Button } from "@mui/material";
import axios from 'axios';
import { getUserToken } from "../helpers/data";
import { useNavigate } from "react-router-dom";
const Otp = (props) => {


    let inputRef = useRef([])

    const [otp, setOtp] = useState([]);



    const { submit, loading, cancel  , setWrongOtp , wrongOtp} = props;

    useEffect(() => {

        if (inputRef && !wrongOtp) inputRef.current[0].focus();
    }, [])

    useEffect(() => {
        // console.log('here', wrongOtp)
    }, [wrongOtp])


    const onCancelClick = () => {
        cancel();
        setOtp([]);
    }

    const onSubmit = async () => {
       await submit(otp);
    }




    const isSubmitDisabled = () => {
        if (otp.includes("-") || otp.length != 6) return true;

        return false;
    }

    const handleOtpInput = (val, index) => {
        if (loading) return
        if (wrongOtp) setWrongOtp()
        if (val && isNaN(val.target.value)) return;
        let otpArr = otp;
        if (val.target.value[val.target.value.length - 1]) otpArr[index] = val.target.value[val.target.value.length - 1]
        else otpArr.splice(index, 1);

        setOtp([...otpArr]);
        if (index < 5 && val.target.value) inputRef.current[index + 1].focus();

        if (!val.target.value && index) inputRef.current[index - 1].focus();
    }

    return (
        <div className="row d-flex justify-content-center">
            <div className="row d-flex justify-content-between mt-3 row-width">
                {/* <div className="col-sm-5 col-xs-5 col-md-5 d-flex justify-content-between"> */}
                <input key={'x'} style={{ border: wrongOtp && "1px solid #CC0000", color: wrongOtp && "#CC0000" }} type="password" value={otp[0] || ""} onChange={(e) => handleOtpInput(e, 0)} ref={(reference) => (inputRef.current[0] = reference)} className="otp-input" disabled={loading} />
                <input key={'y'} style={{ border: wrongOtp && "1px solid #CC0000", color: wrongOtp && "#CC0000" }} type="password" value={otp[1] || ""} onChange={(e) => handleOtpInput(e, 1)} ref={(reference) => (inputRef.current[1] = reference)} className="otp-input" disabled={loading} />
                <input key={'z'} style={{ border: wrongOtp && "1px solid #CC0000", color: wrongOtp && "#CC0000" }} type="password" value={otp[2] || ""} onChange={(e) => handleOtpInput(e, 2)} ref={(reference) => (inputRef.current[2] = reference)} className="otp-input" disabled={loading} />
                <input key={'a'} style={{ border: wrongOtp && "1px solid #CC0000", color: wrongOtp && "#CC0000" }} type="password" value={otp[3] || ""} onChange={(e) => handleOtpInput(e, 3)} ref={(reference) => (inputRef.current[3] = reference)} className="otp-input" disabled={loading} />
                <input key={'b'} style={{ border: wrongOtp && "1px solid #CC0000", color: wrongOtp && "#CC0000" }} type="password" value={otp[4] || ""} onChange={(e) => handleOtpInput(e, 4)} ref={(reference) => (inputRef.current[4] = reference)} className="otp-input" disabled={loading} />
                <input key={'c'} style={{ border: wrongOtp && "1px solid #CC0000", color: wrongOtp && "#CC0000" }} type="password" value={otp[5] || ""} onChange={(e) => handleOtpInput(e, 5)} ref={(reference) => (inputRef.current[5] = reference)} className="otp-input" disabled={loading} />
                {/* </div> */}
            </div>

            <Counter resend = {() => props.resend()} wrongOtp={wrongOtp} />
            <div className="row d-flex justify-content-between mt-4 row-width">
                <Button onClick={onCancelClick} size="medium" className="button cancel-button mr-3" disabled={loading}> Cancel </Button>
                <Button onClick={onSubmit} disabled={isSubmitDisabled()} size="medium" variant={"contained"} className="button"> {loading ? <CircularProgress style={{ color: 'white' }} size={"25px"} className="loader" /> : "Submit"} </Button>

            </div>
        </div>
    )
}

export default Otp;