import { Country, State, City } from 'country-state-city';
import { getNextRoute , updatePageStatus } from '../apis';
import axios from 'axios';

export const States = State.getStatesOfCountry("IN").map((state) => {
  if (state?.name == 'Puducherry') state.name = 'Pondicherry';
  const obj = state;
  obj.value = state.name;
  obj.label = state.name;
  return obj;
});

export const Cities = (state) =>
  City.getCitiesOfState('IN', state?.isoCode).map((city) => {
    const obj = city;
    obj.value = city.name;
    obj.label = city.name;
    return obj;
  });

export const getUserToken = () => {
  return JSON.parse(localStorage.getItem("user_payload"));
}

export const getRoute = async (request_id) => {
  try {
    const headers = {
      'api-key': `Bearer ${request_id}`
    }

    const token = getUserToken();
    const user = token.user_id;
    const company = token.company_id || '8782777'

    const url = process.env.REACT_APP_SERVICE_URL + getNextRoute + `/${user}/${company}`;

    const response = await axios.get(url, { headers });
    if (response && response.data && response.data.code === 101) return { status: true, url: response.data.redirect }
  } catch (e) {
    console.log(e);
    return { status: false };
  }
}

export const updateAndNext = async (path) => {
  try {
    const token = getUserToken();

    const url = process.env.REACT_APP_SERVICE_URL + updatePageStatus + `/${token.user_id}/${token.company_id}${path}`;
    const request_id = localStorage.getItem("request_id");
    const headers = {
      'api-key': `Bearer ${request_id}`
    }
    const response = await axios.put(url, {}, { headers });
    if (response && response.data && response.data.code === 101) {
      return response.data.redirect
    } else if (response && response.data && response.data.code === 103) return "/error";
  } catch (e) {
    console.log(e);
    return "/error";
  }
}


