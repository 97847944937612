import React, { useEffect, useState } from "react";
import { Dialog, Slide, Button } from "@mui/material";
import '../styles/commonModal.css';
import 'bootstrap/dist/css/bootstrap.css';

const BottomStickModal2 = (props) => {
    const { header = "", subHeading = "", buttons, customStyle1="container my-5",customStyle2="row heading-row",customStyle3="col-12 heading d-flex justify-content-center align-items-center",customStyle4="row heading-row",customStyle5="col-12 sub-heading d-flex justify-content-center align-items-center",customStyle6="row heading-row d-flex justify-content-center align-items-center", styling3={} } = props;

    const { isOpen = true } = props;

    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });


    const [open, setOpen] = useState(false);



    useEffect(() => {
        setOpen(isOpen);
    }, []);



    return (
        <Dialog
            open={open}
            className="dialog-text"
            TransitionComponent={Transition}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className={customStyle1}>
                <div className={customStyle2}>
                    <div className={customStyle3}
                    style = {styling3}> 
                    {/* style={{color:"#767888", fontSize:"16px"}}> */}
                        {header}
                    </div>
                </div>
                <div className={customStyle4}>
                    <div className={customStyle5}>
                        {subHeading}
                    </div>
                </div>
                <div className={customStyle6}>
                    {buttons}
                </div>
            </div>
        </Dialog>
    )
}


export default BottomStickModal2;