import React from "react";
import { Button, TextField, Select, MenuItem } from "@mui/material";
import { useState } from "react";
import '../styles/personalDetails.css';
import FlowHeader from "../commonComponents/FlowHeader";
import Loader from "../commonComponents/loader";
import Footer from "../commonComponents/Footer";
import { updateLeadInfo , getStatus } from "../apis";
import { getUserToken , updateAndNext } from "../helpers/data";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import RouteProtector from "../commonComponents/routeProctector";
const PersonalDetails = (props) => {
    const [empStatus, setEmpStatus] = useState('');
    const [email, setEmail] = useState({ value: "", error: false });
    const [name, setName] = useState('');
    const [income, setIncome] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const handleEmailChange = (e) => {
        if (e.target.value.length <= 50){
            let emailObj = email;
            emailObj.value = e.target.value;
            setEmail({...emailObj});
            validateEmail(e.target.value)
        }
    }

    const handleNameChange = (e) => {
        if (/^[a-zA-Z ]+$/.test(e.target.value) || !e.target.value) setName(e.target.value)
    }

    const handleIncomeChange = (e) => {
        if (isNaN(e.target.value)) return;

        setIncome(e.target.value);
    }

    const handleEmpStatusChange = (e) => {
        setEmpStatus(e.target.value)
    }

    const validateEmail = (val) => {
        const emailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let emailObj = email;
        const value = val || email.value;
        if(emailValidation.test(value)){
            emailObj.error = false;
            setEmail({...emailObj});
            return true;
        }


        emailObj.error = true;
        setEmail({...emailObj});
       
        return false;
    }


    const handleButtonDisabled = () =>{
        if(!empStatus || email.error || !name || !income || loading) return true;

        return false;
    }

    const onSubmit = async () => {
        setLoading(true);
        try {
            const url = process.env.REACT_APP_SERVICE_URL + updateLeadInfo;
            const request_id = localStorage.getItem("request_id");
            const token = getUserToken();
            const headers = {
                'api-key': `Bearer ${request_id}`
            }

            const payload = {
                "submitData": {
                    "partner_loan_app_id": token.partner_loan_app_id,
                    "partner_borrower_id": token.partner_borrower_id,
                    "pan": token.pan,
                    "first_name": token.first_name,
                    "last_name": token.last_name,
                    "dob": token.dob,
                    "email_id": email.value,
                    "father_fname": name.split(" ")[0],
                    "father_lname": name.split(" ")[1] ? name.split(" ")[1] : "",
                    "borrower_monthly_income": income,
                    "borrower_profile": empStatus

                },
                pageName:'personalDetails'
            };
            const response = await axios.post(url, payload, { headers });
            if (response && response.data && response.data.success) {
                const resp = await pollStatus();
                if (!resp) throw new Error();

                const url = await updateAndNext(location.pathname);
                navigate(url);
                return;
            }
        } catch (e) {
            console.log(e);
            navigate('/error');
        }
        setLoading(false);
    }

    const pollStatus = async () => {
        return new Promise(async (resolve) => {
            try {
                let interval = "";
                const response = await checkUpdateStatus(interval, resolve);
                if (response == 2) {
                    interval = setInterval(async () => {
                        const resp = await checkUpdateStatus(interval, resolve);
                        // success
                        if (resp === 1) {
                            clearInterval(interval);
                            return resolve(true);
                        } else if (response === 3 || response === 4) {
                            clearInterval(interval);
                            return response(false);
                        }
                    })
                }else if(response === 1) return resolve(true);
                else if(response === 3 || response == 4) resolve(false);
            } catch (e) {
                console.log(e);
                resolve(false);
            }
        })
    }


    const checkUpdateStatus = async (interval, resolve) => {
        try {
            const token = getUserToken();
            const url = process.env.REACT_APP_SERVICE_URL + getStatus + `?pageName=personalDetails&pan=${token.pan}`;
            const request_id = localStorage.getItem("request_id");
            const headers = {
                'api-key': `Bearer ${request_id}`

            }
            const response = await axios.get(url, { headers });
            if (response && response.data && response.data.success) {
                return 1;
            } 
            // else if (response && response.data && response.data.success.toLowerCase() === 'initiated') {
            //     if (interval) {
            //         setPollAttempts((prevState) => {
            //             if (prevState === 1) {
            //                 clearInterval(interval);
            //                 return resolve(false)
            //             } else return prevState = prevState - 1;
            //         })
            //     } else {
            //         return 2;
            //     }
            // } 
            else if (response && response.data && !response.data.success) {
                return 3;
            } else throw new Error();

        } catch (e) {
            console.log(e);
            return 4;
        }
    }



    return (
        <>
            {loading && <Loader />}
            <FlowHeader page = {props.page} />
            <div className="main-div d-flex flex-column justify-content-between text-font">
                <div className="container mt-4 text-font">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 header">
                                Personal Details
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 sub-heading">
                                We will use these details for communication purposes and to tailor our services to your needs.                        </div>
                        </div>

                    </div>
                    <div className="container mt-4">
                        <div className="row">
                            <div className="col-12 input-headers">
                                Email
                            </div>
                            <div className="col-12">
                                <TextField type="email" onChange={handleEmailChange} value={email.value} className="input" id="outlined-basic" label="" placeholder="Enter your active email address" variant="outlined" />
                                {email.error && <p style={{margin:0 , padding:0 ,fontSize:'12px'}} className="text-danger">Please enter a valid email</p>}
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 input-headers">
                                Father Name
                            </div>
                            <div className="col-12">
                                <TextField onChange={handleNameChange} value={name} className="input" id="outlined-basic" label="" placeholder="Enter your father's full name" variant="outlined" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 input-headers">
                                Monthly Income
                            </div>
                            <div className="col-12">
                                <TextField onChange={handleIncomeChange} value={income} className="input" id="outlined-basic" label="" placeholder="Enter your monthly income(INR)" variant="outlined" />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 input-headers">
                                Employment Status
                            </div>
                            <div className="col-12">

                                <Select
                                    labelId="demo-simple-select-label"
                                    id="city"
                                    displayEmpty
                                    placeholder="Select"
                                    onChange={handleEmpStatusChange}
                                    value={empStatus}
                                    style={{ width: "100%" }}
                                >
                                    <MenuItem value="">
                                        <em style={{ color: "#9CA3AF", fontStyle: "normal" }}>Select</em>
                                    </MenuItem>
                                    <MenuItem key={'salary'} value={'Salaried'}>Salaried</MenuItem>
                                    <MenuItem key={'selfEmployed'} value={'Self-Employed'}>Self-Employed</MenuItem>
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container button-container mt-4">
                    <div className="row button-container-com">
                        <div className="col-12">
                            <Button onClick={onSubmit} disabled={handleButtonDisabled()} className='submit-button' variant='contained' style={{ width: "100%" }}>Submit & Next</Button>
                            <Footer />
                        </div>

                    </div>
                </div>
            </div>
        </>
    )



}

export default RouteProtector(PersonalDetails);