// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.drop-down{
    position: absolute;
    background: white;
    z-index: 5;
    border-radius: 10px;
    box-shadow: 0px 4px 6px 0px #00000040;
    top:20px;
    right: 34%;
    width: 200px;

}

.drop-down-menu{
    color:#181818;
    font-weight: 600;
    font-size: 14px;
    text-decoration: none;
}

.drop-down-menu:hover{
    background-color: #F8F8F8;
}`, "",{"version":3,"sources":["webpack://./src/styles/dropdown.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,UAAU;IACV,mBAAmB;IACnB,qCAAqC;IACrC,QAAQ;IACR,UAAU;IACV,YAAY;;AAEhB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".drop-down{\n    position: absolute;\n    background: white;\n    z-index: 5;\n    border-radius: 10px;\n    box-shadow: 0px 4px 6px 0px #00000040;\n    top:20px;\n    right: 34%;\n    width: 200px;\n\n}\n\n.drop-down-menu{\n    color:#181818;\n    font-weight: 600;\n    font-size: 14px;\n    text-decoration: none;\n}\n\n.drop-down-menu:hover{\n    background-color: #F8F8F8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
