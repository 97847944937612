// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.text-font{
    font-family: "Inter", sans-serif;
}



.main-div{
    height: 90%;
}

.button-container-com{
    margin-bottom: 10px;
}


.input{
    border: 1px solid #E5E7EB !important;
    width: 100%;
}


.header{
    font-size: 24px;
    font-weight: 600;
}

.sub-heading{
    font-size: 14px;
    font-weight: 400;
}

.input-headers{
    font-size: 14px;
    font-weight: 500;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    border: none !important;
}


.placeholder{
    color: #9CA3AF;
}


`, "",{"version":3,"sources":["webpack://./src/styles/personalDetails.css"],"names":[],"mappings":";;AAEA;IACI,gCAAgC;AACpC;;;;AAIA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;;AAGA;IACI,oCAAoC;IACpC,WAAW;AACf;;;AAGA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,uBAAuB;AAC3B;;;AAGA;IACI,cAAc;AAClB","sourcesContent":["\n\n.text-font{\n    font-family: \"Inter\", sans-serif;\n}\n\n\n\n.main-div{\n    height: 90%;\n}\n\n.button-container-com{\n    margin-bottom: 10px;\n}\n\n\n.input{\n    border: 1px solid #E5E7EB !important;\n    width: 100%;\n}\n\n\n.header{\n    font-size: 24px;\n    font-weight: 600;\n}\n\n.sub-heading{\n    font-size: 14px;\n    font-weight: 400;\n}\n\n.input-headers{\n    font-size: 14px;\n    font-weight: 500;\n}\n\n.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{\n    border: none !important;\n}\n\n\n.placeholder{\n    color: #9CA3AF;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
