import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
const PageNotFound = () => {




    return (
        <div style={{height:"100%"}}>
            <div className='container'>
                <div className='row'>
                    <div style={{paddingTop:"100px" , fontSize:"60px"}}  className='col-12 d-flex justify-content-center'>
                        404
                    </div>
                </div>
                <div style={{fontSize:"16px"}} className='row'>
                    <div className='col-12 d-flex justify-content-center'>
                        <center>The resource requested could not be found on this server!</center>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PageNotFound;