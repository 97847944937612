import React, { useEffect, useState, useContext } from "react";
import '../styles/offerGenerated.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Button, Dialog, Slide } from "@mui/material";
import { Context } from "../context/context";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Loader from "../commonComponents/loader";
import { getOfferData, offerDet, getAScoreData, getStatus, colenderSelectorV3 } from "../apis";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { getUserToken, updateAndNext } from "../helpers/data";
import { stateConvertionMapping } from "../helpers";
import RouteProtector from "../commonComponents/routeProctector";
import moment from "moment";




const OfferGenerated = (props) => {

    const global = useContext(Context);

    const [showDetails, setShowDetails] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [planSelected, setPlanSelected] = useState("br");
    const [loading, setLoading] = useState(false);
    const [prem, setPrem] = useState({ total: 0, downPayment: 0 })
    const [threeMonthsTenure, setThreeMonthsTenure] = useState({ tenure: "", interestRate: "", emi: "", appFee: "", gstOnAppFee: "", downPayment: "" });
    const [sixMonthsTenure, setSixMonthsTenure] = useState({ tenure: "", interestRate: "", emi: "", appFee: "", gstOnAppFee: "", downPayment: "" });
    const [nineMonthsTenure, setNineMonthsTenure] = useState({ tenure: "", interestRate: "", emi: "", appFee: "", gstOnAppFee: "", downPayment: "" });
    const [twelveMonthsTenure, setTwelveMonthsTenure] = useState({ tenure: "", interestRate: "", emi: "", appFee: "", gstOnAppFee: "", downPayment: "" });
    const [pollAscoreAttempts, setPollAscoreAttempts] = useState(10);
    const [pollColenderAttempts, setPollColenderAttempts] = useState(10);

    const location = useLocation();
    const navigate = useNavigate();


    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    useEffect(() => {
        onPageLoad();
    }, [])


    const onPageLoad = async () => {
        setLoading(true);
        try {
            const resp = await callAscoreRequest();
            if (!resp) return navigate('/error');
            const response = await getOfferInital();

            if (!response) throw new Error();
        } catch (e) {
            console.log(e);
            navigate('/error');
        }
        setLoading(false)
    }



    const callAscoreRequest = async () => {
        try {
            const token = getUserToken();
            const request_id = localStorage.getItem("request_id");
            const headers = {
                "api-key": `Bearer ${request_id}`
            }
            const payload = {
                "submitData": {
                    "pan": token.pan,
                    "first_name": token.first_name,
                    "last_name": token.last_name,
                    "dob": token.dob,
                    "loan_app_id": token.loan_app_id,
                    "gender": token.gender.trim().toUpperCase(),
                    "mobile_number": token.appl_phone,
                    "address": (token.resi_addr_ln1 ? token.resi_addr_ln1 + " " : "") + (token.resi_addr_ln2 ? token.resi_addr_ln1 + " " : "") + token.city + " " + token.state + " " + token.pincode,
                    "city": token.city,
                    "pin_code": token.pincode.toString(),
                    "enquiry_amount": token.loan_amount,
                    "bureau_type": "cibil",
                    "tenure": parseFloat(token?.loan_tenure),
                    "consent": "Y",
                    "consent_timestamp": moment().format("YYYY-MM-DD HH:mm:ss"),
                    "state_code": stateConvertionMapping[token.state.toUpperCase()],
                    "enquiry_purpose": "01",
                    "product_type": "STPL",
                    "enquiry_stage": "PRE-SCREEN",
                    "en_acc_account_number_1": token.loan_app_id,
                },
                pageName: "offerGenerated",
            };

            const url = process.env.REACT_APP_SERVICE_URL + getAScoreData;


            const resp = await axios.post(url, payload, { headers });
            if (resp && resp.data && !resp.data.success) return false

            if (resp && resp.data && resp.data.success) {


                const pollResp = await pollAscoreRequestStatus();

                if (!pollResp) return false;

                const tenure = parseInt(token.tenure);
                const interest_rate = parseFloat(token.interest_rate)
                const sanction_amount = parseFloat(token.loan_amount)
                const colenderResp = await coLenderSelectorV3Api('offerGenerated', tenure, sanction_amount, interest_rate);

                return colenderResp;
            }

        } catch (e) {
            return false;
        };



    }

    const pollAscoreRequestStatus = async () => {
        return new Promise(async (resolve) => {
            try {
                let interval = "";
                const response = await checkAscoreRequestStatus(interval, resolve);
                if (response == 2) {
                    interval = setInterval(async () => {
                        const resp = await checkAscoreRequestStatus(interval, resolve);
                        if (resp === 1) {
                            clearInterval(interval);
                            return resolve(true);
                        } else if (resp == 3 || resp === 4) {
                            clearInterval(interval);
                            return resolve(false);
                        }
                    }, 3000)
                } else if (response === 1) return resolve(true);
                else if (response === 3 || response === 4) throw new Error();
            } catch (e) {
                resolve(false);
            }
        })
    }


    const checkAscoreRequestStatus = async (interval, resolve) => {
        try {
            const token = getUserToken();
            const url = process.env.REACT_APP_SERVICE_URL + getStatus + `?pan=${token.pan}&pageName=offerGenerated`;
            const request_id = localStorage.getItem("request_id");

            const headers = {
                'api-key': `Bearer ${request_id}`
            }
            const response = await axios.get(url, { headers });
            if (response && response.data && response.data.queueDetails) {
                const queueDetails = response.data.queueDetails;
                //ASCORE_REQUEST
                //ascore_status_check

                const ascoreRequestResp = queueDetails.find((obj) => obj.apiName.toLowerCase() === 'ascore_request');
                const ascoreStatusCheckResp = queueDetails.find((obj) => obj.apiName.toLowerCase() === 'ascore_status_check');

                if (ascoreRequestResp.apiStatus.toLowerCase === 'success' && ascoreStatusCheckResp.apiStatus.toLowerCase() === 'success') return 1;
                else if (ascoreRequestResp.apiStatus.toLowerCase === 'initiated' || ascoreStatusCheckResp.apiStatus.toLowerCase() === 'initiated') {
                    if (interval) {
                        setPollAscoreAttempts((prevState) => {
                            if (prevState == 1) {
                                clearInterval(interval);
                                return resolve(false);
                            } else return prevState = prevState - 1;
                        })
                    } else return 2;
                } else if (ascoreRequestResp.apiStatus.toLowerCase === 'failure' || ascoreStatusCheckResp.apiStatus.toLowerCase() === 'failure') return 3;

            } else throw new Error();
        } catch (e) {
            return 4;
        }
    }


    const coLenderSelectorV3Api = async (pageName, tenureVal, sanctionAmountVal, interestRateVal) => {
        try {
            const token = getUserToken();
            const payload = {
                "submitData": { //context
                    "partner_loan_app_id": token.partner_loan_app_id,
                    "partner_borrower_id": token.partner_borrower_id,
                    "pan": token.pan,
                    "first_name": token.first_name,
                    "last_name": token.last_name,
                    "dob": token.dob,
                    "loan_app_id": token.loan_app_id,
                    "appl_pan": token.pan,
                    "gender": token.gender,
                    "appl_phone": token.appl_phone,
                    "address": (token.resi_addr_ln1 ? token.resi_addr_ln1 + " " : "") + (token.resi_addr_ln2 ? token.resi_addr_ln1 + " " : "") + token.city + " " + token.state + " " + token.pincode,
                    "city": token.city,
                    "pincode": token.pincode.toString(),
                    "state": stateConvertionMapping[token.state.toUpperCase()],
                    "borrower_profile": "Others",
                    "tenure": tenureVal,
                    "interest_rate": interestRateVal,
                    "sanction_amount": sanctionAmountVal,
                    "consent": "Y",
                    "consent_timestamp": moment().format("YYYY-MM-DD HH:mm:ss"),
                    "enquiry_purpose": "05",
                    "product_type_code": "STPL",

                },
                pageName,
            };

            const url = process.env.REACT_APP_SERVICE_URL + colenderSelectorV3;
            const request_id = localStorage.getItem("request_id");
            const headers = {
                'api-key': `Bearer ${request_id}`
            }
            const response = await axios.post(url, payload, { headers });


            if (response && response.data && response.data.success) {
                const resp = await pollCheckColenderStatus();

                return resp;
            }

            return false;
        } catch (e) {
            return false;
        };



    }


    const pollCheckColenderStatus = async () => {
        return new Promise(async (resolve) => {
            try {
                let interval = "";
                const response = await checkColenderStatus(interval, resolve);
                if (response == 2) {
                    interval = setInterval(async () => {
                        const resp = await checkColenderStatus(interval, resolve);
                        if (resp === 1) {
                            clearInterval(interval);
                            return resolve(true);
                        } else if (resp == 3 || resp === 4) {
                            clearInterval(interval);
                            return resolve(false);
                        }
                    }, 3000)
                } else if (response == 1) return resolve(true);
                else if (response === 3 || response === 4) return resolve(false);
            } catch (e) {
                return resolve(false);
            }
        })
    }

    const checkColenderStatus = async (interval, resolve) => {
        try {
            const token = getUserToken();
            const url = process.env.REACT_APP_SERVICE_URL + getStatus + `?pan=${token.pan}&pageName=offerGenerated`;
            const request_id = localStorage.getItem("request_id");

            const headers = {
                'api-key': `Bearer ${request_id}`
            }
            const response = await axios.get(url, { headers });
            if (response && response.data && response.data.queueDetails) {
                const queueDetails = response.data.queueDetails[0];
                if (queueDetails.apiStatus.toLowerCase() === 'success') return 1;
                else if (queueDetails.apiStatus.toLowerCase === 'initiated') {
                    if (interval) {
                        setPollColenderAttempts((prevState) => {
                            if (prevState == 1) {
                                clearInterval(interval);
                                return resolve(false);
                            } else return prevState = prevState - 1;
                        })
                    } else return 2;
                } else if (queueDetails.apiStatus.toLowerCase === 'failure') return 3;

            } else throw new Error();
        } catch (e) {
            return 4;
        }
    }






    const toggleConfirmationBox = () => {
        setIsOpen(!isOpen)
    }

    const selectPlan = async (plan) => {
        setPlanSelected(plan);
    }

    const onProceeed = async () => {
        setLoading(true);
        try {
            let token = getUserToken();
            let selectedTenure = getBoardDetails().tenure;
            const tenure = parseInt(selectedTenure);
            const interest_rate = parseFloat(token.interest_rate)
            const sanction_amount = parseFloat(token.loan_amount)
            const colenderResp = await coLenderSelectorV3Api('offerGenerated', tenure, sanction_amount, interest_rate);
            if (colenderResp) setShowDetails(true);
            else throw new Error();
        } catch (e) {
            navigate('/error');
        }
        setLoading(false);
    }

    const onReset = () => {
        setShowDetails(false);
    }


    const getOfferInital = async () => {
        try {
            const request_id = localStorage.getItem("request_id");
            const token = getUserToken();
            const headers = {
                'api-key': `Bearer ${request_id}`
            }


            const id = process.env.NODE_ENV === 'production' ? token.loan_app_id : 'ANANT-5101116443755'

            const url = process.env.REACT_APP_SERVICE_URL + getOfferData + `?loan_app_id=${id}`;
            const resp = await axios.get(url, { headers });
            if (resp && resp.data && resp.data.statusCode === 200) {
                const response = await getOfferDetails();
                return response;
            }

            return false;

        } catch (e) {
            return false;
        }
    }

    const getOfferDetails = async () => {
        try {
            const request_id = localStorage.getItem("request_id");
            const token = getUserToken();
            const headers = {
                'api-key': `Bearer ${request_id}`
            }
            const policy_premium = token.policy_premium_amount;
            const promises = [];
            const offerUrl = process.env.REACT_APP_SERVICE_URL + offerDet;
            [3, 6, 9, 12].map((item) => {
                const reqBody = {
                    tenure: item,
                    policy_premium
                }

                promises.push(axios.post(offerUrl, reqBody, { headers }));
            })
            const offerDetailsResp = await Promise.all(promises);

            if (offerDetailsResp && offerDetailsResp.length) {
                offerDetailsResp.map((obj) => {
                    if (obj && obj.status == 200) {
                        const data = obj.data;

                        if (!prem.total || !prem.downPayment) {
                            let premPayload = {
                                total: data.payload.policy_premium,
                                downPayment: data.payload.down_payment_amount
                            }
                            setPrem({ ...premPayload });
                        }

                        let tenurePayload = {
                            tenure: data.payload.tenure,
                            interestRate: data.payload.roi,
                            emi: data.data.installments[1].emi_amount,
                            appFee: data.payload.tech_fees,
                            gstOnAppFee: data.payload.gst_on_tech_fees,
                            downPayment: data.data.installments[0].emi_amount
                        }
                        if (data.payload.tenure == 3) {
                            setThreeMonthsTenure({ ...tenurePayload });
                        } else if (data.payload.tenure == 6) {
                            setSixMonthsTenure({ ...tenurePayload });
                        } else if (data.payload.tenure == 9) {
                            setNineMonthsTenure({ ...tenurePayload });
                        } else if (data.payload.tenure == 12) {
                            setTwelveMonthsTenure({ ...tenurePayload });
                        }
                    }
                })

                return true;
            }

            return false;
        } catch (e) {
            return false;
        }
    }


    const getBoardDetails = () => {
        switch (planSelected) {
            case "tr":
                return sixMonthsTenure;
            case "tl":
                return threeMonthsTenure;
            case "br":
                return twelveMonthsTenure;
            case "bl":
                return nineMonthsTenure;
            default:
                return null

        }
    }


    const onSubmit = async () => {
        try {
            setLoading(true);
            let selectedTenure = getBoardDetails().tenure;
            localStorage.setItem('offer_tenure', JSON.stringify({ "offer_tenure": selectedTenure }));
            const url = await updateAndNext(location.pathname);
            navigate(url);
            return;
        } catch (e) {
            navigate('/error');
        }
        setLoading(true);
    }

    return (
        <>

            {loading && <Loader />}
            {
                isOpen ? <Dialog
                    open={true}
                    className="dialog-text"
                    TransitionComponent={Transition}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <div className="p-3 fonts">
                        <div className="row confirmation-box-heading">
                            <div className="col-12 fonts">
                                Please Confirm
                            </div>

                        </div>
                        <div className="row mb-4 confirmation-box-content">
                            <div className="col-12">
                                Are you sure you want to proceed with the selected loan tenure?
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-6">
                                <Button onClick={toggleConfirmationBox} variant='contained' style={{ width: "100%", background: "white", color: "black" }}> No</Button>
                            </div>
                            <div className="col-6">
                                <Button onClick={onSubmit} variant='contained' style={{ width: "100%" }}> Yes</Button>
                            </div>

                        </div>
                    </div>
                </Dialog> : ""
            }
            <div className="of-background-color fonts">
                <div style={!showDetails && getBoardDetails() ? {} : { marginBottom: "25px" }} className="repay-div">
                    <div className="repay-content-div">
                        {
                            showDetails ?
                                <div className="emi-div">
                                    <center>
                                        <p style={{ fontWeight: "500", fontSize: "14px", margin: 0 }}>Based on the chosen repayment period your</p>
                                        <p style={{ fontWeight: "600", fontSize: "31px" }}> EMI ₹{getBoardDetails().emi}</p>
                                    </center>
                                </div>
                                :
                                <>
                                    <center>
                                        <p style={{ fontSize: "30px", fontWeight: "700", margin: 0 }}>Congratulations!</p>
                                        <p style={{ fontSize: "14px", fontWeight: "300" }}>You are eligible for a loan against your premium amount</p>
                                    </center>
                                    <div className="d-flex justify-content-between">
                                        <p style={{ fontSize: '14px', fontWeight: "300" }}>
                                            Your Premium (Total Amount)
                                        </p>
                                        <p style={{ fontSize: '16px', fontWeight: "600" }}>
                                            ₹{prem.total}
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p style={{ fontSize: '14px', fontWeight: "300" }}>
                                            Down payment to be made by you
                                        </p>
                                        <p style={{ fontSize: '16px', fontWeight: "600" }}>
                                            ₹{prem.downPayment}
                                        </p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <p style={{ fontSize: '14px', fontWeight: "300" }}>
                                            Amount we finanace
                                        </p>
                                        <p style={{ fontSize: '16px', fontWeight: "600" }}>
                                            ₹{prem.total - prem.downPayment}
                                        </p>
                                    </div>
                                </>
                        }
                    </div>
                </div>
                {
                    !showDetails && getBoardDetails() &&
                    <div className="emi-div pt-3 pb-3">
                        <center>
                            <p style={{ fontWeight: "500", fontSize: "14px", margin: 0 }}>Based on the chosen repayment period your</p>
                            <p style={{ fontWeight: "600", fontSize: "31px" }}> EMI ₹{getBoardDetails().emi}</p>
                        </center>
                    </div>
                }
                <div className="offer-details fonts">
                    {!showDetails ? <> <div style={{ padding: "10px" }} className="d-flex justify-content-center">
                        <div className="offer-details-header">Over what period would you like to repay
                            the loan? </div>
                    </div>
                        <div style={{ position: "relative" }} className="row d-flex justify-content-around mb-2">
                            <div onClick={() => selectPlan("tl")} className="col-5 d-flex justify-content-center cards">3 months {planSelected?.toLowerCase() === 'tl' ? <CheckCircleIcon color="success" className="tick-tl" /> : ""}</div>
                            <div onClick={() => selectPlan("tr")} className="col-5 d-flex justify-content-center cards">6 months {planSelected?.toLowerCase() === 'tr' ? <CheckCircleIcon color="success" className="tick-tr" /> : ""}</div>
                        </div>
                        <div style={{ position: "relative" }} className="row d-flex justify-content-around mb-2">
                            <div onClick={() => selectPlan("bl")} className="col-5 d-flex justify-content-center cards">9 months {planSelected?.toLowerCase() === 'bl' ? <CheckCircleIcon color="success" className="tick-tl" /> : ""}</div>
                            <div onClick={() => selectPlan("br")} className="col-5 d-flex justify-content-center cards">12 months {planSelected?.toLowerCase() === 'br' ? <CheckCircleIcon color="success" className="tick-tr" /> : ""}</div>
                        </div>
                        <div className="row mb-3">
                            <div style={{ fontSize: "15px", fontWeight: "500" }} className="col-12">
                                Why down payment is required?
                            </div>
                            <div style={{ fontSize: "12px", fontWeight: "400" }} className="col-12">
                                By making a down payment, you can immediately start enjoying your policy benefits while we take care of the rest. It's like paying a small amount upfront to receive full protection.
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <Button disabled={!planSelected} onClick={onProceeed} style={{ width: "100%" }} variant="contained"> Proceed </Button>
                            </div>
                        </div>
                    </>
                        :
                        <>
                            <div style={{ fontSize: "17px", fontWeight: "500" }}>Loan Details</div>
                            <div className="loan-details mt-2 mb-2">
                                <div className="p-4">
                                    <div className="row d-flex justify-content-between mb-2">
                                        <div className="col-8 d-flex justify-content-start">
                                            Tenure
                                        </div>
                                        <div className="col-4">
                                            {getBoardDetails().tenure} months
                                        </div>

                                    </div>
                                    <div className="row d-flex justify-content-between mb-2">
                                        <div className="col-8 d-flex justify-content-start">
                                            Interest Rate
                                        </div>
                                        <div className="col-4">
                                            {getBoardDetails().interestRate}% p.a
                                        </div>

                                    </div>
                                    <div className="row d-flex justify-content-between mb-2">
                                        <div className="col-8 d-flex justify-content-start">
                                            Monthly EMI
                                        </div>
                                        <div className="col-4">
                                            ₹{getBoardDetails().emi}
                                        </div>

                                    </div>
                                    <div className="row d-flex justify-content-between mb-2">
                                        <div className="col-8 d-flex justify-content-start">
                                            Application Fee
                                        </div>
                                        <div className="col-4">
                                            ₹ {getBoardDetails().appFee}
                                        </div>

                                    </div>
                                    <div className="row d-flex justify-content-between mb-2">
                                        <div className="col-8 d-flex justify-content-start">
                                            GST on Application Fee (18%)
                                        </div>
                                        <div className="col-4">
                                            ₹{getBoardDetails().appFee * (getBoardDetails().gstOnAppFee / 100)}
                                        </div>

                                    </div>
                                    <div className="row d-flex justify-content-between mb-2">
                                        <div className="col-8 d-flex justify-content-start">
                                            Down Payment
                                        </div>
                                        <div className="col-4">
                                            ₹{getBoardDetails().downPayment}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>

                    }
                    {showDetails ? <div className="button-container-offer d-flex justify-content-between">
                        <div style={{ padding: "5px" }} className="col-6">
                            <Button onClick={onReset} className="cancel" variant='contained' style={{ width: "100%", background: "white", color: "black" }}>Reset</Button>
                        </div>
                        <div style={{ padding: "5px" }} className="col-6">
                            <Button key={'accept-details'} onClick={toggleConfirmationBox} variant='contained' style={{ width: "100%" }}> Accept</Button>
                        </div>
                    </div> : ""}
                </div>
            </div>
        </>
    )
}

export default RouteProtector(OfferGenerated);