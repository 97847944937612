// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container{
    height: 10%;
    background-color: #D4E3FC;
    display: flex;
    justify-content: center;

}

.header-content-container{
    height: 100%;
    width: 100%;
    padding: 10px;
}

.arrow{
    cursor: pointer;
}

.nav-bar-div{
    align-items: center;
}

.nav-bars{
    height: 4px;
    width: 32px;
    border-radius: 8px;
}


@media screen and (min-width:800px) {
    .header-content-container{
        width: 50%;
    }

    .nav-bars{
        width: 60px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/flowHeader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,uBAAuB;;AAE3B;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,kBAAkB;AACtB;;;AAGA;IACI;QACI,UAAU;IACd;;IAEA;QACI,WAAW;IACf;AACJ","sourcesContent":[".header-container{\n    height: 10%;\n    background-color: #D4E3FC;\n    display: flex;\n    justify-content: center;\n\n}\n\n.header-content-container{\n    height: 100%;\n    width: 100%;\n    padding: 10px;\n}\n\n.arrow{\n    cursor: pointer;\n}\n\n.nav-bar-div{\n    align-items: center;\n}\n\n.nav-bars{\n    height: 4px;\n    width: 32px;\n    border-radius: 8px;\n}\n\n\n@media screen and (min-width:800px) {\n    .header-content-container{\n        width: 50%;\n    }\n\n    .nav-bars{\n        width: 60px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
