// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
    position: absolute  !important;
    bottom: 0;
    max-width: 100% ;
    width: 100%;
    margin: 0 !important;
    overflow-x: hidden !important;
}

@media screen and (min-width:992px) {
    .css-1t1j96h-MuiPaper-root-MuiDialog-paper{
        position: relative !important;
    }
}

.dialog-content{
    height: 300px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;  
}

.dialog-text{
    font-family: "Inter", sans-serif;
}

.dialog-content-text{
    font-size: 20px;
    font-weight: 600;
}

.dialog-subheading{
    font-size: 16px;
    font-weight: 500;
    color: #767888
}

.otp-input{
    width:45px;
    border:1px solid black;
    padding: 5px;
    margin: 2px;
    border-radius: 10px;
    text-align: center;
}

.button{
    max-width: 45%;
    border-radius: 10px !important;
    padding:8px !important;
}


.cancel-button{
    border: 1px solid grey !important;
    color:black !important;
    padding: 2px;
}

.row-width{
    width: 80%;
}`, "",{"version":3,"sources":["webpack://./src/styles/otpValidation.css"],"names":[],"mappings":";AACA;IACI,8BAA8B;IAC9B,SAAS;IACT,gBAAgB;IAChB,WAAW;IACX,oBAAoB;IACpB,6BAA6B;AACjC;;AAEA;IACI;QACI,6BAA6B;IACjC;AACJ;;AAEA;IACI,aAAa;IACb,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB;AACJ;;AAEA;IACI,UAAU;IACV,sBAAsB;IACtB,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,8BAA8B;IAC9B,sBAAsB;AAC1B;;;AAGA;IACI,iCAAiC;IACjC,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,UAAU;AACd","sourcesContent":["\n.css-1t1j96h-MuiPaper-root-MuiDialog-paper{\n    position: absolute  !important;\n    bottom: 0;\n    max-width: 100% ;\n    width: 100%;\n    margin: 0 !important;\n    overflow-x: hidden !important;\n}\n\n@media screen and (min-width:992px) {\n    .css-1t1j96h-MuiPaper-root-MuiDialog-paper{\n        position: relative !important;\n    }\n}\n\n.dialog-content{\n    height: 300px;\n    border-top-left-radius: 20px;\n    border-top-right-radius: 20px;  \n}\n\n.dialog-text{\n    font-family: \"Inter\", sans-serif;\n}\n\n.dialog-content-text{\n    font-size: 20px;\n    font-weight: 600;\n}\n\n.dialog-subheading{\n    font-size: 16px;\n    font-weight: 500;\n    color: #767888\n}\n\n.otp-input{\n    width:45px;\n    border:1px solid black;\n    padding: 5px;\n    margin: 2px;\n    border-radius: 10px;\n    text-align: center;\n}\n\n.button{\n    max-width: 45%;\n    border-radius: 10px !important;\n    padding:8px !important;\n}\n\n\n.cancel-button{\n    border: 1px solid grey !important;\n    color:black !important;\n    padding: 2px;\n}\n\n.row-width{\n    width: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
