
import React, { useState } from 'react';
import { useContext } from 'react';
import LandingPage from './pages/landingPage';
import KycVerification from './pages/KycVerification';
import SelfieDetails from './pages/SelfieDetails';
import BankDetails from './pages/BankDetails';
import CommunicationDetails from './pages/CommunicationDetails';
import PageNotFound from './commonComponents/notfounderror';
import FAQs from './pages/FAQs';
import CustomerSupport from './pages/customerSupport';
import { Route, Routes, Navigate } from "react-router-dom";
import { Context } from './context/context';
import OfferGenerated from './pages/OfferGenerated';
import ErrorPage from './commonComponents/error';
import SuccessPage from './commonComponents/success';
import PaymentSchedule from './pages/paymentSchedule';
import PaymentStatus from './pages/paymentStatus';
import Payments from './pages/payments';
import PersonalDetails from './pages/PersonalDetails';
import Main from './pages/Main';
const App = () => {

  const globalVars = useContext(Context);

  const [showHeader, setShowHeader] = useState(true);
  const [showFooter, setShowFooter] = useState(true);

  return (
    <>
      <Routes>
        <Route path="/" element={<Main/>} />
        <Route path='/get-started' element={<LandingPage />} />
        <Route path="/kyc-verification" element={<KycVerification />} />
        <Route path="/communication-details" element={<CommunicationDetails />} />
        <Route path="/personal-details" element={<PersonalDetails />} />
        <Route path="/selfie-details" element={<SelfieDetails />} />
        <Route path="/bank-details" element={<BankDetails />} />
        <Route path="/offer-generated" element={<OfferGenerated />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/payment-status" element={<PaymentStatus/>} />
        <Route path="/payment-schedule" element={<PaymentSchedule />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path='/customer-support' element={<CustomerSupport />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;