import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import ContextProvider
  from './context';
const root = ReactDOM.createRoot(document.getElementById('root'));
// implement the authentication/authorization of the user here
root.render(
  <BrowserRouter>
    <ContextProvider>
      <App />
    </ContextProvider>
  </BrowserRouter>
);

serviceWorkerRegistration.register();
