import CircularProgress from '@mui/material/CircularProgress';

const PageLoader = () => {
    return (
        <div style={{ height: "100%", width: "100%", padding: "30px", display: "flex", flexDirection: 'column', justifyContent: "center" }}>
            <div style={{ width: "100%", display: 'flex', justifyContent: 'center', padding: "10px" }}><CircularProgress style={{ color: "#2F629A" }} /></div>

            <p style={{ width: "100%", display: 'flex', justifyContent: 'center', padding: "10px", fontWeight: '500', fontSize: '15px' , color:"#767888" }}>
                Processing...
            </p>
            <div style={{ width: "100%", display: 'flex', justifyContent: 'center', padding: "10px" }}>
                <div style={{ height: "10px", width: "10px", backgroundColor: "#2F629A", borderRadius: "50px" }} />
            </div>
            <center>
                <p style={{ width: "100%", display: 'flex', justifyContent: 'center', padding: "10px", fontWeight: '500', fontSize: '15px' , color:'#767888' }}>
                    Please do not refresh the page or click the "Back" or "Close" button of your browser
                </p>
            </center>

        </div>
    )
}


export default PageLoader;