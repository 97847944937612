import React, { useState, useEffect } from "react";
import { Dialog, Slide, } from "@mui/material";
import '../styles/otpValidation.css';
import 'bootstrap/dist/css/bootstrap.css';
import Otp from "./otp";

const OtpVerificationModal = (props) => {


    return (
        <Dialog
            open={true}
            className="dialog-text"
            // TransitionComponent={Transition}
            aria-describedby="alert-dialog-slide-description"
        >
            <div className="dialog-content mt-4">
                <div className="row dialog-content-text">
                    <div className="col-12-sm col-12-xs col-12-md d-flex justify-content-center">
                        Aadhaar Verification
                    </div>
                </div>
                <div className="row dialog-subheading">
                    <div className="col-12-sm col-12-xs col-12-md d-flex justify-content-center">
                        Please enter the OTP sent to your
                    </div>
                </div>
                <div className="row dialog-subheading">
                    <div className="col-12-sm col-12-xs col-12-md d-flex justify-content-center">
                        Aadhaar-linked mobile number
                    </div>
                </div>
                <Otp
                    
                    loading={props.loading}
                    submit = {(otp) => props.submit(otp)}
                    cancel={props.cancel}
                    resend = {() => props.resend()}
                    wrongOtp = {props.wrongOtp}
                    setWrongOtp = {() => props.setWrongOtp()}
                />

            </div>
        </Dialog>
    )
}


export default OtpVerificationModal;