import React from "react";
import arthmateLogo from '../images/arthmateLogo/Group 10.png';

const Footer = ({styles = {}}) => {
    
    return(
        <div className='d-flex justify-content-center div-width p-3'>
               <p style={{color:"#9D9D9C"}}>Powered by:  <img src={arthmateLogo} /></p>
        </div>
    )
}

export default Footer;