// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader{
    color:#0076B2;
    position:relative
}

.loader-text{
    font-weight: 400;
    font-size: 12px;
    font-family: "Inter", sans-serif;
    color: #767888;
    text-align: center;
    position: relative;
}

.loader-div{
    background-color: #FFFFFF;
    border-radius: 10px;
    height: 16%;
    width: 45%;
}

.content-container-loader{
    height: 70%;
    align-items: center;
    padding: 2px;
}`, "",{"version":3,"sources":["webpack://./src/styles/loader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb;AACJ;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gCAAgC;IAChC,cAAc;IACd,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,UAAU;AACd;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".loader{\n    color:#0076B2;\n    position:relative\n}\n\n.loader-text{\n    font-weight: 400;\n    font-size: 12px;\n    font-family: \"Inter\", sans-serif;\n    color: #767888;\n    text-align: center;\n    position: relative;\n}\n\n.loader-div{\n    background-color: #FFFFFF;\n    border-radius: 10px;\n    height: 16%;\n    width: 45%;\n}\n\n.content-container-loader{\n    height: 70%;\n    align-items: center;\n    padding: 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
