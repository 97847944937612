import React, { useEffect, useState, useContext } from "react";
import { Button } from "@mui/material";
import Footer from "../commonComponents/Footer";
import FlowHeader from "../commonComponents/FlowHeader";
import logo from '../images/downpaymentLogo/wallet-check.png'
import '../styles/accordian.css';
import { useNavigate } from 'react-router-dom';
import { Context } from "../context/context";
import Loader from "../commonComponents/loader";
import moment from "moment";
import { getOfferData, offerDet} from '../apis';
import RouteProtector from "../commonComponents/routeProctector";
import axios from 'axios';
import { getUserToken } from '../helpers/data';

const PaymentSchedule = (props) => {

    const [nextPayment, setNextPayment] = useState(null);
    const [loading , setLoading] = useState(false);
    const [upcomingPayments, setUpcomingPayments] = useState([{ total: 12, date: "14/04/2001", amount: 20000, current: 1 }, { total: 12, date: "14/04/2001", amount: 20000, current: 2 }]);
    const navigate = useNavigate();

    const globalVars = useContext(Context);


    useEffect(() => {
        // get payment schedule here
        getSelectedTenure()

    }, [])


    const getSelectedTenure = async () => {
        setLoading(true);
        try {
            const request_id = localStorage.getItem("request_id");
            const token = getUserToken();
            const headers = {
                'api-key': `Bearer ${request_id}`
            }


            const id = process.env.NODE_ENV === 'production' ? token.loan_app_id : 'ANANT-5101116443755'

            const url = process.env.REACT_APP_SERVICE_URL + getOfferData + `?loan_app_id=${id}`;
            const resp = await axios.get(url, { headers });
            if (resp && resp.data && resp.data.statusCode === 200) {
                const tenure = resp.data.body.data.tenure;
                const response = await getDownPaymentAmount(tenure);
                if (!response) throw new Error();
            }
        } catch (e) {
            navigate('/error');
        }

        setLoading(false);
    }

    const getDownPaymentAmount = async (tenure) => {
        try {
            const request_id = localStorage.getItem("request_id");
            const token = getUserToken();
            const headers = {
                'api-key': `Bearer ${request_id}`
            }
            const policy_premium = token.policy_premium_amount;

            const url = process.env.REACT_APP_SERVICE_URL + offerDet;

            const reqBody = {
                tenure: tenure,
                policy_premium
            }
            const offerDetailsResp = await axios.post(url, reqBody, { headers });

            if (offerDetailsResp && offerDetailsResp.data && offerDetailsResp.data.data && offerDetailsResp.data.data.installments) {
                const nextPay = offerDetailsResp.data.data.installments[1];
                setNextPayment(nextPay);
                const upcomingPay = offerDetailsResp.data.data.installments.slice(2 ,offerDetailsResp.data.data.installments.length);
                setUpcomingPayments([...upcomingPay]);
                return true;
            }
            return false;
        } catch (e) {
            return false;
        }
    }

    return (
        <>
          {loading && <Loader />}
            <FlowHeader page = {props.page} />
            <div style={{ height: "90%", fontFamily: '"Inter", sans-serif' }} className="d-flex flex-column justify-content-between">
                <div className="container mt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 header">
                                Payment Schedule
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                Review your upcoming payment dates and amounts. Ensure timely payments to avoid any penalties or service disruptions.
                            </div>
                        </div>
                        <div style={{ alignItems: "center", height: "100%", background: '#F4F4F4', padding: "15px" }} className='row mt-4'>
                            <div className='col-2'>
                                <div className='d-flex justify-content-center' style={{ background: "#D4E3FC", borderRadius: "20px", height: "100%" }}>
                                    <img style={{ padding: "10px" }} src={logo} />
                                </div>
                            </div>
                            <div className='col-9'>
                                <div className='row'>
                                    <div style={{ fontWeight: "600", fontSize: "16px", color: "#181818" }} className='col-12 d-flex justify-content-between'>
                                        <div>
                                            Next Payment
                                        </div>
                                        <div style={{fontWeight:"500" , fontSize:"20px"}}>
                                            ₹{nextPayment?.emi_amount}
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div style={{ fontWeight: "400", fontSize: "12px" }} className='col-12'>
                                        Due on {moment(nextPayment?.due_date).format('DD/MM/YYYY')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ padding: "15px" }} className='row mt-2'>
                            <div className="row">
                                <div style={{ fontSize: "14px", fontWeight: "500", color: "#111827" }} className="col-12">
                                    Upcoming Payments
                                </div>
                            </div>
                        </div>
                        {
                            upcomingPayments && upcomingPayments.length && upcomingPayments.map((obj, index) => {
                                return (
                                    <>
                                        <div key={index} style={{ padding: "15px" }} className='row'>
                                            <div className="row d-flex justify-content-between">
                                                <div style={{width:"max-content"}} className="col-6">
                                                    <div className="row">
                                                        <div style={{fontWeight:"600" , fontSize:"16px" , color:'#181818'}} className="col-12">
                                                            {index+1} out of {upcomingPayments.length}
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div style={{fontWeight:"400" , fontSize:"12px"}} className="col-12">
                                                            Due on {obj.due_date}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{fontWeight:"500" , fontSize:"20px" , width:"max-content"}} className="col-6">
                                                    ₹ {obj.emi_amount}
                                                </div>
                                            </div>
                                        </div>
                                        <hr style={{margin:"auto"}} />
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="container button-container mt-4">
                    <div className="container">
                        <div className="row button-container-com">
                            <div className="col-12">
                                <Button className='submit-button' variant='contained' style={{ width: "100%" }} onClick={() => navigate('/')}>Back to Home</Button>
                            </div>

                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
          
        </>
    )
}

export default RouteProtector(PaymentSchedule);