import React from "react";
import { useState } from "react";
import { Button, Dialog } from "@mui/material";
import '../styles/selfieDetails.css';
import FlowHeader from "../commonComponents/FlowHeader";
import 'react-html5-camera-photo/build/css/index.css';
import logo from '../images/photoLogo/1592220663157 1.png'
import 'bootstrap/dist/css/bootstrap.css';
import person from '../images/person/Vector.png';
import bulb from '../images/bulb/Group.png';
import glass from '../images/glasses/glass.png';
import mute from '../images/muteLogo/volume-slash.png';
import Footer from '../commonComponents/Footer';
import Recorder from "../commonComponents/useRecorder";
import CameraCounter from "../commonComponents/cameraCounter";
import Loader from '../commonComponents/loader'
import { useLocation, useNavigate } from "react-router-dom";
import { getUserToken , updateAndNext } from "../helpers/data";
import { verifyLiveliness,getStatus,verifySelfie } from "../apis";
import RouteProtector from "../commonComponents/routeProctector";
import axios from 'axios';
// 0 camera not started
// 1 camera started,not recording
// 2 camera recording
// 3 recording done
const SelfieDetails = (props) => {

    const [openPermBox, setOpenPermBox] = useState(false);
    const [permissionProvided, setPermissionProvided] = useState(false);
    const [stage, setStage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [otp , setOtp] = useState('');

    const [selfieReqPollAttempts , setSelfieReqPollAttempts] = useState(10); 
    const [selfieCheckPollAttempts , setSelfieCheckPollAttempts] = useState(10); 

    const navigate = useNavigate();
    const location = useLocation();

    const openCamera = (permBox = false, reset = false) => {
        if (reset) {
            setStage(1)
        } else if (permBox && stage === 0) {
            setOpenPermBox(false)
            setPermissionProvided(true);
            setStage(1)
        } else if (stage == 0) {
            setOpenPermBox(true);
        } else if (stage == 1) {
            const code = generateRandomNum();
            setOtp(code);
            setStage(2);    
        } else if (stage == 2) {
            setStage(3);
        } else if (stage == 3) {
            setStage(4);
        }
    };


    const sendSelfieDetailsData = async (blobs, img) => {
        setLoading(true);
        try {
            const request_id = localStorage.getItem("request_id");
            const token = getUserToken();
            
            const headers = {
                'Content-Type': `multipart/form-data`,
                "api-key": `Bearer ${request_id}`

            }
            const form = new FormData();
            form.append('video', blobs);
            form.append('otp', otp);
            form.append('pan', token.pan);

            const url = process.env.REACT_APP_SERVICE_URL + verifyLiveliness;
        
            const resp = await axios.post(url, form, {headers});
            if (resp && resp.data && resp.data.message && resp.data.message.toLowerCase() === 'success') {
                const selfieReqResp = await pollSelfieReq();
                if(!selfieReqResp) throw new Error();

                const selfieCheckResp = await pollSelfieCheck();
                if(!selfieCheckResp) throw new Error();

                const selfieUploadResp =await selfieUpload(img);

                if(!selfieUploadResp) throw new Error();

                const url = await updateAndNext(location.pathname);
                navigate(url);
                return;
            }else throw new Error();

        } catch (e) {
            navigate('/error');
        }
        setLoading(false);
    }

    const pollSelfieReq = async () =>{
        return new Promise (async (resolve) =>{
            try{
                const interval = "";
                const resp = await checkSelfieReq(interval , resolve);
                if(resp === 2){
                    interval = setInterval(async () => {
                        const response = await checkSelfieReq(interval , resolve);
                        if(response === 1){
                            clearInterval(interval);
                            return resolve(true);
                        }else if(response === 3){
                            clearInterval(interval);
                            return resolve(false);
                        }
                    },3000)
                }else if(resp === 1) return resolve(true);
                else if(resp === 3) return resolve(false);
            }catch(e){
                resolve(false);
            }
        })
    }

    const checkSelfieReq = async (interval , resolve) => {
        try{
            const token = getUserToken();
            const url = process.env.REACT_APP_SERVICE_URL + getStatus + `?pageName=livenessCheck&pan=${token.pan}&nodeName=LIVENESS_REQUEST`
            const request_id = localStorage.getItem("request_id");
            const headers = {
                'api-key':`Bearer ${request_id}`
            }
            const resp = await axios.get(url , {headers});
            if(resp && resp.data && resp.data.queueDetails){
                const queueDetails = resp.data.queueDetails.find((obj) => obj.nodeName === 'LIVENESS_REQUEST');
                if(queueDetails.apiStatus.toLowerCase() === 'success'){
                    return 1;
                }else if(queueDetails.apiStatus.toLowerCase() === 'initiated'){
                   if(interval){
                    setSelfieReqPollAttempts((prevState) => {
                        if(prevState === 1){
                            clearInterval(interval);
                            return resolve(false);
                        }else return prevState = prevState - 1;
                    })
                   }else return 2;
                }else if(queueDetails.apiStatus.toLowerCase() === 'failure'){
                    return 3;
                }else throw new Error();
            }
        }catch(e){
            return 3;
        }
    }

    const pollSelfieCheck = async () =>{
        return new Promise (async (resolve) =>{
            try{
                const interval = "";
                const resp = await checkSelfieCheck(interval , resolve);
                if(resp === 2){
                    interval = setInterval(async () => {
                        const response = await checkSelfieCheck(interval , resolve);
                        if(response === 1){
                            clearInterval(interval);
                            return resolve(true);
                        }else if(response === 3){
                            clearInterval(interval);
                            return resolve(false);
                        }
                    },3000)
                }else if(resp === 1) return resolve(true);
                else if(resp === 3) return resolve(false);
            }catch(e){
                resolve(false);
            }
        })
    }

    const checkSelfieCheck = async (interval , resolve) => {
        try{
            const token = getUserToken();
            const url = process.env.REACT_APP_SERVICE_URL + getStatus + `?pageName=livenessCheck&pan=${token.pan}&nodeName=LIVENESS_CHECK`
            const request_id = localStorage.getItem("request_id");
            const headers = {
                'api-key':`Bearer ${request_id}`
            }
            const resp = await axios.get(url , {headers});
            if(resp && resp.data && resp.data.queueDetails){
                const queueDetails = resp.data.queueDetails.find((obj) => obj.nodeName === 'LIVENESS_CHECK');
                if(queueDetails.apiStatus.toLowerCase() === 'success'){
                    return 1;
                }else if(queueDetails.apiStatus.toLowerCase() === 'initiated'){
                   if(interval){
                    setSelfieCheckPollAttempts((prevState) => {
                        if(prevState === 1){
                            clearInterval(interval);
                            return resolve(false);
                        }else return prevState = prevState - 1;
                    })
                   }else return 2;
                }else if(queueDetails.apiStatus.toLowerCase() === 'failure'){
                    return 3;
                }else throw new Error();
            }
        }catch(e){
            return 3;
        }
    }

    const selfieUpload = async (img) => {
        try {
            const payload = getUserToken();
            const request_id = localStorage.getItem('request_id');
            const headers = {
                "api-key": `Bearer ${request_id}`
            }
            const body = {
                "context": {
                    "partner_loan_app_id": payload.partner_loan_app_id,
                    "partner_borrower_id": payload.partner_borrower_id,
                    "loan_app_id": payload.loan_app_id,
                    "borrower_id": payload.borrower_id,
                    "code": "003",
                    "pan": payload.pan,
                    "base64pdfencodedfile": img
                }
            }
        
            const url = process.env.REACT_APP_SERVICE_URL + verifySelfie;
            const resp = await axios.post(url, body, {headers});
            if(resp && resp.data && resp.data.message.toLowerCase() === 'success') return true;

            return false;
        } catch (e) {
            return false;
        }
    }


    const generateRandomNum = () => {
        return Math.floor(1000 + Math.random() * 9000);
    }

    return (
        <>
            {loading && <Loader/>}
            {openPermBox && !permissionProvided ?
                <Dialog
                    open={openPermBox}
                    className="text-font"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <div className='container' style={{ padding: "20px" }}>
                        <div className="row d-flex justify-content-center">
                            <div className="col-8">
                                <center>“Arthmate” would like to access the camera</center>
                            </div>

                        </div>
                        <div className="row d-flex justify-content-center mt-3">
                            <div className="col-8">
                                <center style={{ fontWeight: "400", fontSize: "14px", color: "#9CA3AF" }}>This permission is needed to take a selfie</center>
                            </div>

                        </div>

                        <div className="row d-flex justify-content-center mt-3">
                            <div className="col-6 d-flex justify-content-center">
                                <Button onClick={() => setOpenPermBox(false)} style={{ fontWeight: "400", fontSize: "16px", color: '#161719' }}>Don't Allow</Button>
                            </div>
                            <div className="col-6 d-flex justify-content-center">
                                <Button onClick={() => openCamera(true)} style={{ fontWeight: "600", fontSize: "16px", color: '#0076B2' }}>Allow</Button>
                            </div>


                        </div>
                    </div>
                </Dialog>
                : ""
            }
            <FlowHeader page = {props.page} />
            <div className="d-flex main-div flex-column justify-content-between text-font">
                <div className="container mt-4 text-font">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 header">
                                Selfie Details
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 sub-heading">
                                The selfie will be used to process your loan application.                        </div>
                        </div>

                    </div>
                </div>

                <div className="container mt-4">
                    <div className="container">
                        <div style={permissionProvided ? {} : { padding: "40px" }} className={`row default-photo-div`}>
                            <div style={permissionProvided ? { padding: "0" } : {}} className="col-12  d-flex justify-content-center">
                                {
                                    permissionProvided ?
                                        <Recorder callApi={(blobs, img) => sendSelfieDetailsData(blobs, img)} stage={stage} /> :
                                        <img src={logo} />
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {stage == 2 && <div className="container mt-4">
                    <div className="container">
                        <div className={`row otp-div`}>
                            <div style={{ backgroundColor: '#D4E3FC', borderRadius: "10px" }} className="col-12  d-flex justify-content-center">
                                {
                                    `OTP - ${otp}`
                                }
                            </div>
                            <center>
                                <div style={{ fontWeight: 400, color: "#767888", fontSize: "12px" }} className="pt-3 d-flex justify-content-center">
                                    Ensure your face is clearly visible
                                    and say the OTP clearly.
                                </div>
                            </center>
                        </div>
                    </div>
                </div>}

                {stage == 3 && <div className="container mt-4">
                    <div className="container">
                        <div>
                            <center>
                                <div style={{ fontWeight: 400, color: "#767888", fontSize: "12px" }} className="pt-3 d-flex justify-content-center">
                                    If everything looks good, press 'Submit'
                                </div>
                            </center>
                        </div>
                        <div>
                            <center>
                                <div style={{ fontWeight: 400, color: "#767888", fontSize: "12px" }} className="d-flex justify-content-center">
                                    If you want to record again, press 'Retry'
                                </div>
                            </center>
                        </div>
                    </div>
                </div>}

                {stage < 2 && <div className="container mt-4">
                    <div className="container pointers">
                        {permissionProvided ?
                            <>
                                <div className="row photo-pointers">
                                    <div className="col-12">
                                        Follow the steps:
                                    </div>
                                </div>

                                <div className="container">
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            1. Click <span className="photo-pointers">'Start'</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            2. Say the <span className="photo-pointers"> OTP</span> displayed on the screen
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-12">
                                            3. Click <span className="photo-pointers">'Submit'</span> after saying the <span className="photo-pointers"> OTP</span>
                                        </div>
                                    </div>
                                </div>

                            </>
                            :
                            <>
                                <div className="row">
                                    <div className="col-1">
                                        <img src={person} />

                                    </div>
                                    <div className="col-11">
                                        <span>Ensure your face is visible within the frame</span>
                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <div className="col-1">
                                        <img src={bulb} />

                                    </div>
                                    <div className="col-11">
                                        <span>Ensure that you are in a place with good lighting</span>
                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <div className="col-1">
                                        <img src={glass} />

                                    </div>
                                    <div className="col-11">
                                        <span>Please take a selfie without wearing a hat, glasses or any other accessories which may block your face</span>
                                    </div>

                                </div>
                                <div className="row mt-3">
                                    <div className="col-1">
                                        <img src={mute} />

                                    </div>
                                    <div className="col-11">
                                        <span>Please ensure you are in a quiet environment. You will be required to speak an OTP which will be captured during the process</span>
                                    </div>

                                </div>
                            </>}
                    </div>
                </div>}

                <div className="container button-container mt-4">
                    <div className="container">
                        {
                            stage === 3 &&
                            <div className="row pb-2">
                                <div className="col-12">
                                    <Button className="retry" onClick={() => openCamera(false, true)} variant='contained' style={{ width: "100%" }}>Retry</Button>
                                </div>
                            </div>
                        }
                        <div className="row">
                            {stage == 2 && <CameraCounter open={() => openCamera()} />}
                            <div className="col-12">
                                <Button disabled={loading} onClick={() => openCamera(false)} variant='contained' style={{ width: "100%" }}>{stage == 1 ? "Start" : stage == 2 ? "Stop" : stage === 3 ? "Submit" : "Submit"}</Button>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    )
}



export default RouteProtector(SelfieDetails);