import React, {useContext} from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/loader.css';
import { Context } from "../context/context";

const Loader = (props) => {
    const { isOpen = true } = props;
    const globalVars = useContext(Context);
    


    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isOpen}
        // onClick={handleClose}
        >
            <div className="loader-div d-flex flex-column justify-content-center p-3 h-auto">
                <div className="content-container-loader">
                    <div className="d-flex justify-content-center mb-3">
                        <CircularProgress className="loader" color="inherit" />
                    </div>

                    <div className="loader-text mb-3">
                        {globalVars?.loadMsg ?? 'Please wait while we process your information'}
                    </div>
                </div>
            </div>
        </Backdrop>
    )
}


export default Loader;