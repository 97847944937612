import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/kycVerification.css';
import '../styles/communicationDetails.css'
import { TextField, Button, Snackbar, Slide, Radio, FormControlLabel, Alert, Select, MenuItem, InputAdornment } from "@mui/material";
import { useState, useEffect } from "react";
import RouteProtector from "../commonComponents/routeProctector";
import FlowHeader from "../commonComponents/FlowHeader";
import Footer from "../commonComponents/Footer";
import { getUserToken } from "../helpers/data";
import Loader from "../commonComponents/loader";
import { States, Cities , updateAndNext } from "../helpers/data";
import { updateLeadInfo, getStatus } from "../apis";
import axios from 'axios';
import { useLocation, useNavigate } from "react-router-dom";

const CommunicationDetails = (props) => {
    const [selectedRadio, setSelectedRadio] = useState("y");
    const [address, setAddress] = useState("");
    const [loading, setLoading] = useState(false);
    const [flat, setFlat] = useState("");
    const [street, setStreet] = useState("");
    const [area, setArea] = useState("");
    const [pincode, setPincode] = useState("");
    const [citiesList, setCitiesList] = useState([]);
    const [city, setCity] = useState("");
    const [state, setState] = useState("");

    // const [pollAttempts, setPollAttempts] = useState(5);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        fillDetailsOnLoad();
    }, [])

    const handleButtonDisabled = () => {
        if (selectedRadio === "") return true;

        if (selectedRadio === 'y' && (address === '' || address.length < 5)) return true;


        if (selectedRadio === 'n') {
            if (!flat || !street || !area || !pincode || pincode.length != 6 || !city || !state || !address) return true;
        }

        return false;
    }


    const fillDetailsOnLoad = () => {
        const token = getUserToken();

        const aadhaarAddress = (token.resi_addr_ln1 ? token.resi_addr_ln1 + " " : "") +
            (token.resi_addr_ln1 ? token.resi_addr_ln2 + ", " : "") +
            (token.resi_addr_ln1 ? token.city + ", " : "") +
            (token.resi_addr_ln1 ? token.state + ", " : "") +
            (token.resi_addr_ln1 ? token.pincode : "");
        setAddress(aadhaarAddress);
    }

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    }


    const handleStateChange = (e) => {
        setCity("");
        const cities = Cities(e.target.value);
        setCitiesList([...cities]);
        setState(e.target.value);
    }

    const handleCityChange = (e) => {
        setCity(e.target.value);
    }

    const handleFlatChange = (e) => {
        let addrr = e.target.value.trim();
        if (addrr.length <= 40) {
            setFlat(addrr)
        }
    }

    const handlePinChange = (e) => {
        let code = e.target.value;
        if (code.length <= 6) setPincode(code);
    }

    const handleStreetChange = (e) => {
        let street = e.target.value.trim();
        if (street.length <= 40) {
            setStreet(street)
        }
    }

    const handleAreaChange = (e) => {
        let area = e.target.value.trim();
        if (area.length <= 40) {
            setArea(area)
        }
    }

    const onSubmit = async () => {
        setLoading(true);
        try {
            const url = process.env.REACT_APP_SERVICE_URL + updateLeadInfo;
            const request_id = localStorage.getItem("request_id");
            const token = getUserToken();
            const headers = {
                'api-key': `Bearer ${request_id}`
            }


            const completeAddr = flat + street + area;

            const addr1 = selectedRadio === 'y' ? token.resi_addr_ln1 : completeAddr.substring(0, 40).trim();
            const addr2 = selectedRadio === 'y' ? token.resi_addr_ln2 : completeAddr.substring(40).trim();
            const pin = selectedRadio === 'y' ? token.pincode : pincode;

            const payload = {
                "submitData": {
                    "partner_loan_app_id": token.partner_loan_app_id,
                    "partner_borrower_id": token.partner_borrower_id,
                    "pan": token.pan,
                    "first_name": token.first_name,
                    "last_name": token.last_name,
                    "dob": token.dob,
                    "resi_addr_ln1": addr1,
                    "resi_addr_ln2": addr2,
                    "pincode": pin,
                    "city": selectedRadio === 'y' ? token.city : city.value,
                    "state": selectedRadio === 'y' ? token.state : state.value
                },
                pageName: 'communicationDetails',
            };
            const response = await axios.post(url, payload, { headers });
            if (response && response.data && response.data.success) {
                const resp = await pollStatus();
                if (!resp) throw new Error();

                const url = await updateAndNext(location.pathname);
                navigate(url);
                return;
            }
        } catch (e) {
            console.log(e);
            navigate('/error');
        }
        setLoading(false);
    }



    const pollStatus = async () => {
        return new Promise(async (resolve) => {
            try {
                let interval = "";
                const response = await checkUpdateStatus(interval, resolve);
                if (response == 2) {
                    interval = setInterval(async () => {
                        const resp = await checkUpdateStatus(interval, resolve);
                        // success
                        if (resp === 1) {
                            clearInterval(interval);
                            return resolve(true);
                        } else if (response === 3 || response === 4) {
                            clearInterval(interval);
                            return response(false);
                        }
                    })
                }else if(response === 1) return resolve(true);
                else if(response === 3 || response == 4) resolve(false);
            } catch (e) {
                console.log(e);
                resolve(false);
            }
        })
    }


    const checkUpdateStatus = async (interval, resolve) => {
        try {
            const token = getUserToken();
            const url = process.env.REACT_APP_SERVICE_URL + getStatus + `?pageName=communicationDetails&pan=${token.pan}`;
            const request_id = localStorage.getItem("request_id");
            const headers = {
                'api-key': `Bearer ${request_id}`

            }
            const response = await axios.get(url, { headers });
            if (response && response.data && response.data.success) {
                return 1;
            } 
            // else if (response && response.data && response.data.success.toLowerCase() === 'initiated') {
            //     if (interval) {
            //         setPollAttempts((prevState) => {
            //             if (prevState === 1) {
            //                 clearInterval(interval);
            //                 return resolve(false)
            //             } else return prevState = prevState - 1;
            //         })
            //     } else {
            //         return 2;
            //     }
            // } 
            else if (response && response.data && !response.data.success) {
                return 3;
            } else throw new Error();

        } catch (e) {
            console.log(e);
            return 4;
        }
    }


    return (
        <>
            {loading && <Loader />}
            <FlowHeader page = {props.page}/>
            <div className="main-div d-flex flex-column justify-content-between text-font">
                <div className="container mt-4 text-font">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 header">
                                Communication Details
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                We will use this information to perform checks & validation to process your loan
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12 field-headers">
                                Address (as per Aadhaar)
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <TextField className="input"
                                    id="outlined-multiline-static"
                                    label=""
                                    placeholder="Enter Here"
                                    multiline
                                    value={address}
                                    style={{ width: "100%" }}
                                    onChange={handleAddressChange}
                                />
                            </div>
                        </div>

                        <div className="row mt-4">
                            <div className="col-12 field-headers">
                                Is your current address the same as your
                                Aadhaar-registered address?
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12 d-flex flex-row justify-content-start">
                                <FormControlLabel onClick={() => setSelectedRadio("y")} checked={selectedRadio == "y"} value="y" control={<Radio />} label="Yes" />
                                <FormControlLabel onClick={() => setSelectedRadio("n")} checked={selectedRadio == "n"} value="n" control={<Radio />} label="No" />
                            </div>
                        </div>
                        {
                            selectedRadio !== 'y' &&
                            <>
                                <div className="row mb-3 location-details">
                                    <div className="col-12">
                                        Flat No./ House No./Building
                                    </div>
                                    <div className="col-12">
                                        <TextField className="input"
                                            value={flat}
                                            onChange={handleFlatChange}
                                            id="outlined-multiline-static"
                                            label=""
                                            placeholder="Address Line 1"
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                </div>
                                <div className="row location-details mb-3">
                                    <div className="col-12">
                                        Street Name
                                    </div>
                                    <div className="col-12">
                                        <TextField className="input"
                                            value={street}
                                            onChange={handleStreetChange}
                                            id="outlined-multiline-static"
                                            label=""
                                            placeholder="Address Line 2"
                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                </div>
                                <div className="row location-details mb-3">
                                    <div className="col-12">
                                        Area
                                    </div>
                                    <div className="col-12">
                                        <TextField className="input"
                                            id="outlined-multiline-static"
                                            value={area}
                                            onChange={handleAreaChange}
                                            label=""
                                            placeholder="Enter Here"

                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                </div>
                                <div className="row location-details mb-3">
                                    <div className="col-12">
                                        Pincode
                                    </div>
                                    <div className="col-12">
                                        <TextField className="input"
                                            value={pincode}
                                            onChange={handlePinChange}
                                            id="outlined-multiline-static"
                                            label=""
                                            placeholder="Enter 6-digit Pincode"

                                            style={{ width: "100%" }}
                                        />
                                    </div>
                                </div>
                                <div className="row location-details mb-1">
                                    <div className="col-6">
                                        State
                                    </div>
                                    <div className="col-6">
                                        City
                                    </div>
                                </div>
                                <div className="row location-details mb-3">
                                    <div className="col-6">
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="city"
                                            displayEmpty
                                            placeholder="Select"
                                            onChange={handleStateChange}
                                            value={state}
                                            style={{ width: "100%" }}
                                        >
                                            <MenuItem value="">
                                                <em style={{ color: "#9CA3AF", fontStyle: "normal" }}>Select</em>
                                            </MenuItem>
                                            {States?.map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={index}>{item.label}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                    <div className="col-6">
                                        <Select
                                            labelId="demo-simple-select-label"
                                            style={{ width: "100%" }}
                                            displayEmpty
                                            value={city}
                                            onChange={handleCityChange}
                                            placeholder="Select"
                                            id="state"
                                            defaultValue={"Select"}
                                        >
                                            <MenuItem value="">
                                                <em style={{ color: "#9CA3AF", fontStyle: "normal" }}>Select</em>
                                            </MenuItem>
                                            {citiesList?.map((item, index) => {
                                                return (
                                                    <MenuItem value={item} key={index}>{item.label}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </div>
                                </div>
                            </>
                        }
                    </div>

                </div>
                <div className="container button-container mt-4">
                    <div className="container">
                        <div className="row button-container-com">
                            <div className="col-12">
                                <Button onClick={onSubmit} disabled={handleButtonDisabled()} className='submit-button' variant='contained' style={{ width: "100%" }}>Submit & Next</Button>
                                <Footer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )


}

export default RouteProtector(CommunicationDetails);