import React, { useEffect } from "react";
import { useState } from "react";
import { Button, TextField, Radio, FormControlLabel } from "@mui/material";
import '../styles/bankDetails.css';
import 'bootstrap/dist/css/bootstrap.css';
import BottomStickModal from "../commonComponents/BottomStickModal";
import FlowHeader from "../commonComponents/FlowHeader";
import Loader from "../commonComponents/loader";
import Footer from "../commonComponents/Footer";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { verifyBankDetails, getStatus, getZoopRequestId , getZoopStatus } from "../apis";
import { getUserToken, updateAndNext } from "../helpers/data";
import RouteProtector from "../commonComponents/routeProctector";
import axios from "axios";



const BankDetails = (props) => {


    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const [ifsc, setIfsc] = useState({ value: "", error: false });
    const [name, setName] = useState('');
    const [accNum, setAccNum] = useState({ value: "", error: false });
    const [confirmAccNum, setConfirmAccNum] = useState({ value: "", error: false });
    const [accType, setAccType] = useState('');

    const [pennyDropPollAttempts, setPennyDropPollAttempts] = useState(10);
    const [nameMatchPollAttempts, setNameMatchPollAttempts] = useState(10);

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams , setSearchParams] = useSearchParams();




    useEffect(() => {
        const id = searchParams.size ? searchParams.get('id') : ""
        if(id) getESignStatus(id);
    },[])

    const onPressSubmit = () => {
        setOpenModal(true);
    }

    const onPressNoModal = () => {
        setOpenModal(false)
    }


    const handleIfscChange = (e) => {
        const value = e.target.value.toUpperCase();

        if (value.length > 11) return;

        let ifscObj = ifsc;
        ifscObj.value = value;
        ifscObj.error = !validateIfsc(value);

        setIfsc({ ...ifscObj });
    }


    const handleBankNameChange = (e) => {
        if (/^[a-zA-Z ]+$/.test(e.target.value) || !e.target.value) setName(e.target.value)

    }

    const handleAccNumChange = (e) => {
        if (/^[0-9]+$/.test(e.target.value) || !e.target.value) {
            let obj = accNum;
            obj.value = e.target.value;
            obj.error = !validateAccNum(e.target.value);
            setAccNum({ ...obj })
        }

    }

    const handleConfirmAccNumChange = (e) => {
        if (/^[0-9]+$/.test(e.target.value) || !e.target.value) {
            let obj = confirmAccNum;
            obj.value = e.target.value;
            obj.error = !(validateAccNum(e.target.value) && e.target.value === accNum.value);
            setConfirmAccNum({ ...obj })
        }
    }

    const handleAccTypeChange = (e) => {
        setAccType(e);
    }

    const validateIfsc = (value) => {
        return /^[A-Z]{4}[A-Z0-9]{7}$/.test(value);
    }

    const validateAccNum = (value) => {
        return /^\d{5,}$/.test(value);
    }


    const handleButtonDisabled = () => {
        if (!ifsc.value || ifsc.error || !name || !accNum.value || accNum.error || !confirmAccNum.value || confirmAccNum.error || !accType || loading) return true;

        return false;
    }

    const onSubmit = async () => {
        setLoading(true);
        // try {
        //     const url = process.env.REACT_APP_SERVICE_URL + verifyBankDetails;
        //     const token = getUserToken();
        //     const request_id = localStorage.getItem("request_id");

        //     const headers = {
        //         'api-key': `Bearer ${request_id}`
        //     }

        //     const body = {
        //         "submitData": {
        //             "pan": token.pan,
        //             "loan_app_id": token.loan_app_id,
        //             "ifsc": ifsc.value,
        //             "account_number": accNum.value,
        //             "account_type": accType,
        //             "bank_name": name,
        //         },
        //         pageName: "bankVerification",
        //         nodeNameData: "bankvalidation",
        //     }

        //     const response = await axios.post(url, body, { headers });
        //     if (response && response.data && response.data.success) {
        //         const bankVerifyResp = await pollBankVerifyStatus();
        //        if(!bankVerifyResp) throw new Error();

        //        const nameMatchResp = await pollBankNameMatchStatus();
        //        if(!nameMatchResp) throw new Error();

        //        // bank page pending 
        //     }
        // } catch (e) {
        //     navigate('/error');
        // }

        await zoopEsign();
        setLoading(false);

    }


    const zoopEsign = async () => {
        // const zoop = window.zoop;
        try{
            const url = process.env.REACT_APP_SERVICE_URL + getZoopRequestId;
            const request_id = localStorage.getItem("request_id");
            const headers = {
                'api-key': `Bearer ${request_id}`
            }
            const response = await axios.post(url, {}, { headers });
            localStorage.setItem("zoop_request_id" ,response.data.data[0].request_id)
            window.location.href = response.data.data[0].signing_url;
            return true;
        }catch(e){
            return false;
        }
    }

    const getESignStatus = async (id) =>{
        setLoading(true);
        try{
            const url = process.env.REACT_APP_SERVICE_URL + getZoopStatus + `/${id}`;
            const request_id = localStorage.getItem("request_id");
            const headers = {
                'api-key': `Bearer ${request_id}`
            }
            const response = await axios.get(url,{ headers });

            if(response && response.data && !response.data.sucess) throw new Error();

            await updateAndNext(location.pathname);
        }catch(e){
            console.log(e);
            navigate('/error');
        }
    }

    const pollBankVerifyStatus = async () => {
        return new Promise(async (resolve) => {
            try {
                let interval = "";
                const response = await checkBankVerify(interval, resolve);
                if (response === 2) {
                    interval = setInterval(async () => {
                        const resp = await checkBankVerify(interval, resolve);
                        if (resp == 1) {
                            clearInterval(interval);
                            return resolve(true);
                        } else if (resp == 3) {
                            clearInterval(interval);
                            return resolve(false);
                        }
                    }, 3000)
                } else if (response === 1) return resolve(true);
                else if (response === 3) return resolve(false);
            } catch (e) {
                return resolve(false);
            }
        })
    }

    const checkBankVerify = async (interval, resolve) => {
        try {
            const token = getUserToken();
            const url = process.env.REACT_APP_SERVICE_URL + getStatus + `?pan=${token.pan}&pageName=bankVerification`;
            const request_id = localStorage.getItem("request_id");
            const headers = {
                'api-key': `Bearer ${request_id}`
            }
            const resp = await axios.get(url, { headers });
            if (resp && resp.data && resp.data.queueDetails) {
                const queueDetails = resp.data.queueDetails.find((obj) => obj.apiName.toLowerCase() === 'bankvalidation');
                if (queueDetails.apiStatus.toLowerCase() === 'success') {
                    return 1;
                } else if (queueDetails.apiStatus.toLowerCase() === 'initiated') {
                    if (interval) {
                        setPennyDropPollAttempts((prevState) => {
                            if (prevState == 1) {

                                clearInterval(interval);
                                return resolve(false);
                            } else return prevState = prevState - 1;
                        })
                    } else return 2;
                } else if (queueDetails.apiStatus.toLowerCase() === 'failure') {
                    return 3;
                }
            } else throw new Error();
        } catch (e) {
            return 3;
        }
    }

    const pollBankNameMatchStatus = async () => {
        return new Promise(async (resolve) => {
            try {
                let interval = "";
                const response = await checkNameMatch(interval, resolve);
                if (response === 2) {
                    interval = setInterval(async () => {
                        const resp = await checkNameMatch(interval, resolve);
                        if (resp == 1) {
                            clearInterval(interval);
                            return resolve(true);
                        } else if (resp == 3) {
                            clearInterval(interval);
                            return resolve(false);
                        }
                    }, 3000)
                } else if (response === 1) return resolve(true);
                else if (response === 3) return resolve(false);
            } catch (e) {
                return resolve(false);
            }
        })
    }

    const checkNameMatch = async (interval, resolve) => {
        try {
            const token = getUserToken();
            const url = process.env.REACT_APP_SERVICE_URL + getStatus + `?pan=${token.pan}&pageName=bankVerification`;
            const request_id = localStorage.getItem("request_id");
            const headers = {
                'api-key': `Bearer ${request_id}`
            }
            const resp = await axios.get(url, { headers });
            if (resp && resp.data && resp.data.queueDetails) {
                const queueDetails = resp.data.queueDetails.find((obj) => obj.apiName.toLowerCase() === 'namematching');
                if (queueDetails.apiStatus.toLowerCase() === 'success') {
                    return 1;
                } else if (queueDetails.apiStatus.toLowerCase() === 'initiated') {
                    if (interval) {
                        setNameMatchPollAttempts((prevState) => {
                            if (prevState == 1) {

                                clearInterval(interval);
                                return resolve(false);
                            } else return prevState = prevState - 1;
                        })
                    } else return 2;
                } else if (queueDetails.apiStatus.toLowerCase() === 'failure') {
                    return 3;
                }
            } else throw new Error();
        } catch (e) {
            return 3;
        }
    }

    const buttons =
        <>
            <Button disabled={loading} onClick={() => setOpenModal(false)} className="back" variant="outlined">Back</Button>
            <Button onClick={onSubmit} className="proceed" variant='contained'>Proceed</Button>
        </>

    return (
        <>
            {openModal && !loading ?
                <BottomStickModal
                    header={"Confirm Submission"}
                    subHeading={"Please ensure you have shared the correct bank account details. Your EMI will be deducted from the account shared here."}
                    isOpen={openModal}
                    buttons={buttons}
                    onPressNo={() => onPressNoModal()}
                /> : ""
            }
            {loading && <Loader />}
            <FlowHeader page={props.page} />
            < div className="d-flex main-div flex-column justify-content-between text-font" >
                < div className="container mt-4 text-font" >
                    <div className="container">
                        <div className="row">
                            <div className="col-12 header">
                                BankDetails
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 sub-heading">
                                This information will be used to process your loan application.         </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 field-heading">
                                IFSC
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <TextField value={ifsc.value} onChange={handleIfscChange} className="input" id="outlined-basic" label="" placeholder="Enter bank IFSC" variant="outlined" />
                                {ifsc.error && <p style={{ margin: 0, padding: 0, fontSize: '12px' }} className="text-danger">Please enter a valid IFSC code</p>}
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 field-heading">
                                Bank Name
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <TextField value={name} onChange={handleBankNameChange} className="input" id="outlined-basic" label="" placeholder="Enter bank name" variant="outlined" />
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 field-heading">
                                Account Number
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <TextField type="password" value={accNum.value} onChange={handleAccNumChange} className="input" id="outlined-basic" label="" placeholder="Enter bank account number" variant="outlined" />
                                {accNum.error && <p style={{ margin: 0, padding: 0, fontSize: '12px' }} className="text-danger">Please enter a valid account number</p>}
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 field-heading">
                                Confirm Account Number
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <TextField value={confirmAccNum.value} onChange={handleConfirmAccNumChange} className="input" id="outlined-basic" label="" placeholder="Confirm bank account number" variant="outlined" />
                                {confirmAccNum.error && <p style={{ margin: 0, padding: 0, fontSize: '12px' }} className="text-danger">Account number does not match</p>}
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-12 d-flex field-heading">
                                <FormControlLabel checked={accType === 'Savings Account'} onClick={() => handleAccTypeChange('Savings Account')} value="savings" control={<Radio />} label="Savings Account" />
                                <FormControlLabel checked={accType === 'Current Account'} onClick={() => handleAccTypeChange('Current Account')} value="current" control={<Radio />} label="Current Account" />
                            </div>
                        </div>
                    </div>
                </div >
                <div className="container button-container mt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <Button disabled={false} onClick={onPressSubmit} variant='contained' style={{ width: "100%" }}>Submit & Next</Button>
                                <Footer />
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}



export default RouteProtector(BankDetails);