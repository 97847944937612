import React, { useEffect, useState } from "react";
import { Dialog, Slide, Button } from "@mui/material";
import '../styles/commonModal.css';
import 'bootstrap/dist/css/bootstrap.css';

const BottomStickModal = (props) => {
    const { header = "", subHeading = "", buttons } = props;

    const { isOpen = true } = props;


    const [open, setOpen] = useState(false);



    useEffect(() => {
        setOpen(isOpen);
    }, []);



    return (
        <Dialog
            open={open}
            className="dialog-text"
            aria-describedby="alert-dialog-slide-description"
        >
            <div className="container">
                <div className="row heading-row">
                    <div className="col-12 heading">
                        {header}
                    </div>
                </div>
                <div className="row heading-row">
                    <div className="col-12 sub-heading">
                        {subHeading}
                    </div>
                </div>
                <div className="row heading-row d-flex justify-content-between">
                    {buttons}
                </div>
            </div>
        </Dialog>
    )
}


export default BottomStickModal;