import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/kycVerification.css'
import { TextField, Button, InputAdornment } from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState, useEffect } from "react";
import OtpVerificationModal from "../commonComponents/OtpVerificationModal";
import { convertDob } from "../helpers";
import Footer from "../commonComponents/Footer";
import RouteProtector from "../commonComponents/routeProctector";
import { useLocation, useNavigate } from "react-router-dom";
import FlowHeader from "../commonComponents/FlowHeader";
import { getUserToken , updateAndNext } from "../helpers/data";
import axios from 'axios';
import { kycVerification, getStatus, verifyOtp as otpVerification , updatePageStatus } from "../apis";
import Loader from "../commonComponents/loader";

const KycVerificationPage = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const successMobile = "Enter mobile number linked to Aadhaar ID";
    const errorMobileInvalid = "Please enter a valid mobile number";
    const errorPan = "Invalid PAN, Please enter a valid PAN";
    const errorAdhaar = "Invalid Aadhaar Number";
    const mobileValidation = /^[1-9][0-9]*$/;
    const panValidation = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    const [mobile, setMobile] = useState({ value: "", error: false, text: successMobile });
    const [pan, setPan] = useState({ value: "", error: false, text: "", verified: false });
    const [adhaar, setAdhaar] = useState({ value: "", error: false, text: "", verified: false, request_id: "" });
    const [name, setName] = useState("");
    const [dob, setDob] = useState("");
    const [loading, setLoading] = useState(false);
    const [adhaarPollAttempts, setAdhaarPollAttempts] = useState(10);
    const [panPollAttempts, setPanPollAttempts] = useState(10);
    const [verifyOtp, setVerifyOtp] = useState(false);
    const [otpPollAttempts, setOtpPollAttempts] = useState(5);
    const [wrongOtp , setWrongOtp] = useState(false);
    useEffect(() => {
        setInitialValues();
    }, []);


    const setInitialValues = () => {
        const token = getUserToken();
        let panNum = token.pan;
        let name = (token.first_name ? token.first_name + " " : "") + (token.middle_name ? token.middle_name + " " : "") + (token.last_name ? token.last_name + " " : "");
        let dob = convertDob(token.dob);
        let mobile = token.appl_phone;
        let adhaar = token.parsed_aadhaar_number;

        setMobile({ ...{ value: mobile, error: false, text: successMobile } });
        setPan({ ...{ value: panNum, error: false, text: "", verified: false } });
        setAdhaar({ ...{ value: adhaar, error: false, text: "", verified: false, request_id: "" } });
        setName(name);
        setDob(dob);
    }



    const checkIfMobileValid = (value) => {
        let number = value || mobile.value
        if (!number) return;

        let mobileObj = mobile;
        if (mobileValidation.test(number) && number.length == 10) {
            mobileObj.value = number;
            mobileObj.error = false;
            mobileObj.text = successMobile;
        } else {
            mobileObj.error = true;
            mobileObj.text = errorMobileInvalid;
        }

        setMobile({ ...mobileObj });
    }


    const setMobileNumber = (value) => {
        if ((mobileValidation.test(value) || value == "" || !value) && value.length <= 10) {
            let mob = mobile;
            mob.value = value;
            checkIfMobileValid();
            setMobile({ ...mob });
        }

    }



    const checkIfPanValid = (value) => {
        if (!value) return;

        let panObj = pan;
        if (panValidation.test(value)) {
            panObj.value = value;
            panObj.error = false;
            panObj.text = "";
        } else {
            panObj.error = true;
            panObj.text = errorPan
        }
        setPan({ ...panObj });
    }

    const setANumber = (val) => {
        let value = val.target.value;

        let adhaarObj = adhaar;
        if ((/^[1-9][0-9]*$/.test(value.split(" ").join("")) || value == "" || !value) && value.length <= 14) {


            if (value.length == 5 || value.length == 10) {
                adhaarObj.value = value.slice(0, value.length - 1);
            } else if (value.length == 4 || value.length == 9) {
                adhaarObj.value = value + " ";
            } else if (value.length < 4 || (value.length > 4 && value.length < 9) || value.length > 9) {
                adhaarObj.value = value;
            }
            checkifAdhaarNumberIsValid()
            setAdhaar({ ...adhaarObj })
        }


    }

    const checkifAdhaarNumberIsValid = (value) => {
        let number = value || adhaar.value
        if (!number) return;
        let adhaarObj = adhaar;
        if (number.length == 14 && number.split(" ").join("").length == 12) {
            adhaarObj.value = number;
            adhaarObj.error = false;
            adhaarObj.text = "";
        } else {
            adhaarObj.error = true;
            adhaarObj.text = errorAdhaar
        }
        setAdhaar({ ...adhaarObj });
    }

    const handleButtonDisabled = () => {
        if (mobile.value && pan.value && adhaar.value && !mobile.error && !pan.error && !adhaar.error && name && dob) return false;


        return true;
    }


    const submit = async () => {
        setLoading(true)
        try {
            const promises = [kycVerify('panvalidation'), kycVerify('aadhaarvalidation')];
            const resp = await Promise.all(promises);

            if (resp.length && resp[0].status && resp[1].status) {
                const checkPromises = [pollStatus('panvalidation'), pollStatus('aadhaarvalidation')];
                const checkResp = await Promise.all(checkPromises);
                if (checkResp.length && checkResp[0] && checkResp[1]) setVerifyOtp(true);
                else navigate('/error');
            }

        } catch (e) {
            navigate('/error');
        }
        setLoading(false);
    }



    const kycVerify = async (validationName) => {
        try {
            const payload = getUserToken();
            const data = localStorage.getItem('data');
            const request_id = localStorage.getItem("request_id");
            const url = process.env.REACT_APP_SERVICE_URL + kycVerification + `?encryptText=${data}&validationName=${validationName}`;

            const headers = {
                'api-key': `Bearer ${request_id}`
            }

            let body = {
                submitData: payload,
                pageName: "kycVerification"
            }

            if(validationName === 'aadhaarvalidation') body.submitData["aadhaar_no"] = adhaar.value.split(" ").join("");

            const response = await axios.post(url, body, { headers });
            if (response && response.data && response.data.success) return { status: true }


            return { status: false };
        } catch (e) {
            console.log(e);
            return { status: false }
        }
    }

    const pollStatus = async (validationName) => {
        return new Promise(async (resolve) => {
            try {
                let interval = "";
                const response = await checkKycStatus(validationName);
                if (response === 3) {
                    interval = setInterval(async () => {
                        const response = await checkKycStatus(validationName, interval, resolve);
                        if (response == 4 || response === 2) {
                            clearInterval(interval);
                            return resolve(false)
                        }
                        else if (response === 1) {
                            clearInterval(interval);
                            return resolve(true);
                        }
                    }, 3000);
                } else resolve(true);

            } catch (e) {
                console.log(e);
                navigate('/error');
            }
        })
    }


    const resendOtp = async () =>{
        setLoading(true);
        try{
            let adhaarObj = adhaar;
            adhaarObj.request_id = "";
            setAdhaar(adhaarObj);
            const validation = 'aadhaarvalidation'
            const response = await kycVerify(validation);
            if(response && response.status) {
                const resp  = await pollStatus(validation);
                if(!resp) {
                    navigate('/error') ;
                    return false;
                }
                return true;
            }
            return false;
        }catch(e){
            console.log(e);
            return false;
        }
        setLoading(false);
    }


    const checkKycStatus = async (validationName, interval, resolve) => {
        try {
            const url = process.env.REACT_APP_SERVICE_URL + getStatus + `?pageName=kycVerification&pan=${pan.value}&nodeName=${validationName}`;
            const request_id = localStorage.getItem("request_id");
            const headers = {
                'api-key': `Bearer ${request_id}`

            }
            const response = await axios.get(url, { headers });
            if (validationName === 'panvalidation') {
                let queueDetails = response.data.queueDetails.find((obj) => obj.apiName.toLowerCase() === 'pan_verify' || obj.apiName.toLowerCase() === 'panvalidation');
                if (queueDetails && queueDetails.apiStatus.toLowerCase() === 'success') {
                    let panObj = pan;
                    panObj.verified = true;
                    setPan({ ...panObj });
                    return 1;
                } else if (queueDetails && queueDetails.apiStatus.toLowerCase() === 'failure') {
                    return 2;
                } else {
                    setPanPollAttempts((prevState) => {
                        if (prevState === 1) {
                            clearInterval(interval);
                            return resolve(false);
                        }
                        else return prevState = prevState - 1;
                    })

                    return 3;
                }
            } else if (validationName === 'aadhaarvalidation') {
                let queueDetails = response.data.queueDetails.find((obj) => obj.apiName.toLowerCase() === 'aadhaarvalidation' || obj.apiName.toLowerCase() === 'aadhaar_verify');
                if (queueDetails && queueDetails.apiStatus.toLowerCase() === 'success') {
                    let adhaarObj = adhaar;
                    adhaarObj.verified = true;
                    adhaarObj.request_id = queueDetails.resBody.data.requestId;
                    setAdhaar({ ...adhaarObj });
                    return 1;
                } else if (queueDetails && queueDetails.apiStatus.toLowerCase() === 'failure') {
                    return 2;
                } else {
                    setAdhaarPollAttempts((prevState) => {
                        if (prevState === 1) {
                            clearInterval(interval);
                            return resolve(false);
                        }
                        else return prevState = prevState - 1;
                    })

                    return 3;
                }
            }
        } catch (e) {
            console.log(e);
            navigate('/error');
        }
    }


    const onSubmit = async (otp) => {
        setLoading(true);
        try {

            const data = localStorage.getItem('data');
            const request_id = localStorage.getItem("request_id");
            const url = process.env.REACT_APP_SERVICE_URL + otpVerification + `?encryptText=${data}`;
            const user_payload = getUserToken();
            const headers = {
                "api-key": `Bearer ${request_id}`
            }
            const payload = {
                "request_id": adhaar.request_id,
                "loan_app_id": user_payload.loan_app_id,
                "aadhaar_number": adhaar.value.split(" ").join(""),
                "pan": pan.value,
                "otp": otp.join("")
            }
            const response = await axios.post(url, payload, {headers});
            if (response && response.data && response.data.statusCode === 101) {
                const resp = await pollOtpStatus();
                if (!resp) throw new Error();


                if(resp && resp.status && resp.code === 4){
                    setWrongOtp(true);
                    return;
                }

                const url = await updateAndNext(location.pathname);
                navigate(url);
                return;
            } else if (response && response.data & response.data.statusCode === 102) {
                
            }

        } catch (e) {
            console.log(e)
            navigate('/error');
        }
        setLoading(false);
    }


    const pollOtpStatus = async () => {
        return new Promise(async (resolve) => {
            debugger;
            try {
                let interval = "";
                const response = await checkOtpStatus();
                if (response === 2) {
                    interval = setInterval(async () => {
                        const resp = await checkOtpStatus(interval, resolve);
                        if (resp == 3) {
                            clearInterval(interval);
                            return resolve(false);
                        } else if (resp == 1) {
                            clearInterval(interval);
                            return resolve(true);
                        } else if(resp == 4){
                            clearInterval(interval);
                            return resolve({status:true , code:4});
                        }
                    }, 3000)
                }else if(response === 3) return resolve(false)
                else if(response === 1) return resolve(true)
            } catch (e) {
                console.log(e);
                resolve(false);
            }
        })
    }


    const checkOtpStatus = async (interval, resolve) => {
        try {
            const request_id = localStorage.getItem('request_id');
            const headers = {
                "api-key": `Bearer ${request_id}`
            }
            debugger
            const response = await axios.get(process.env.REACT_APP_SERVICE_URL + getStatus + `?pageName=kycVerification&pan=${pan.value}&nodeName=AadhaarOtpVerify`, { headers });
            if (response && response.data && response.data.queueDetails) {
                let queueDetails = response.data.queueDetails;
                queueDetails = queueDetails[0];
                if (queueDetails.apiStatus.toLowerCase() === 'success') {
                    return 1;
                } else if (queueDetails.apiStatus.toLowerCase() === 'initiated') {
                    if(interval){
                        setOtpPollAttempts((prevState) => {
                            if (prevState === 1) {
                                clearInterval(interval);
                                return resolve(false);
                            } else return prevState = prevState - 1;
                        })
                    }else  return 2;

                   
                } else if (queueDetails.apiStatus.toLowerCase() === 'failure') {
                    if (queueDetails.resBody.data.statusCode === 102) {
                        return 4;
                    }
                    return 3;
                }
            } else throw new Error();
        } catch (e) {
            console.log(e);
            return 3;
        }
    }






    return (
        <>
            {verifyOtp && <OtpVerificationModal
                loading={loading}
                cancel={() => setVerifyOtp(false)}
                resend = {() => resendOtp()}
                submit = {(otp) => onSubmit(otp)}
                wrongOtp = {wrongOtp}
                setWrongOtp = {() => setWrongOtp(false)}
            />}
            {loading && !verifyOtp && <Loader />}
            <FlowHeader page = {props.page} />
            <div className="container mt-4 text-font">
                <div className="container">
                    <div className="row">
                        <div className="col-12 header">
                            KYC Verification
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            This information will be used to process your loan application.
                        </div>
                    </div>



                    <div className="row mt-4">
                        <div className="col-12 field-headers">
                            PAN
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <TextField
                                onBlurCapture={checkIfPanValid}
                                value={pan.value}
                                disabled={true}
                                className="input disabled"
                                id="outlined-basic"
                                label=""
                                placeholder="Enter Here"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {pan && pan.verified && <CheckCircleIcon color="success" />}
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined" />

                            {pan && pan.error && <p className={`error-desc text-danger`}>{pan && pan.text}</p>}
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 field-headers">
                            Name
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <TextField value={name} disabled={true} className="input disabled" id="outlined-basic" label="" placeholder="Enter Here" variant="outlined" />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 field-headers">
                            Date of Birth
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <TextField value={dob} disabled={true} className="input disabled" id="outlined-basic" label="" placeholder="Enter Here" variant="outlined" />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 field-headers">
                            Aadhaar Number
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <TextField onBlurCapture={() => checkifAdhaarNumberIsValid()} onChange={setANumber} value={adhaar.value} className="input" id="outlined-basic" label="" placeholder="Enter Here" variant="outlined" />
                            <p className={`error-desc ${adhaar && adhaar.error ? 'text-danger' : ""}`}>{adhaar && adhaar.text ? adhaar.text : ""}</p>
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-12 field-headers">
                            Mobile Number
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <TextField onBlurCapture={() => checkIfMobileValid()} onChange={(e) => setMobileNumber(e.target.value)} value={mobile.value} className="input" id="outlined-basic" label="" placeholder="Enter Here" variant="outlined" />
                            <p className={`error-desc ${mobile && mobile.error ? 'text-danger' : ""}`}>{mobile && mobile.text ? mobile.text : ""}</p>
                        </div>
                    </div>
                    <div className="row button-container-kyc">
                        <div className="col-12">
                            <Button onClick={submit} disabled={handleButtonDisabled()} className='submit-button' variant='contained' style={{ width: "100%" }}>Submit & Next</Button>
                        </div>
                        <Footer />
                    </div>
                </div>

            </div>
        </>
    )


}

export default RouteProtector(KycVerificationPage);