import React, { useState, useContext, useEffect } from "react";
import { Modal, Button, Box } from "@mui/material";
import '../styles/persmissions.css';
import { useNavigate } from 'react-router-dom';
import { Context } from '../context/context';
import { termsAndConditions } from "../constants";
const Permissions = ({ open, close, callApi, createLead, route }) => {


    const [modalOpen, setModalOpen] = useState(true);
    const globalStates = useContext(Context);
    const [showConditions, setShowConditions] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setModalOpen(open);
    }, [open])

    const closeModal = () => {
        setModalOpen(false);
        close();
    }


    const handleClick = async () => {
        if (createLead) callApi();
        else navigate(route);
    }




    return (
        <Modal
            open={modalOpen}
            style={{ padding: '10px' }}
            onClose={() => closeModal()}
            className="dialog-text"
            // TransitionComponent={Transition}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
        >
            {
                showConditions ?
                    <Box className='perm-div perm-div-tc' >
                        <div className="modal-heading">
                            <span>Terms and Conditions</span>
                            <span onClick={() => setShowConditions(false)} className="cross">X</span>
                        </div>
                        <div className="modal-content">
                            {termsAndConditions}
                        </div>


                    </Box>
                    :
                    <Box className='perm-div' >
                        <div className="modal-heading">
                            We need your permission
                        </div>
                        <div className="modal-content modal-pointers-desc">
                            To complete your loan application journey, please provide us with the following access:
                        </div>
                        <div className="modal-content">
                            <span className="modal-pointers">1. Camera and Microphone Access:</span>
                            <div className="modal-pointers-desc">The camera and microphone access will be used to verify your Selfie Liveliness.</div>
                        </div>
                        <div className="modal-content">
                            <span className="modal-pointers">2. Location Access:</span>
                            <div className="modal-pointers-desc">
                                The location is taken to record your coordinates while you are performing this journey from a compliance point of view.
                            </div>
                        </div>
                        <div className="modal-content">
                            <span className="modal-pointers">3. Terms and Conditions:</span>
                            <div className="modal-pointers-desc">I accept the <a onClick={() => setShowConditions(true)} className="anchor">terms & conditions.</a></div>
                        </div>
                        <div className="modal-content">
                            <span className="modal-pointers">4. KYC Verification:</span>
                            <div className="modal-pointers-desc">
                                I hereby consent to verification of the information submitted by me for KYC verification including PAN verification and OTP-based Aadhaar verification.
                            </div>
                        </div>
                        <div className="modal-content">

                            <Button onClick={handleClick} style={{ backgroundColor: '#161719', color: "white", fontWeight: 600, fontSize: "16px", width: "100%" }} variant="contained">Accept All</Button>

                        </div>

                    </Box>
            }
        </Modal>
    )
}

export default Permissions;