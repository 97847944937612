import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const Main = () =>{
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const request_id= localStorage.getItem("request_id");
    const data = localStorage.getItem("data");
    return searchParams.get('request_id') && searchParams.get('data') ? <Navigate to = {`/get-started?${searchParams.toString()}`} replace/> : request_id && data ? <Navigate to = {`/get-started?request_id=${request_id}&data=${data}`} replace/> : <Navigate to = {'/error'}/>
}

export default Main;