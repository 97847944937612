import React from "react";
import { useState, useEffect } from "react";
import { Button } from "@mui/material";


const Counter = ({ wrongOtp , resend }) => {
    const [seconds, setSeconds] = useState(59);


    const resendOtp = async () => {
        setSeconds(59);
        const response = await resend();
        
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((prevTime) => {
                return prevTime > 0 ? prevTime - 1 : 0;
            });

        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    return (
        <div className="row mt-4">
            <div className="col d-flex justify-content-center">
                {wrongOtp ?
                    <div className="text-danger d-flex justify-content-center"> Incorrect OTP </div>
                    :
                    seconds == 0 ? <Button onClick={resendOtp}> Resend OTP</Button> : `Resend OTP in ${seconds} seconds` 
                }
            </div>
        </div>
    )
}


export default Counter;