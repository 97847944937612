import React from "react";
import { useState, useEffect } from "react";

const CameraCounter = (props) => {
    const [seconds, setSeconds] = useState(15);

    useEffect(() => {
        const interval = setInterval(() => {
            setSeconds((prevTime) => {
                return prevTime > 0 ? prevTime - 1 : 0;
            });

        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {
        if (!seconds) {
            props.open()
        }
    }, [seconds])

    return (
        <div className="row mt-4">
            <div className="col d-flex justify-content-center">
                {seconds &&

                    <div style={{ fontWeight: "400", fontSize: "12px" }}>
                        Auto-stopping in <span style={{ fontWeight: "700", fontSize: "12px" }}>{seconds} seconds </span>
                    </div>
                }
            </div>
        </div>
    )
}


export default CameraCounter;