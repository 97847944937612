// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.perm-div{
    background-color: white;
    border-radius: 10px;
    /* padding: 10px; */
    outline: 0;
    margin: auto;
}

.perm-div-tc{
    height:50%;
    overflow-y: scroll;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}


.cross{
    position: absolute;
    right: 10px;
}

.css-79ws1d-MuiModal-root{
    display: flex;
}


.modal-heading{
    background-color: #E5E5E8;
    color: #161719;
    border-radius: 10px 10px 0 0;
    padding: 5px;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    font-family: sans-serif;
    position: relative;
}

.modal-content{
    padding: 15px;
}

.modal-pointers{
    font-size: 12px;
    font-weight: 700;
}

.modal-pointers-desc{
    font-size: 12px;
    font-weight: 400;
}


@media (min-width:1025px){
    .perm-div{
        width: 30%;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/persmissions.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;;AAGA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,aAAa;AACjB;;;AAGA;IACI,yBAAyB;IACzB,cAAc;IACd,4BAA4B;IAC5B,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,eAAe;IACf,gBAAgB;IAChB,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;;AAGA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".perm-div{\n    background-color: white;\n    border-radius: 10px;\n    /* padding: 10px; */\n    outline: 0;\n    margin: auto;\n}\n\n.perm-div-tc{\n    height:50%;\n    overflow-y: scroll;\n    font-size: 14px;\n    font-weight: 400;\n    color: #000000;\n}\n\n\n.cross{\n    position: absolute;\n    right: 10px;\n}\n\n.css-79ws1d-MuiModal-root{\n    display: flex;\n}\n\n\n.modal-heading{\n    background-color: #E5E5E8;\n    color: #161719;\n    border-radius: 10px 10px 0 0;\n    padding: 5px;\n    display: flex;\n    justify-content: center;\n    font-size: 14px;\n    font-weight: 600;\n    font-family: sans-serif;\n    position: relative;\n}\n\n.modal-content{\n    padding: 15px;\n}\n\n.modal-pointers{\n    font-size: 12px;\n    font-weight: 700;\n}\n\n.modal-pointers-desc{\n    font-size: 12px;\n    font-weight: 400;\n}\n\n\n@media (min-width:1025px){\n    .perm-div{\n        width: 30%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
