import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { validateToken, validateRoute } from "../apis";
import { getUserToken } from "../helpers/data";
import PageLoader from '../commonComponents/pageLoader';
import axios from "axios";
const RouteProtector = (Component) => {
    const ProtectedRoute = (props) => {

        const [authenticated, setAuthenticated] = useState(false);
        const [newUser, setNewUser] = useState(false);
        const [pageNum , setPageNum] = useState(0);
        const location = useLocation();
        const navigate = useNavigate();


        const routeValidation = async (path) => {
            const searchParams = new URLSearchParams(location.search);
            const localRequestId = localStorage.getItem("request_id");
            const localData = localStorage.getItem("data");

            const request_id = localRequestId || searchParams.get("request_id");
            const data = localData || searchParams.get('data');
            if (!request_id || !data) navigate('/error');

            if (!localRequestId || !localData) {
                localStorage.setItem('request_id', request_id);
                localStorage.setItem('data', data);
            }

            const response = await validateUser(data, request_id);



            // user could not be validated
            if (!response) {
                navigate('/error');
                return;
            }

            // if landing page just return from here
            if (path === '/get-started') {
                setAuthenticated(true);
                return;
            }


            // if any other route the validate if the user is allowed to reach that route.

            const validateResponse = await validateCurrentRoute(request_id, path);


            // 3 - error occurred
            if (validateResponse && validateResponse.status === 3) {
                navigate('/error');
                return;
            } else if (validateResponse && validateResponse.status == 1) {
                setAuthenticated(true);
                return;
            } else if (validateResponse && validateResponse.status === 2) {
                navigate(validateResponse.url);
                return;
            }

        }


        const validateUser = async (data, request_id) => {
            try {
                const url = process.env.REACT_APP_SERVICE_URL + validateToken + `?encryptText=${data}`;
                const headers = {
                    "api-key": `Bearer ${request_id}`
                }

                let response = await axios.post(url, {}, { headers });
                if (response && response.data && response.data.payload) {
                    let finalPayload = {
                        ...(response.data.payload.data !== "No Lead Found" && response.data.payload.data),
                        ...response.data.payload.payload
                    }

                    finalPayload.user_id = finalPayload.user_id.user_id;
                    localStorage.setItem("user_payload", JSON.stringify(finalPayload));
                }

                if (response.data.payload.data === "No Lead Found") setNewUser(true)

                return true;

            } catch (e) {
                return false;
            }
        }


        const validateCurrentRoute = async (request_id, path) => {
            try {
                const headers = {
                    'api-key': `Bearer ${request_id}`
                }

                const token = getUserToken();
                const user = token.user_id;
                const company = token.company_id || '8782777';

                const url = process.env.REACT_APP_SERVICE_URL + validateRoute + `/${user}/${company}${path}`;

                const response = await axios(url, { headers });
                if (response && response.data && response.data.code === 101){
                    setPageNum(response.data.order);
                    return { status: 1 }
                }
                else if (response && response.data && response.data.code === 102) return { status: 2, url: response.data.redirect }
            } catch (e) {
                return { status: 3 }
            }
        }

        useEffect(() => {
            const pathName = location.pathname;
            routeValidation(pathName);
        }, [location])



        if (!authenticated) return <PageLoader />

        return <Component {...props} new={newUser} page={pageNum} />

    };

    return ProtectedRoute;
};


export default RouteProtector;

