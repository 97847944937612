
const convertDob = (dob) => {
    const date = new Date(dob);

    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    return day + "/" + (month && month <= 9 ? '0' + month : month) + "/" + year;
}

const InputFieldsJson = {

    "monthlyIncome" : {
        validationMsg: 'Monthly income must be greater than 25000 to avail a loan',
        errorHelperTextRequired : 'Monthly income should be less than or equal to 8 digits',
      },
      "email" : {
        validationMsg: 'Invalid email ID, please provide a valid email ID less than or equal to 50 characters',
      },
      "fatherName" : {
        validationMsg: "Father's name should be more than 3 characters",
      },
      "flatNo" : {
        validationMsg: 'Flat No./ House No./Building is required, please enter Flat No./ House No./Building in 40 characters',
      },
      "streetName" : {
        validationMsg: 'Street name is required, please enter street name in 40 characters',
      },
      "area" : {
        validationMsg: 'Area is required, please enter area in 40 characters',
      },
      "pincode" : {
        validationMsg: 'Please enter valid pincode',
      },
      

}



const stateConvertionMapping = {
    'JAMMU AND KASHMIR': '01',
    'HIMACHAL PRADESH': '02',
    PUNJAB: '03',
    CHANDIGARH: '04',
    UTTARANCHAL: '05',
    UTTARAKHAND: '05',
    HARYANA: '06',
    DELHI: '07',
    RAJASTHAN: '08',
    'UTTAR PRADESH': '09',
    BIHAR: '10',
    SIKKIM: '11',
    'ARUNACHAL PRADESH': '12',
    NAGALAND: '13',
    MANIPUR: '14',
    MIZORAM: '15',
    TRIPURA: '16',
    MEGHALAYA: '17',
    ASSAM: '18',
    'WEST BENGAL': '19',
    JHARKHAND: '20',
    ORISSA: '21',
    ODISHA: '21',
    CHHATTISGARH: '22',
    'MADHYA PRADESH': '23',
    GUJARAT: '24',
    'DAMAN AND DIU': '25',
    'DADRA AND NAGAR HAVELI AND DAMAN AND DIU': '26',
    'DADRA AND NAGAR HAVELI': '26',
    MAHARASHTRA: '27',
    'ANDHRA PRADESH': '28',
    KARNATAKA: '29',
    GOA: '30',
    LAKSHADWEEP: '31',
    KERALA: '32',
    'TAMIL NADU': '33',
    PONDICHERRY: '34',
    PUDUCHERRY: '34',
    'ANDAMAN AND NICOBAR ISLANDS': '35',
    'Andaman and Nicobar Islands': '35',
    TELANGANA: '36',
    'APO ADDRESS': '99',
    '01': '01',
    '02': '02',
    '03': '03',
    '04': '04',
    '05': '05',
    '06': '06',
    '07': '07',
    '08': '08',
    '09': '09',
    10: '10',
    11: '11',
    12: '12',
    13: '13',
    14: '14',
    15: '15',
    16: '16',
    17: '17',
    18: '18',
    19: '19',
    20: '20',
    21: '21',
    22: '22',
    23: '23',
    24: '24',
    25: '25',
    26: '26',
    27: '27',
    28: '28',
    29: '29',
    30: '30',
    31: '31',
    32: '32',
    33: '33',
    34: '34',
    35: '35',
    36: '36',
    99: '99',
  };
  

module.exports = {
    convertDob,
    InputFieldsJson,
    stateConvertionMapping
}