import React, { useContext, useEffect, useState } from "react";
import '../styles/dropdown.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/flowHeader.css';
import backArrow from "../images/backArrow/Group.png";
import learnMore from '../images/learnMore/Vector.png'
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../context/context";

const FlowHeader = (props) => {
    const {showProgress = true , page } = props;
    const [activeBars, setActiveBars] = useState([0, 0, 0, 0, 0, 0]);
    const [openMenu, setOpenMenu] = useState(false);
    const globalVars = useContext(Context);

    const navigate = useNavigate();


    useEffect(() =>{
        let active = activeBars;
        for(let i = 0 ; i <= page-1 ; i++){
            active[i] = 1;
        }

        setActiveBars([...active]);
    },[page])



    const decrementHeaderBar = () => {
        navigate(-1)
        return;
        
    }


    return (
        <div className="header-container">
            <div className="header-content-container d-flex flex-column justify-content-center">
                <div className="d-flex justify-content-around content-container">
                    <div style={{ marginBottom: "5px" }} className="col-1 d-flex justify-content-start">
                        <Link to={globalVars.currentRoute}><button onClick={decrementHeaderBar} style={{ background: "none", border: "none" }}><img key="backarrow-1234" className="arrow" src={backArrow} /></button></Link>
                    </div>
                    <div className="col-10 d-flex justify-content-center nav-bar-div">
                        {
                            showProgress && activeBars.length && activeBars.map((ind, index) => {
                                return (
                                    <>
                                        <div key={ind} style={{ backgroundColor: activeBars[index] ? "#0076B2" : "white" }} className="container nav-bars" />
                                    </>
                                )
                            })
                        }
                    </div>
                    <div style={{alignItems:'center' , position:'relative'}} className="col-1 d-flex justify-content-end">
                        <img onClick={() => setOpenMenu(!openMenu)} className="arrow" src={learnMore} />
                        {openMenu ?
                            <div className="drop-down p-2">
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                        <Link onClick={() => setOpenMenu(!openMenu)} to={'/customer-support'} className="drop-down-menu  drop-down-opt">Customer Support</Link>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                        <Link onClick={() => setOpenMenu(!openMenu)} to={'/faqs'} className="drop-down-menu">FAQs</Link>
                                    </div>
                                </div>
                            </div>
                            : ""
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FlowHeader; 