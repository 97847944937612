// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.text-font{
    font-family: "Inter", sans-serif;
}


.main-div{
    height: 90%;
}

.button-container-com{
    margin-bottom: 10px;
}


.header{
    font-size: 24px;
    font-weight: 600;
}

.sub-heading{
    font-size: 14px;
    font-weight: 400;
}


.default-photo-div{
    margin: auto;
    border: 1px dotted #C6C6C6;
    border-radius: 10px;
}

.photo-div{
    margin: auto;
    border: 1px dotted #C6C6C6;
    border-radius: 10px;
}

.otp-div{
    margin: auto;
}




.pointers{
    font-weight: 400;
    font-size: 12px;
    color: var(--Neutrals-Neutral-60, #767888);

}

.retake-button{
    color:#4B5563 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}


.dialog-place{
    margin-bottom: 60% !important;
}


.photo-pointers{
    font-weight: 600;
    font-size: 12px;
}

.retry{
    background-color: #D4E3FC !important;
    box-shadow: none !important;
    color: black !important;
    font-size: 16px;
    font-weight: 500;
}

`, "",{"version":3,"sources":["webpack://./src/styles/selfieDetails.css"],"names":[],"mappings":";;AAEA;IACI,gCAAgC;AACpC;;;AAGA;IACI,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;;AAGA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;;AAGA;IACI,YAAY;IACZ,0BAA0B;IAC1B,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,0BAA0B;IAC1B,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;;;;AAKA;IACI,gBAAgB;IAChB,eAAe;IACf,0CAA0C;;AAE9C;;AAEA;IACI,wBAAwB;IACxB,0BAA0B;IAC1B,2BAA2B;AAC/B;;;AAGA;IACI,6BAA6B;AACjC;;;AAGA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,oCAAoC;IACpC,2BAA2B;IAC3B,uBAAuB;IACvB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":["\n\n.text-font{\n    font-family: \"Inter\", sans-serif;\n}\n\n\n.main-div{\n    height: 90%;\n}\n\n.button-container-com{\n    margin-bottom: 10px;\n}\n\n\n.header{\n    font-size: 24px;\n    font-weight: 600;\n}\n\n.sub-heading{\n    font-size: 14px;\n    font-weight: 400;\n}\n\n\n.default-photo-div{\n    margin: auto;\n    border: 1px dotted #C6C6C6;\n    border-radius: 10px;\n}\n\n.photo-div{\n    margin: auto;\n    border: 1px dotted #C6C6C6;\n    border-radius: 10px;\n}\n\n.otp-div{\n    margin: auto;\n}\n\n\n\n\n.pointers{\n    font-weight: 400;\n    font-size: 12px;\n    color: var(--Neutrals-Neutral-60, #767888);\n\n}\n\n.retake-button{\n    color:#4B5563 !important;\n    font-size: 16px !important;\n    font-weight: 600 !important;\n}\n\n\n.dialog-place{\n    margin-bottom: 60% !important;\n}\n\n\n.photo-pointers{\n    font-weight: 600;\n    font-size: 12px;\n}\n\n.retry{\n    background-color: #D4E3FC !important;\n    box-shadow: none !important;\n    color: black !important;\n    font-size: 16px;\n    font-weight: 500;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
