export const validateToken = 'api/v1/validateToken';
export const getStatus = 'api/v1/check-status'
export const verifyOtp = 'api/v1/aadharVerify';
export const kycVerification = 'api/v1/kycVerification'
export const createLead = 'api/v1/createLead';
export const verifyLiveliness = 'api/v1/liveliness';
export const getOfferData = 'api/v1/offer';
export const offerDet = 'api/v1/loan-repayment-schedule';
export const getAScoreData = 'api/v1/ascore_request';
export const verifySelfie = 'api/v1/selfie';
export const colenderSelectorV3 = 'api/v1/co_lender_selector_v3'
export const nachUrl = 'api/v1/create-nach-subscription'
export const getNachStatusUrl = 'api/v1/get-nach-status'
export const getXmlUi = 'api/v1/render-ui';
export const getPageStatusUrl = 'api/v1/page-status';
export const updatePageStatusUrl = 'api/v1/page-status';
export const validateRoute = 'api/v1/route/validate'
export const getNextRoute = 'api/v1/route/next';
export const updatePageStatus = 'api/v1/route/update';
export const updateLeadInfo = 'api/v1/updateLead';
export const verifyBankDetails = 'api/v1/bank_verify'
export const createOrder = 'api/v1/create_order'
export const getPaymentStatus = 'api/v1/get-payment-status'
export const getZoopRequestId = 'api/v1/zoop/initialize'
export const getZoopStatus = 'api/v1/zoop/status'