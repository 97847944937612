// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-submit {
    min-width: 93%;
    bottom: 0;
    position: absolute;
}


.btn-submit-selfie{
    min-width: 93%;
    margin-top: 20px;
}

@media screen and (min-width:500px) {
    .btn-submit {
        min-width: 50%;
    }

    .btn-submit-selfie{
        min-width: 50%;
    }

}


@media screen and (min-height:800px) {
    .btn-submit-selfie{
        position: absolute;
        bottom: 0;
        margin-top: 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/styles/button.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,SAAS;IACT,kBAAkB;AACtB;;;AAGA;IACI,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI;QACI,cAAc;IAClB;;IAEA;QACI,cAAc;IAClB;;AAEJ;;;AAGA;IACI;QACI,kBAAkB;QAClB,SAAS;QACT,aAAa;IACjB;AACJ","sourcesContent":[".btn-submit {\n    min-width: 93%;\n    bottom: 0;\n    position: absolute;\n}\n\n\n.btn-submit-selfie{\n    min-width: 93%;\n    margin-top: 20px;\n}\n\n@media screen and (min-width:500px) {\n    .btn-submit {\n        min-width: 50%;\n    }\n\n    .btn-submit-selfie{\n        min-width: 50%;\n    }\n\n}\n\n\n@media screen and (min-height:800px) {\n    .btn-submit-selfie{\n        position: absolute;\n        bottom: 0;\n        margin-top: 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
