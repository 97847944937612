import React from "react";
import '../styles/fallback.css';
import logo from '../images/landingPageLogo/4457 1.png';
import { useNavigate } from 'react-router-dom';

// use Link instead of <a> below once wrapped in browser router
const ErrorPage = () => {
    const navigate = useNavigate();

    return (
        <div className="main-div fonts">
            <div className="container-div">
                <div className="image-div d-flex justify-content-center">
                    <img src={logo} />
                </div>
                <center className="mt-2">
                    <div style={{ fontWeight: "700" }} className="content d-flex justify-content-center">
                        Unable To Proceed
                    </div>
                    <div className="content d-flex justify-content-center">
                    We're experiencing a technical issue. Please try again later.
                    </div>
                </center>
                <div className="d-flex justify-content-center mt-5">
                    <a onClick={() => navigate('/')} className="footer"> Back to Home </a>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;