export const pincodeData = {
    "110001": [
        "ARYA NAGAR",
        "LAKKARPUR",
        "MALAKPUR",
        "OLD DARYA GANJ",
        "RAJEEV COLONY",
        "SYED VILLAGE",
        "VALMIKI BASTI (PUNCHKUYAN ROAD)"
    ],
    "110002": [
        "ALL KUCHAS IN DARYA GANJ",
        "SHAKUR KI DANDI (BEHIND ZAKIR HUSSAIN COLLEGE)"
    ],
    "110003": [
        "ALI GANJ"
    ],
    "110005": [
        "DERI WALAN",
        "KALYAN VAS(EXCEPT DDA FLATS)",
        "MODEL BASTI",
        "PILI BUILDING (KAROL BAGH)",
        "THAN SINGH NAGAR"
    ],
    "110006": [
        "AHATA KIDARA",
        "AJMERI GATE",
        "BALLIMARAN",
        "BARA HINDU RAO",
        "CHANDNI MAHAL",
        "CHAUHAN BANGER",
        "DARYAGANJ (BACKSIDE OF GOLCHA )",
        "FATEH PURI",
        "HAUZ QAZI",
        "IDGAH",
        "IDGAH ROAD",
        "JAMA MASJID",
        "KABIR BASTI",
        "KASABPURA",
        "KHARI BABLI",
        "RAJEEV NAGAR",
        "SAHASPUR",
        "SHAH GANJ",
        "SHRADHANANDMARG",
        "TURKMAN GATE"
    ],
    "110007": [
        "CHANDRAVAL",
        "GULAB BAGH",
        "RAM BAGH"
    ],
    "110008": [
        "ARKIMGANJ",
        "BABA FARIDPURI",
        "BABA PARLDPURI",
        "PANDAVNAGAR(OPP SHADIPUR DEPOT)",
        "SHADI KHAMPUR",
        "SHADI PUR VILLAGE",
        "SHADIPURVILLAGE"
    ],
    "110009": [
        "GOPAL VILL",
        "KHAJAN BASTI",
        "SWEEPERS CLY",
        "SWEEPERS COLONY"
    ],
    "110012": [
        "J J COLONY",
        "KADAN PAHRI",
        "TODA PUR VILLAGE",
        "TODAPUR"
    ],
    "110014": [
        "HARI ENC"
    ],
    "110015": [
        "BAKAR GARH",
        "BAKKARWALA",
        "BALI BADIYAN",
        "BASAI DARAPUR",
        "DHAKA VILLAGE"
    ],
    "110017": [
        "ARAMNAGAR (EXCEPT GOVT FLATS)",
        "CHIRAG VILLAGE",
        "HAUZ RANI",
        "KHIRKEE VILLAGE",
        "SHEKH SARAI VILLAGE"
    ],
    "110018": [
        "BABA COLONY",
        "CHAND MOHALLA",
        "CHAND NAGAR",
        "CHAUKHANDI",
        "HIND NAGAR",
        "KHAYALA VILLAGE",
        "MAJOR BHUPINDER SINGH NAGAR",
        "RAVI NAGAR",
        "WELCOME COLONY"
    ],
    "110019": [
        "LAL KUAN",
        "PRAHLADPUR",
        "TEKHAND VILLAGE",
        "Harkesh Nagar"
    ],
    "110020": [
        "OKHLA VILL",
        "SHAHEED NAGAR"
    ],
    "110024": [
        "AMAR VIHAR",
        "AMRIT PURI",
        "GAFFUR NAGAR",
        "GHUMNHERA",
        "HARSH DEV COLONY",
        "SHIV NAGAR",
        "SHIV VIHAR"
    ],
    "110025": [
        "ABUL FAZAL ENCLAVE",
        "ASOLA",
        "BARSHDEV COLONY",
        "BATLA HOUSE",
        "JAMIA NAGAR",
        "JASOLA Village (BADARPUR)",
        "MEER KHWAJA (MINTO ROAD)",
        "SARAI JULENA (DDA FLATS)",
        "SHAHIN BAG",
        "ZAKIR NAGAR"
    ],
    "110026": [
        "RAJAN PK"
    ],
    "110027": [
        "SUBHASH PARK",
        "TAKHIA"
    ],
    "110029": [
        "RAMA ENCLAVE"
    ],
    "110030": [
        "AYA NAGAR",
        "KUNDAN NAGAR",
        "LADO SARAI VILLAGE",
        "MUNIRKAVILLAGE",
        "SAILAJAB VILL",
        "SAYYAD ULAZA",
        "SULTANPUR COLONY",
        "SULTANPUR EXT",
        "Kotala",
        "Chattarpur"
    ],
    "110031": [
        "MAHILLA COLONY",
        "TEACHERS CLY"
    ],
    "110032": [
        "AMBARHAI",
        "BHAGWANPUR KHERA",
        "MAHARAM MOHALLA",
        "Shadhra",
        "Seelam Pur",
        "Maujpur",
        "Seemapuri"
    ],
    "110033": [
        "BHALSAWA DAIRY",
        "BHALSAWA VILL",
        "ISA PUR",
        "JAHANGIR PURI EXCEPT A & B BLK",
        "SANJAY ENCLAVE",
        "SARAI PEEPAL VILL"
    ],
    "110034": [
        "SHAKUR PUR JJ CLY",
        "SHAKUR VILL"
    ],
    "110035": [
        "DAYABASTI",
        "KESHAV PURI",
        "KESHU PUR",
        "PHOOL BAGH",
        "SHAHZADA BAGH",
        "SHANTI NAGAR"
    ],
    "110036": [
        "ALIPUR SHAHBAD",
        "KHADIPUR",
        "MAUZ PUR VILLAGE",
        "NANGLI POONA"
    ],
    "110037": [
        "MAHIPAL PUR VILLAGE",
        "NANGLI",
        "RANG PURI"
    ],
    "110039": [
        "KAMRUDDIN NAGAR"
    ],
    "110040": [
        "Burari",
        "Nagaloi",
        "Narela"
    ],
    "110041": [
        "GULSHAN PARK",
        "HIRAN KUDNA",
        "KALRA (Karala Village)",
        "KARALA VILLAGE",
        "MUNDKA VILL",
        "NANGLI RAJA PUR",
        "NANGLOI (ALL VILLAGE)",
        "NARESH PARK EXTN",
        "NIHAL VIHAR",
        "NILOTHI VILL",
        "NOOR PUR NAGAR",
        "PEERA GARHI VILL",
        "POOTH KALAN",
        "PRAHLAD BANGA",
        "PRAHLAD VIHAR",
        "PUTHKALA",
        "RAJIV NAGAR",
        "RAMA VIHAR",
        "RANAJEE ENCLAVE",
        "RANHOLLA (WEST DELHI)",
        "SULTANPUR",
        "VEENA ENC",
        "YADAV NAGAR",
        "YADAV PARK",
        "YADAV PK",
        "Nihal Vihar",
        "Chandar Vihar"
    ],
    "110042": [
        "BADLI VILLAGE(PROFILE & FI BASED)",
        "DAULATPUR",
        "MAKANDPUR",
        "MUKAND PUR",
        "SAHIBABAD DAULATPUR",
        "SAMAYPUR VILL",
        "SAMYAPUR",
        "SWAROOP NAGAR",
        "SHAHBAD",
        "SHAHBAD DAULATPUR",
        "SIRASPUR"
    ],
    "110043": [
        "DAURALA VILL",
        "DEEN PUR",
        "DHASA VILLAGE",
        "DICHAON KALAN",
        "JAFRABAD",
        "MITRAON KALAN",
        "PANDWALA",
        "PARAWAT",
        "Nazafgargh",
        "Vikash Nagar"
    ],
    "110044": [
        "GAUTAM PURI",
        "J P NAGAR",
        "JAITPUR",
        "PAHARI TAJPUR",
        "TUGHLAKABAD VILL",
        "Badarpur",
        "Jat Pur"
    ],
    "110045": [
        "PURAN NAGAR",
        "RAGHU NAGAR",
        "RAMA ENC",
        "SITAPURI",
        "SYNDICATE ENCLAVE",
        "ULDHANPUR",
        "ZAFRABAD"
    ],
    "110048": [
        "JAMRUDPUR VILL",
        "MASJID MOTH VILLAGE",
        "ZAMRUDPUR VILLAGE"
    ],
    "110049": [
        "SHAHPUR JAT VILL"
    ],
    "110051": [
        "KHUREJI KHAS",
        "KRISHNA COLONY",
        "MANDOLI (VILLAGE ONLY)"
    ],
    "110052": [
        "QAZIRPUR VILLAGE",
        "WAZIRPUR VILLAGE"
    ],
    "110053": [
        "GAMRI",
        "GHONDA VILLAGE",
        "GHONDALI",
        "JAFRABAD (Except DDA flats)",
        "JAGJEET NAGAR",
        "JANTA FLATS (FI AND PROFILE BASE)",
        "KARTAR NAGAR",
        "KATHWARA",
        "KHAJURI KHAS",
        "NEW SEELAMPUR",
        "NOOR E ILAHI",
        "SEELAM PUR",
        "SHASTRI NGR PUSHTA",
        "SHASTRI PARK- ALL PUSTTA",
        "USMANPUR",
        "VIJAY PARK (MAUJ PUR)"
    ],
    "110054": [
        "EAST GOKUL PUR"
    ],
    "110055": [
        "ARAM BAGH",
        "NABI KARIM"
    ],
    "110058": [
        "ASALAT PUR VILL",
        "KAKRAULA",
        "PREM NAGAR (JANAK PURI)",
        "TIHAR VILLAGE"
    ],
    "110059": [
        "BHAGWATI VIHAR",
        "BINDA PUR VILL",
        "HASTAL VILL",
        "NAWADA",
        "RAMGARH",
        "SURERA VILLAGE",
        "VIPAN GARDEN"
    ],
    "110060": [
        "DURGA COLONY",
        "RAJENDRA PARK EXTN"
    ],
    "110061": [
        "MOHAMMAD PUR",
        "MOHD PUR MAJRI"
    ],
    "110062": [
        "AMBEDKAR COLONY",
        "AMBEDKAR NAGAR",
        "DAKSHIN PURI",
        "DEOLI GEON",
        "MADAN PARK",
        "REWLA KHAN PUR",
        "SHIKARPUR",
        "TIGRI",
        "Khanpur Mandangiri",
        "Sangam Vihar"
    ],
    "110063": [
        "MADIPUR J J COLONY",
        "MADIPUR VILL",
        "MADIPUR VILLAGE",
        "NANDNAGARI VILLAGE",
        "SAYED GAON"
    ],
    "110064": [
        "BRIJ PURI"
    ],
    "110065": [
        "TAIMUR NAGAR"
    ],
    "110068": [
        "GARHI",
        "GARHI VILLAGE"
    ],
    "110070": [
        "MASAUD PUR",
        "MASOOD PUR VILL"
    ],
    "110073": [
        "JAFARPUR KALAN",
        "UJWA VILL"
    ],
    "110074": [
        "FATEHPUR BERI",
        "SADATPUR VILL"
    ],
    "110076": [
        "MADANPUR KHADDAR"
    ],
    "110081": [
        "KARALA",
        "KARALA VILLAGE",
        "MAJRI KARALA"
    ],
    "110083": [
        "MANGOLPURI"
    ],
    "110084": [
        "BURARI KUSHAK",
        "NATHU PURA",
        "SANT GARH",
        "SANT VILL",
        "TOMAR COLO NY",
        "VIDHWA COLONY"
    ],
    "110085": [
        "RAZA PUR VILL",
        "RAZA PUR VILLAGE",
        "RITHALA VILL",
        "Rohini Above Sec-14"
    ],
    "110086": [
        "BEGUM PUR",
        "BEGUMPUR VILL",
        "MANGOLPURI KALAN"
    ],
    "110087": [
        "QURESHI NAGAR"
    ],
    "110088": [
        "HAIDARPUR",
        "HAIDER PUR VILL",
        "QUILA KADAM SHARIF",
        "SHAHIPUR VILL",
        "VILL PITAM PURA(PROFILE & FI)"
    ],
    "110091": [
        "CHILLA",
        "Wazirabad",
        "Kondli",
        "Harshvihar",
        "Nandnagri"
    ],
    "110092": [
        "FAZALPUR",
        "JOHRABAD",
        "KABIR NAGAR",
        "KAMAL PARK",
        "KARDANPURI",
        "KARKARDOOMA VILLAGE",
        "KAURIA COLONY",
        "KAURTA COLONY",
        "KAUSHAK HIRANKI",
        "KUMAR COLONY",
        "LIBASPUR",
        "MAKANPUR",
        "MALIKPUR",
        "MANDOWALI FAZAL PUR",
        "MOCHI PUR",
        "MODH PUR MAJRI",
        "MOHALLA MEJRAM",
        "NAV DURGA VIHAR",
        "NAWABGANJ",
        "NAYAGAON",
        "OLD DANPUR",
        "RAJBAGH",
        "RAMESH PARK (DP)",
        "RISHAV PARK",
        "RISHAV PK",
        "SAKDAPUR",
        "SAMAS PUR",
        "SHAKKAR PUR VILLAGE"
    ],
    "110093": [
        "PRATAP NAGAR (SABOLI)",
        "PRATAP PURA ( SABOLI)",
        "SABOLI"
    ],
    "110094": [
        "CHAND BAGH (KARAWAL NAGAR)",
        "DAHIRPUR",
        "GOKUL PURI VILLAGE ONLY",
        "KARAWAL NAGAR",
        "SADATPUR",
        "SHER PUR (KARAWAL NAGAR)",
        "SONIA VIHAR",
        "WAZIR PUR VILL",
        "YAMUNA PUSHTA"
    ],
    "110095": [
        "OLD SEEMA PURI",
        "SEEMA PURI",
        "TAHARPUR",
        "VEER SINGH COLONY"
    ],
    "110096": [
        "GHAROLI DAIRY",
        "GHAROLI VILLAGE",
        "SORAS VIHAR",
        "Mandoli",
        "Khajuri",
        "Bhajanpura",
        "Ghandi Nagar"
    ],
    "121001": [
        "A C NAGAR",
        "ANANGPUR VILLAGE",
        "ANKHIR VILLAGE",
        "ARUVA VILLAGE",
        "ATALI VILLAGE",
        "AZZI COLONY",
        "BADROLA VILLAGE",
        "BHAGAT SINGH COLONY",
        "BHAKRI VILLAGE",
        "BHATIA COLONY",
        "CHANDAWLI VILLAGE",
        "DABUA COLONY",
        "DEEG VILLAGE",
        "DHAKOLA VILLAGE",
        "DHAUJ VILLAGE",
        "MACHGHAR VILLAGE",
        "MALERNA ROAD",
        "MEWALA VILLAGE",
        "MOGAR VILLAGE",
        "MOHTABAD VILLAGE",
        "NANGLA VILLAGE",
        "NEHRU COLONY",
        "PALI VILLAGE",
        "SHIKRI VILLAGE",
        "SUBHASH COLONY",
        "TIGAON",
        "UNCHAGAON"
    ],
    "121002": [
        "RAJEEV NAGAR",
        "Bali",
        "Bhagrdi",
        "Suryavihar",
        "Ac Nagar"
    ],
    "121004": [
        "ADARSH NAGAR"
    ],
    "121006": [
        "Badkal",
        "Nagla",
        "Jeevan Nagar"
    ],
    "121009": [
        "LAKKAR PUR VILLAGE"
    ],
    "121105": [
        "NANGAL VILLAGE"
    ],
    "121107": [
        "ANAND PUR VILLAGE"
    ],
    "122001": [
        "12 VISWA",
        "8 VISWA",
        "BAJGHEDA",
        "BASAI",
        "BEGAMPUR KHATOLA",
        "BHIMGARH KHERI",
        "DAULTABAD",
        "DHANWAPUR",
        "FAZILPUR",
        "GHASOLA",
        "GHATA",
        "GWAL PAHARI",
        "HARIPURA",
        "INDRA COLONY (JHARSA)",
        "ISLAMPUR",
        "JHARSA",
        "KANAI",
        "KATERPURI",
        "KHANDSA",
        "KHEDKI DHAULA",
        "KHERI",
        "MALIKHEDA",
        "MOHAMMADPUR",
        "MOHAMMADPUR JHARSA",
        "MULLAHEDA",
        "NAHARPUR",
        "NATHUPUR",
        "SAINIKHERA",
        "SAMASPUR",
        "SIHI",
        "SILOKHARA",
        "TIGRA",
        "TIKLI",
        "ULLAWAS",
        "WHOLE ABADI",
        "Jacobpura",
        "Vill Hasru Garhi",
        "Rajeev Nagar",
        "Subhash Nagar",
        "Om Nagar",
        "Shivaji Nagar"
    ],
    "122002": [
        "HARIJAN BASTI",
        "HARIJAN COLONY"
    ],
    "122005": [
        "WAJEERABAD"
    ],
    "123401": [
        "Shashtri Colony",
        "Vpo-Gokul Pur",
        "Vpo-Chandawas",
        "Vpo-Kalu Was",
        "Ramsingh Pura",
        "Shadhu Shah Nagar",
        "Gulabi Bagh Qutubpur",
        "Balmiki Basti Dharuhera Chungi",
        "Gujjar Wada",
        "Sati Colony"
    ],
    "125001": [
        "Parav Chowk",
        "Bharat Nagar",
        "Any Balmiki Mohalla",
        "Govind Nagar(Dabra Chowk)",
        "Bhiwani Rohilla ",
        "\u00a0\u00a0\u00a0 Dhani Sham Lal",
        "\u00a0\u00a0\u00a0\u00a0 Dhani Kishan Dutt",
        "\u00a0\u00a0\u00a0\u00a0\u00a0 Ambedkar Basti",
        "\u00a0\u00a0\u00a0\u00a0\u00a0 Vpo Peerawali",
        "\u00a0\u00a0\u00a0\u00a0\u00a0 Vpo Dhandor",
        "\u00a0\u00a0\u00a0\u00a0\u00a0 Vpo Kharar Alipur",
        "\u00a0\u00a0 Vpo Mangali"
    ],
    "126102": [
        "Chota Junction, Rohtak Road",
        "New Anaj Mandi Front Side, Rohtak Road",
        "Railway Junjuction,Jalal Pura Road",
        "Meet Market",
        "Chaker Road",
        "New Employies Colony",
        "Vijay Nagar (Gohana Road)",
        "Buda Baba Basti (Bhiwani Road)",
        "Jalal Pur Kalan",
        "Jalal Pur\u00a0 Khurd",
        "Vpo -Barsana",
        "Vpo- Khokhari Kheri",
        "Vpo-Barad",
        "Vpo-Giwana"
    ],
    "132001": [
        "BANSO GATE",
        "DARAR",
        "JUNDLA GATE",
        "SADAR BAZAR",
        "SHAMGARH",
        "Meerut Road- Suraj Colony",
        "Vikash Colony Kanika Vihar"
    ],
    "132103": [
        "BHARAT NAGAR",
        "BHARAT NAGAR KABRI ROAD",
        "BINJHOL",
        "BUDSHAM",
        "DHAR",
        "DHOOP SINGH NAGAR",
        "EKTA VIHAR COLONY",
        "GOS ALI SHAH COLONY",
        "HALI COLONY",
        "HARI SINGH PURA",
        "IMAM SHAIB MOHALLA",
        "JATAL",
        "KHATIK BASTI",
        "KUTANI",
        "NANGAL KHEDI",
        "RAJA KHEDI",
        "SAI COLONY",
        "SHASTRI COLONY",
        "SHIVA",
        "UGRA KHEDI",
        "VIDYA NAND COLONY",
        "VILL -UJHA",
        "VILL-DIWANA",
        "VILL-GADHI SIKANDERPUR",
        "VILL-NIJAM PUR",
        "VILL-RISALU",
        "Vikash  Nagar"
    ],
    "133302": [
        "BANGALI BASTI   KALKA"
    ],
    "134109": [
        "AMBEDKAR COLONY   PUNCHKULA",
        "AZAD COLONY",
        "BANGALI COLONY PANCHKULA",
        "KADAK MANGOLI",
        "MAZDOOR COLONY",
        "Ashiana Colony(Sec 20Pkl)",
        "Rajeev Colony(Sec 17Pkl)"
    ],
    "134114": [
        "Bhansa Tibba"
    ],
    "135001": [
        "AZAD NAGAR(MUNDA MAJRA)",
        "BALMIKI BASTI & HARIJAN BASTI",
        "GOBINDPURI & GOBINDPURA",
        "NAHARPUR & MANDOLI",
        "OLD HAMIDA(KHADDA COLONY)",
        "RAMPURA COLONY",
        "Vill- Rai Pur & Rai Pur Colony "
    ],
    "140603": [
        "NEHRU COLONY"
    ],
    "141001": [
        "AMBEDKAR NAGAR",
        "DHAKA COLONY",
        "JAWAHAR NAGAR",
        "MODEL COLONY",
        "PAKHOWAL ROAD",
        "Chauni Muhalla"
    ],
    "141002": [
        "CHURPUR ROAD",
        "CHURPUR VPO",
        "CIVIL CITY",
        "DAIRY COMPLEX",
        "PARTAP SINGH WALA",
        "PAVITAR NAGAR (HAIBOWAL)",
        "RISHI NAGAR (Y-2 BLOCK)"
    ],
    "141003": [
        "ABDULAPUR BASTI",
        "AZAD NAGAR",
        "BAROTA ROAD",
        "DABA LOHARA ROAD",
        "DABA ROAD",
        "DHANDRA VPO",
        "DHURI LINES",
        "DUGRI VPO",
        "GILL VPO",
        "JAIN COLONY (DABA ROAD)",
        "S.B.S NAGAR    DHANDRA ROAD",
        "S.B.S NAGAR    GILL ROAD",
        "SALIMAR PARK",
        "VPO JAWADHI",
        "VPO LOHARA",
        "Abdullapur Basti",
        "Area From Dugri",
        "Model Town",
        "Manak Wal",
        "Jawahar Nagar Camp",
        "Lig Flats Dugri Ph-1",
        "Crpf Flats Dugri Ph-1 (Red Flats)"
    ],
    "141007": [
        "MASKEEN NAGAR",
        "SALEM TABRI"
    ],
    "141008": [
        "AMARPURA",
        "ARYA MOHALLA",
        "AZAD NAGAR (DIV NO. 3)",
        "BAHADURK ROAD",
        "BAL SINGH NAGAR",
        "BALMIKI COLONY",
        "BALMIKI MOHALLA",
        "BASTI SHEIKH",
        "BHORA COLONY",
        "CHANDERLOK COLONY (RAHON ROAD)",
        "CHOWNI MOHALLA",
        "DHOKA MOHALLA",
        "EKTA COLONY",
        "EWS COLONY",
        "FATEHGANJ MOHALLA",
        "FAUZI MOHALLA",
        "GANDHI NAGAR",
        "GATTI JEEVA RAM",
        "GHATEE GUZRA",
        "HABIB ROAD",
        "HARGOBIND NAGAR  ",
        "INDIRA COLONY (ROHON ROAD)",
        "INDRA NAGAR",
        "ISSA NAGARI",
        "JALANDHAR BYE PASS",
        "KAKOWAL ROAD",
        "KARABARA ROAD",
        "KHURD MOHALLA",
        "KILLA MOHALLA",
        "LUV KUSH NAGAR",
        "NALI MOHALLA",
        "NEW MODHOPURI",
        "NEW SHAKTI NAGAR",
        "NOORWALA ROAD",
        "SADHU SINGH COLONY",
        "SIKANDARI ROAD KHURD MOHALLA",
        "SUBHASH NAGAR",
        "VPO MEHARBAAN",
        "GANDHI NAGAR"
    ],
    "141010": [
        "DHANDARI KHURD VPO",
        "JEEWAN NAGAR (FOCOL POINT)",
        "RAM NAGAR 33 FT ROAD",
        "SHERPUR KALAN",
        "SHERPUR KHURD",
        "Jamalpur Awana"
    ],
    "141014": [
        "Preet Nagar",
        "Gurunanak Nagar",
        "New Shimlapuri",
        "Daba Road",
        "Daba Colony",
        "Lohara"
    ],
    "141016": [
        "Lohara "
    ],
    "145001": [
        "Gandhi Mohalla",
        "Issa Nagar",
        "Gopi Pura Mohalla",
        "Housing Board Colony",
        "Dhangu Peer",
        "Dhakka Colony",
        "Mud Hut Colony",
        "Nathu Nagar",
        "Bank Colony",
        "Dheera",
        "Bharoli",
        "Shanni"
    ],
    "147001": [
        "BABAU JIWAN SINGH NAGAR",
        "BABU SINGH COLONYABLOWAL",
        "BADUNGAR",
        "BADUNGAR BASTI",
        "BARADARI ASTABAL",
        "BHARAT NAGAR NABHA ROAD",
        "DHIRU BASTI",
        "GURU NANAK NAGAR FACTORY AREA",
        "INDRA COLONY",
        "JAI JAWAN COLONY",
        "KAULI",
        "MATHURA COLONY",
        "NC COLONY MILITRY COLONY",
        "P TYPE QUARTERS MEDICAL COLLEGE",
        "RAVI DASS NAGAR",
        "SANJAY COLONY",
        "SHED KHERI",
        "TAFAZALPURA",
        "VIKAS NAGAR",
        "VIRK COLONY",
        "YADWINDERA COLONY SIRHIND ROAD"
    ],
    "160002": [
        "COLONY NO 4"
    ],
    "160014": [
        "DADU MAJRA"
    ],
    "160022": [
        "CHAMAN COLONY"
    ],
    "160023": [
        "BASKAR COLONY SEC-25 CHANDIGARH"
    ],
    "160036": [
        "38 WEST COLONY CHANDIGARH"
    ],
    "160055": [
        "AMBEDKAR COLONYSECTOR56",
        "JUJHAR NAGAR",
        "Palsora"
    ],
    "160101": [
        "BAPU DHAMPH12",
        "BAPU DHAMPH3",
        "INDIRA COLONY",
        "INDIRA COLONY OLD",
        "MAULI JAGRAN",
        "RAJIV COLONY",
        "VIKAS NAGAR",
        "Balmiki Basti Manimajra",
        "Harijan Basti Manimajra",
        "Indra Colony",
        "Kishangarh",
        "Mauli Jagran",
        "Vikas Nagar"
    ],
    "201001": [
        "ARTHLA",
        "ATOOR NANGLA FIROZ MOHANPUR",
        "BHAMETA",
        "BHATIA MORE",
        "BHOPURA",
        "CHIPYANA",
        "DASNA MASOURI",
        "DINDAYALPURI NANDGRAM",
        "GARHI",
        "GHUGNA",
        "GULDHAR",
        "HARSAUN",
        "ISLAM NAGAR",
        "JATWARA",
        "JHANDAPUR",
        "KAILA BHATTA",
        "KANAWANI",
        "KARHERA",
        "KOT GAON",
        "NASARPUR PHATAK",
        "NOOR NAGAR SIHANI",
        "PAPPU COLONY",
        "PASONDA",
        "PREM NAGAR",
        "SADIQ NAGAR SIHANI",
        "SAHIBABAD VILLAGE",
        "SHAEED NAGAR",
        "SIKROAD",
        "Loni",
        "Rajeev Colony Mohan Nagar",
        "Phaphonda",
        "Arthla",
        "Baljivihar",
        "Slam Nagar"
    ],
    "201002": [
        "SADARPUR"
    ],
    "201010": [
        "ALL LAL KUAN",
        "HINDON VIHAR",
        "KHORA COLONY   AND VILLAGE",
        "MORTI AND MORTA",
        "VILLAGE MANDOLI (DELHI)"
    ],
    "201206": [
        "BHIL AKBARPUR"
    ],
    "201301": [
        "BISHRAKH",
        "CHALERA",
        "CHAPROLA",
        "CHAURA",
        "GHEZA",
        "GHIZOOR",
        "HAROLA",
        "KAKROLA",
        "MAMURA",
        "MILAKPUR",
        "MORNA",
        "NITHARI",
        "POCKET I TO III GR. NOIDA",
        "RAJAT VIHAR",
        "SALARPUR",
        "SECTOR 82 - 107 (SHARMIK KUNJ QUARTER)",
        "SORKHA ZAHIDE"
    ],
    "201303": [
        "NANGLA",
        "SAKHIPUR"
    ],
    "201304": [
        "SARFABAD"
    ],
    "201306": [
        "HALDWANI",
        "KASNA",
        "KULESHARA",
        "SURAJPUR"
    ],
    "201307": [
        "TILPATA"
    ],
    "203207": [
        "DUJANA",
        "VEDPURA"
    ],
    "208001": [
        "ALLENGANG",
        "BADSAHI NAKA",
        "BAQAR GANJ",
        "CHAMANGANG",
        "CHOTE MIYA KA HATA",
        "FAHIMA BAD",
        "GORA KABRISTAN",
        "HUMAYUN BAGH",
        "MAQBARA",
        "NAI SADAK",
        "PECH BAGH",
        "PREM NAGAR",
        "TALAQ MOHAL"
    ],
    "208007": [
        "AHIRANA"
    ],
    "208014": [
        "GOVERDHANPURWA   KIDWAI NAGAR"
    ],
    "226002": [
        "ARJUNGANJ OGL",
        "BUTCHER MOHAL(SADAR)"
    ],
    "226003": [
        "AMBARGANJ",
        "BAZAAR KHAALA-BHADEVA",
        "BILLAUJPURA",
        "DEARI AGA MEER",
        "KASHI VIHAR(THAKUR GANJ)",
        "KASHMIRI MOHALLA",
        "KHATIKANA(QAISERBAGH)",
        "MADEHGANJ-KHADRA-SHIA COLLEGE-PURANI BANSMANDI",
        "MEHNDIGANJ(SHAHADATGANJ)",
        "MUFTIGANJ(THAKURGANJ)",
        "MUQARIMGANJ(CHOWK)RATA NALA",
        "MUSAHIBGANJ(THAKURGANJ)",
        "NAI BASTI(MURAD ALI LANE)",
        "NAKHAS-BILLOJPURA-SAHGANJ-CHAWAL WALI GALI",
        "NALLAH BEGUMGANJ(YAHIYAGANJ)",
        "RASSI BATTAN",
        "SHIA LANE(SHAHADATGANJ)-HAZRAT ALI DARGAH"
    ],
    "226004": [
        "ASTBAL(CHAARBAGH)",
        "BULAAKI ADDA",
        "CHITTWAPUR(BHUIAN & PAJAWAN)",
        "OLD HAIDERGANJ",
        "PHOOL BAGH-BAISI KI MASZID",
        "YASEENGANJ"
    ],
    "226005": [
        "BADI MASJID AREA(AZAD NAGAR-ALAMBAGH)",
        "CHOTI MASJID AREA(AZAD NAGAR-ALAMBAGH)"
    ],
    "226010": [
        "UJADIYAAON"
    ],
    "226014": [
        "GOHARI VILLAGE OGL"
    ],
    "226016": [
        "AKBARPUR",
        "TAKROHI(INDIRA NAGAR)"
    ],
    "226018": [
        "CHESIYAARI MANDI",
        "CHICK MANDI",
        "QASAAIBADA(GANESHGANJ)"
    ],
    "247667": [
        "Macchi Mohalla",
        "Mahi Gram",
        "Shekhpuri",
        "Shakti Vihar",
        "Pathar Pura"
    ],
    "248001": [
        "AZAD COLONY",
        "BADA BHARUWALA",
        "BUDHI GAON",
        "CHAMANPURI",
        "INAMULLAH BUILDING/GANDHI ROAD",
        "LOHIYA NAGAR",
        "MEHUWALA MAFI",
        "MUSLIM COLONY  LAKHI BAGH",
        "NIRANJANPUR",
        "SANJAY COLONY",
        "Clement Town- Army Area",
        "Ghari Cant - Army Area",
        "Moro Wala Turner Road"
    ],
    "248197": [
        "Sheergotia",
        "Narayanpur",
        "Fauji Matkota",
        "Preet Vihar",
        "Lok Vihar",
        "Bhadaipura"
    ],
    "282001": [
        "BASAI KHURD",
        "BEECH KA UKHARA - FATEHABAD - SHAMSHABAD ROAD",
        "KACHHI SARAI  - FATEHABAD - SHAMSHABAD ROAD",
        "KATRA RESHAM",
        "NAGLA BHAGWAN SINGH",
        "NAGLA BHURI SINGH",
        "NAGLA CHHAUA",
        "PAKKI SARAI - FATEHABAD - SHAMSHABAD ROAD"
    ],
    "282002": [
        "ALAMGANJ  - LOHAMANDI",
        "BHEM NAGAR  - LOHAMANDI",
        "TELEPADA  - LOHAMANDI"
    ],
    "282003": [
        "DHOLIKHAR - MANTOLA",
        "KASHMIRI BAZAR"
    ],
    "282005": [
        "MASTA KI BAGICHI - VIJAY NAGAR",
        "NAGLA DHANI - VIJAY NAGAR"
    ],
    "282006": [
        "NAGLA BAL CHAND - NUNHAI",
        "NAGLA DEVJEET  - NUNHAI",
        "NAI ABADI - NARAICH",
        "NAI KI SARAI - RAMBAGH",
        "RAM VIHAR - NARAICH",
        "SATI NAGAR - NARAICH",
        "SITA NAGAR INNER AREA - NARAICH"
    ],
    "282007": [
        "KACHPURA",
        "KAKHERATA VILLAGE  - SIKANDRA"
    ],
    "282010": [
        "AZAM PARA - SHAHGANJ",
        "CHILLIPARA  - SHAHGANJ",
        "HAMID NAGAR - SHAHGANJ",
        "HAVELI KABIR KHAN - NAI KI MANDI",
        "NAI KI MANDI - INNER AREA"
    ],
    "301019": [
        "Ghandhola",
        "Jhiwana",
        "Gwalda",
        "Gwalda To Tapukra Road Per Shiya-Kho Tak",
        "Khairani",
        "Khobass",
        "Musakhera",
        "Noornagar"
    ],
    "302001": [
        "BABU KA TIBARAMGUNJ BAZAR",
        "BADA PARKGHATGATE",
        "BAS BADAN PURA",
        "CHAR DARWAZA",
        "JHALUPURA",
        "KALI KA BHATTA OPPOSITERACTRANSPORT NAGAR"
    ],
    "302002": [
        "FAKIRON KI DUNGRIAMER ROAD"
    ],
    "302003": [
        "GHAT GATE",
        "HANDI PURA",
        "HAZI COLONY   RAMGARH MODE",
        "HIDA KI MORI",
        "IMAM CHOWK",
        "KAGZIWADA",
        "KARBALA",
        "MANDI KHATIKAN",
        "MOHALLA BORIYAN",
        "MOHALLA DAKOTAN",
        "MOHALLA ICHAWATAN",
        "NAWALSAHAB COLONY",
        "PAHADGANG   SURAJPOLE",
        "PANCH NEEM",
        "PANCHRANGPATTI MOHALLA",
        "PHUTTA KHURRARAMGUNJ",
        "RAM GUNJ",
        "SABIR COLONYCHAR DARWAZAGANGAPOL",
        "SADWA MODE   NAI KI THADI  IDGAHA",
        "SAEED COLONYCHAR DARWAZAGANGAPOL",
        "SAIYYED COLONY",
        "TOPKHANA HUZURIGHATGATE"
    ],
    "302004": [
        "ALL TILA  OF JAWAHAR NAGAR"
    ],
    "302006": [
        "AMA GARHTRANSPORT NAGAR",
        "BISMILLHA COLONY",
        "HARIJAN BASTIHASANPURA",
        "KACCHI BASTI HASAN PURA A"
    ],
    "302012": [
        "HARIJAN BASTI BHDLATA CINEMA",
        "LALCHANDPURA  ( NIWARU ROAD)",
        "MADINA COLONYKHO NAGORIAN"
    ],
    "302016": [
        "BHATTA BASTI",
        "KACCHI BASTISANJAY NAGAR",
        "LANKA PURI",
        "MAKKA COLONY"
    ],
    "302031": [
        "PREM NAGAR"
    ],
    "311001": [
        "Malan - Shubash Nagar",
        "Khatik Mohalla-Sanganeri Gate",
        "Khatik Mohalla-Dadabari",
        "Kawa Kheda - Backward Area",
        "Harijan Colony - Kashi Puri",
        "Gandhi Nagar- Behind Gandhi Nagar",
        "Chaprasi Colony - Backward Area",
        "Shiv Nagar - Backward Area",
        "Maruti Colony - Backward Area",
        "Guldmandi",
        "Nala Bazar - Behind Bada Mandir",
        "Bahala - Behind Bada Mandir",
        "Bhomiya Ka Rawala - Gulmandi",
        "Junawas - Some Area",
        "Khatik Mohalla - Dhanmandi",
        "Old Dhanmandi",
        "Jawahar Nagar",
        "Labour Colony",
        "Pratap Nagar",
        "Koli Mohalla",
        "Harijan Colony - R K Colony",
        "Mohammadi Colony - Shastri Nagar",
        "Bhawani Nagar",
        "Sanganeri Gate - Some Of Area Gali No 6,7 And 8",
        "Gulnagri",
        "Gulshan Nagar",
        "Haled",
        "Bholi"
    ],
    "313001": [
        "AMBAVGARH KACCHI BASTI",
        "BICHU GHATI",
        "HARBAN JI KI KHURA(ONLY KARWADI)",
        "HARIJAN BASTI ALL AREA",
        "KISHANPOLE(GOSHIYA COLONY)",
        "KISHANPOLE-OTHER THEN EXCISE OFFICE AREA",
        "KUNJARWADI",
        "LAL MAGRI KACCHI BASTI",
        "MASTAN BABA KACCHI BASTI"
    ],
    "313002": [
        "BANJARA BASTI  SEC-6",
        "KACCHI BASTI SUNDERWAS",
        "KACCHI BASTI  SEC-11",
        "KACCHI BASTI  SEC-5",
        "MACHLA MAGRA SEC-11(ONLY PAHADI AREA)"
    ],
    "324002": [
        "Opp Old Thana Vigyan Nagar Kota",
        "Sanjay Nagar Kota Jn"
    ],
    "324005": [
        "Sanjay Nagar Vigyan Nagar Kota",
        "Waqf Colony Kota"
    ],
    "324006": [
        "Harijan Basti All Parts Of Kota"
    ],
    "324007": [
        "Chatrapura Talab Vigyan Nagar"
    ],
    "324009": [
        "Bangali Colony Kota",
        "Tipta",
        "Retwali",
        "Lalburj"
    ],
    "342001": [
        "ALL INDRA GANDHI COLONY",
        "ASHAON GAYON KI PHATAK  SHIP HOUSE  BHARAT COLONY",
        "BABBA MOLLAHA",
        "BAKRA MANDI-KALLA DHUKKA",
        "BEHIND UMRAWO KHAN PETROL PUMP BAPU COLONY",
        "GUL JARPURA",
        "HADI MILL MAHVER COLONY",
        "KHATIKON KA MOHALLA",
        "LAYKAN MOHLLA",
        "MASURIY NAT BASTI",
        "MEGHWAL BASTI HARIJAN BASTI KAGA COLONY OPPOSITE SEWA SADAN ROAD",
        "NAYA TALAB KALAL COLONY KAGA KAGDI",
        "RAM BAGH KAGA COLANY NAT BASTI",
        "SINDHI SAHAB JADON KA MOHALLA",
        "SINDHIYON KA BASS",
        "SWANCHI GATE BAKRA MANDI",
        "UDAIY MANDIR",
        "UTHON KI GHATI"
    ],
    "342006": [
        "BAMBA MOHALLA",
        "GULZARPURA DHAMANPURA",
        "HARIJAN & BHIL BASTI",
        "STADIUM & DARPAN CINEMA"
    ],
    "342007": [
        "BHAGWAN MAHAVIR COLONY(HADDI MILL)",
        "SASI BASTI BADASIYA"
    ],
    "342008": [
        "KACHHI BASTI"
    ],
    "360001": [
        "THAKKAR BAPA HARIJAN VAS",
        "BHISTIWAD",
        "LILUDI WOKADI AREA",
        "LOHANAGAR MAFATIYA",
        "REFUGEE COLONY",
        "AMARJIT NAGAR",
        "BURHANI PARA",
        "MAHATMA GANDHI NAGAR KOTHARIYA"
    ],
    "360002": [
        "KHODIYAR NAGAR GONDAL ROAD"
    ],
    "360003": [
        "VALMIKI WADI",
        "CHUNARWADA",
        "CHAMADIYA PARA",
        "MALDHARI SOCIETY",
        "JANGLESHWAR / KHODIAR NAGAR",
        "NADODA NAGAR",
        "MAJOTHI NAGAR",
        "LAKHAJIRAJ SHRAMJIVI SOCIETY",
        "ROHIDASPARA",
        "GANESH NAGAR"
    ],
    "360004": [
        "GANJIWADA"
    ],
    "360007": [
        "RAIYA DHAR MAFATRIAPARA"
    ],
    "361008": [
        "BEDI",
        "MACCHARNAGAR",
        "GANDHINAGAR"
    ],
    "380001": [
        "LALDARWAJA- - THREEGATE",
        "BHADRA",
        "JAMALPUR",
        "JUMMA MASZID",
        "KALUPUR - SODAGAR NI POLE & PANCH PATTI",
        "KALUPUR TOWER",
        "PATHAR MASZID",
        "SHAHPAR ( VILLAGE)",
        "SHAHPUR PATWASHERI",
        "Dariyapur",
        "Kalupur",
        "Shahpur"
    ],
    "380004": [
        "DARIYAKHAN GUMMAT"
    ],
    "380007": [
        "CHHARA NAGAR",
        "GUPTANAGAR"
    ],
    "380028": [
        "DANI LIMDA",
        "Shahalam"
    ],
    "380051": [
        "VEJALPUR - GOKULDHAM"
    ],
    "380055": [
        "JUHAPURA"
    ],
    "380058": [
        "Daskroi"
    ],
    "380059": [
        "Daskroi"
    ],
    "380060": [
        "Daskroi"
    ],
    "380061": [
        "LAXMANGADH TEKRA- - CHANKYAPURI CROSS ROAD-GHATLODIYA",
        "JANTANAGAR - CHANAKIYAPURI"
    ],
    "382405": [
        "Narol"
    ],
    "382418": [
        "AMRAIWADI - RABARI COLONY"
    ],
    "382443": [
        "MILLATNAGAR"
    ],
    "382481": [
        "GOTA HOUSING"
    ],
    "387810": [
        "Dholka"
    ],
    "390001": [
        "BAVAMANPURA",
        "DUDHWALA MOHALLA",
        "MOGHALWADA",
        "NAWAB WADA",
        "RAENBASERA BUILDING",
        "YAKUTPURA",
        "KAHAR MOHALLA",
        "MACHHIPITH",
        "NAVAPURA- - R V DESAI ROAD"
    ],
    "390002": [
        "BHIMNATH MANDIR ROAD",
        "PARSHURAM BHATTA",
        "KAMATIPURA",
        "PENSON PURA"
    ],
    "390006": [
        "HATHIKHANA MANSURI KABRISTAN",
        "JAHANGIRPURA",
        "JUNI GHADI  - CHIPWAD",
        "FATEHPURA  - RANAVASPANJARI GAR MOHALLA",
        "MOTI-NANI  - CHIPWAD",
        "RANA VAS - NAVIDHARTI",
        "RAWCHA VAD",
        "WARSIYA - SANJAY NAGAR",
        "SWAD QUARTERS"
    ],
    "390013": [
        "TULSI WADI"
    ],
    "390016": [
        "HARIJAN VAS"
    ],
    "390018": [
        "AJABDI MILL  - SHRAMJIVI SOCIETY"
    ],
    "390019": [
        "MEMON COLONY",
        "PANIGATE AREA",
        "SULEMANI CHAWL",
        "VOHRAWAD",
        "TAIWAD",
        "TANDALJA VILLAGE",
        "VOHRA COLONY"
    ],
    "394210": [
        "KAMATI PURA",
        "LIMBAYAT",
        "LIMBAYAT-MITHIKHADI",
        "RAM RAJYA NAGAR",
        "UDHANA YARD",
        "UNN/GABHENI",
        "VIKAS COLONY"
    ],
    "395001": [
        "ADARSH PACHHAT VARG SOC",
        "KHANDERAOPURA",
        "KHARWAWAD",
        "MACHHIWAD",
        "MOMNAWAD-GOPIPURA (CITY)"
    ],
    "395002": [
        "AMBAWADI KALIPUL - SALABATPURA",
        "MALEK WADI",
        "NIRWAN AKHADO",
        "SALABATPURA",
        "SURAJ TALKIES"
    ],
    "395003": [
        "BHARIMATA",
        "HEGWADWAD VASAHAT UDHANA",
        "HODI BUNGALOW",
        "KHATKIWAD-RANI TALAO",
        "KOTSAFIL ROAD",
        "LALMIYA MAZJID",
        "MUGLISARA",
        "POLICE LINE-RAMPURA",
        "PRANAMI MANDIR-SAIYEDPURA",
        "PREMNAGAR HALPATIWASMOHMADNAGAR",
        "PUMPING",
        "RAMPURA",
        "RANI TALAO",
        "RUDARPURA-RUDARPURA (CITY)",
        "RUDERPURA",
        "SAIYEDPURA",
        "SAUDAGARWAD",
        "TUNKI-RAMPURA",
        "VARIYALI BAZAAR"
    ],
    "395004": [
        "ANJIRIA WADI",
        "B/H RAJMAHAL CINEMA",
        "BHIMNAGAR RAMDEV NAGAR VIKASNAGAR",
        "HALPATIWAS LAXMINAGAR SHIVAJI NAGAR",
        "HARIJANWAS",
        "IDGAHWAD",
        "NANI BAHUCHRAJI",
        "SHAKTI NAGR ZUPADPATTI",
        "SPINING MILL RD",
        "SUKHI NAGAR JAY BHIM SHAKTINAGAR",
        "TRIVENI SOC TRILOK SOC ANAND NAGAR",
        "WARSI TEKRO"
    ],
    "395005": [
        "BHUVNESHWARI SOC",
        "RANDER VILLAGE"
    ],
    "395006": [
        "B/SPODDAR ARC"
    ],
    "395007": [
        "AZAD NAGAR",
        "AZAD NAGAR - ALTHAN",
        "RAJIV NAGAR EKTA NAGAR",
        "TADKESHWAR NAGAR"
    ],
    "395009": [
        "BAPU NAGAR"
    ],
    "400001": [
        "MANISH MARKET",
        "MOHAMMED SHAHID MARG\u00a0"
    ],
    "400002": [
        "SUTAR CHAWL STREET\u00a0"
    ],
    "400003": [
        "DONGRI",
        "NAGDEVI STREET\u00a0",
        "SANDHURST ROAD\u00a0"
    ],
    "400004": [
        "PANJRAPOLE\u00a0"
    ],
    "400005": [
        "MUSAFIR KHANA ROAD\u00a0"
    ],
    "400008": [
        "BHENDI BAZAR\u00a0",
        "FORUS ROAD",
        "HOSPITAL LANE J J HOSPITAL",
        "KAMATHIPURA\u00a0",
        "MADANPURA",
        "NAGPADA\u00a0",
        "NATHPAI MARG\u00a0",
        "NULLBAZAR"
    ],
    "400011": [
        "AGRIPADA"
    ],
    "400015": [
        "SEWARI KOLI WADA ROAD\u00a0"
    ],
    "400016": [
        "DARGA ROAD",
        "MAHIM FISHERMAN COLONY",
        "VANJIWADI"
    ],
    "400017": [
        "DHARAVI"
    ],
    "400022": [
        "KOLIWADA",
        "MURUGAN CHAWL\u00a0"
    ],
    "400024": [
        "BELGAMI\u00a0 ROAD\u00a0"
    ],
    "400027": [
        "DAGDI CHAWL"
    ],
    "400033": [
        "GUN POWDER ROAD",
        "REAY ROAD\u00a0"
    ],
    "400037": [
        "ANTOPHILL"
    ],
    "400043": [
        "SHIVAJI NAGAR\u00a0"
    ],
    "400050": [
        "BHARAT NAGAR",
        "Bharat Nagar, Bandra"
    ],
    "400051": [
        "ALI YAVAR JANG MARG\u00a0"
    ],
    "400052": [
        "SLUM AREA OF KHAR DANDA",
        "Ghajdar Ban, Khar"
    ],
    "400053": [
        "MULLANA AZAD ROAD"
    ],
    "400056": [
        "JUHU GALLI"
    ],
    "400057": [
        "SLUM AREA OF PARK SITE COLONY\u00a0"
    ],
    "400058": [
        "MILAT NAGAR"
    ],
    "400059": [
        "MAROL PIPELINE\u00a0",
        "TEEN DONGRI\u00a0"
    ],
    "400060": [
        "DARGA ROAD"
    ],
    "400063": [
        "MAHAGIRI ROAD",
        "PANDURANGWADI\u00a0",
        "SANTOSH NAGAR\u00a0"
    ],
    "400064": [
        "MALWANI\u00a0",
        "VILLAGE AREA OF KAMOTHE VILLAGE"
    ],
    "400066": [
        "DEVIPADA",
        "KALLI GALLI",
        "SLUM AREA OF KAJUPADA"
    ],
    "400068": [
        "SLUM AREA OF RAWAL PADA"
    ],
    "400070": [
        "BAILBAZAR",
        "CST ROAD",
        "GAFOOR KHAN ESTATE",
        "JARI MARI ROAD\u00a0",
        "KASAI WADA",
        "KURLA PIPE ROAD",
        "PIPI LINE ROAD\u00a0",
        "QUERSHI NAGAR KURLA\u00a0",
        "SLUM AREA OF SION KOLIWADA",
        "TAXI COLONY",
        "ZARIMALI"
    ],
    "400071": [
        "SAHAKAR NAGAR",
        "SIDDHARTH NAGAR\u00a0"
    ],
    "400072": [
        "ASALPHA SAKINAKA",
        "SAFED POOL SAHAKAR\u00a0\u00a0"
    ],
    "400074": [
        "Thakkar Nagar, Chembur"
    ],
    "400075": [
        "Pantnagar, Ghatkoper"
    ],
    "400076": [
        "SLUM AREA OF SAKI VIHAR ROAD\u00a0"
    ],
    "400077": [
        "INDIRA NAGAR",
        "RAMABAI NAGAR\u00a0",
        "SUBHASH NAGAR\u00a0",
        "Kamraj Nagar, Ghatkoper"
    ],
    "400078": [
        "SONAPUR"
    ],
    "400079": [
        "PARK SIDE"
    ],
    "400081": [
        "SLUM AREA OF GAVANPADA GAOTHAN"
    ],
    "400083": [
        "VIKROLI VILLAGE"
    ],
    "400084": [
        "GHATAKOPER\u00a0 MANKHURD LINK ROAD\u00a0"
    ],
    "400086": [
        "RAMABAI COLONY",
        "SLUM AREA OF AMRUT NAGAR",
        "SLUM AREA OF PANT NAGAR"
    ],
    "400088": [
        "DEONAR",
        "MANKHURD\u00a0\u00a0",
        "Trombay Slum, Chembur"
    ],
    "400093": [
        "PIPE LINE ROAD\u00a0"
    ],
    "400097": [
        "BHAGAT SING NAGAR",
        "KURAR VILLAGE\u00a0",
        "KURLA VILLAGE",
        "Appa Pada, Malad"
    ],
    "400101": [
        "HANUMAN NAGAR\u00a0",
        "Hanuman Nager, Kandivali",
        "Kranti Nager, Kandivali"
    ],
    "400102": [
        "JANTA COLONY",
        "MAGAN JIVAN CHAWL",
        "MAGASWADI",
        "MEGHWADI",
        "SLUM AREA OF MAJASWADI DEPOT\u00a0"
    ],
    "400601": [
        "RABODI\u00a0"
    ],
    "400603": [
        "RABODI\u00a0"
    ],
    "400605": [
        "ATKONESHWAR NAGAR",
        "DIGHA"
    ],
    "400612": [
        "DIVA",
        "MUMBRA",
        "Kausa, Mumbra"
    ],
    "400701": [
        "RABALE"
    ],
    "400705": [
        "VILLAGE AREA OF KALAMBOLI VILLAGE"
    ],
    "401105": [
        "BHAYANDER PADA",
        "UTTAN\u00a0",
        "VILLAGE AREA OF VASAI VILLAGE"
    ],
    "401107": [
        "MIRAGAON",
        "Mira Gaon, Miraraod"
    ],
    "401201": [
        "INDUSTRIAL AREA OF SATIVALI"
    ],
    "401202": [
        "HATIMOHLA"
    ],
    "401203": [
        "ARNALA",
        "GOKHIWARE",
        "JIVDANI ROAD",
        "VILLAGE AREA OF BOLINJ"
    ],
    "401209": [
        "VILLAGE AREA OF ACHOLE ROAD",
        "VILLAGE AREA OF TULINJ ROAD",
        "Santosh Bhavan, Nallasopara"
    ],
    "410208": [
        "TALOJA VILLAGE\u00a0"
    ],
    "411002": [
        "HIRABAG ZOPADAPATTI"
    ],
    "411003": [
        "KHADKI GAWALI WADA",
        "Khadaki Bazar And Gawaliwada"
    ],
    "411004": [
        "PATIL ESTATE"
    ],
    "411006": [
        "YERWADA KAMRAJ NAGAR",
        "YERWADA LAXMI NAGAR",
        "Yerawada Nagpur Chwal And Housing Board"
    ],
    "411007": [
        "AUNDH-INDIRA VASAHAT"
    ],
    "411012": [
        "Bopodi Slum Areas"
    ],
    "411013": [
        "SLUM AREA OF VAIDUWADI"
    ],
    "411016": [
        "VADARWADI SHIVAJI NAGAR"
    ],
    "411017": [
        "PIMPRI BHAT NAGAR",
        "PIMPRI RAMABAI NAGAR"
    ],
    "411037": [
        "GULTEKADIDASS PLOT"
    ],
    "411042": [
        "KASEWADI  LOHIYA NAGAR"
    ],
    "411044": [
        "NIGADI  OTA SCHEME",
        "NIGADI VIDYA NAGARA"
    ],
    "411046": [
        "Kondhwa "
    ],
    "412216": [
        "GOSAVI VASAHAT    KAGAL"
    ],
    "413001": [
        "AMBEDKAR NAGAR",
        "BEGAM PETH",
        "FREE SETTLEMENT COLONY NO. 7",
        "JAGJIVAN RAM WASTI MODI",
        "KONAPURE CHAWL",
        "LIMAYEWADI",
        "RAMWADI",
        "SLUM AREA OF SALAGARWASTI",
        "THIREGAON FOREST OPP WADIA HOSPITAL"
    ],
    "413002": [
        "BEGAM PETH",
        "HANUMAN NAGAR",
        "KHARI BOUDI",
        "MILIND NAGAR ( BUDHWAR PETH )",
        "MUKUND NAGAR ( BUDHWAR PETH )",
        "SAMACHAR CHOWK ( DHOR GALLI)",
        "SLUM AREA OF BUDHWAR PETH",
        "SLUM AREA OF DHARAMISI LINE   OPP. S.T. STAND",
        "SLUM AREA OF SHAHIR WASTI",
        "SLUM AREA OF VIJAPUR VES (KURESHI GALLI)"
    ],
    "413003": [
        "AMBEDKAR NAGAR - BUDHWAR PETH",
        "BEDAR POOL",
        "CHAMBAR GALLI",
        "COMMUNITY DOMINATED AREA OF NAI ZINDAGI",
        "GAIBI PIR ZOPADPATTIL (HOTGI ROAD)",
        "KURBAN HUSAIN NAGAR",
        "LODHI GALLI LASHKAR",
        "MOULALI CHOWK",
        "SHASTRI NAGAR",
        "SLUM AREA OF BASWESHWAR NAGAR",
        "SLUM AREA OF KONGAD KUMBHAR GALLI",
        "SLUM AREA OF SHIVGANGA NAGAR NO. 1 & 2",
        "SLUM AREA OF SHIVGANGA NAGAR PART-1",
        "SLUM AREA OF SHIVGANGA NAGAR PART-2",
        "SLUM AREA OF SHIVGANGA NAGAR PART-3",
        "SLUM AREA OF SHIVGANGA NAGAR PART-4",
        "SLUM AREA OF SHIVGANGA NAGAR PART-5",
        "SLUM AREA OF SIDDHESHWAR NAGAR NO. 1 TO 5",
        "SLUM AREA OF SIDDHESHWAR NAGAR PART-1",
        "SLUM AREA OF SIDDHESHWAR NAGAR PART-2",
        "SLUM AREA OF SIDDHESHWAR NAGAR PART-3",
        "SLUM AREA OF SIDDHESHWAR NAGAR PART-4",
        "SLUM AREA OF SIDDHESHWAR NAGAR PART-5"
    ],
    "413004": [
        "GARIBI HATAW ZOPADPATTI NO 2",
        "NO. 2 ZOPADPATTI",
        "SLUM AREA OF PRATAP NAGAR"
    ],
    "413005": [
        "COMMUNITY DOMINATED AREA OF MUSLIM PACHHA PETH",
        "JOSHI GALLI OF RAVIWAR PETH"
    ],
    "413006": [
        "SLUM AREA OF MULEGAON TANDA",
        "SLUM AREA OF NEELAM NAGAR\u00a0"
    ],
    "413007": [
        "SLUM AREA OF PATRA TALIM"
    ],
    "413008": [
        "GHODA TANDA"
    ],
    "416002": [
        "Somwar Peth",
        "Akbar Mohalla"
    ],
    "416003": [
        "COMMUNITY DOMINATED AREA OF VICHARE MAL",
        "KAPUR VASAHAT"
    ],
    "416005": [
        "MAKADWALA VASAHAT",
        "SLUM AREA OF VIKRAM NAGAR"
    ],
    "416008": [
        "JAGRUTI NAGAR ZOPADPATTI"
    ],
    "416010": [
        "COMMUNITY DOMINATED AREA OF LAKSHTIRTH VASAHAT"
    ],
    "416012": [
        "CRUSHAR CHOWK   ZOPADPATTI.",
        "GANJI MALA",
        "INDIRA GANDHI AAWAS",
        "S.V. JOSHI NAGAR",
        "VARE VASAHAT",
        "Shivaji Peth"
    ],
    "421001": [
        "Ulhasnagar No 1, Ulhanagar"
    ],
    "421002": [
        "SUBHASH TEKDI"
    ],
    "421005": [
        "Gaikwad Pada, Ambernath"
    ],
    "421201": [
        "Khambhal Pada, Thakurli"
    ],
    "421202": [
        "LODHA HEAVEN",
        "RIVER WOOD PARK"
    ],
    "421203": [
        "RETI BUNDER"
    ],
    "421301": [
        "DUDH NAKA",
        "FISHMARKET",
        "GASSBAZAR",
        "HAJIMALANG ROAD"
    ],
    "421302": [
        "BHIWANDI",
        "Kalher, Bhiwandi"
    ],
    "421305": [
        "Tisai Gaon, Kalyan"
    ],
    "421306": [
        "Haji Malang Road Area, Kalyan"
    ],
    "422001": [
        "OLD NASIK- BAGWAN PURA",
        "OLD NASIK- CHOWK MANDAI",
        "OLD NASIK- DOODH BAJAR",
        "OLD NASIK- KATHADA",
        "OLD NASIK- KHADKALI",
        "OLD NASIK- MAHALAXMI NAGAR",
        "OLD NASIK- MOTHA RAJWADA",
        "OLD NASIK- MULTAN PURA",
        "OLD NASIK- PANCHOLI NAGAR",
        "OLD NASIK- PANCHSHIL NAGAR",
        "RAVIVAR KARANJA- BHANGI GALLI"
    ],
    "422002": [
        "SHARANPUR ROAD- KASTURBA NAGAR",
        "SHARANPUR ROAD- SIDDHARTHA NAGAR",
        "TRIMBAK ROAD- BETHEL NAGARI",
        "TRIMBAK ROAD- UTKARSHA NAGAR"
    ],
    "422003": [
        "PANCHAWATI- AVDHOOT WADI",
        "PANCHAWATI- DATTA NGAR SHANI STOP",
        "PANCHAWATI- DINDORI ROAD CANAL",
        "PANCHAWATI- HIRAWADI CANAL",
        "PANCHAWATI- LAXMAN NAGAR",
        "PANCHAWATI- MAHATMA FULE NAGAR",
        "PANCHAWATI- WAGHADI",
        "PANCHAWATI- WALMIK NAGAR"
    ],
    "422005": [
        "SAMARTH NAGAR- SANT KABIR NAGAR",
        "SAMARTH NAGAR-GAUTAM NAGAR",
        "Bhadrakali",
        "Shalimar",
        "Ganjamal",
        "Khadkali",
        "Doodh Bazar",
        "Malharkhan",
        "Phule Nagar",
        "Datta Nagar",
        "Waghadi",
        "Majurwadi",
        "Wajreshwari"
    ],
    "422006": [
        "NASIK ROAD- AMBEDKAR WADI",
        "NASIK ROAD- LIG SOCIETY (GANDHI NAGAR)",
        "NASIK ROAD- VAIDU WADI"
    ],
    "422007": [
        "SATPUR- SANTOSHI MATA NAGAR",
        "SATPUR- SWARBABA NAGAR",
        "SATPUR-KAMBLE NAGAR",
        "SATPUR-MAHADEO WADI",
        "SATPUR-RAJWADA"
    ],
    "422009": [
        "CIDCO- INDIRA GANDHI VASAHAT - SHIVAJI CHOWK",
        "CIDCO- INDIRIA NAGAR   RAJIV NAGAR ZOPAD PATTI",
        "CIDCO- LEKHA NAGAR ZOPADPATTI"
    ],
    "422010": [
        "AMBAD- GAULANE",
        "Shanti Nagar, Ambad"
    ],
    "422013": [
        "GANGAPUR RAOD- MALHAR KHAN",
        "GANGAPUR RAOD- MANGAL WADI",
        "GANGAPUR RAOD- POLICE HEAD QTR",
        "GANGAPUR RAOD- RAM NAGAR (B/H KTHM)"
    ],
    "422101": [
        "JAIL ROAD- BHIM NAGAR",
        "NASIK ROAD- BAGUL NAGAR(VIHITGAON)",
        "NASIK ROAD- DEOLALI GAON NMC QTR",
        "NASIK ROAD- DEOLALI RAJWADA",
        "NASIK ROAD- EKLAHARA SLUMS",
        "NASIK ROAD- FARNADIS WADI",
        "NASIK ROAD- GODREJ WADI",
        "NASIK ROAD- GOSAVI WADI",
        "NASIK ROAD- MALDHAKA ROAD",
        "NASIK ROAD- PANCHSHIL NAGAR",
        "NASIK ROAD- ROKDOBA WADI",
        "Bajarang Wadi, Jail Road",
        "Frandis Wadi",
        "Jagtap Mala",
        "Gosavi Wadi",
        "Ambedkar Nagar"
    ],
    "425001": [
        "Tambapur",
        "Shanipeth",
        "Shivaji Nagar",
        "Gendalal Mill",
        "Kanjarwada"
    ],
    "425002": [
        "Pimprala Hudko Colony"
    ],
    "431001": [
        "AMBEDKAR NAGAR",
        "BADA TAKIYA",
        "BARI COLONY",
        "BOKUL GUDHA",
        "BOUDH NAGAR",
        "CHISHTIYA COLONY",
        "GADI GUDAM",
        "GANDHI NAGAR",
        "GANDHINAGAR",
        "GHATI ZOPADPATTI",
        "HARSHA NAGAR",
        "HUSSAIN COLONY",
        "JAIBHIM NAGAR",
        "JASWANTPURA",
        "KABADIPURA",
        "KABIRNAGAR",
        "KAISAR COLONY",
        "KATKAT GATE",
        "KIRADPURA",
        "NEHARU NAGAR",
        "PART OF TOWNHALL",
        "RAHUL NAGAR",
        "REHMANIYA COLONY",
        "RENGTIPURA",
        "ROHIDASPURA",
        "ROSHAN GATE",
        "SAMATA NAGAR",
        "SANJAY NAGAR",
        "SANSAR NAGAR",
        "SHAH BAZAR",
        "SHAHSHOKAT COLONY",
        "SILLEKHANA",
        "SLUM AREA OF BHAUSINGPURA",
        "SLUM AREA OF JAHAGIRDAR COLONY",
        "SLUM AREA OF KHOKADPURA",
        "SLUM AREA OF LOTAKARANJA",
        "Ambedkar Nagar-Jalgaon Road",
        "Baijipura",
        "Begaumpura",
        "Chelipura",
        "Eknath Nagar Osmanpura",
        "Kailash Nagar",
        "Sanjay Nagar",
        "Misrwadi",
        "Naregaon",
        "Powerlum Midc -Chiklthna",
        "Rajnagar",
        "Mukundwadi",
        "Mukund Nagar",
        "Roahangate",
        "Katkat Gate",
        "Bhadkal Gate",
        "Sinddhi Bun-Chikalthan",
        "Heena Nagar ",
        "Sonia Nagar _Mukundwadi"
    ],
    "431003": [
        "HATTISINGPURA",
        "SIDDHARTH NAGAR",
        "Siddhrt Nagar-N12"
    ],
    "431005": [
        "PEER BAZAR ZOPADPATTI",
        "RAMA NAGAR",
        "SLUM AREA OF PEERBAZAR"
    ],
    "431136": [
        "BANEWADI",
        "GANDHI NAGAR WALUJ",
        "PHULE NAGAR PANDHARPUR",
        "SATHE NAGAR WALUJ",
        "Ekata Nagar",
        "Gangotri Park"
    ],
    "440002": [
        "Shanti Nagar"
    ],
    "440008": [
        "GANGA JAMUNA",
        "THAKKAR GRAM"
    ],
    "440009": [
        "BADA TAJBAG",
        "HASAN BAG",
        "NANDAWAN -  ZOPADPATTI"
    ],
    "440013": [
        "Gittikhadan"
    ],
    "440014": [
        "MARTIN NAGAR"
    ],
    "440017": [
        "ASI NAGAR TEKA",
        "TAJ NAGAR TEKA",
        "TIPU SULUTAN CHOWK",
        "Indora"
    ],
    "440018": [
        "BHALDARPURA",
        "GANJA KHET",
        "MOMINPURA",
        "Mominpura"
    ],
    "440026": [
        "Uppalwadi, Kamptee Road"
    ],
    "440027": [
        "TAJ  BAG MANEWADA",
        "Babulkheda"
    ],
    "440033": [
        "Pandhrabodi"
    ],
    "452001": [
        "VIJAY PALACE ( MANIK BAGH )",
        "TRANSPORT NAGAR",
        "SURAJ NAGAR",
        "SHANKARBAGH ( CHHAWANI )",
        "MAHARANA PRATAP NAGAR",
        "MAHAL KACHARI",
        "LUNIYAPURA ( JUNI INDORE )",
        "LOHA MANDI",
        "KACCHI MOHALLA",
        "JUNA SOUTH TUKOGANJ",
        "JEET NAGAR KHANDWA ROAD",
        "JEET NAGAR",
        "CHOTIGWALTOLI",
        "BLOCK NEW BASTI",
        "BAPU NAGAR ( TOWER CHOURAHA )",
        "BAPU NAGAR",
        "TRANSPORT NAGAR",
        "SHANKARBAGH ( CHHAWANI )",
        "GADI ADDA",
        "GAADI ADDA",
        "KRISHNA BAG COLONY",
        "KARAS DEV NAGAR",
        "DEV NAGAR",
        "USHA PATHAK",
        "Azad Nagar",
        "Juni Indore"
    ],
    "452002": [
        "VALMIKI NAGAR",
        "TATPATTI BAKHAL",
        "SADAR BAZAR",
        "PANCHMURTI NAGAR",
        "MOHANPURA",
        "MHOWNAKA",
        "MALI MOHALLA",
        "LOKNAYAK NAGAR",
        "LABRIYA BHERU",
        "KAGDIPURA",
        "KADABIN",
        "PANCHMURTI NAGAR",
        "DAULATGANJ",
        "BIYABANI",
        "BHISHTI MOHALLA",
        "BARAMATHA",
        "ALKAPURI",
        "ALAKPURI",
        "24 & 48 QUARTERS DUSRI PALTON",
        "SHANKAR GANJ ( JISNI )",
        "RAMGANJ JINSI",
        "RAMGANJ ( JINSI )",
        "NEW GOURI NAGAR",
        "JANTA COLONY ( BADA GANPATI )",
        "BALDA COLONY",
        "ADARSH NAGAR INDIRA NAGAR",
        "SAMAJWADI INDIRA NAGAR",
        "RAJMOHALLA HARIJAN COLONY",
        "RAJ MOHALLA ( HARIJAN COLONY )",
        "JAI BHAWANI NAGAR",
        "HARIOM NAGAR",
        "KADAV GHAT NEAR RAJWADA",
        "PINJRA BAKHAL",
        "AALAPURA",
        "ALAPURA",
        "BALAI MOHALLA",
        "BARABHAI",
        "BHEEL PALTAN",
        "Raj Mohaala"
    ],
    "452003": [
        "TAJI KUWAR MOHALLA",
        "SHIV SHAKTI NAGAR",
        "SHANKAR BAGH ( MARIMATA )",
        "PATNIPURA",
        "PARDESHIPURA",
        "PANCHSHEEL NAGAR ( MUSAKHEDI )",
        "MAHESH YADAV NAGAR",
        "LODHI MOHALLA",
        "HAMMAL COLONY",
        "GOURI SHNAKAR NAGAR",
        "DAULAT BAGH",
        "BHEEM NAGAR",
        "BHAGAT SINGH NAGAR",
        "BAPU GHANSHYAMDAS NAGAR",
        "AMAN NAGAR",
        "SHIVAJI NAGAR",
        "SHEEL NATH CAMP",
        "LODHIPURA",
        "GOVIND NAGAR KHARCHA",
        "GOVIND COLONY KHARCHA",
        "GOVIND COLONY",
        "BANGANGA",
        "VERMA MOHALLA",
        "RAJIV NAGAR",
        "LALARAM NAGAR (HARIJAN COLONY)",
        "HARIJAN COLONY",
        "LODHA COLONY",
        "SILAWAT PURA",
        "SHRADHA DHAM COLONY ( BANGANGA )",
        "SHANKAR KUMHAR KI CHAWL",
        "BANGANGA ( UNDER COLONY )",
        "GANGA BAGH COLONY ( BANGANGA )",
        "GULAB BAI KA BAGICHA",
        "JEEVAN KI PHEL",
        "KANHAIYALAL KI CHAWL",
        "KANNU PATEL KI CHAWL",
        "MALWA MILL KI CHAWL",
        "MOTILAL KI CHAWL",
        "NAGA KA KHET",
        "NARSINGH KI CHAWL",
        "NEW DURGA NAGAR ( BANGANGA )",
        "RADHAKRISHNA NAGAR ( BANGANGA )",
        "RAJKUMAR MILL CHAWL",
        "REDWAL COLONY",
        "RUSTAM KA BAGICHA",
        "SALVI BAKHAL",
        "NALAPURA",
        "Valabh Nagar"
    ],
    "452005": [
        "VISHAL NAGAR",
        "SATYA SAI BAG COLONY",
        "PUSHP VIHAR",
        "ARJUN SINGH NAGAR",
        "BABU MURAI COLONY",
        "BABU MOURAI COLONY"
    ],
    "452006": [
        "MARATHI MOHALLA",
        "Sadar Bazar"
    ],
    "452007": [
        "SHYAMACHARAN SHUKL NAGAR",
        "SHUKLA NAGAR",
        "SHRI KRISHNA COLONY",
        "NEW RAJARAM COLONY",
        "JAGJEEVAN RAM NAGAR",
        "JAGJEEVAN RAM MOHALLA",
        "GOVIND NAGAR",
        "GANAG PURI",
        "BUDDH NAGAR",
        "BHICHOLI MARDANA",
        "ARCHANA NAGAR",
        "JABRAN COLONY",
        "BHEIL COLONY",
        "BADWALI CHOWKI",
        "RAJIV GANDHI NAGAR",
        "RAJEEV GANDHI NAGAR",
        "RAJARAM COLONY",
        "MUKHERJI NAGAR",
        "BHOI MOHALLA",
        "BHAGIRATH PURA",
        "BHAT MOHALLA",
        "CHAMPABAGH",
        "GAFFUR KHAN KI BAJARIYA",
        "JUNA RISALA",
        "KABUTAR KHANA",
        "KALALI MOHALLA",
        "KAMATHIPURA",
        "KARBALA MAIDAN",
        "KASGI KA BAGICHA",
        "KATAKATPURA",
        "KATKATPURA",
        "KHASGI KA BAGICHA",
        "KHATIK MOHALLA",
        "LAXAMANJI KA BHATTA",
        "MAMAJI KA BHATTA",
        "NORTH TODA"
    ],
    "452009": [
        "VYAS PHALA",
        "SHAHI BAGH",
        "RANA COLONY",
        "RAMKRISHNA BAG COLONY",
        "RAJNAGAR",
        "NIRANJANPUR GAON",
        "NAYA PURA",
        "NAGIN NAGAR",
        "MAHAWAR NAGAR",
        "KABIT KHEDI",
        "HUKUMCHAND COLONY",
        "HARIHAR COLONY",
        "GANGABAI JOSHI COLONY",
        "GANGA NAGAR",
        "DHAR ROAD",
        "CHOTA BANGARDA",
        "SIRPUR TALAB",
        "GADRA KHEDI",
        "CHANDAN NAGAR",
        "KARMA NAGAR",
        "BHATTA EXCEPT CHAND MARI KA BHATTA",
        "EAT BHATTA",
        "GADARIYA MOHALLA",
        "MEGHDOOT NAGAR",
        "AYODHYA NAGARI"
    ],
    "452010": [
        "SAI NAGAR ( RING ROAD )",
        "MOHTA NAGAR",
        "INDIRA EKTA NAGAR ( RING ROAD )",
        "GURU NAGAR ( RING ROAD )",
        "GAJRAJ NAGAR ( RING ROAD )",
        "AZAD NAGAR",
        "SHANTI NAGAR ( RING ROAD )",
        "MAMTA COLONY",
        "KUSHWAH NAGAR",
        "CHOUHAN NAGAR ( RING ROAD )",
        "VIJAYVARGIYA NAGAR",
        "SUKLIYA KAKAD",
        "ALL BAGICHA'S",
        "ALL BHATTA'S",
        "ALL CHAWLS",
        "ALL CHAWL'S",
        "ALL PHEL'S",
        "ALL PHELS & KAKADS"
    ],
    "452012": [
        "SANJAY GANDHI NAGAR",
        "CHITAWAD KAKAD",
        "TEJPUR GADBADI",
        "SONIA GANDHI NAGAR"
    ],
    "452015": [
        "ADARSH MOULIK NAGAR",
        "AADIVASI NAGAR",
        "YADAVNANAD NAGAR",
        "YADAV NAGAR ( BANGANGA )"
    ],
    "452016": [
        "Kanadia Road"
    ],
    "452020": [
        "Kasturba Gram"
    ],
    "462001": [
        "BARKHEDA PATHANI (SLUM)",
        "UNION CARBIDE",
        "DHOBHI GHAT",
        "ALL SLUMS AREA",
        "KAJI CAMP",
        "ARIF NAGAR",
        "ANNA NAGAR",
        "PHOOTI BAGDI",
        "BARELA GAON",
        "KALA BAZAR",
        "CHATAIPURA",
        "KHATIKPURA",
        "BALAIPURA",
        "BAG UMRAO DULHA",
        "SAEED COLONY",
        "BHEEM NAGAR",
        "MUKADDAS NAGAR",
        "VALLABH NAGAR",
        "ARJUN NAGAR",
        "SUDAMA NAGAR",
        "BHIM NAGAR",
        "ARJUN NAGAR",
        "MUKADDAS NAGAR",
        "BALAIPURA",
        "BHEEM NAGAR",
        "PHOOTI BAGDI",
        "KHATIKPURA"
    ],
    "462042": [
        "Chichali Bairagarh",
        "Baba Nagar",
        "Inayatpur",
        "Gehu Kheda",
        "Banjari Basti",
        "Chuna Bhatti Basti",
        "Panchhil Nagar",
        "Rahul Pampapur",
        "Sabari Nagari",
        "Naya Basera",
        "Badganga",
        "Shyam Nagar, Bheem Nagar",
        "Bheem Nagar"
    ],
    "482001": [
        "Dhanvantri Nagar"
    ],
    "500001": [
        "FATEH SULTAN LANE",
        "MUBARAK BAZAR",
        "Dhoolpet "
    ],
    "500002": [
        "BARA GALLI",
        "CHOWK MAIDAN KHANA",
        "FATEHULLA BAIG GALLI",
        "OSMAN SAHI",
        "QUILWATH",
        "SHAMSHIR GUNJ(OUTSIDE)",
        "TADI COMPOUND",
        "TAHER",
        "THALEE MALLAD"
    ],
    "500003": [
        "BARJAWADI",
        "GULLAGUDA",
        "Bandlaguda"
    ],
    "500004": [
        "DAMDAMA GALLI"
    ],
    "500005": [
        "RASHEED COLONY",
        "SADAT NAGAR",
        "SALALA BARKAS"
    ],
    "500006": [
        "LAKDIKA ADDA"
    ],
    "500007": [
        "VADDERA BASTHI"
    ],
    "500008": [
        "ARAB BASTI(GATE)"
    ],
    "500009": [
        "AMBEDKAR HUTS"
    ],
    "500012": [
        "Hasannagar B.O"
    ],
    "500014": [
        "LAMBADI TANDA"
    ],
    "500018": [
        "DOST NOOR MOHAMMED COLONY"
    ],
    "500020": [
        "PAKEERWADA"
    ],
    "500023": [
        "ALZABRI COLONY",
        "DHOBI GHAT",
        "FATESHA NAGAR",
        "HASNABAD"
    ],
    "500024": [
        "MIYA KUNTA",
        "MOZZAMPURA",
        "SAHIFA"
    ],
    "500027": [
        "HARRAJ PENTA",
        "KHAJA GARIB NAGAR",
        "SHAMSHEEN BAGH",
        "SHAMSHEER BAGH"
    ],
    "500028": [
        "FARHA COLONY"
    ],
    "500030": [
        "WEAKER SECTION COLONY",
        "WEAKER SECTION COLONY(S R PALLI)",
        "Chandrayangutta"
    ],
    "500033": [
        "IBRAHIM BAGH"
    ],
    "500034": [
        "IBRAHIM NAGAR"
    ],
    "500036": [
        "AKBAR BAGH",
        "KALADERA",
        "VEER SINGH GALLI",
        "Falaknuma "
    ],
    "500045": [
        "Chevalla"
    ],
    "500048": [
        "THALLA BASTHI"
    ],
    "500053": [
        "GULAM MUSTAFA NAGAR",
        "Talabkatta"
    ],
    "500058": [
        "TALABKATTA"
    ],
    "500059": [
        "KALENDAR NAGAR",
        "MADARSA TAJEED",
        "MOHIN BAGH"
    ],
    "500061": [
        "DATTAR COMPOUND",
        "JOSHI COMPOUND",
        "MOHAMMED GUDA"
    ],
    "500064": [
        "MAHMOOD NAGAR"
    ],
    "500067": [
        "NAL SAB ASHOOR KHANA"
    ],
    "500068": [
        "Dattatreya Colony "
    ],
    "500081": [
        "HARIJAN BASTHI"
    ],
    "500087": [
        "Falaknuma"
    ],
    "501506": [
        "Maheshwaram"
    ],
    "505001": [
        "Shasha Mahela,Road No. 1",
        "Raziwi Chaman",
        "Butti Rajaram Colony",
        "Butti Rajaram Colony,Road No.2",
        "Subhash Nagar,Road No.2",
        "Kisan Nagar, "
    ],
    "508001": [
        "Old City",
        "Patha Bazar",
        "Panugallu",
        "Kanagal",
        "Tuppalapally",
        "Devara Konda",
        "Thanagallu",
        "Jama Masjid"
    ],
    "508207": [
        "Sunyampahad",
        "Gandhinagar",
        "Bangraugdaa"
    ],
    "508213": [
        "Psr Center",
        "Seetharampuram Bazar",
        "Rajeev Nagar",
        "Jemmi Gadda",
        "Tallagadda"
    ],
    "515001": [
        "Ambedkar Nagar",
        "Buddpa Nagar",
        "Kakkalapalli Colony",
        "Munna Nagar",
        "Stalin Nagar"
    ],
    "516001": [
        "Masapet",
        "Jaangam Colony"
    ],
    "516002": [
        "Tilak Nagar",
        "Rk Nagar",
        "Auto Ngar"
    ],
    "516003": [
        "Indira Nagar"
    ],
    "518001": [
        "Bangarupeta",
        "Budhwarapeta",
        "Old Bustand",
        "Onetown Area",
        "Osmaniya College",
        "Sharif Nagar"
    ],
    "518003": [
        "Mujafar Nagar",
        "Ntr Buildings",
        "Weaker Section Colony"
    ],
    "518501": [
        "Arjuna Peta",
        "Bonthalaveedhi",
        "Viswa Nagar",
        "Saibaba Nagar",
        "Pasula Padu"
    ],
    "520001": [
        "Lambadipet",
        "Mallikarjunapet",
        "Wynchipet"
    ],
    "520003": [
        "Pezzonipet"
    ],
    "520004": [
        "Gunadala",
        "Gandhiji Colony"
    ],
    "520010": [
        "Badava Pet",
        "Pakeer Gudem"
    ],
    "520011": [
        "Rajarajeswari Pet"
    ],
    "520012": [
        "Bhavani Puram Karakatta",
        "Kabela ",
        "Urmila Nagar"
    ],
    "520015": [
        "Payakapuram",
        "Vambay Colony"
    ],
    "522001": [
        "Anadapet"
    ],
    "522002": [
        "Donka Road"
    ],
    "522003": [
        "Chinnabazar",
        "Sangatigunta"
    ],
    "522006": [
        "Swarnabarathi Nagar",
        "Tofan Nagar"
    ],
    "522503": [
        "Errabalem",
        "Navuluru"
    ],
    "522601": [
        "Varavakatta",
        "Christian Palem",
        "Chandra Babu Naidu Colony",
        "Bc Colony",
        "Khambampalem",
        "Islampeta",
        "Venkatareddy Nagar",
        "Rehamatulla Nagar"
    ],
    "522616": [
        "Sanjeeva Nagar",
        "Thurpu Malapalle",
        "Shubhani Colony Back Side",
        "Route Dikumen Colony"
    ],
    "523001": [
        "Prakash Nagar",
        "Venkateshwara Colony",
        "Papa Colony",
        "Kapadipalem",
        "Islampeta"
    ],
    "524001": [
        "Jenda Veedhi",
        "Kothapeta",
        "Kusuma Harijanawada",
        "Mansoor Nagar",
        "Neclens Road"
    ],
    "524002": [
        "Kapadipalem"
    ],
    "524004": [
        "Chandrababu Nagar",
        "Kothur",
        "Nagamma Colony",
        "Rehmath Nagar",
        "Sarvepalli Kalava Katta",
        "Sundaraiah Colony"
    ],
    "530001": [
        "Town Area",
        "Fishing Harbour Area",
        "Town Area",
        "Kota Veedhi",
        "Bowdara Road",
        "Kobbari Thota",
        "Maharani Peta Area",
        "Chilakhapeta",
        "Maharani Peta Area",
        "Relliveedi"
    ],
    "530004": [
        "Rly Station",
        "Seva Nagar"
    ],
    "530008": [
        "Dharma Nagar Colony",
        "Kanchara Palem",
        "Kanchara Palem",
        "Jagjeevan Colony",
        "R&B",
        "Ambedkar Nagar"
    ],
    "530011": [
        "Malkapuram",
        "Kakara Lova"
    ],
    "530017": [
        "Pedwaltair",
        "Jalari Peta"
    ],
    "530027": [
        "Gopalapatnam",
        "Sc Colony",
        "Relli Colony",
        "Narava"
    ],
    "530040": [
        "Arilova",
        "Sc Colony",
        "Madhurawada",
        "S C Colony"
    ],
    "530041": [
        "Madhurawada",
        "S C Colony",
        "Ysr Nagar",
        "Marakavalasa"
    ],
    "530044": [
        "Gangavara",
        "Dibbapalem"
    ],
    "530046": [
        "Duvvada",
        "Yeduru Narava"
    ],
    "560002": [
        "D OSMAN KHAN ROAD",
        "FIREWORKS COLONY",
        "GANGAMMACHAR STREET",
        "KALASIPALYAM",
        "MOTINAGAR",
        "NEW BAMBOO BAZAR",
        "SLAUGHTER HOUSE ROAD"
    ],
    "560003": [
        "JABBAR BLOCK",
        "M D BLOCK",
        "PG HALLI MASZID 1  2 AND 3RD CROSS",
        "PIPELINE"
    ],
    "560004": [
        "KAZI STREET",
        "PARVATHIPURA"
    ],
    "560005": [
        "BSA ROAD",
        "NEHRUPURAM",
        "POTTERY ROAD"
    ],
    "560008": [
        "CORPORATION COLONY",
        "MURPHY TOWN",
        "VENKATAPPA COLONY",
        "Corporation Colony",
        "Murphy Town",
        "Venkatappa Colony"
    ],
    "560010": [
        "MURALI COLONNY",
        "Murali Colonny"
    ],
    "560011": [
        "LALBAGH SIDDAPURA",
        "Lalbagh Siddapura"
    ],
    "560014": [
        "MAMAS PALYA"
    ],
    "560016": [
        "AMBEDKAR NAGAR(SLUM)",
        "B/H SUDHEER TRANSPORT",
        "DARGA MOHALLA",
        "DASTAGIRI ROAD",
        "OPP. TIN FACTORY",
        "RAILWAY SLUM",
        "VIJANAPURA SLUM"
    ],
    "560017": [
        "ANANDA PURA",
        "ANNASANDRAPALYA SYED BLOCK",
        "ISLAMPURA",
        "JYOTHINAGAR SLUM AREA",
        "KULLAPPA COLONY",
        "SYED COLONY",
        "Syed Colony"
    ],
    "560018": [
        "AZAD NAGAR",
        "J J NAGAR",
        "M D BLK",
        "PENSIONER CLY (OLD&NEW)",
        "TIPPUNAGAR",
        "Azad Nagar",
        "J J Nagar"
    ],
    "560019": [
        "DHOBIGHAT"
    ],
    "560021": [
        "GOUTHAMNAGAR",
        "L N PURA",
        "MASZID BLK",
        "OKLIPURA",
        "SRIRAMPURA",
        "Mariyappana Palya",
        "Oklipura"
    ],
    "560022": [
        "SUBEDARPALYA"
    ],
    "560023": [
        "CHALUVAPPA GARDEN",
        "MALLIGE THOTA",
        "NETHAJI NAGAR",
        "PAPREDDYPALYA"
    ],
    "560025": [
        "MARKHAM ROAD AND CROSS"
    ],
    "560026": [
        "BYTARAYANAPURA",
        "DEEPANJALINAGAR",
        "MOMINPURA",
        "PANTRAPALYA",
        "VALMIKINAGAR"
    ],
    "560027": [
        "BADA MAKHAN (OPP URVASHI THEATRE)",
        "CKC GARDEN (SLUM)",
        "GAJENDRA NAGAR",
        "K S GARDEN(SLUM)",
        "SHIVASHIKTHI COLONY",
        "SOULAKERE"
    ],
    "560029": [
        "BISMILLANAGAR",
        "CHIKKA AUDUGODI",
        "GURAPPANAPALYA OLD AND NEW"
    ],
    "560030": [
        "GAJENDRANAGAR"
    ],
    "560032": [
        "KANAKADASA LAYOUT",
        "KAUSAR NAGAR",
        "MOTINAGAR",
        "RAHAMATH NAGAR"
    ],
    "560033": [
        "GAJENDRANAGAR"
    ],
    "560036": [
        "DEVASANDRA",
        "J C LAYOUT",
        "M V GARDEN",
        "MASZID ROAD",
        "TAMBUCHETTY PALYA"
    ],
    "560040": [
        "PIPELINE COLONY",
        "Pipeline Colony"
    ],
    "560041": [
        "CORPORATION COLONY",
        "LALBAGH SIDDAPURA",
        "S R K GARDEN",
        "TILAK NAGAR",
        "Corporation Colony",
        "Lalbagh Siddapura",
        "S R K Garden",
        "Tilak Nagar"
    ],
    "560043": [
        "ILYAS NAGAR",
        "PILLAREDDY NAGAR",
        "YASHMIN LYT",
        "Ilyas Nagar",
        "Pillareddy Nagar"
    ],
    "560045": [
        "ASHWATHNAGAR",
        "GANDHINAGAR",
        "MUSLIM CLY"
    ],
    "560046": [
        "POTTARY ROAD"
    ],
    "560047": [
        "DR. AMBEDKAR NAGAR",
        "EJIPURA VILLAGE",
        "MUSLIM COLONY",
        "NEELASANDRA",
        "RAJENDRA NAGAR",
        "ROSE GARDEN",
        "VANNARPET"
    ],
    "560049": [
        "BYADRAHALLI"
    ],
    "560050": [
        "DOBHI GHAT"
    ],
    "560051": [
        "CHANDINI CHOK",
        "CHARMINAR MASK ROAD",
        "GOVIND SHETTY COLONY",
        "J C CLY",
        "MACKAN ROAD",
        "NEHRU PURAM",
        "NEW BAMBOO BAZAR",
        "RAJIVGANDHI CLY",
        "RUSSEL MARKET",
        "SEPPINGS ROAD"
    ],
    "560053": [
        "BAKSHI GARDEN",
        "JOLLY MOHALLA"
    ],
    "560054": [
        "PIPELINE ROAD",
        "RAMACHANDRAPURAM"
    ],
    "560058": [
        "PREETHI NAGAR"
    ],
    "560061": [
        "A K CLY"
    ],
    "560066": [
        "GANDHI PURAM AND VILLAGE AREA"
    ],
    "560070": [
        "BSK SLUM",
        "YARABNAGAR"
    ],
    "560072": [
        "PAPAREDDY PALYA",
        "Papareddy Palya"
    ],
    "560075": [
        "ANANDPURAM",
        "BENNIGANAHALLI SLUM",
        "SUDHAMANAGAR",
        "Anandpuram"
    ],
    "560076": [
        "BISMILLA NAGAR",
        "MADEENA NAGAR"
    ],
    "560078": [
        "ILYAZ NAGAR",
        "MINAZ NAGAR",
        "RAJYOTHSAVA NAGAR",
        "YARABNAGAR"
    ],
    "560084": [
        "KARIYANNAPALYA (SLUM)",
        "SAIT PALYA"
    ],
    "560085": [
        "A K CLY",
        "BANASHANKARI SLUM",
        "KAVERI NAGAR",
        "YARAB NAGAR",
        "Banashankari Slum",
        "Kaveri Nagar",
        "Yarab Nagar"
    ],
    "560086": [
        "CAUVERY NAGAR KURUBARAHALLI",
        "J C NAGAR",
        "KURUBARAHALLI",
        "Cauvery Nagar Kurubarahalli",
        "J C Nagar",
        "Kurubarahalli"
    ],
    "560097": [
        "BHARATHNAGAR",
        "SINGAPURA LYT"
    ],
    "570001": [
        "BB KERI"
    ],
    "570007": [
        "BADA MAKHAN",
        "KURI MANDI",
        "MUYIM NAGAR"
    ],
    "570008": [
        "INDUSTRIAL SUBURB(AMBEDKAR COLONY)",
        "NALLUR SHED"
    ],
    "570016": [
        "JAYADEVA NAGAR"
    ],
    "570019": [
        "AZEZZ SAIT NAGAR",
        "B D COLONY",
        "K N PURA TENT ROAD",
        "SHANTHI NAGAR"
    ],
    "570021": [
        "KAILAS PURAM",
        "SECOND IDGA"
    ],
    "600001": [
        "ASRVATHAPURAM  PARRYS",
        "BEACH LANE 3RD  PARRYS",
        "BEACH LANE 4TH  PARRYS"
    ],
    "600002": [
        "AYYASAMI STREET  CHINDADARIPET",
        "BORDAR THOTAM  PUDUPET",
        "EAST KUVAM ROAD  PUDUPET",
        "L.G.ROAD  CHINDADARIPET",
        "SOUTH COOVAM ROAD  CHINDADARIPET",
        "SUPPAN STREET  CHINDADARIPET",
        "VARDHARAJ PURAM  CHINDADARIPET"
    ],
    "600010": [
        "DAVID PURAM SLUM  KILPAUK",
        "DAVID PURAM  KILPAUK",
        "GIRIDA SAMY DASPURAM SLUM  KILPAUK",
        "SAMSIPURAM SLUM  KILPAUK"
    ],
    "600011": [
        "PALANTHEOPY - SLUM BOARD  PERAMBUR"
    ],
    "600012": [
        "PULLIANTHOPE HOUSING BOARD  PERAMBUR",
        "Kannikapuram",
        "Otteri"
    ],
    "600013": [
        "ANJANAPURAM  ROYAPURAM",
        "ANJANEYA NAGAR",
        "KALMANDAPAM  JEEVA NAGAR  JOTHI NAGAR  ROYAPURAM",
        "KASI MEDY  ROYAPURAM",
        "KASI PURAM  ROYAPURAM",
        "KASIMEDU",
        "MEENAKSHIAMMAN PET  ROYAPURAM",
        "PANAMARATHOTTI  ROYAPURAM",
        "PUDHU MANAI KUPPAN  ROYAPURAM",
        "SANJEEVARAYANPET"
    ],
    "600015": [
        "Aduthotti",
        "Neruppu Medu",
        "Sp Garden"
    ],
    "600019": [
        "KASIM NAGAR  WIMCO NAGAR-THIRUVOTTIYUR"
    ],
    "600021": [
        "KALYANAPURAM MAIN ROAD    KORRUKUPET"
    ],
    "600023": [
        "PUDHU NAGAR SLUM  AYANAVARAM"
    ],
    "600037": [
        "Eri Scheme"
    ],
    "600039": [
        "BAKTHAVATSALAM COLONY",
        "CHINNAIYAN COLONY",
        "VYASAR NAGAR",
        "Vyasarpadi"
    ],
    "600072": [
        "CHATIRAM  THIRUVALLUR TO PATTABIRAM"
    ],
    "600081": [
        "CHERIAN NGR   TONDAIARPET",
        "KARUNANIDHI 1ST ST  TONDAIARPET",
        "KARUNANIDHI 2ND ST  TONDAIARPET",
        "KARUNANIDHI 3RD ST  TONDAIARPET",
        "MARKETPURAM ST  TONDAIARPET",
        "PRESS QUARTERS  TONDAIARPET"
    ],
    "600096": [
        "TAMILAR ELANGAI KUDIYURUPPU  PERUNGUDI"
    ],
    "600097": [
        "Kannagi Nagar"
    ],
    "600099": [
        "Kolathur"
    ],
    "600106": [
        "T P CHATRAM"
    ],
    "600112": [
        "SALT COTAURS",
        "THATTANKULAM",
        "Choolai"
    ],
    "602025": [
        "KANNIKAPURAM SLUM  THIRUVALLUR TO THIRUNIRAUR",
        "RAMADASSPURAM  THIRUVALLUR TO THIRUNIRAUR",
        "THOZHUR KUPPAM  THIRUVALLURE TO MTH ROAD",
        "THOZHUR  THIRUVALLURE TO MTH ROAD",
        "THRUVUR COLONY  THIRUVALLURE TO MTH ROAD",
        "THRUVUR  THIRUVALLURE TO MTH ROAD",
        "Tirur",
        "Sevvapet",
        "Aranvoyal",
        "Thozhur"
    ],
    "613004": [
        "Jabamalaipuram"
    ],
    "613602": [
        "Budhalur"
    ],
    "624005": [
        "Balakrishna Puram",
        "Nandavana Patti"
    ],
    "625001": [
        "THIDEER NAGAR",
        "Keerathurai",
        "Thideer Nagar"
    ],
    "625002": [
        "CHINNCHOKKIKULAM ANNANAGAR",
        "Meenambalpuram",
        "Narimedu"
    ],
    "625003": [
        "MUNICIPLE COLONY MKPURAM"
    ],
    "625007": [
        "MATHOOR",
        "SAMBAM KULAM PUDUR"
    ],
    "625011": [
        "CORPORATION COLONY APK ROAD",
        "KARADIKAL",
        "Subramaniyapuram"
    ],
    "625016": [
        "Karimedu"
    ],
    "625022": [
        "PERUNGUDI AMBEDHGAR NAGAR",
        "VALAYANKULAM"
    ],
    "625107": [
        "CEYLON COLONY TIRUVATHAVUR",
        "Uthangudi"
    ],
    "625214": [
        "SHOLAVANDAN"
    ],
    "625535": [
        "Uthapuram"
    ],
    "625602": [
        "S.Paraipatti",
        "Sorakkapati",
        "Chettiyankottai",
        "Devathanapatti"
    ],
    "625703": [
        "Peraiyur"
    ],
    "627005": [
        "MELAPALAYAM"
    ],
    "627006": [
        "PATTAPATHU",
        "VAYALTHERU",
        "Karuppan Durai"
    ],
    "627356": [
        "Keelanatham"
    ],
    "627358": [
        "Ramaiyanpatti"
    ],
    "627853": [
        "SIVALARKULAM"
    ],
    "627951": [
        "DEVARKULAM"
    ],
    "632001": [
        "Kaspa",
        "R.N Palayam",
        "Old Town",
        "Sankaranpalayam",
        "Vasanthapuram",
        "Salavanpet"
    ],
    "632006": [
        "Viruthumpet",
        "Kalinjur",
        "Old Katpadi"
    ],
    "632008": [
        "Mullipalayam",
        "Shenbakkam",
        "Konavattam",
        "Karugamputhur"
    ],
    "632506": [
        "Kalvai"
    ],
    "635109": [
        "Ramnagar",
        "Pannapalli",
        "Anna Nagar",
        "Arasanetty"
    ],
    "636001": [
        "FORT(ANNANAGER)",
        "Old Bustand -Anna Nagar"
    ],
    "636003": [
        "CARPET STREET(AMMAPET)",
        "CEYLON COLONY",
        "NANJAMPATTY"
    ],
    "636004": [
        "ALAGAPURAM (SOUTH)",
        "South Alazhapuram"
    ],
    "636006": [
        "AMBEDKAR NAGAR",
        "DADAGAPATTI(PERIYAR HOUSEING BOARD)",
        "MUNICIPAL CORPORATION COLONY",
        "PERIYAR HOUSING UNIT"
    ],
    "636007": [
        "JOHNSONPET",
        "KALLAKUTHU",
        "MARKAPANDU STREET",
        "Hasthampatty",
        "Johnsonpet",
        "Manakadu"
    ],
    "636008": [
        "GANDHI NAGAR"
    ],
    "636015": [
        "Kitchipalayam"
    ],
    "636016": [
        "SOUTH ALAGAPURAM"
    ],
    "638001": [
        "PERIYAR NAGAR E BLOCK",
        "Periyar Nagar E Block"
    ],
    "638003": [
        "ALAGESWARAN NAGAR - HOUSING UNIT",
        "MARAPLAM",
        "Alageswaran Nagar",
        "Maraplam"
    ],
    "638301": [
        "MEKKAN STEET(BHAVANI)",
        "Mekkan Steet Bhavani"
    ],
    "639115": [
        "Elamanur",
        "Kodiyalam"
    ],
    "641001": [
        "ALLAMEEN COLONY",
        "CMC COLONY",
        "KARUMBUKADAI",
        "KEERAIKARA STREET",
        "KOTTAIMEDU",
        "UKKADAM - ALL AMEIN COLONY",
        "UKKADAM - KEMPATTY COLONY",
        "UKKADAM - KOTA MEDUFORT",
        "UKKADAM - UKKADAMCMC COLONY",
        "Townhall",
        "Ukkadam"
    ],
    "641002": [
        "GANDHIPARK - MUTHUANNAN KULAM"
    ],
    "641005": [
        "SINGANALLUR - KALLIMADAI"
    ],
    "641006": [
        "GANAPATHY - MORE MARKET",
        "Ganapathy"
    ],
    "641023": [
        "PODANUR - 20 BLOCK",
        "PODANUR - KURCHISOUTH",
        "PODANUR - RAUOOTHAR ST",
        "Podanur"
    ],
    "641027": [
        "RATHINAPURAI - KANNAPPAN NAGAR",
        "SIVAANANDHA NAGAR - ANNA NEW LINE",
        "Rathinapuri"
    ],
    "641028": [
        "PULIYAKULAM - EARI MEDU",
        "SOWRIPALAYAM - KAVITHNAIDU ST"
    ],
    "641037": [
        "Pappanaickenpalayam"
    ],
    "641045": [
        "RAMANATHPURAM - TIRUMAL STREET",
        "Ramanathapuram"
    ],
    "641201": [
        "CHEETAIPALAYAM - PERIYAR NAGAR"
    ],
    "641601": [
        "Krishna Nagar"
    ],
    "641603": [
        "Samundipuram"
    ],
    "641604": [
        "Kvr Nagar",
        "Rve Nagar South"
    ],
    "641605": [
        "Perunthozhuvu"
    ],
    "641606": [
        "Mudalipalayam",
        "Nachipalayam"
    ],
    "641652": [
        "Anupparpalayam"
    ],
    "695001": [
        "RAJAJI NAGAR(CHENKAL CHOOLA)   MOSQU COLONY"
    ],
    "695008": [
        "ALL AREA   BEEMAPALLY",
        "BANGALDESH COLONY"
    ],
    "700006": [
        "BENIATOLLA",
        "GOABAGAN LANE",
        "GOABAGAN STREET",
        "JORABAGAN",
        "SONAGACHI",
        "SONAGACHI LANE"
    ],
    "700009": [
        "BADU BAGAN BASTY",
        "RAJABAZAR",
        "Rajabajar"
    ],
    "700019": [
        "PAKISTANI MARKET PLACE"
    ],
    "700023": [
        "DENT MISSION ROW",
        "FANCY MARKET",
        "METIABRUZ",
        "MOMINPORE",
        "Kidderpore"
    ],
    "700039": [
        "KUSTIA",
        "Topsia"
    ],
    "700046": [
        "Govinda Khattick Rd"
    ],
    "700140": [
        "Bata Nagar"
    ],
    "711101": [
        "BELLIOUS ROAD",
        "BELLIUS LANE",
        "PILKHANA",
        "Pilkhana"
    ],
    "711102": [
        "KAZIPARA LANE"
    ],
    "732101": [
        "Puratoli Quarter Line",
        "8 Mile",
        "Puratoli Quarter Line",
        "8 Mile"
    ],
    "732102": [
        "Town Rail Colony",
        "Town Rail Colony"
    ],
    "732206": [
        "Sujapur",
        "Sujapur"
    ],
    "751002": [
        "BADUSAHI",
        "KAPILESWARVILLAGE",
        "OLD TOWN   BADU SAHI",
        "OLD TOWN   MALIA SAHI",
        "OLDTOWN  MALASAHI",
        "SISUPLA"
    ],
    "751003": [
        "BHARAT PUR   JOKANANDI BASTI"
    ],
    "751006": [
        "JHARPADAVILLAGE",
        "LAXMISAGARVILLAGE"
    ],
    "751019": [
        "DELANGA VILLAGE",
        "JADUPUR VILLAGE",
        "PATRAPADA VILLAGE"
    ],
    "751024": [
        "KALARAHANGA"
    ],
    "752055": [
        "GURUJANGA",
        "TOTAPADA"
    ],
    "753001": [
        "BAUNSA SAHI",
        "DARJI SAHI",
        "HARI POKARI",
        "MACHUA BAZAR",
        "SEKH BAZAR",
        "Sankarpur"
    ],
    "753004": [
        "OMP BATELIAN",
        "TALA SAHI",
        "THATHARIA SAHI",
        "Talasahi Chalganja",
        "Thatrisahi Chalganja",
        "Aprnanagar Chalganja",
        "Mathasahi Chalganja"
    ],
    "756002": [
        "Purana Basalore"
    ],
    "781012": [
        "Garigain"
    ],
    "781102": [
        "Dampur"
    ],
    "781354": [
        "Dhapargaon",
        "Tarani"
    ],
    "800002": [
        "Deur"
    ],
    "800007": [
        "Alamgunj"
    ],
    "800008": [
        "Malsalami",
        "Sultan Gunj"
    ],
    "800014": [
        "Samanpura"
    ],
    "801503": [
        "Danapur"
    ],
    "834001": [
        "AZAD BASTI",
        "DHURWA DAM SIDE",
        "HINDPIRI",
        "KARBALA CHOWK"
    ],
    "834002": [
        "MANI TOLA"
    ],
    "842001": [
        "Paaru"
    ],
    "843105": [
        "Sakra"
    ],
    "843125": [
        "Sahebganj"
    ]
}


